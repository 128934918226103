import { MasterData } from '../../../../types/types'
import { GetAllPlansResponse, InsurancePlanDetail, PlanDetailById, PlanInfoById } from '../../services/plan/types'

export enum BannerType {
  Recommend = 'recommend',
  GreatValue = 'greatValue',
  Popular = 'popular',
  None = ''
}

export interface PreviewPlan extends Partial<PlanDetail> {
  logoImageUrl: string
  companyName: string
  lifeInsurance: string
  accidentalDeathAndDismembermentIndemnity: string
  permanentDisabilityIndemnityTotal: string
  hospitalAndBoardPerDay: string
  icuCostPerDay: string
  maximumDaysForHospitalIPD: string
  maximumDaysForICURoom: string
  nursingFees: string
  generalHospitalExpenses: string
  ambulanceFees: string
  opdFollowUpTreatmentAfterIPDStay: string
  surgeryFee: string
  physicianHospitalVisitFee: string
  accidentCostForOPDPerAccident: string
  durationAfterOPDTreatment: string
  specialistConsultationFee: string
  hbIncentiveBenefit: string
  maximumHBIncentiveBenefits: string
  opdTreatingCost: string
  dentalCost: string
}

interface EditState {
  insuranceInfo: boolean
  planType: boolean
  bannerType: boolean
  specialCustomers: boolean
}

interface PlanFilterValue {
  search: string
  company: string
  status: string
  specialLabel: string
  pageSize: string
  page: string
}

export interface PlanState {
  generalInfoEditState: EditState
  isPreviewPlanOpen: boolean
  currentPlan: string
  isPlanDetailOpen: boolean
  planTypes: string[]
  bannerType: BannerType
  specialCustomers: { id: string; name: string }[]
  planFile: File | null
  previewPlan: PreviewPlan
  isPlanActive: boolean
  insurancePlan?: InsurancePlanDetail
  planFilterValues: PlanFilterValue
  // Stores
  fetchedPlans?: GetAllPlansResponse
  fetchedPlanInfo?: PlanInfoById
  fetchedPlanDetail?: PlanDetailById
  timeStampData?: {
    createdBy: string
    createdAt: string
    updatedBy: string
    updatedAt: string
  }
  insuranceTypesMasterData: MasterData[]
}
