import { Box, BoxProps, FormControlLabel, Radio, RadioProps, styled } from '@mui/material'
import React from 'react'

import { COLORS, COLORS_THEME } from '../../../constants/colors'
import { fontSize } from '../../../themes/themes'

interface RadioButtonProps {
  label: string
  value: string
  onClick: () => void
  currentValue: string
  textSize?: string
  textColor?: string
  fontWeight?: number
  fullWidth?: boolean
  noBorder?: boolean
  isDisabled?: boolean
  selectedRadioColor?: string
  radioBorder?: boolean
}

interface StyledBoxProps extends BoxProps {
  isSelected: boolean
  noBorder: boolean
  selectedRadioColor: string
  label: string
}

interface StyledRadioProps extends RadioProps {
  textSize: string
  fontWeight: number
  textColor: string
  radioBorder: boolean
}

const RadioButton = ({
  label,
  value,
  onClick,
  currentValue,
  fullWidth,
  noBorder = false,
  textSize = fontSize.h5,
  isDisabled = false,
  selectedRadioColor = COLORS_THEME.primary,
  radioBorder = false,
  fontWeight = 400,
  textColor = 'black'
}: RadioButtonProps) => {
  const isSelected = currentValue === value
  return (
    <StyledBox
      isSelected={isSelected}
      width={fullWidth ? '100%' : 'auto'}
      noBorder={noBorder}
      selectedRadioColor={selectedRadioColor}
      label={label}
    >
      <FormControlLabel
        label={label}
        checked={isSelected}
        disabled={isDisabled}
        control={
          <StyledRadio
            textSize={textSize}
            name={label}
            value={value}
            onClick={onClick}
            disabled={isDisabled}
            fontWeight={fontWeight}
            textColor={textColor}
            radioBorder={radioBorder}
          />
        }
      />
    </StyledBox>
  )
}

const StyledBox = styled(Box, {
  shouldForwardProp: (props) => !['isSelected', 'noBorder', 'selectedRadioColor', 'label'].includes(props as string)
})<StyledBoxProps>(({ theme, isSelected, noBorder, selectedRadioColor, label }) => ({
  border: noBorder ? 'none' : `1px solid ${isSelected ? theme.palette.primary.main : COLORS.grey300}`,
  textTransform: 'none',
  borderRadius: theme.spacing(2),
  padding: noBorder ? theme.spacing(0) : theme.spacing(2, 4),
  cursor: 'pointer',
  '& .MuiFormControlLabel-root': {
    marginRight: label ? theme.spacing(4) : theme.spacing(0)
  },
  '& .MuiFormControlLabel-root .MuiRadio-root span .MuiSvgIcon-root': {
    color: isSelected ? selectedRadioColor : COLORS.grey300
  }
}))

export const StyledRadio = styled(Radio, {
  shouldForwardProp: (props) => !['textSize', 'fontWeight', 'textColor', 'radioBorder'].includes(props as string)
})<StyledRadioProps>(({ theme, textSize, fontWeight, textColor, radioBorder }) => ({
  '&.MuiRadio-root': {
    padding: 0,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    whiteSpace: 'nowrap',
    border: radioBorder ? `1px solid ${COLORS.grey300}` : 'none'
  },
  '&.MuiRadio-root:hover': {
    backgroundColor: 'transparent'
  },
  '& .MuiTouchRipple-root, & .MuiTouchRipple-root:hover': {
    width: theme.spacing(6),
    height: theme.spacing(6)
  },
  '& + .MuiTypography-root': {
    color: textColor,
    fontSize: textSize,
    fontWeight: fontWeight,
    whiteSpace: 'nowrap',
    userSelect: 'none'
  }
}))

export default RadioButton
