import { createAsyncThunk } from '@reduxjs/toolkit'

import { CUSTOMER_URLS } from '../../../../configs/api'
import { REQUEST_OPTIONS } from '../../../../constants/request-options'
import { AxiosService } from '../../../../services/api'
import { createThunkName } from '../../../../utils/createThunkname'
import { saveData } from '../../../../utils/downloadFile'
import {
  CreateCustomerDto,
  ExportCustomerQueryParams,
  GetAllCustomerQueryParams,
  GetAllCustomerResponse,
  GetCustomerCompanyNameResponse,
  GetCustomerMasterDataQueryParams,
  GetCustomerMasterDataResponse,
  GetCustomerResponse,
  GetCustomerTimeStampDataResponse,
  GetDistrictMasterDataQueryParams,
  GetDistrictMasterDataResponse,
  GetInsuranceCompaniesResponse,
  GetProvinceMasterDataResponse,
  GetRegistrationTypeMasterDataResponse,
  GetSelectYearResponse,
  GetSubDistrictMasterDataQueryParams,
  GetSubDistrictMasterDataResponse,
  GetYearMasterDataResponse,
  UpdateCustomerAddressDto,
  UpdateCustomerInfoDto,
  UpdateCustomerNoteDto,
  UpdateCustomerStatusDto
} from './types'

const PREFIX = 'company-info'

export const CompanyInfoService = {
  getAllCustomerAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getAllCustomer'),
    async (queryParams: GetAllCustomerQueryParams) => {
      try {
        const result = await AxiosService.get<GetAllCustomerResponse>(CUSTOMER_URLS.getAllCustomer, {
          params: queryParams
        })
        return result.data
      } catch (error) {
        throw error
      }
    }
  ),
  createCustomer: async (dto: CreateCustomerDto) => {
    try {
      await AxiosService.post(CUSTOMER_URLS.createCustomer, dto)
    } catch (error) {
      throw error
    }
  },
  exportCustomer: async (queryParams: ExportCustomerQueryParams) => {
    try {
      const result = await AxiosService.get(CUSTOMER_URLS.exportCustomer, {
        ...REQUEST_OPTIONS,
        params: queryParams
      })
      saveData(result, result.request.getResponseHeader('Content-Disposition'))
    } catch (error) {
      throw error
    }
  },
  updateCustomerStatus: async (dto: UpdateCustomerStatusDto) => {
    try {
      await AxiosService.put(CUSTOMER_URLS.updateCustomerStatus, dto)
    } catch (error) {
      throw error
    }
  },
  getCustomerCompanyNameAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getCustomerCompanyName'),
    async (customerId: string) => {
      try {
        const result = await AxiosService.get<GetCustomerCompanyNameResponse>(
          CUSTOMER_URLS.getCustomerCompanyName(customerId)
        )
        return result.data.customerCompanyName
      } catch (error) {
        throw error
      }
    }
  ),
  getSelectYearAsyncThunk: createAsyncThunk(createThunkName(PREFIX, 'getSelectYear'), async () => {
    try {
      const result = await AxiosService.get<GetSelectYearResponse>(CUSTOMER_URLS.getSelectYear)
      return result.data
    } catch (error) {
      throw error
    }
  }),
  getCustomerByIdAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getCustomerById'),
    async (customerId: string) => {
      try {
        const result = await AxiosService.get<GetCustomerResponse>(
          CUSTOMER_URLS.companyInfoTab.getCustomerById(customerId)
        )
        return result.data.customer
      } catch (error) {
        throw error
      }
    }
  ),
  updateCustomerInfo: async (dto: UpdateCustomerInfoDto) => {
    try {
      await AxiosService.put(CUSTOMER_URLS.companyInfoTab.updateCustomerInfo, dto)
    } catch (error) {
      throw error
    }
  },
  updateCustomerAddress: async (dto: UpdateCustomerAddressDto) => {
    try {
      await AxiosService.put(CUSTOMER_URLS.companyInfoTab.updateCustomerAddress, dto)
    } catch (error) {
      throw error
    }
  },
  updateCustomerNote: async (dto: UpdateCustomerNoteDto) => {
    try {
      await AxiosService.put(CUSTOMER_URLS.companyInfoTab.updateCustomerNote, dto)
    } catch (error) {
      throw error
    }
  },
  getProvinceMasterDataAsyncThunk: createAsyncThunk(createThunkName(PREFIX, 'getProvinceMasterData'), async () => {
    try {
      const result = await AxiosService.get<GetProvinceMasterDataResponse>(
        CUSTOMER_URLS.masterData.getProvinceMasterData
      )
      return result.data.provinces
    } catch (error) {
      throw error
    }
  }),
  getDistrictMasterDataAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getDistrictMasterData'),
    async (queryParams: GetDistrictMasterDataQueryParams) => {
      try {
        const result = await AxiosService.get<GetDistrictMasterDataResponse>(
          CUSTOMER_URLS.masterData.getDistrictMasterData,
          { params: queryParams }
        )
        return result.data.districts
      } catch (error) {
        throw error
      }
    }
  ),
  getSubDistrictMasterDataAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getSubDistrictMasterData'),
    async (queryParams: GetSubDistrictMasterDataQueryParams) => {
      try {
        const result = await AxiosService.get<GetSubDistrictMasterDataResponse>(
          CUSTOMER_URLS.masterData.getSubDistrictMasterData,
          { params: queryParams }
        )
        return result.data.subDistricts
      } catch (error) {
        throw error
      }
    }
  ),
  getYearMasterDataAsyncThunk: createAsyncThunk(createThunkName(PREFIX, 'getYearMasterData'), async () => {
    try {
      const result = await AxiosService.get<GetYearMasterDataResponse>(CUSTOMER_URLS.masterData.getYearMasterData)
      return result.data.years
    } catch (error) {
      throw error
    }
  }),
  getRegistrationTypesMasterDataAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getRegistrationTypesMasterData'),
    async () => {
      try {
        const result = await AxiosService.get<GetRegistrationTypeMasterDataResponse>(
          CUSTOMER_URLS.masterData.getRegistrationTypesMasterData
        )
        return result.data.registrationTypes
      } catch (error) {
        throw error
      }
    }
  ),
  getInsuranceCompaniesAsyncThunk: createAsyncThunk(createThunkName(PREFIX, 'getInsuranceCompanies'), async () => {
    try {
      const result = await AxiosService.get<GetInsuranceCompaniesResponse>(
        CUSTOMER_URLS.masterData.getInsuranceCompanies
      )
      return result.data.insuranceCompanies
    } catch (error) {
      throw error
    }
  }),
  getCustomerMasterDataAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getCustomerMasterData'),
    async (queryParams: GetCustomerMasterDataQueryParams) => {
      try {
        const result = await AxiosService.get<GetCustomerMasterDataResponse>(
          CUSTOMER_URLS.masterData.getCustomerMasterData,
          { params: queryParams }
        )
        return result.data.customers
      } catch (error) {
        throw error
      }
    }
  ),
  getTimestampDataAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getTimestampData'),
    async (customerId: string) => {
      try {
        const result = await AxiosService.get<GetCustomerTimeStampDataResponse>(CUSTOMER_URLS.getTimeStamp(customerId))
        return result.data
      } catch (error) {
        throw error
      }
    }
  )
}
