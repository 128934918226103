import React from 'react'

import { IconProps } from '../../../types/types'

const AddIcon = ({ color = '#FFF', size = 24 }: IconProps) => {
  return (
    <svg width={size} height={size} viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12 4.5c.69 0 1.25.56 1.25 1.25l-.001 4.999 5.001.001a1.25 1.25 0 0 1 0 2.5l-5.001-.001.001 5.001a1.25 1.25 0 0 1-2.5 0l-.001-5.001-4.999.001a1.25 1.25 0 0 1 0-2.5l4.999-.001.001-4.999c0-.69.56-1.25 1.25-1.25z'
        fill={color}
        fillRule='evenodd'
      />
    </svg>
  )
}

export default AddIcon
