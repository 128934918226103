import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'

import companyInfo from '../features/customer/stores/company-info/slice'
import employeeInfo from '../features/customer/stores/employee-info/slice'
import insuranceInfo from '../features/customer/stores/insurance-info/slice'
import orderInfo from '../features/customer/stores/order/slice'
import welfareInfo from '../features/customer/stores/welfare/slice'
import benefitInfo from '../features/e-claim/stores/benefit-info/slice'
import eClaimInfo from '../features/e-claim/stores/e-claim-info/slice'
import flexBenefit from '../features/flex-benefit/stores/slice'
import insurer from '../features/insurance/stores/insurer/slice'
import plan from '../features/insurance/stores/plan/slice'
import medical from '../features/medical/stores/slice'
import hrUser from '../features/user/stores/hr-user/slice'
import role from '../features/user/stores/role/slice'
import user from '../features/user/stores/user/slice'
import currentUser from './current-user/slice'
import language from './language/slice'
import visible from './visible/slice'

export const createStore = () =>
  configureStore({
    reducer: {
      visible,
      currentUser,
      insurer,
      language,
      plan,
      medical,
      user,
      role,
      companyInfo,
      orderInfo,
      insuranceInfo,
      employeeInfo,
      eClaimInfo,
      benefitInfo,
      welfareInfo,
      flexBenefit,
      hrUser
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false })
  })

export const store = createStore()

export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>
