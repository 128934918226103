import {
  Box,
  CircularProgress,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableRowProps,
  Typography
} from '@mui/material'
import DOMPurify from 'isomorphic-dompurify'
import React, { Fragment, PropsWithChildren, ReactNode, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import PDFIcon from '../../../components/global/icons/PDFIcon'
import CustomButton from '../../../components/global/interactions/CustomButton'
import CustomSwitch from '../../../components/global/interactions/CustomSwitch'
import { BaseCard } from '../../../components/global/ui/Card'
import BackButton from '../../../components/project/navigations/BackButton'
import ImageBox from '../../../components/project/ui/ImageBox'
import { COLORS, COLORS_THEME } from '../../../constants/colors'
import { useAppDispatch } from '../../../stores/store'
import { combineFileUrl, combineImageUrl } from '../../../utils/combineImageUrl'
import { convertToCurrency } from '../../../utils/convertToCurrency'
import { PLAN_DETAIL_TEXT_FIELD_LIST } from '../constants/planDetailTextFieldList'
import { PlanService } from '../services/plan/PlanService'
import { bannerTypeSelector, currentPlanIdSelector, insurancePlanSelector } from '../stores/plan/selector'
import { closePlanDetail } from '../stores/plan/slice'
import { BannerType } from '../stores/plan/types.d'
import BannerLabel from './BannerLabel'

interface StyledTableRowProps extends TableRowProps {
  isColored: boolean
}

interface ChildTableRowProps extends TableRowProps {
  isColored: boolean
  needBorder: boolean
}

const MobileBox = ({ children }: PropsWithChildren) => {
  return <Mobile>{children}</Mobile>
}

const PlanDetail = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isMobile, setIsMobile] = useState(false)

  const insurancePlan = useSelector(insurancePlanSelector)
  const currentPlanId = useSelector(currentPlanIdSelector)
  const bannerType = useSelector(bannerTypeSelector)
  const dispatch = useAppDispatch()

  const cleanHTML = DOMPurify.sanitize(insurancePlan?.additionalDetail ?? '-')

  const toggleMobile = () => setIsMobile((prevIsMobile) => !prevIsMobile)
  const onCloseModal = () => dispatch(closePlanDetail())
  const onOpenFile = () =>
    insurancePlan && window.open(combineFileUrl(insurancePlan.documentUrl), '_blank', 'noopener,noreferrer')
  const renderBannerType = () => bannerType !== BannerType.None && <BannerLabel bannerType={bannerType} />
  const renderDetailContent = (content: ReactNode) =>
    isMobile ? (
      <Box display='flex' justifyContent='center'>
        <MobileBox>
          <Typography variant='h3' marginBottom={8} textAlign='center' marginTop={2}>
            รายละเอียดแผนประกัน
          </Typography>
          <BaseCard position='relative' overflow='hidden'>
            {content}
            {renderBannerType()}
          </BaseCard>
        </MobileBox>
      </Box>
    ) : (
      <BaseCard position='relative' overflow='hidden'>
        {content}
        {renderBannerType()}
      </BaseCard>
    )

  useEffect(() => {
    ;(async () => {
      if (!currentPlanId) return
      setIsLoading(true)
      await dispatch(PlanService.getInsurancePlanAsyncThunk(currentPlanId)).unwrap()
      setIsLoading(false)
    })()
  }, [currentPlanId])

  return (
    <PreviewPlanDetailContainer>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <BackButton title='รายละเอียดแผนประกัน' onClick={onCloseModal} />
        <Stack direction='row' spacing={2} alignItems='center'>
          <CustomSwitch
            value={isMobile}
            onChange={toggleMobile}
            customLabel={{
              active: 'Mobile View',
              inActive: 'Desktop View'
            }}
          />
        </Stack>
      </Box>
      <Box marginTop={6}>
        {isLoading || !insurancePlan ? (
          <Box display='flex' justifyContent='center' marginTop={10}>
            <CircularProgress size={64} />
          </Box>
        ) : (
          renderDetailContent(
            <>
              <Box
                paddingY={8}
                paddingX={18}
                padding={isMobile ? 4 : ''}
                display='flex'
                flexDirection={isMobile ? 'column' : 'row'}
                alignItems='center'
                justifyContent='space-between'
              >
                <Box display='flex' alignItems='center'>
                  <ImageBox imageUrl={combineImageUrl(insurancePlan.insurerLogoUrl)} size='large' />
                  <Box marginLeft={4}>
                    <Typography variant='h6' color={COLORS.grey700}>
                      {insurancePlan.displayName}
                    </Typography>
                    <Typography variant='h3'>{insurancePlan.name}</Typography>
                  </Box>
                </Box>
                <Box display='flex' marginTop={isMobile ? 4 : ''} width={isMobile ? '100%' : 'auto'}>
                  <CustomButton
                    text='ดาวโหลดเอกสาร'
                    variant='outlined'
                    startIcon={<PDFIcon />}
                    direction='column'
                    fullWidth={isMobile}
                    onClick={onOpenFile}
                  />
                </Box>
              </Box>
              <Box
                borderTop={1}
                borderColor={COLORS.grey100}
                display='flex'
                flexDirection={isMobile ? 'column' : 'row'}
              >
                <Box paddingY={6} paddingX={4} borderRight={1} borderColor={COLORS.grey100} flex={1}>
                  <Table>
                    <TableBody>
                      {PLAN_DETAIL_TEXT_FIELD_LIST.map((textField, index) => (
                        <Fragment key={textField.id}>
                          <StyledTableRow key={textField.id} isColored={index % 2 === 0}>
                            <TableCell>
                              <Typography variant='h6'>• {textField.label}</Typography>
                            </TableCell>
                            <CenterTableCell>
                              <Typography marginLeft={6} variant='h6' fontWeight='normal'>
                                <Typography
                                  component='span'
                                  variant='inherit'
                                  color={COLORS_THEME.primary}
                                  fontWeight='bold'
                                >
                                  {textField.unit &&
                                    (insurancePlan as any)[textField.id] !== 0 &&
                                    (insurancePlan as any)[textField.id] &&
                                    convertToCurrency((insurancePlan as any)[textField.id], true)}{' '}
                                </Typography>
                                {!textField.unit &&
                                  (insurancePlan as any)[textField.id] &&
                                  (insurancePlan as any)[textField.id]}
                                {!(insurancePlan as any)[`${textField.id}Unit`] && !(insurancePlan as any)[textField.id]
                                  ? '-'
                                  : (insurancePlan as any)[`${textField.id}Unit`]}
                              </Typography>
                            </CenterTableCell>
                          </StyledTableRow>
                          {textField?.child?.map((item, childIndex) => (
                            <ChildTableRow key={item.id} isColored={index % 2 === 0} needBorder={childIndex !== 0}>
                              <TableCell>
                                <Typography marginLeft={6} variant='h6' fontWeight='normal'>
                                  - {item.label}
                                </Typography>
                              </TableCell>
                              <CenterTableCell>
                                <Typography marginLeft={6} variant='h6' fontWeight='normal'>
                                  {(insurancePlan as any)[item.id] !== 0 &&
                                    (insurancePlan as any)[item.id] &&
                                    convertToCurrency((insurancePlan as any)[item.id], true)}{' '}
                                  {(insurancePlan as any)[`${item.id}Unit`]}
                                  {!(insurancePlan as any)[`${item.id}Unit`] && !(insurancePlan as any)[item.id] && '-'}
                                </Typography>
                              </CenterTableCell>
                            </ChildTableRow>
                          ))}
                        </Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
                <Box
                  paddingY={6}
                  paddingX={4}
                  flex={1}
                  borderTop={isMobile ? 1 : 0}
                  borderColor={COLORS.grey100}
                  minHeight='500px'
                >
                  <div dangerouslySetInnerHTML={{ __html: cleanHTML }} />
                </Box>
              </Box>
            </>
          )
        )}
      </Box>
    </PreviewPlanDetailContainer>
  )
}

const Mobile = styled(Box)(({ theme }) => ({
  width: '500px',
  height: '80vh',
  overflowY: 'scroll',
  border: `20px solid ${COLORS.grey750}`,
  borderRadius: theme.spacing(10),
  padding: theme.spacing(4)
}))

const StyledTableRow = styled(TableRow, { shouldForwardProp: (props) => props !== 'isColored' })<StyledTableRowProps>(
  ({ theme, isColored }) => ({
    backgroundColor: isColored ? COLORS.purple100 : COLORS.white,
    '& td': {
      padding: theme.spacing(2, 4),
      minHeight: '56px',
      height: '56px',
      border: 'none'
    }
  })
)
const ChildTableRow = styled(TableRow, {
  shouldForwardProp: (props) => !['isColored', 'needBorder'].includes(props as string)
})<ChildTableRowProps>(({ theme, isColored, needBorder }) => ({
  backgroundColor: isColored ? COLORS.purple100 : COLORS.white,
  '& td': {
    padding: theme.spacing(2, 4),
    minHeight: '32px',
    border: 'none',
    borderTop: needBorder ? `1px solid ${COLORS.purple100}` : 'none'
  }
}))

const CenterTableCell = styled(TableCell)({ textAlign: 'center' })

const PreviewPlanDetailContainer = styled(Box)(({ theme }) => ({
  width: '100vw',
  height: '100vh',
  backgroundColor: COLORS.grey150,
  zIndex: theme.zIndex.appBar,
  padding: theme.spacing(14, 33),
  overflowY: 'scroll'
}))

export default PlanDetail
