import { Box } from '@mui/material'
import { FormikProps } from 'formik'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import ImagePicker from '../../../components/global/forms/ImagePicker'
import CustomButton from '../../../components/global/interactions/CustomButton'
import { BaseCard } from '../../../components/global/ui/Card'
import CardDetailBar from '../../../components/global/ui/CardDetailBar'
import { COLORS } from '../../../constants/colors'
import { combineImageUrl } from '../../../utils/combineImageUrl'
import { fetchedHRUserInfoSelector } from '../stores/hr-user/selector'
import HRUserInfoSection from './hr-user/HRUserInfoSection'

interface AddOrEditHRUserFormProps {
  isEditPage?: boolean
  isLoading?: boolean
  formik: FormikProps<HRUserFormikType>
  onCancel?: () => void
}

const AddOrEditHRUserForm = ({ isEditPage = false, formik, isLoading, onCancel }: AddOrEditHRUserFormProps) => {
  const [isEdit, setIsEdit] = useState(!isEditPage)

  const fetchedHRUserInfo = useSelector(fetchedHRUserInfoSelector)

  const onEdit = () => setIsEdit(true)
  const onCancelEdit = () => {
    formik.resetForm()
    onCancel && onCancel()
    setIsEdit(false)
  }
  const onSubmitEdit = () => {
    formik.handleSubmit()
    setIsEdit(false)
  }
  const onSelectFile = (file: File) => formik.setFieldValue('profileImage', file)

  const isProfileValid =
    formik.values.name && formik.values.email && formik.values.position && formik.values.phoneNumber
  const isImageValid = isEditPage || formik.values.profileImage
  const isListValid =
    formik.values.departmentIdList.length > 0 &&
    formik.values.sectionIdList.length > 0 &&
    formik.values.subCompanyIdList.length > 0
  const isFormDisabled = !isProfileValid || !isImageValid || !isListValid

  return (
    <>
      <BaseCard marginTop={6}>
        <Box display='flex'>
          <Box width='184px' padding={6} borderRight={1} borderColor={COLORS.grey100}>
            <ImagePicker
              id='hr-user-img'
              onSelectFile={onSelectFile}
              height='136px'
              isEdit={isEdit && isEditPage}
              isSelectNew={!isEditPage}
              isDisabledRecommendText={isEditPage && !isEdit}
              previewImage={fetchedHRUserInfo ? combineImageUrl(fetchedHRUserInfo.profileUrl) : undefined}
            />
          </Box>
          <HRUserInfoSection
            isEditPage={isEditPage}
            isEdit={isEdit}
            formik={formik}
            onCancelEdit={onCancelEdit}
            onEdit={onEdit}
            onSubmitEdit={onSubmitEdit}
            isFormDisabled={isFormDisabled}
          />
        </Box>
        {isEditPage && (
          <CardDetailBar
            createdDetail={{
              username: fetchedHRUserInfo?.createdBy ?? '',
              date: fetchedHRUserInfo?.createdAt ?? ''
            }}
            updatedDetail={{
              username: fetchedHRUserInfo?.updatedBy ?? '',
              date: fetchedHRUserInfo?.updatedAt ?? ''
            }}
          />
        )}
      </BaseCard>
      {!isEditPage && (
        <Box display='flex' marginTop={6} justifyContent='flex-end'>
          <CustomButton text='เพิ่ม' onClick={formik.handleSubmit} isLoading={isLoading} isDisabled={isFormDisabled} />
        </Box>
      )}
    </>
  )
}

export default AddOrEditHRUserForm
