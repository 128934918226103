export const baseApiUrl = `${process.env.REACT_APP_BASE_URL}/admin`
const MASTER_DATA = 'master-data'

export const AUTHENTICATION_URLS = {
  login: `${baseApiUrl}/login`,
  forgotPassword: `${baseApiUrl}/forgot-password`,
  resetPassword: `${baseApiUrl}/reset-password`,
  getCurrentUser: `${baseApiUrl}/current`,
  checkPasswordResetToken: `${baseApiUrl}/reset-password`
}

const baseRoleUrl = `${baseApiUrl}/roles`
export const ROLE_URLS = {
  getAllRole: baseRoleUrl,
  createRole: baseRoleUrl,
  getRoleById: (roleId: string) => `${baseRoleUrl}/${roleId}`,
  deleteRole: `${baseRoleUrl}/delete`,
  updateRoleInfo: `${baseRoleUrl}/info`,
  updateRolePermissions: `${baseRoleUrl}/role-permissions`,
  masterData: {
    getPermissionMasterData: `${baseRoleUrl}/permissions/${MASTER_DATA}`,
    getRoleMasterData: `${baseRoleUrl}/${MASTER_DATA}`
  }
}

const baseUserUrl = `${baseApiUrl}/users`
export const USER_URLS = {
  getAllUser: baseUserUrl,
  createUser: baseUserUrl,
  getUserById: (userId: string) => `${baseUserUrl}/${userId}`,
  updateUser: `${baseUserUrl}/info`,
  resetUserPassword: `${baseUserUrl}/reset-password`,
  updateUserStatus: `${baseUserUrl}/status`
}

const baseInsuranceUrl = `${baseApiUrl}/insurances`
const baseInsurerUrl = `${baseInsuranceUrl}/insurers`
export const INSURER_URLS = {
  getAllInsurer: baseInsurerUrl,
  addInsurer: baseInsurerUrl,
  getInsurerById: (insurerId: string) => `${baseInsurerUrl}/${insurerId}`,
  getAllPlansByInsurerId: (insurerId: string) => `${baseInsurerUrl}/${insurerId}/plans`,
  deleteInsurer: `${baseInsurerUrl}/delete`,
  exportInsurer: `${baseInsurerUrl}/export`,
  updateInsurerStatus: `${baseInsurerUrl}/status`,
  updateInsurerInfo: `${baseInsurerUrl}/info`,
  updateInsurerPlanOrder: `${baseInsurerUrl}/plans/order`,
  masterData: {
    getInsurerMasterData: `${baseInsurerUrl}/${MASTER_DATA}`,
    getInsurerShortNameMasterData: `${baseInsurerUrl}/${MASTER_DATA}/name`,
    getInsuranceCompaniesMasterData: `${baseInsuranceUrl}/insurance-companies/${MASTER_DATA}`
  }
}

const basePlanUrl = `${baseInsuranceUrl}/plans`
export const PLAN_URLS = {
  getAllPlans: basePlanUrl,
  addPlan: basePlanUrl,
  downloadTemplate: `${basePlanUrl}/template`,
  importTemplate: `${baseInsuranceUrl}/plans/import`,
  exportPlanData: `${basePlanUrl}/export`,
  deletePlan: `${basePlanUrl}/delete`,
  getPlanInfo: (planId: string) => `${basePlanUrl}/${planId}/info`,
  getPlanInsuranceType: (planId: string) => `${basePlanUrl}/${planId}/insurance-type`,
  getPlanSpecialLabel: (planId: string) => `${basePlanUrl}/${planId}/special-label`,
  getSpecialCustomers: (planId: string) => `${basePlanUrl}/${planId}/special-customer`,
  getPlanDetail: (planId: string) => `${basePlanUrl}/${planId}/detail`,
  getInsurancePlanDetail: (planId: string) => `${basePlanUrl}/${planId}`,
  getTimeStampData: (planId: string) => `${basePlanUrl}/${planId}/timestamp`,
  updatePlanInfo: `${basePlanUrl}/info`,
  updatePlanInsuranceType: `${basePlanUrl}/insurance-type`,
  updatePlanSpecialLabel: `${basePlanUrl}/special-label`,
  updateSpecialCustomers: `${basePlanUrl}/special-customer`,
  updatePlanDetail: `${basePlanUrl}/detail`,
  updatePlanStatus: `${basePlanUrl}/status`,
  masterData: {
    getInsuranceTypeMasterData: `${basePlanUrl}/insurance-type/${MASTER_DATA}`
  }
}

const baseCustomerUrl = `${baseApiUrl}/customers`
const baseCustomerEmployeeLevelUrl = `${baseCustomerUrl}/employee-level`
const baseCustomerEmployeesUrl = `${baseCustomerUrl}/employees`
const baseCustomerPurchaseOrderUrl = `${baseCustomerUrl}/purchase-order`
const baseCustomerWelfareUrl = `${baseCustomerUrl}/benefits`
export const CUSTOMER_URLS = {
  getAllCustomer: baseCustomerUrl,
  createCustomer: baseCustomerUrl,
  exportCustomer: `${baseCustomerUrl}/export`,
  getCustomerCompanyName: (customerId: string) => `${baseCustomerUrl}/company-name/${customerId}`,
  getSelectYear: `${baseCustomerUrl}/year`,
  updateCustomerStatus: `${baseCustomerUrl}/status`,
  getTimeStamp: (customerId: string) => `${baseCustomerUrl}/${customerId}/timestamp`,
  companyInfoTab: {
    getCustomerById: (customerId: string) => `${baseCustomerUrl}/${customerId}`,
    updateCustomerInfo: `${baseCustomerUrl}/info`,
    updateCustomerAddress: `${baseCustomerUrl}/address`,
    updateCustomerNote: `${baseCustomerUrl}/note`
  },
  insuranceInfoTab: {
    createEmployeeLevel: baseCustomerEmployeeLevelUrl,
    importInsurancePlan: `${baseCustomerEmployeeLevelUrl}/import`,
    getInsuranceTabByCustomerId: (yearId: string, customerId: string) =>
      `${baseCustomerUrl}/${yearId}/insurance-plan/${customerId}`,
    getAllInsurancePlans: `${baseCustomerUrl}/insurance`,
    updateEffectiveDate: `${baseCustomerUrl}/insurance-plan/effective-date`,
    updateEnrollmentDate: `${baseCustomerUrl}/insurance-plan/enrollment-date`,
    updateEmployeeLevel: `${baseCustomerUrl}/insurance-plan/employee-level`,
    updateInsurancePoint: `${baseCustomerUrl}/insurance-plan/insurance-point`,
    importEmployeeFromLastYear: `${baseCustomerUrl}/employees/year-import`
  },
  employeeInfoTab: {
    createEmployee: baseCustomerEmployeesUrl,
    getAllEmployeeById: (yearId: string, customerId: string) => `${baseCustomerUrl}/${yearId}/employees/${customerId}`,
    exportEmployeeById: (yearId: string, customerId: string) =>
      `${baseCustomerUrl}/${yearId}/employees/export/${customerId}`,
    getAllCoupleById: (yearId: string, customerId: string) => `${baseCustomerUrl}/${yearId}/couples/${customerId}`,
    exportAllCoupleById: (yearId: string, customerId: string) =>
      `${baseCustomerUrl}/${yearId}/couples/export/${customerId}`,
    getAllChildrenById: (yearId: string, customerId: string) => `${baseCustomerUrl}/${yearId}/childrens/${customerId}`,
    exportAllChildrenById: (yearId: string, customerId: string) =>
      `${baseCustomerUrl}/${yearId}/childrens/export/${customerId}`,
    getEmployeeInfoByEmpId: (yearId: string, employeeId: string) =>
      `${baseCustomerUrl}/${yearId}/employees/info/${employeeId}`,
    getInsurancePlanByEmpId: (yearId: string, employeeId: string) =>
      `${baseCustomerUrl}/${yearId}/employees/plan/${employeeId}`,
    getCoupleInfoByEmpId: (yearId: string, employeeId: string) =>
      `${baseCustomerUrl}/${yearId}/employees/couple/${employeeId}`,
    getChildrenInfoByEmpId: (yearId: string, employeeId: string) =>
      `${baseCustomerUrl}/${yearId}/employees/childrens/${employeeId}`,
    getTimestampDataByEmpId: (employeeId: string) => `${baseCustomerEmployeesUrl}/timestamp/${employeeId}`,
    updateEmployeeInfo: `${baseCustomerEmployeesUrl}/info`,
    updateEmployeeInsurancePlan: `${baseCustomerEmployeesUrl}/plan`,
    updateEmployeeCoupleInfo: `${baseCustomerEmployeesUrl}/couple`,
    createOrUpdateChildrenByEmployee: `${baseCustomerEmployeesUrl}/childrens`,
    deleteEmployeeById: (employeeId: string) => `${baseCustomerEmployeesUrl}/delete/${employeeId}`,
    updateEmployeeStatus: `${baseCustomerEmployeesUrl}/status`,
    getIsAlreadyHaveEmployee: (customerId: string) => `${baseCustomerEmployeesUrl}/${customerId}/already`,
    getEmployeesCount: (yearId: string, customerId: string) =>
      `${baseCustomerUrl}/${yearId}/employees/${customerId}/count`,
    downloadEmployeeTemplate: (yearId: string, customerId: string) =>
      `${baseCustomerUrl}/${yearId}/${customerId}/employees/template`,
    getEmployeeListFromExcel: (yearId: string, customerId: string) =>
      `${baseCustomerUrl}/${yearId}/${customerId}/employees/list`,
    importEmployee: `${baseCustomerEmployeesUrl}/import`
  },
  orderTab: {
    confirmUploadDocument: `${baseCustomerPurchaseOrderUrl}/document`,
    getDocuments: (customerId: string) => `${baseCustomerPurchaseOrderUrl}/documents/${customerId}`,
    approveDocument: `${baseCustomerPurchaseOrderUrl}/documents/approved`,
    cancelDocument: `${baseCustomerPurchaseOrderUrl}/documents/cancel`,
    // Quotation
    getOriginalQuotation: (customerId: string) => `${baseCustomerPurchaseOrderUrl}/original-quotation/${customerId}`,
    confirmUploadQuotation: `${baseCustomerPurchaseOrderUrl}/quotation`,
    getQuotationDetail: (historyId: string) => `${baseCustomerUrl}/quotation-histories/${historyId}`,
    getQuotationHistory: (customerId: string) => `${baseCustomerPurchaseOrderUrl}/quotation-histories/${customerId}`,
    getSignedQuotation: (customerId: string) => `${baseCustomerPurchaseOrderUrl}/signed-quotations/${customerId}`,
    updateQuotation: `${baseCustomerPurchaseOrderUrl}/quotation`,
    uploadSignedQuotation: `${baseCustomerPurchaseOrderUrl}/signed-quotation`,
    rejectQuotation: `${baseCustomerPurchaseOrderUrl}/quotation/reject`,
    confirmQuotation: `${baseCustomerPurchaseOrderUrl}/quotation/confirm`,
    // Payment
    getPayment: (customerId: string) => `${baseCustomerPurchaseOrderUrl}/payment/${customerId}`,
    savePayment: `${baseCustomerPurchaseOrderUrl}/payment`,
    rejectPayment: `${baseCustomerPurchaseOrderUrl}/payment/reject`,
    confirmPayment: `${baseCustomerPurchaseOrderUrl}/payment/confirm`,
    getSlip: (customerId: string) => `${baseCustomerPurchaseOrderUrl}/payment-slip/${customerId}`,
    getPayIn: (customerId: string) => `${baseCustomerPurchaseOrderUrl}/payment-pay-in/${customerId}`,
    getFrontCheque: (customerId: string) => `${baseCustomerPurchaseOrderUrl}/payment-front-cheque/${customerId}`,
    getInvoice: (customerId: string) => `${baseCustomerPurchaseOrderUrl}/payment-invoice/${customerId}`,
    uploadSlip: `${baseCustomerPurchaseOrderUrl}/payment-slip`,
    uploadPayIn: `${baseCustomerPurchaseOrderUrl}/payment-pay-in`,
    uploadFrontCheque: `${baseCustomerPurchaseOrderUrl}/payment-front-cheque`,
    uploadInvoice: `${baseCustomerPurchaseOrderUrl}/payment-invoice`,
    updateInvoiceFile: `${baseCustomerPurchaseOrderUrl}/payment-invoice/file`,
    updateInvoicePayment: `${baseCustomerPurchaseOrderUrl}/payment-invoice/payment`,
    // Insurer Document
    getInsurerInfoByCustomerId: (customerId: string) => `${baseCustomerPurchaseOrderUrl}/insurer-info/${customerId}`,
    uploadOriginalInsurerDocument: `${baseCustomerPurchaseOrderUrl}/original-insurer-document`,
    confirmUploadInsurerDocument: `${baseCustomerPurchaseOrderUrl}/insurer-document`,
    getInsurerDocuments: (customerId: string) => `${baseCustomerPurchaseOrderUrl}/insurer-documents/${customerId}`,
    updateOriginalInsurerDocument: `${baseCustomerPurchaseOrderUrl}/insurer-document`,
    approveInsurerDocument: `${baseCustomerPurchaseOrderUrl}/insurer-document/approved`,
    cancelInsurerDocument: `${baseCustomerPurchaseOrderUrl}/insurer-document/cancel`,
    getCanAccessPayment: (customerId: string) => `${baseCustomerPurchaseOrderUrl}/${customerId}`
  },
  welfareTab: {
    importBenefitFromLastYear: `${baseCustomerWelfareUrl}/import`,
    getAllBenefit: (customerId: string) => `${baseCustomerUrl}/${customerId}/benefits`,
    addBenefit: `${baseCustomerWelfareUrl}`,
    updateBenefitDetailById: `${baseCustomerWelfareUrl}`,
    updateBenefitDocumentById: `${baseCustomerWelfareUrl}/documents`,
    getBenefitDetailById: (benefitId: string) => `${baseCustomerWelfareUrl}/${benefitId}`,
    getBenefitDocumentById: (benefitId: string) => `${baseCustomerWelfareUrl}/${benefitId}/documents`,
    deleteBenefitById: (benefitId: string) => `${baseCustomerWelfareUrl}/${benefitId}/delete`
  },
  masterData: {
    getProvinceMasterData: `${baseApiUrl}/provinces/${MASTER_DATA}`,
    getDistrictMasterData: `${baseApiUrl}/districts/${MASTER_DATA}`,
    getSubDistrictMasterData: `${baseApiUrl}/sub-districts/${MASTER_DATA}`,
    getYearMasterData: `${baseApiUrl}/years/${MASTER_DATA}`,
    getRegistrationTypesMasterData: `${baseApiUrl}/registration-types/${MASTER_DATA}`,
    getInsuranceCompanies: `${baseApiUrl}/insurance-companies/${MASTER_DATA}`,
    getCustomerMasterData: `${baseCustomerUrl}/${MASTER_DATA}`,
    getEmployeeLevelsMasterData: (yearId: string, customerId: string) =>
      `${baseApiUrl}/employee-levels/${yearId}/${customerId}/${MASTER_DATA}`,
    getNationalityMasterData: `${baseApiUrl}/nationalities/${MASTER_DATA}`,
    getInsurancePlansMasterData: `${baseApiUrl}/insurance-plans/${MASTER_DATA}`,
    // For Order Tab
    getCustomerDocumentMasterData: `${baseApiUrl}/customer-documents/${MASTER_DATA}`,
    getPaymentMethodMasterData: `${baseApiUrl}/payment-methods/${MASTER_DATA}`,
    getBankMasterData: `${baseApiUrl}/banks/${MASTER_DATA}`,
    getInsurerDocumentsMasterData: (insurerId: string) => `${baseApiUrl}/insurer-documents/${MASTER_DATA}/${insurerId}`,
    getBenefitCategoryMasterData: `${baseApiUrl}/benefit-categories/${MASTER_DATA}`,
    getBenefitDocumentMasterData: `${baseApiUrl}/benefit-documents/${MASTER_DATA}`,
    getPaymentBanksMasterData: `${baseApiUrl}/payment-banks/${MASTER_DATA}`
  }
}

const baseEClaimUrl = `${baseApiUrl}/e-claims`
const baseBenefitUrl = `${baseApiUrl}/benefits`
export const E_CLAIM_URLS = {
  getAllCustomer: `${baseEClaimUrl}/customers`,
  getCompanyName: (customerId: string) => `${baseEClaimUrl}/customers/${customerId}/company-name`,
  exportAllEClaim: `${baseEClaimUrl}/export`,
  benefitTab: {
    getAllBenefitByCustomerId: (yearId: string, customerId: string) =>
      `${baseBenefitUrl}/customers/${yearId}/${customerId}`,
    exportBenefitByCustomerId: (customerId: string) => `${baseBenefitUrl}/customers/${customerId}/export`,
    getBenefitById: (benefitClaimId: string) => `${baseBenefitUrl}/${benefitClaimId}/detail`,
    approveBenefitById: `${baseBenefitUrl}/approve`,
    rejectBenefitById: `${baseBenefitUrl}/reject`
  },
  eClaimTab: {
    getAllEClaimByCustomerId: (yearId: string, customerId: string) =>
      `${baseEClaimUrl}/customers/${yearId}/${customerId}`,
    exportEClaimByCustomerId: (customerId: string) => `${baseEClaimUrl}/customers/${customerId}/export`,
    getEClaimById: (insuranceClaimId: string) => `${baseEClaimUrl}/${insuranceClaimId}/detail`,
    approveEClaimById: `${baseEClaimUrl}/approve`,
    rejectEClaimById: `${baseEClaimUrl}/reject`
  },
  masterData: {
    getEClaimTypeMasterData: (yearId: string) => `${baseEClaimUrl}/${yearId}/e-claim-types/${MASTER_DATA}`,
    getEClaimDocumentMasterData: `${baseEClaimUrl}/e-claim-documents/${MASTER_DATA}`,
    getBenefitMasterData: (yearId: string, customerId: string) =>
      `${baseEClaimUrl}/${yearId}/${customerId}/benefits/${MASTER_DATA}`,
    getBenefitDocumentMasterData: (benefitId: string) =>
      `${baseEClaimUrl}/${benefitId}/benefit-documents/${MASTER_DATA}`
  }
}

const baseFlexBenefitUrl = `${baseApiUrl}/flex-benefits`
export const FLEX_BENEFIT_URLS = {
  getAllCustomer: `${baseFlexBenefitUrl}/customers`,
  getAllFlexBenefitByCustomerId: (customerId: string) => `${baseFlexBenefitUrl}/customers/${customerId}`,
  exportAllFlexBenefit: `${baseFlexBenefitUrl}/export`,
  getFlexBenefitDetailByEmployeeId: (employeeId: string, yearId: string) =>
    `${baseFlexBenefitUrl}/${yearId}/${employeeId}/detail`,
  updatePointByEmployeeId: `${baseFlexBenefitUrl}/points`,
  downloadPointTemplate: (customerId: string) => `${baseFlexBenefitUrl}/customers/${customerId}/point/template`,
  importPoint: (yearId: string, customerId: string) =>
    `${baseFlexBenefitUrl}/${yearId}/customers/${customerId}/point/import`,
  exportFlexBenefit: (customerId: string) => `${baseFlexBenefitUrl}/customers/${customerId}/export`,
  getYearMasterDataEnrollmentFiltering: (customerId: string) =>
    `${baseFlexBenefitUrl}/customers/${customerId}/years/master-data`
}

const baseMedicalUrl = `${baseApiUrl}/medical`
export const MEDICAL_URLS = {
  getAllCustomers: `${baseMedicalUrl}/customers`,
  updateMedicalResult: `${baseMedicalUrl}/result`,
  getAllEmployees: (customerId: string) => `${baseMedicalUrl}/customers/${customerId}/employees`,
  getMedicalResultByEmployeeId: (customerId: string, yearId: string, employeeId: string) =>
    `${baseMedicalUrl}/customers/${customerId}/${yearId}/employees/${employeeId}/medical-result`,
  getEmployeeInfoByEmployeeId: (customerId: string, yearId: string, employeeId: string) =>
    `${baseMedicalUrl}/customers/${customerId}/${yearId}/employees/${employeeId}/info`,
  getSummaryInfoByEmployeeId: (customerId: string, employeeId: string) =>
    `${baseMedicalUrl}/customers/${customerId}/employees/${employeeId}/summary`,
  downloadMedicalTemplateByHospitalId: (customerId: string, yearId: string) =>
    `${baseMedicalUrl}/customers/${customerId}/${yearId}/template`,
  getMedicalResultListFromExcel: (customerId: string, yearId: string) =>
    `${baseMedicalUrl}/customers/${customerId}/${yearId}/import/list`,
  importMedicalResultByHospitalId: (customerId: string, yearId: string) =>
    `${baseMedicalUrl}/customers/${customerId}/${yearId}/import`,
  exportMedicalResult: (customerId: string) => `${baseMedicalUrl}/customers/${customerId}/employees/export`,
  masterData: {
    getHospitalMasterData: `${baseMedicalUrl}/hospital/master-data`,
    getHospitalBranchMasterData: (hospitalId: string) =>
      `${baseMedicalUrl}/hospital/${hospitalId}/branches/master-data`,
    getMedicalResultYearMasterData: (employeeId: string) => `${baseMedicalUrl}/${employeeId}/year/master-data`
  }
}

const baseHRUserUrl = `${baseApiUrl}/hr/customers`
export const HR_USERS_URL = {
  getAllCustomers: baseHRUserUrl,
  getAllHRByCustomerId: (customerId: string) => `${baseHRUserUrl}/${customerId}`,
  getHRInfoById: (hrId: string) => `${baseHRUserUrl}/${hrId}/info`,
  createHR: `${baseHRUserUrl}/info`,
  updateHR: `${baseHRUserUrl}/info`,
  updateHRStatus: `${baseHRUserUrl}/status`,
  masterData: {
    getSubCompanyMasterData: (customerId: string) => `${baseHRUserUrl}/sub-company/${MASTER_DATA}/${customerId}`,
    getDepartmentMasterData: (customerId: string) => `${baseHRUserUrl}/department/${MASTER_DATA}/${customerId}`,
    getSectionMasterData: (customerId: string) => `${baseHRUserUrl}/section/${MASTER_DATA}/${customerId}`
  }
}

const baseUploadUrl = `${baseApiUrl}/upload`
export const UPLOAD_URLS = {
  uploadImage: `${baseUploadUrl}/image`,
  uploadFile: `${baseUploadUrl}/file`
}
