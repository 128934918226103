import React from 'react'

import { COLORS_THEME } from '../../../../constants/colors'

const InsuranceMenuIcon = () => {
  return (
    <svg width='25' height='24' viewBox='0 0 25 24' xmlns='http://www.w3.org/2000/svg'>
      <g fill={COLORS_THEME.primary} fillRule='nonzero'>
        <path d='M4.865 2h10.158c.016.01.03.027.048.03.961.127 1.95 1.004 1.93 2.401-.017 1.224-.003 2.448-.003 3.673v.22l-2.546.87c-.197-.408-.527-.526-.948-.522-2.38.009-4.76.004-7.14.004-.077 0-.155 0-.233.005a.82.82 0 0 0-.602.346c-.14.2-.185.45-.124.687.107.4.415.622.875.623h5.385c-.559.503-.83 1.082-.887 1.755-.139-.03-.265-.08-.39-.08-1.407-.005-2.815-.01-4.222 0-.45.003-.798.4-.789.85.011.47.381.813.891.815 1.148.003 2.295.003 3.444 0 .352 0 .703-.026 1.065-.04 0 .58.008 1.2 0 1.816-.022 1.405.347 2.699 1.063 3.9.027.046.049.095.08.157-2.317 0-4.606.013-6.895-.008-.674-.007-1.225-.34-1.651-.87-.271-.33-.396-.716-.493-1.116V3.993c.013-.042.03-.084.039-.127.127-.62.435-1.135.971-1.463.296-.183.65-.272.974-.403zm2.983 5.003h1.633c.499 0 .869-.359.871-.83.002-.472-.363-.836-.864-.839a315.146 315.146 0 0 0-3.245 0c-.509.002-.879.373-.872.85.008.47.38.816.882.818.533.002 1.064 0 1.595 0z' />
        <path d='M22.806 16.07c-.071.349-.12.704-.218 1.044-.363 1.289-1.135 2.312-2.119 3.185a10.385 10.385 0 0 1-2.412 1.593.936.936 0 0 1-.875 0c-1.517-.745-2.834-1.73-3.788-3.155a5.652 5.652 0 0 1-.97-3.214c.002-1.044 0-2.084 0-3.126 0-.557.177-.806.7-.985 1.379-.47 2.758-.942 4.136-1.415.227-.083.475-.085.703-.005 1.408.483 2.818.964 4.228 1.443.324.11.51.337.614.65l.001 3.985zm-5.49.092c-.178-.182-.336-.345-.495-.506-.159-.162-.326-.336-.496-.496a.823.823 0 0 0-1.158.004.822.822 0 0 0-.008 1.175c.554.568 1.113 1.13 1.678 1.685a.8.8 0 0 0 1.217-.083 285.82 285.82 0 0 0 2.52-3.372.82.82 0 0 0-.179-1.148.82.82 0 0 0-1.147.152c-.103.126-.195.262-.292.39l-1.64 2.2z' />
      </g>
    </svg>
  )
}

export default InsuranceMenuIcon
