import loadable from '@loadable/component'
import React, { ReactNode } from 'react'

import CustomerIcon from '../components/project/icons/sidebar/CustomerMenuIcon'
import EClaimMenuIcon from '../components/project/icons/sidebar/EClaimMenuIcon'
import FlexBenefitMenuIcon from '../components/project/icons/sidebar/FlexBenefitMenuIcon'
import InsuranceIcon from '../components/project/icons/sidebar/InsuranceMenuIcon'
import MedicalMenuIcon from '../components/project/icons/sidebar/MedicalMenuIcon'
import RoleMenuIcon from '../components/project/icons/sidebar/RoleIconMenu'
import UserIcon from '../components/project/icons/sidebar/UserMenuIcon'
import { PERMISSIONS } from '../constants/permissions'
import {
  addCustomerPath,
  addEmployeePath,
  addHRUserPath,
  addInsurancePlanPath,
  addInsurerPath,
  addRolePath,
  addUserPath,
  addWelfarePath,
  benefitDetailPath,
  customerPath,
  dashboardPath,
  eClaimDetailPath,
  eClaimListByCompanyPath,
  eClaimPath,
  editCustomerPath,
  editEmployeePath,
  editHRUserPath,
  editInsurancePlanPath,
  editInsurerPath,
  editMedicalPath,
  editRolePath,
  editUserPath,
  editWelfarePath,
  employeeMedicalListByCompanyPath,
  flexBenefitDetail,
  flexBenefitListByCompany,
  flexBenefitPath,
  forgotPasswordPath,
  hrUserListPath,
  hrUserPath,
  importMedicalPath,
  insurerPath,
  loginPath,
  medicalPath,
  planPath,
  resetPasswordPath,
  rolePath,
  selectYearPath,
  userPath
} from '../constants/route-name'
import AddHRUserPage from '../pages/user/hr-user/AddHRUserPage'
import EditHRUserPage from '../pages/user/hr-user/EditHRUserPage'
import HRUserListPage from '../pages/user/hr-user/HRUserListPage'

// Authen
const LoginPage = loadable(() => import('../pages/auth/LoginPage'))
const ForgotPasswordPage = loadable(() => import('../pages/auth/ForgotPasswordPage'))
const ResetPasswordPage = loadable(() => import('../pages/auth/ResetPasswordPage'))

// Dashboard
const DashboardPage = loadable(() => import('../pages/dashboard/DashboardPage'))

// Customer
const CustomerPage = loadable(() => import('../pages/customer/CustomerPage'))
const AddCustomerPage = loadable(() => import('../pages/customer/AddCustomerPage'))
const EditCustomerPage = loadable(() => import('../pages/customer/EditCustomerPage'))
const AddEmployeePage = loadable(() => import('../pages/customer/AddEmployeePage'))
const EditEmployeePage = loadable(() => import('../pages/customer/EditEmployeePage'))
const SelectInsuranceYearPage = loadable(() => import('../pages/customer/SelectInsuranceYearPage'))
const AddCompanyWelfarePage = loadable(() => import('../pages/customer/AddCompanyWelfarePage'))
const EditCompanyWelfarePage = loadable(() => import('../pages/customer/EditCompanyWelfarePage'))

// Insurer
const InsurerManagementPage = loadable(() => import('../pages/insurance/InsurerManagementPage'))
const AddInsurerPage = loadable(() => import('../pages/insurance/AddInsurerPage'))
const EditInsurerPage = loadable(() => import('../pages/insurance/EditInsurerPage'))
// Plan
const AddInsurancePlanPage = loadable(() => import('../pages/insurance/AddInsurancePlanPage'))
const EditInsurancePlanPage = loadable(() => import('../pages/insurance/EditInsurancePlanPage'))
const PlanManagementPage = loadable(() => import('../pages/insurance/PlanManagementPage'))

// EClaim
const EClaimPage = loadable(() => import('../pages/e-claim/EClaimPage'))
const EClaimListByCompanyPage = loadable(() => import('../pages/e-claim/EClaimListByCompanyPage'))
const EClaimDetailPage = loadable(() => import('../pages/e-claim/EClaimDetailPage'))
const BenefitDetailPage = loadable(() => import('../pages/e-claim/BenefitDetailPage'))

// FlexBenefit
const FlexBenefitPage = loadable(() => import('../pages/flex-benefit/FlexBenefitPage'))
const FlexBenefitListByCompanyPage = loadable(() => import('../pages/flex-benefit/FlexBenefitListByCompanyPage'))
const FlexBenefitDetailPage = loadable(() => import('../pages/flex-benefit/FlexBenefitDetailPage'))
// User
const UserPage = loadable(() => import('../pages/user/UserPage'))
const AddUserPage = loadable(() => import('../pages/user/user/AddUserPage'))
const EditUserPage = loadable(() => import('../pages/user/user/EditUserPage'))

const RolePage = loadable(() => import('../pages/user/RolePage'))
const AddRolePage = loadable(() => import('../pages/user/role/AddRolePage'))
const EditRolePage = loadable(() => import('../pages/user/role/EditRolePage'))

const HRUserPage = loadable(() => import('../pages/user/HRUserPage'))

//Medical
const MedicalPage = loadable(() => import('../pages/medical/MedicalPage'))
const MedicalListByCompanyPage = loadable(() => import('../pages/medical/MedicalListByCompanyPage'))
const EditMedicalPage = loadable(() => import('../pages/medical/EditMedicalPage'))
const ImportMedicalPage = loadable(() => import('../pages/medical/ImportMedicalPage'))

export interface SidebarType {
  title: string
  icon: ReactNode
  permissions: string[]
  path?: string
  child?: { title: string; path: string; permissions: string }[]
}

export const RENDER_SIDEBAR_ROUTES: SidebarType[] = [
  {
    title: 'Insurance',
    icon: <InsuranceIcon />,
    permissions: [PERMISSIONS.companyInfo, PERMISSIONS.companyUsers],
    child: [
      { title: 'Insurer Management', path: insurerPath, permissions: PERMISSIONS.companyInfo },
      { title: 'Plan Management', path: planPath, permissions: PERMISSIONS.companyUsers }
    ]
  },
  {
    title: 'Customer',
    icon: <CustomerIcon />,
    permissions: [PERMISSIONS.customer],
    path: customerPath
  },
  { title: 'E-Claim', icon: <EClaimMenuIcon />, path: eClaimPath, permissions: [PERMISSIONS.eClaim] },
  {
    title: 'Flex Benefit',
    icon: <FlexBenefitMenuIcon />,
    path: flexBenefitPath,
    permissions: [PERMISSIONS.flexBenefit]
  },
  {
    title: 'Role Management',
    icon: <RoleMenuIcon />,
    permissions: [PERMISSIONS.roleManagement],
    path: rolePath
  },
  {
    title: 'User Management',
    icon: <UserIcon />,
    permissions: [PERMISSIONS.userManagement, PERMISSIONS.roleManagement],
    child: [
      { title: 'Admin Management', path: userPath, permissions: PERMISSIONS.userManagement },
      { title: 'HR Management', path: hrUserPath, permissions: PERMISSIONS.userManagement }
    ]
  },
  {
    title: 'Medical Portal',
    icon: <MedicalMenuIcon />,
    path: medicalPath,
    permissions: [PERMISSIONS.roleManagement]
  }
]

const authRoutes = [
  {
    path: loginPath,
    component: <LoginPage />
  },
  {
    path: forgotPasswordPath,
    component: <ForgotPasswordPage />
  },
  {
    path: resetPasswordPath,
    component: <ResetPasswordPage />
  }
]

const dashboardRoutes = [
  {
    path: dashboardPath,
    component: <DashboardPage />,
    permissions: PERMISSIONS.dashboard
  }
]

const insuranceRoutes = [
  {
    path: insurerPath,
    component: <InsurerManagementPage />,
    permissions: PERMISSIONS.companyInfo
  },
  {
    path: addInsurerPath,
    component: <AddInsurerPage />,
    permissions: PERMISSIONS.companyInfo
  },
  {
    path: editInsurerPath(':insurerId'),
    component: <EditInsurerPage />,
    permissions: PERMISSIONS.companyInfo
  },
  {
    path: planPath,
    component: <PlanManagementPage />,
    permissions: PERMISSIONS.companyUsers
  },
  {
    path: addInsurancePlanPath,
    component: <AddInsurancePlanPage />,
    permissions: PERMISSIONS.companyUsers
  },
  {
    path: editInsurancePlanPath(':planId'),
    component: <EditInsurancePlanPage />,
    permissions: PERMISSIONS.companyUsers
  }
]

const customerRoutes = [
  {
    path: customerPath,
    component: <CustomerPage />,
    permissions: PERMISSIONS.customer
  },
  {
    path: addCustomerPath,
    component: <AddCustomerPage />,
    permissions: PERMISSIONS.customer
  },
  {
    path: addEmployeePath(':yearId', ':customerId'),
    component: <AddEmployeePage />,
    permissions: PERMISSIONS.customer
  },
  {
    path: editEmployeePath(':yearId', ':customerId', ':employeeId'),
    component: <EditEmployeePage />,
    permissions: PERMISSIONS.customer
  },
  {
    path: editCustomerPath(':yearId', ':customerId'),
    component: <EditCustomerPage />,
    permissions: PERMISSIONS.customer
  },
  {
    path: selectYearPath(':customerId'),
    component: <SelectInsuranceYearPage />,
    permissions: PERMISSIONS.customer
  },
  {
    path: addWelfarePath(':yearId', ':customerId'),
    component: <AddCompanyWelfarePage />,
    permissions: PERMISSIONS.customer
  },
  {
    path: editWelfarePath(':yearId', ':customerId', ':benefitId'),
    component: <EditCompanyWelfarePage />,
    permissions: PERMISSIONS.customer
  }
]

const eClaimRoutes = [
  {
    path: eClaimPath,
    component: <EClaimPage />,
    permissions: PERMISSIONS.eClaim
  },
  {
    path: eClaimListByCompanyPath(':customerId'),
    component: <EClaimListByCompanyPage />,
    permissions: PERMISSIONS.eClaim
  },
  {
    path: eClaimDetailPath(':customerId', ':insuranceClaimId'),
    component: <EClaimDetailPage />,
    permissions: PERMISSIONS.eClaim
  },
  {
    path: benefitDetailPath(':customerId', ':benefitClaimId'),
    component: <BenefitDetailPage />,
    permissions: PERMISSIONS.eClaim
  }
]
const flexBenefitRoutes = [
  {
    path: flexBenefitPath,
    component: <FlexBenefitPage />,
    permissions: PERMISSIONS.flexBenefit
  },
  {
    path: flexBenefitListByCompany(':customerId'),
    component: <FlexBenefitListByCompanyPage />,
    permissions: PERMISSIONS.flexBenefit
  },
  {
    path: flexBenefitDetail(':yearId', ':employeeId'),
    component: <FlexBenefitDetailPage />,
    permissions: PERMISSIONS.flexBenefit
  }
]

const userRoutes = [
  {
    path: userPath,
    component: <UserPage />,
    permissions: PERMISSIONS.userManagement
  },
  {
    path: addUserPath,
    component: <AddUserPage />,
    permissions: PERMISSIONS.userManagement
  },
  {
    path: editUserPath(':userId'),
    component: <EditUserPage />,
    permissions: PERMISSIONS.userManagement
  },
  {
    path: rolePath,
    component: <RolePage />,
    permissions: PERMISSIONS.roleManagement
  },
  {
    path: addRolePath,
    component: <AddRolePage />,
    permissions: PERMISSIONS.roleManagement
  },
  {
    path: editRolePath(':roleId'),
    component: <EditRolePage />,
    permissions: PERMISSIONS.roleManagement
  },
  {
    path: hrUserPath,
    component: <HRUserPage />,
    permissions: PERMISSIONS.userManagement
  },
  {
    path: hrUserListPath(':customerId'),
    component: <HRUserListPage />,
    permissions: PERMISSIONS.userManagement
  },
  {
    path: addHRUserPath(':customerId'),
    component: <AddHRUserPage />,
    permissions: PERMISSIONS.userManagement
  },
  {
    path: editHRUserPath(':customerId', ':hrUserId'),
    component: <EditHRUserPage />,
    permissions: PERMISSIONS.userManagement
  }
]

const medicalRoutes = [
  {
    path: medicalPath,
    component: <MedicalPage />,
    permissions: PERMISSIONS.roleManagement
  },
  {
    path: employeeMedicalListByCompanyPath(':customerId'),
    component: <MedicalListByCompanyPage />,
    permissions: PERMISSIONS.roleManagement
  },
  {
    path: editMedicalPath(':customerId', ':employeeId'),
    component: <EditMedicalPage />,
    permissions: PERMISSIONS.roleManagement
  },
  {
    path: importMedicalPath(':customerId'),
    component: <ImportMedicalPage />,
    permissions: PERMISSIONS.roleManagement
  }
]

export const RENDER_UNPROTECTED_ROUTES = [...authRoutes]

export const RENDER_PROTECTED_ROUTES = [
  ...dashboardRoutes,
  ...insuranceRoutes,
  ...eClaimRoutes,
  ...flexBenefitRoutes,
  ...customerRoutes,
  ...userRoutes,
  ...medicalRoutes
]
