import React from 'react'

import { IconProps } from '../../../types/types'

const ImportIcon = ({ size = 24, color = '#000000' }: IconProps) => {
  return (
    <svg width={size} height={size} viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
      <g fill={color} fillRule='nonzero'>
        <path d='M21 18.643a2.63 2.63 0 0 1-.466 1.09 3.288 3.288 0 0 1-2.083 1.19 4.53 4.53 0 0 1-.837.074H6.388a3.761 3.761 0 0 1-2.45-.792 2.382 2.382 0 0 1-.935-1.942c0-2.505.023-5.01 0-7.53a2.581 2.581 0 0 1 1.55-2.486 3.931 3.931 0 0 1 1.8-.396h2.979v1.776H6.357a2.237 2.237 0 0 0-1.113.273.825.825 0 0 0-.47.793v7.459a.793.793 0 0 0 .45.792c.394.21.837.31 1.283.29h9.47c.65 0 1.302 0 1.95-.024.347-.02.682-.141.962-.349a.734.734 0 0 0 .343-.65v-7.57a.742.742 0 0 0-.363-.674 2.069 2.069 0 0 0-1.148-.336h-3.042V7.879a.255.255 0 0 1 .079 0c1.1 0 2.201-.02 3.298.031a3.28 3.28 0 0 1 2.336 1.043c.309.328.511.743.58 1.189a.131.131 0 0 0 .028.063v8.438z' />
        <path d='M12.994 12.916c.355-.357.702-.725 1.065-1.074a.882.882 0 0 1 1.467.445.891.891 0 0 1-.256.851l-1.637 1.645-.908.908a.898.898 0 0 1-1.377 0l-2.573-2.58a.863.863 0 0 1-.248-.896.842.842 0 0 1 .647-.61.85.85 0 0 1 .837.245l.994.999.166.154V4.046a1.652 1.652 0 0 1 .043-.42.876.876 0 0 1 .955-.618c.428.049.76.397.79.828v9.08h.035z' />
      </g>
    </svg>
  )
}

export default ImportIcon
