import { createAsyncThunk } from '@reduxjs/toolkit'

import { FLEX_BENEFIT_URLS } from '../../../configs/api'
import { REQUEST_OPTIONS } from '../../../constants/request-options'
import { AxiosService } from '../../../services/api'
import { createThunkName } from '../../../utils/createThunkname'
import { saveData } from '../../../utils/downloadFile'
import {
  ExportAllFlexBenefitQueryParams,
  ExportFlexBenefitQueryParams,
  GetAllCustomerQueryParams,
  GetAllCustomerResponse,
  GetAllFlexBenefitByCustomerIdQueryParams,
  GetAllFlexBenefitByCustomerIdResponse,
  GetFlexBenefitDetailByEmployeeIdResponse,
  GetYearsMasterDataEnrollmentFilteringResponse,
  UpdatePointByEmployeeIdDto
} from './types'

const PREFIX = 'flex-benefit'

export const FlexBenefitService = {
  getAllCustomerAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getAllCustomer'),
    async (queryParams: GetAllCustomerQueryParams) => {
      try {
        const result = await AxiosService.get<GetAllCustomerResponse>(FLEX_BENEFIT_URLS.getAllCustomer, {
          params: queryParams
        })
        return result.data
      } catch (error) {
        throw error
      }
    }
  ),
  getAllFlexBenefitByCustomerIdAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getAllFlexBenefitByCustomerId'),
    async ({
      customerId,
      queryParams
    }: {
      customerId: string
      queryParams: GetAllFlexBenefitByCustomerIdQueryParams
    }) => {
      try {
        const result = await AxiosService.get<GetAllFlexBenefitByCustomerIdResponse>(
          FLEX_BENEFIT_URLS.getAllFlexBenefitByCustomerId(customerId),
          { params: queryParams }
        )
        return result.data
      } catch (error) {
        throw error
      }
    }
  ),
  getFlexBenefitDetailByEmployeeIdAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getFlexBenefitDetailByEmployeeId'),
    async ({ employeeId, yearId }: { employeeId: string; yearId: string }) => {
      try {
        const result = await AxiosService.get<GetFlexBenefitDetailByEmployeeIdResponse>(
          FLEX_BENEFIT_URLS.getFlexBenefitDetailByEmployeeId(employeeId, yearId)
        )
        return result.data
      } catch (error) {
        throw error
      }
    }
  ),
  updatePointByEmployeeIdAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'updatePointByEmployeeId'),
    async (updatePointByEmployeeIdDto: UpdatePointByEmployeeIdDto) => {
      try {
        await AxiosService.put(FLEX_BENEFIT_URLS.updatePointByEmployeeId, updatePointByEmployeeIdDto)
      } catch (error) {
        throw error
      }
    }
  ),
  downloadPointTemplateAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'downloadPointTemplate'),
    async (customerId: string) => {
      try {
        const result = await AxiosService.get(FLEX_BENEFIT_URLS.downloadPointTemplate(customerId), {
          ...REQUEST_OPTIONS
        })
        saveData(result, result.request.getResponseHeader('Content-Disposition'))
      } catch (error) {
        throw error
      }
    }
  ),
  importPointAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'importPoint'),
    async ({ file, yearId, customerId }: { file: File; yearId: string; customerId: string }) => {
      try {
        const formData = new FormData()
        formData.append('file', file)
        await AxiosService.post(FLEX_BENEFIT_URLS.importPoint(yearId, customerId), formData)
      } catch (error) {
        throw error
      }
    }
  ),
  exportFlexBenefitAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'exportFlexBenefit'),
    async ({ customerId, queryParams }: { customerId: string; queryParams: ExportFlexBenefitQueryParams }) => {
      try {
        const result = await AxiosService.get(FLEX_BENEFIT_URLS.exportFlexBenefit(customerId), {
          ...REQUEST_OPTIONS,
          params: queryParams
        })
        saveData(result, result.request.getResponseHeader('Content-Disposition'))
      } catch (error) {
        throw error
      }
    }
  ),
  getYearMasterDataEnrollmentFilteringAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getYearMasterDataEnrollmentFiltering'),
    async (customerId: string) => {
      try {
        const result = await AxiosService.get<GetYearsMasterDataEnrollmentFilteringResponse>(
          FLEX_BENEFIT_URLS.getYearMasterDataEnrollmentFiltering(customerId)
        )
        return result.data.years
      } catch (error) {
        throw error
      }
    }
  ),
  exportAllFlexBenefitAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'exportAllFlexBenefit'),
    async (queryParams: ExportAllFlexBenefitQueryParams) => {
      try {
        const result = await AxiosService.get(FLEX_BENEFIT_URLS.exportAllFlexBenefit, {
          ...REQUEST_OPTIONS,
          params: queryParams
        })
        saveData(result, result.request.getResponseHeader('Content-Disposition'))
      } catch (error) {
        throw error
      }
    }
  )
}
