import React from 'react'

import { IconProps } from '../../../types/types'

const CancelIcon = ({ color, size = 16 }: IconProps) => {
  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} xmlns='http://www.w3.org/2000/svg'>
      <path
        transform={`scale(${size / 16})`}
        d='M11.814 4.186a.636.636 0 0 0-.899 0L8 7.101 5.085 4.186a.636.636 0 0 0-.899.899L7.101 8l-2.915 2.915a.636.636 0 0 0 .899.899L8 8.899l2.915 2.915a.636.636 0 0 0 .899-.899L8.899 8l2.915-2.915a.636.636 0 0 0 0-.899z'
        fill={color || '#4D4949'}
        fillRule='nonzero'
      />
    </svg>
  )
}

export default CancelIcon
