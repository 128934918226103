import { Box } from '@mui/material'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import * as yup from 'yup'

import CustomSwitch from '../../components/global/interactions/CustomSwitch'
import BackButton from '../../components/project/navigations/BackButton'
import { YupValidation } from '../../constants/yup'
import { onUploadImage } from '../upload-service/utils/uploadUtils'
import AddOrEditHRUserForm from './components/AddOrEditHRUserForm'
import { HRUserService } from './services/hr-user/HRUserService'
import { CreateHRUserDto } from './services/hr-user/types'

const initialValues = {
  name: '',
  profileImage: null,
  position: '',
  phoneNumber: '',
  email: '',
  employeeCode: '',
  subCompanyIdList: [] as string[],
  departmentIdList: [] as string[],
  sectionIdList: [] as string[],
  isActive: true
}

const validationSchema = yup.object({
  phoneNumber: YupValidation.telNumber,
  email: YupValidation.email()
})

const AddHRUser = () => {
  const [isLoading, setIsLoading] = useState(false)

  const { customerId } = useParams()
  const navigate = useNavigate()

  const onToggleIsActive = () => formik.setFieldValue('isActive', !formik.values.isActive)
  const onSubmitAdd = async () => {
    try {
      if (!customerId) return
      setIsLoading(true)
      const imageUrl = await onUploadImage(formik.values.profileImage, 'user', '')
      const createHRUserDto: CreateHRUserDto = {
        hr: {
          profileUrl: imageUrl,
          customerId: customerId,
          name: formik.values.name,
          position: formik.values.position,
          phoneNumber: formik.values.phoneNumber,
          email: formik.values.email,
          isActive: formik.values.isActive,
          employeeCode: formik.values.employeeCode,
          sectionIdList: formik.values.sectionIdList,
          subCompanyIdList: formik.values.subCompanyIdList,
          departmentIdList: formik.values.departmentIdList
        }
      }
      await HRUserService.createHRUser(createHRUserDto)
      navigate(-1)
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }

  const formik = useFormik({
    initialValues,
    onSubmit: onSubmitAdd,
    validationSchema
  })

  return (
    <>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <BackButton title='เพิ่มผู้ใช้งาน' />
        <CustomSwitch value={formik.values.isActive} onChange={onToggleIsActive} />
      </Box>
      <AddOrEditHRUserForm formik={formik} isLoading={isLoading} />
    </>
  )
}

export default AddHRUser
