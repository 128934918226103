import { createAsyncThunk } from '@reduxjs/toolkit'

import { USER_URLS } from '../../../../configs/api'
import { AxiosService } from '../../../../services/api'
import { createThunkName } from '../../../../utils/createThunkname'
import {
  CreateUserDto,
  GetAllUserQueryParams,
  GetAllUserResponse,
  GetUserByIdResponse,
  ResetUserPasswordDto,
  UpdateUserDto,
  UpdateUserStatusDto
} from './types'

const PREFIX = 'user'

export const UserService = {
  getAllUserAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getAllUser'),
    async (getAllUserQueryParams: GetAllUserQueryParams) => {
      try {
        const result = await AxiosService.get<GetAllUserResponse>(USER_URLS.getAllUser, {
          params: getAllUserQueryParams
        })
        return result.data.users
      } catch (error) {
        throw error
      }
    }
  ),
  getUserByIdAsyncThunk: createAsyncThunk(createThunkName(PREFIX, 'getUserById'), async (id: string) => {
    try {
      const result = await AxiosService.get<GetUserByIdResponse>(USER_URLS.getUserById(id))
      return result.data.user
    } catch (error) {
      throw error
    }
  }),
  createUser: async (createUserDto: CreateUserDto) => {
    try {
      await AxiosService.post(USER_URLS.createUser, createUserDto)
    } catch (error) {
      throw error
    }
  },
  updateUser: async (updateUserDto: UpdateUserDto) => {
    try {
      await AxiosService.put(USER_URLS.updateUser, updateUserDto)
    } catch (error) {
      throw error
    }
  },
  resetUserPassword: async (resetUserPasswordDto: ResetUserPasswordDto) => {
    try {
      await AxiosService.put(USER_URLS.resetUserPassword, resetUserPasswordDto)
    } catch (error) {
      throw error
    }
  },
  updateUserStatus: async (updateUserStatusDto: UpdateUserStatusDto) => {
    try {
      await AxiosService.put(USER_URLS.updateUserStatus, updateUserStatusDto)
    } catch (error) {
      throw error
    }
  }
}
