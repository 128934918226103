import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { transformMasterData } from '../../../../utils/transformMasterData'
import { HRUserService } from '../../services/hr-user/HRUserService'
import { HRUserFilterValue, HRUserListFilterValue, HRUserState } from './types'

const hrUserFilterInitialValues = {
  search: '',
  page: '1',
  pageSize: '20'
}

const hrUserListFilterInitialValues = {
  search: '',
  status: 'ทั้งหมด',
  page: '1',
  pageSize: '20'
}

const initialState: HRUserState = {
  hrUserFilterValue: hrUserFilterInitialValues,
  hrUserListFilterValue: hrUserListFilterInitialValues,
  isGetAllCustomersForHRLoading: false,
  isGetAllHRForUsersByCustomerIdLoading: false,
  isGetDepartmentListMasterDataLoading: false,
  isGetHRInfoLoading: false,
  isGetSectionListMasterDataLoading: false,
  isGetSubCompanyListMasterDataLoading: false,
  sectionListMasterData: [],
  departmentListMasterData: [],
  subCompanyListMasterData: []
}

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setHRUserFilterValues(state, action: PayloadAction<{ field: keyof HRUserFilterValue; value: string }>) {
      const { field, value } = action.payload
      if (field === 'pageSize') state.hrUserFilterValue.page = '1'
      state.hrUserFilterValue[field] = value
    },
    setHRUserListFilterValues(state, action: PayloadAction<{ field: keyof HRUserListFilterValue; value: string }>) {
      const { field, value } = action.payload
      if (field === 'pageSize') state.hrUserFilterValue.page = '1'
      state.hrUserListFilterValue[field] = value
    },
    resetHRUserFilterValues(state) {
      state.hrUserFilterValue = hrUserFilterInitialValues
    },
    resetHRUserListFilterValues(state) {
      state.hrUserListFilterValue = hrUserListFilterInitialValues
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(HRUserService.getAllCustomerForHRAsyncThunk.pending, (state) => {
        state.isGetAllCustomersForHRLoading = true
      })
      .addCase(HRUserService.getAllCustomerForHRAsyncThunk.rejected, (state) => {
        state.isGetAllCustomersForHRLoading = false
      })
      .addCase(HRUserService.getAllCustomerForHRAsyncThunk.fulfilled, (state, action) => {
        state.fetchedAllCustomersForHR = action.payload
        state.isGetAllCustomersForHRLoading = false
      })
      .addCase(HRUserService.getAllHRByCustomerIdAsyncThunk.pending, (state) => {
        state.isGetAllHRForUsersByCustomerIdLoading = true
      })
      .addCase(HRUserService.getAllHRByCustomerIdAsyncThunk.rejected, (state) => {
        state.isGetAllHRForUsersByCustomerIdLoading = false
      })
      .addCase(HRUserService.getAllHRByCustomerIdAsyncThunk.fulfilled, (state, action) => {
        state.fetchedAllHRUsersByCustomerId = action.payload
        state.isGetAllHRForUsersByCustomerIdLoading = false
      })
      .addCase(HRUserService.getHRInfoByIdAsyncThunk.pending, (state) => {
        state.isGetHRInfoLoading = true
      })
      .addCase(HRUserService.getHRInfoByIdAsyncThunk.rejected, (state) => {
        state.isGetHRInfoLoading = false
      })
      .addCase(HRUserService.getHRInfoByIdAsyncThunk.fulfilled, (state, action) => {
        state.fetchedHRUserInfo = action.payload
        state.isGetHRInfoLoading = false
      })
      .addCase(HRUserService.getDepartmentListMasterDataAsyncThunk.pending, (state) => {
        state.isGetDepartmentListMasterDataLoading = true
      })
      .addCase(HRUserService.getDepartmentListMasterDataAsyncThunk.rejected, (state) => {
        state.isGetDepartmentListMasterDataLoading = false
      })
      .addCase(HRUserService.getDepartmentListMasterDataAsyncThunk.fulfilled, (state, action) => {
        state.departmentListMasterData = transformMasterData(action.payload.departmentList)
        state.isGetDepartmentListMasterDataLoading = false
      })
      .addCase(HRUserService.getSectionMasterDataAsyncThunk.pending, (state) => {
        state.isGetSectionListMasterDataLoading = true
      })
      .addCase(HRUserService.getSectionMasterDataAsyncThunk.rejected, (state) => {
        state.isGetSectionListMasterDataLoading = false
      })
      .addCase(HRUserService.getSectionMasterDataAsyncThunk.fulfilled, (state, action) => {
        state.sectionListMasterData = transformMasterData(action.payload.sectionList)
        state.isGetSectionListMasterDataLoading = false
      })
      .addCase(HRUserService.getSubCompanyListMasterDataAsyncThunk.pending, (state) => {
        state.isGetSubCompanyListMasterDataLoading = true
      })
      .addCase(HRUserService.getSubCompanyListMasterDataAsyncThunk.rejected, (state) => {
        state.isGetSubCompanyListMasterDataLoading = false
      })
      .addCase(HRUserService.getSubCompanyListMasterDataAsyncThunk.fulfilled, (state, action) => {
        state.subCompanyListMasterData = transformMasterData(action.payload.subCompanyList)
        state.isGetSubCompanyListMasterDataLoading = false
      })
  }
})

export const {
  setHRUserListFilterValues,
  resetHRUserListFilterValues,
  setHRUserFilterValues,
  resetHRUserFilterValues
} = slice.actions
export default slice.reducer
