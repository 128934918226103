import { AxiosResponse } from 'axios'

export const saveData = (response: AxiosResponse, contentDispositionHeader: string) => {
  const fileName = contentDispositionHeader.replace('attachment; filename=', '').replaceAll('"', '')
  const url = window.URL.createObjectURL(new Blob([response.data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName) //or any other extension
  document.body.appendChild(link)
  link.click()
}
