import React from 'react'

import { COLORS_THEME } from '../../../../constants/colors'

const EClaimMenuIcon = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
      <g fill={COLORS_THEME.primary} fillRule='nonzero'>
        <path d='M12.273 21.997c-.439-.26-.507-.428-.412-.946.13-.71.254-1.42.374-2.132.033-.225.14-.433.301-.592 2.002-1.994 4.002-3.99 6.001-5.987.177-.18.379-.336.599-.46.476-.262.975-.191 1.369.182.378.357.76.716 1.096 1.11.179.209.269.493.399.743v.47c-.09.312-.222.602-.461.84-2.036 2.031-4.071 4.062-6.105 6.094a1.017 1.017 0 0 1-.454.238c-.313.078-.64.114-.959.173-.478.087-.956.18-1.434.27l-.314-.003zM10.44 19.497H4.387C2.99 19.497 2 18.51 2 17.114V4.386C2 2.991 2.991 2 4.386 2h9.393c1.397 0 2.388.986 2.39 2.382 0 2.59 0 5.18.003 7.772a.434.434 0 0 1-.146.338c-1.51 1.502-3.004 3.018-4.526 4.504-.54.527-.867 1.125-.948 1.868-.022.205-.071.408-.112.633zM9.09 10.33h2.87c.313 0 .627.01.94-.006a.817.817 0 0 0 .75-.968c-.077-.428-.405-.686-.883-.687H6.538c-.41 0-.82-.005-1.23.003a.815.815 0 0 0-.765.565c-.182.547.216 1.087.817 1.09 1.244.007 2.487.002 3.73.003zm-1.67 3.338h1.952c.091.001.182-.006.271-.02a.818.818 0 0 0 .685-.882.816.816 0 0 0-.796-.763 452.414 452.414 0 0 0-4.235 0 .814.814 0 0 0-.756.578c-.174.547.226 1.079.83 1.086.682.008 1.365.002 2.049.002zM7.004 7h1.618c.502 0 .875-.353.879-.824.004-.471-.361-.84-.862-.843a257.333 257.333 0 0 0-3.277 0c-.5.003-.865.373-.86.845.005.471.378.82.882.822.539.002 1.078 0 1.618 0h.002z' />
      </g>
    </svg>
  )
}

export default EClaimMenuIcon
