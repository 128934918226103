import { Box, Button, ButtonProps, CircularProgress, styled, Typography } from '@mui/material'
import { Variant } from '@mui/material/styles/createTypography'
import React, { ReactNode } from 'react'

import { COLORS } from '../../../constants/colors'

interface CustomButtonProps {
  text: string
  exactWidth?: number
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning'
  textVariant?: Variant
  startIcon?: ReactNode
  endIcon?: ReactNode
  size?: 'small' | 'medium' | 'large'
  variant?: 'text' | 'contained' | 'outlined'
  disabledBorder?: boolean
  onClick?: () => void
  isLoading?: boolean
  isDisabled?: boolean
  fullWidth?: boolean
  type?: 'button' | 'reset' | 'submit'
  direction?: 'row' | 'column'
  borderRadius?: number
  customBorderColor?: string
}

interface StyledButtonProps extends ButtonProps {
  borderRadius: number
  direction?: 'row' | 'column'
  customSize?: string
  disabledBorder?: boolean
  customBorderColor?: string
}

const CustomButton = ({
  text,
  exactWidth,
  startIcon,
  endIcon,
  disabledBorder,
  onClick,
  isLoading,
  isDisabled,
  fullWidth,
  color = 'primary',
  size = 'large',
  textVariant = 'body1',
  direction = 'row',
  variant = 'contained',
  type = 'button',
  borderRadius = 2,
  customBorderColor
}: CustomButtonProps) => {
  if (isLoading) {
    return (
      <Box position='relative'>
        {isLoading && <StyledProgress size={24} />}
        <StyledButton
          borderRadius={borderRadius}
          fullWidth={fullWidth}
          onClick={isLoading ? undefined : onClick}
          customSize={size}
          variant={variant}
          disabledBorder={disabledBorder}
          style={exactWidth ? { width: exactWidth } : {}}
          disableElevation
          color={color}
          disabled={isDisabled || isLoading}
          type={type}
          direction={direction}
          customBorderColor={customBorderColor}
        >
          {startIcon && (
            <Box display='flex' alignItems='center' marginRight={2}>
              {startIcon}
            </Box>
          )}
          <Typography
            style={{ color: variant === 'outlined' ? 'inherit' : COLORS.white }}
            variant={textVariant}
            whiteSpace='nowrap'
          >
            {text}
          </Typography>
          {endIcon && (
            <Box display='flex' alignItems='center' marginLeft={2}>
              {endIcon}
            </Box>
          )}
        </StyledButton>
      </Box>
    )
  }

  return (
    <StyledButton
      borderRadius={borderRadius}
      fullWidth={fullWidth}
      onClick={isLoading ? undefined : onClick}
      customSize={size || 'large'}
      variant={variant}
      disabledBorder={disabledBorder}
      style={exactWidth ? { width: exactWidth } : {}}
      disableElevation
      color={color || 'primary'}
      disabled={isDisabled || isLoading}
      type={type}
      direction={direction}
      customBorderColor={customBorderColor}
    >
      {startIcon && (
        <Box display='flex' alignItems='center' marginRight={2}>
          {startIcon}
        </Box>
      )}
      <Typography
        style={{ color: variant === 'outlined' ? 'inherit' : COLORS.white }}
        variant={textVariant || 'body1'}
        whiteSpace='nowrap'
      >
        {text}
      </Typography>
      {endIcon && (
        <Box display='flex' alignItems='center' marginLeft={2}>
          {endIcon}
        </Box>
      )}
    </StyledButton>
  )
}

const StyledProgress = styled(CircularProgress)(({ theme }) => ({
  zIndex: 10,
  position: 'absolute',
  top: '50%',
  left: '50%',
  color: theme.palette.primary.main,
  marginTop: '-12px',
  marginLeft: '-12px'
}))

const StyledButton = styled(Button, {
  shouldForwardProp: (props) =>
    !['customSize', 'disabledBorder', 'direction', 'borderRadius', 'customBorderColor'].includes(props as string)
})<StyledButtonProps>(({ customSize, theme, disabledBorder, direction, borderRadius, customBorderColor, variant }) => ({
  display: 'flex',
  flexDirection: direction,
  borderRadius: theme.spacing(borderRadius),
  padding:
    customSize === 'large' ? theme.spacing(2, 10) : customSize === 'small' ? theme.spacing(1, 4) : theme.spacing(2, 6),
  borderColor: disabledBorder ? 'black' : customBorderColor ? customBorderColor : '',
  backgroundColor: variant === 'outlined' ? COLORS.white : '',
  textTransform: 'none',
  '&.Mui-disabled': {
    backgroundColor: COLORS.grey350
  },
  '&:hover': {
    borderColor: disabledBorder ? 'black' : customBorderColor ? customBorderColor : ''
  }
}))

export default CustomButton
