import React from 'react'

import { COLORS_THEME } from '../../../../constants/colors'

const FlexBenefitMenuIcon = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11.843 2h.32a.671.671 0 0 0 .082.045l7.731 2.808c.375.136.518.312.518.673 0 2.317.018 4.635-.01 6.95-.023 1.816-.585 3.479-1.65 4.967-1.269 1.767-2.985 2.994-4.96 3.889-.556.252-1.14.447-1.71.668h-.32c-.072-.028-.143-.06-.217-.084-1.89-.625-3.598-1.56-5.058-2.901-1.909-1.755-2.987-3.904-3.042-6.481-.05-2.344-.015-4.685-.015-7.028 0-.33.146-.517.478-.638l6.167-2.24c.565-.205 1.125-.42 1.686-.628zm.155 6.476c-.072-.079-.123-.139-.178-.194a2.908 2.908 0 0 0-3.565-.408c-1.528.952-1.823 3.167-.568 4.508 1.255 1.34 2.559 2.662 3.84 3.993.169.176.382.254.612.186a.955.955 0 0 0 .392-.247c1.224-1.258 2.435-2.524 3.656-3.784.72-.741 1.007-1.614.874-2.627-.243-1.855-2.24-2.97-3.985-2.226-.415.176-.758.445-1.078.799z'
        fill={COLORS_THEME.primary}
        fillRule='nonzero'
      />
    </svg>
  )
}

export default FlexBenefitMenuIcon
