import { RootState } from '../../../../stores/store'

// State
export const isGetAllCustomersForHRLoadingSelector = (state: RootState) => state.hrUser.isGetAllCustomersForHRLoading
export const isGetAllHRForUsersByCustomerIdLoadingSelector = (state: RootState) =>
  state.hrUser.isGetAllHRForUsersByCustomerIdLoading
export const isGetHRInfoLoadingSelector = (state: RootState) => state.hrUser.isGetHRInfoLoading
export const isGetSubCompanyListMasterDataLoadingSelector = (state: RootState) =>
  state.hrUser.isGetSubCompanyListMasterDataLoading
export const isGetDepartmentListMasterDataLoadingSelector = (state: RootState) =>
  state.hrUser.isGetDepartmentListMasterDataLoading
export const isGetSectionListMasterDataLoadingSelector = (state: RootState) =>
  state.hrUser.isGetSectionListMasterDataLoading
export const hrUserFilterValueSelector = (state: RootState) => state.hrUser.hrUserFilterValue
export const hrUserListFilterValueSelector = (state: RootState) => state.hrUser.hrUserListFilterValue
// Response
export const fetchedAllCustomersForHRSelector = (state: RootState) => state.hrUser.fetchedAllCustomersForHR
export const fetchedAllHRUsersByCustomerIdSelector = (state: RootState) => state.hrUser.fetchedAllHRUsersByCustomerId
export const fetchedHRUserInfoSelector = (state: RootState) => state.hrUser.fetchedHRUserInfo
// MasterData
export const subCompanyListMasterDataSelector = (state: RootState) => state.hrUser.subCompanyListMasterData
export const departmentListMasterDataSelector = (state: RootState) => state.hrUser.departmentListMasterData
export const sectionListMasterDataSelector = (state: RootState) => state.hrUser.sectionListMasterData
