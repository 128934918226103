import { UploadService } from '../services/UploadServices'

export const onUploadImage = async (file: File | null, prefix: UploadImagePrefix, imageUrl: string) => {
  let newImageUrl = imageUrl
  if (file) {
    const formData = new FormData()
    formData.append('image', file)
    const result = await UploadService.uploadImage({ prefix }, formData)
    newImageUrl = result
  }
  return newImageUrl
}

export const onUploadFile = async (file: File | null, prefix: UploadFilePrefix, fileUrl: string) => {
  let newFileUrl = fileUrl
  if (file) {
    const formData = new FormData()
    formData.append('file', file)
    const result = await UploadService.uploadFile({ prefix }, formData)
    newFileUrl = result
  }
  return newFileUrl
}
