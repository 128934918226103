import { UPLOAD_URLS } from '../../../configs/api'
import { AxiosService } from '../../../services/api'

export const UploadService = {
  uploadImage: async (queryParams: UploadImageQueryParams, body: FormData) => {
    try {
      const result = await AxiosService.post<UploadImageResponse>(UPLOAD_URLS.uploadImage, body, {
        params: queryParams
      })
      return result.data.imageUrl
    } catch (error) {
      throw error
    }
  },
  uploadFile: async (queryParams: UploadFileQueryParams, body: FormData) => {
    try {
      const result = await AxiosService.post<UploadFileResponse>(UPLOAD_URLS.uploadFile, body, { params: queryParams })
      return result.data.fileUrl
    } catch (error) {
      throw error
    }
  }
}
