import { Pagination, styled } from '@mui/material'
import React from 'react'

import { COLORS } from '../../../constants/colors'
import { fontSize } from '../../../themes/themes'

interface CustomPaginationProps {
  currentPage?: number
  totalPage?: number
  onChangePage?: (_: React.ChangeEvent<unknown>, value: number) => void
}

const CustomPagination = ({ currentPage = 1, totalPage = 10, onChangePage }: CustomPaginationProps) => {
  return <StyledPagination page={currentPage} count={totalPage} onChange={onChangePage} color='primary' />
}

const StyledPagination = styled(Pagination)(({ count, theme }) => ({
  width: 'max-content',
  ul: {
    alignItems: 'baseline'
  },
  '& ul li .MuiPaginationItem-root': {
    fontSize: fontSize.body2,
    fontWeight: 'normal',
    minWidth: '24px',
    maxWidth: '24px',
    minHeight: '24px',
    maxHeight: '24px',
    zIndex: 2,
    borderRadius: theme.spacing(2)
  },
  '& ul li:first-of-type': {
    marginRight: theme.spacing(1)
  },
  '& ul li:last-of-type': {
    marginLeft: theme.spacing(1)
  },
  '& ul li .MuiPaginationItem-previousNext': {
    borderRadius: theme.spacing(2),
    border: `1px solid ${COLORS.grey350}`,
    background: 'white'
  },
  '& ul': {
    position: 'relative'
  },
  '& ul li .MuiPaginationItem-ellipsis': {
    color: 'black',
    opacity: 1
  },
  '& ul::after': {
    content: '""',
    position: 'absolute',
    width: `${count! >= 8 ? 8 * 32 : count! * 40}px`,
    height: '24px',
    zIndex: 1,
    opacity: 0.035,
    borderRadius: theme.spacing(2),
    left: '50%',
    transform: 'translateX(-50%)'
  }
}))

export default CustomPagination
