import React from 'react'

import { COLORS } from '../../../constants/colors'
import { IconProps } from '../../../types/types'

const ArrowLeftIcon = ({ color = COLORS.grey700, size = 24 }: IconProps) => {
  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} xmlns='http://www.w3.org/2000/svg'>
      <path
        transform={`scale(${size / 16})`}
        d='M10.571 2.857 5.634 7.848a.444.444 0 0 0 .003.629l4.934 4.88h0'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        fill='none'
        fillRule='evenodd'
      />
    </svg>
  )
}

export default ArrowLeftIcon
