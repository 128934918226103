export interface WithItemLog {
  createdBy?: string
  createdAt?: string
  updatedAt?: string
  updatedBy?: string
}

export enum Language {
  EN = 'en',
  TH = 'th'
}

export interface WithPagination {
  pagination: {
    page: number
    pageCount: number
    totalCount: number
  }
}

export interface MasterData {
  id: string
  name: string
}

export interface TransformedMasterData {
  id: string
  label: string
}

export enum Status {
  Pending = 'pending',
  NeedInfo = 'needInfo',
  Approve = 'approved',
  Rejected = 'rejected'
}

export enum CustomerStatus {
  Visitor = 'visitor',
  Lead = 'lead',
  Verified = 'verified',
  PlanConfirmed = 'planConfirmed',
  EmpConfirmed = 'empConfirmed',
  Unpaid = 'unpaid',
  Current = 'current'
}

export enum MedicalStatus {
  Normal = 'normal',
  Abnormal = 'abnormal'
}

export interface IconProps {
  color?: string
  size?: number
}

export type RelationshipType = 'employee' | 'couple' | 'children' | 'father' | 'mother'

export type SortDirection = 'asc' | 'desc' | ''

export interface Pagination {
  pageSize: number
  page: number
}
