export const PERMISSIONS = {
  roleManagement: 'Role Management',
  userManagement: 'User Management',
  companyInfo: 'Company Info',
  companyUsers: 'Company Users',
  dashboard: 'Dashboard',
  flexBenefit: 'Flex Benefit',
  eClaim: 'E-Claim',
  customer: 'Customer'
}
