import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { transformMasterData } from '../../../utils/transformMasterData'
import { FlexBenefitService } from '../services/FlexBenefitService'
import { FlexBenefitByCompanyFilterValue, FlexBenefitFilterValue, FlexBenefitState } from './types'

const flexBenefitFilterInitialValues = {
  search: '',
  status: '',
  page: '1',
  pageSize: '20'
}

const flexBenefitByCompanyInitialValues = {
  search: '',
  employeeLevelId: 'ทั้งหมด',
  gender: 'ทั้งหมด',
  page: '1',
  pageSize: '10'
}

const initialState: FlexBenefitState = {
  //State
  isFetchedCustomersLoading: false,
  isFetchedFlexBenefitByCustomerIdLoading: false,
  isFetchedFlexBenefitDetailByEmployeeIdLoading: false,
  isUpdatePointByEmployeeIdLoading: false,
  isDownloadPointTemplateLoading: false,
  isImportPointLoading: false,
  isExportFlexBenefitLoading: false,
  isYearsMasterDataEnrollmentFilteringLoading: false,
  isExportAllFlexBenefitLoading: false,
  flexBenefitFilterValues: flexBenefitFilterInitialValues,
  flexBenefitByCompanyFilterValues: flexBenefitByCompanyInitialValues,
  flexBenefitYearId: '',

  //MasterData
  yearsMasterDataEnrollmentFiltering: []
}
const slice = createSlice({
  name: 'flex-benefit',
  initialState,
  reducers: {
    setFlexBenefitFilterValues(state, action: PayloadAction<{ field: keyof FlexBenefitFilterValue; value: string }>) {
      const { field, value } = action.payload
      if (field === 'pageSize') state.flexBenefitFilterValues.page = '1'
      state.flexBenefitFilterValues[field] = value
    },
    resetFlexBenefitFilterValues(state) {
      state.flexBenefitFilterValues = flexBenefitFilterInitialValues
    },
    setFlexBenefitByCompanyFilterValues(
      state,
      action: PayloadAction<{ field: keyof FlexBenefitByCompanyFilterValue; value: string }>
    ) {
      const { field, value } = action.payload
      if (field === 'pageSize') state.flexBenefitByCompanyFilterValues.page = '1'
      state.flexBenefitByCompanyFilterValues[field] = value
    },
    resetFlexBenefitByCompanyFilterValues(state) {
      state.flexBenefitByCompanyFilterValues = flexBenefitByCompanyInitialValues
    },
    setFlexBenefitYearId: (state, action: PayloadAction<string>) => {
      state.flexBenefitYearId = action.payload
    },
    resetFlexBenefitState: (state) => {
      state.isFetchedCustomersLoading = false
      state.isFetchedFlexBenefitByCustomerIdLoading = false
      state.isFetchedFlexBenefitDetailByEmployeeIdLoading = false
      state.isUpdatePointByEmployeeIdLoading = false
      state.isDownloadPointTemplateLoading = false
      state.isImportPointLoading = false
      state.isExportFlexBenefitLoading = false
      state.isYearsMasterDataEnrollmentFilteringLoading = false
      state.isExportAllFlexBenefitLoading = false
      state.yearsMasterDataEnrollmentFiltering = []
    },
    resetFlexBenefitYearId: (state) => {
      state.flexBenefitYearId = ''
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(FlexBenefitService.getAllCustomerAsyncThunk.pending, (state) => {
        state.isFetchedCustomersLoading = true
      })
      .addCase(FlexBenefitService.getAllCustomerAsyncThunk.rejected, (state) => {
        state.isFetchedCustomersLoading = false
      })
      .addCase(FlexBenefitService.getAllCustomerAsyncThunk.fulfilled, (state, action) => {
        state.fetchedCustomers = action.payload
        state.isFetchedCustomersLoading = false
      })
      .addCase(FlexBenefitService.getAllFlexBenefitByCustomerIdAsyncThunk.pending, (state) => {
        state.isFetchedFlexBenefitByCustomerIdLoading = true
      })
      .addCase(FlexBenefitService.getAllFlexBenefitByCustomerIdAsyncThunk.rejected, (state) => {
        state.isFetchedFlexBenefitByCustomerIdLoading = false
      })
      .addCase(FlexBenefitService.getAllFlexBenefitByCustomerIdAsyncThunk.fulfilled, (state, action) => {
        state.fetchedFlexBenefitByCustomerId = action.payload
        state.isFetchedFlexBenefitByCustomerIdLoading = false
      })
      .addCase(FlexBenefitService.getFlexBenefitDetailByEmployeeIdAsyncThunk.pending, (state) => {
        state.isFetchedFlexBenefitDetailByEmployeeIdLoading = true
      })
      .addCase(FlexBenefitService.getFlexBenefitDetailByEmployeeIdAsyncThunk.rejected, (state) => {
        state.isFetchedFlexBenefitDetailByEmployeeIdLoading = false
      })
      .addCase(FlexBenefitService.getFlexBenefitDetailByEmployeeIdAsyncThunk.fulfilled, (state, action) => {
        state.fetchedFlexBenefitDetailByEmployeeId = action.payload
        state.isFetchedFlexBenefitDetailByEmployeeIdLoading = false
      })
      .addCase(FlexBenefitService.updatePointByEmployeeIdAsyncThunk.pending, (state) => {
        state.isUpdatePointByEmployeeIdLoading = true
      })
      .addCase(FlexBenefitService.updatePointByEmployeeIdAsyncThunk.rejected, (state) => {
        state.isUpdatePointByEmployeeIdLoading = false
      })
      .addCase(FlexBenefitService.updatePointByEmployeeIdAsyncThunk.fulfilled, (state) => {
        state.isUpdatePointByEmployeeIdLoading = false
      })
      .addCase(FlexBenefitService.downloadPointTemplateAsyncThunk.pending, (state) => {
        state.isDownloadPointTemplateLoading = true
      })
      .addCase(FlexBenefitService.downloadPointTemplateAsyncThunk.rejected, (state) => {
        state.isDownloadPointTemplateLoading = false
      })
      .addCase(FlexBenefitService.downloadPointTemplateAsyncThunk.fulfilled, (state) => {
        state.isDownloadPointTemplateLoading = false
      })
      .addCase(FlexBenefitService.importPointAsyncThunk.pending, (state) => {
        state.isImportPointLoading = true
      })
      .addCase(FlexBenefitService.importPointAsyncThunk.rejected, (state) => {
        state.isImportPointLoading = false
      })
      .addCase(FlexBenefitService.importPointAsyncThunk.fulfilled, (state) => {
        state.isImportPointLoading = false
      })
      .addCase(FlexBenefitService.exportFlexBenefitAsyncThunk.pending, (state) => {
        state.isExportFlexBenefitLoading = true
      })
      .addCase(FlexBenefitService.exportFlexBenefitAsyncThunk.rejected, (state) => {
        state.isExportFlexBenefitLoading = false
      })
      .addCase(FlexBenefitService.exportFlexBenefitAsyncThunk.fulfilled, (state) => {
        state.isExportFlexBenefitLoading = false
      })
      .addCase(FlexBenefitService.getYearMasterDataEnrollmentFilteringAsyncThunk.pending, (state) => {
        state.isYearsMasterDataEnrollmentFilteringLoading = true
      })
      .addCase(FlexBenefitService.getYearMasterDataEnrollmentFilteringAsyncThunk.rejected, (state) => {
        state.isYearsMasterDataEnrollmentFilteringLoading = false
      })
      .addCase(FlexBenefitService.getYearMasterDataEnrollmentFilteringAsyncThunk.fulfilled, (state, action) => {
        state.yearsMasterDataEnrollmentFiltering = transformMasterData(action.payload)
        state.isYearsMasterDataEnrollmentFilteringLoading = false
      })
      .addCase(FlexBenefitService.exportAllFlexBenefitAsyncThunk.pending, (state) => {
        state.isExportAllFlexBenefitLoading = true
      })
      .addCase(FlexBenefitService.exportAllFlexBenefitAsyncThunk.rejected, (state) => {
        state.isExportAllFlexBenefitLoading = false
      })
      .addCase(FlexBenefitService.exportAllFlexBenefitAsyncThunk.fulfilled, (state) => {
        state.isExportAllFlexBenefitLoading = false
      })
  }
})

export const {
  resetFlexBenefitState,
  resetFlexBenefitFilterValues,
  setFlexBenefitFilterValues,
  setFlexBenefitByCompanyFilterValues,
  resetFlexBenefitByCompanyFilterValues,
  setFlexBenefitYearId,
  resetFlexBenefitYearId
} = slice.actions
export default slice.reducer
