import React from 'react'

import { COLORS_THEME } from '../../../../constants/colors'

const UserMenuIcon = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11.843 2h.313a.21.21 0 0 0 .045.033l7.211 2.624c.453.164.582.349.583.828 0 1.967.011 3.934 0 5.904a51.57 51.57 0 0 1-.083 2.948 7.394 7.394 0 0 1-.715 2.78c-.57 1.177-1.457 2.066-2.524 2.788-1.374.93-2.892 1.552-4.457 2.068a.872.872 0 0 1-.473-.012c-.294-.08-.578-.194-.864-.3-1.342-.505-2.64-1.094-3.81-1.938-1.285-.925-2.225-2.104-2.67-3.644-.297-1.03-.392-2.088-.395-3.15-.009-2.507 0-5.014 0-7.524 0-.378.152-.588.51-.72l5.324-1.941c.67-.243 1.338-.496 2.005-.744zm.155 14.676h3.256c.454 0 .73-.258.737-.676.031-1.621-1.265-3.12-2.882-3.286-.611-.062-1.232-.046-1.849-.038-1.74.022-3.182 1.441-3.25 3.182-.021.557.228.816.79.816 1.066.003 2.132.003 3.198.002zm-2.66-8.01c0 1.471 1.19 2.663 2.657 2.664a2.662 2.662 0 0 0 2.662-2.659 2.662 2.662 0 0 0-2.65-2.67 2.661 2.661 0 0 0-1.887.779 2.67 2.67 0 0 0-.783 1.887z'
        fill={COLORS_THEME.primary}
        fillRule='nonzero'
      />
    </svg>
  )
}

export default UserMenuIcon
