import { createAsyncThunk } from '@reduxjs/toolkit'

import { E_CLAIM_URLS } from '../../../../configs/api'
import { REQUEST_OPTIONS } from '../../../../constants/request-options'
import { AxiosService } from '../../../../services/api'
import { createThunkName } from '../../../../utils/createThunkname'
import { saveData } from '../../../../utils/downloadFile'
import {
  ApproveBenefitByIdDto,
  ExportBenefitByCustomerIdQueryParams,
  GetAllBenefitByCustomerIdQueryParams,
  GetAllBenefitByCustomerIdResponse,
  GetBenefitByIdResponse,
  GetBenefitDocumentMasterDataResponse,
  GetBenefitMasterDataResponse,
  RejectBenefitByIdDto
} from './types'

const PREFIX = 'benefit-info'

export const BenefitInfoService = {
  getBenefitMasterDataAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getBenefitMasterData'),
    async ({ yearId, customerId }: { yearId: string; customerId: string }) => {
      try {
        const result = await AxiosService.get<GetBenefitMasterDataResponse>(
          E_CLAIM_URLS.masterData.getBenefitMasterData(yearId, customerId)
        )
        return result.data.benefits
      } catch (error) {
        throw error
      }
    }
  ),
  getBenefitDocumentMasterDataAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getBenefitDocumentMasterData'),
    async (benefitId: string) => {
      try {
        const result = await AxiosService.get<GetBenefitDocumentMasterDataResponse>(
          E_CLAIM_URLS.masterData.getBenefitDocumentMasterData(benefitId)
        )
        return result.data.benefitDocuments
      } catch (error) {
        throw error
      }
    }
  ),
  getAllBenefitByCustomerIdAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getAllBenefitByCustomerId'),
    async ({
      queryParams,
      yearId,
      customerId
    }: {
      queryParams: GetAllBenefitByCustomerIdQueryParams
      yearId: string
      customerId: string
    }) => {
      try {
        const result = await AxiosService.get<GetAllBenefitByCustomerIdResponse>(
          E_CLAIM_URLS.benefitTab.getAllBenefitByCustomerId(yearId, customerId),
          { params: queryParams }
        )
        return result.data
      } catch (error) {
        throw error
      }
    }
  ),
  exportBenefitByCustomerIdAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'exportBenefitByCustomerId'),
    async ({ customerId, queryParams }: { customerId: string; queryParams: ExportBenefitByCustomerIdQueryParams }) => {
      try {
        const result = await AxiosService.get(E_CLAIM_URLS.benefitTab.exportBenefitByCustomerId(customerId), {
          ...REQUEST_OPTIONS,
          params: queryParams
        })
        saveData(result, result.request.getResponseHeader('Content-Disposition'))
      } catch (error) {
        throw error
      }
    }
  ),
  getBenefitByIdAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getBenefitById'),
    async (benefitClaimId: string) => {
      try {
        const result = await AxiosService.get<GetBenefitByIdResponse>(
          E_CLAIM_URLS.benefitTab.getBenefitById(benefitClaimId)
        )
        return result.data.benefit
      } catch (error) {
        throw error
      }
    }
  ),
  approveBenefitByIdAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'approveBenefitById'),
    async (approveBenefitByIdDto: ApproveBenefitByIdDto) => {
      try {
        await AxiosService.post(E_CLAIM_URLS.benefitTab.approveBenefitById, approveBenefitByIdDto)
      } catch (error) {
        throw error
      }
    }
  ),
  rejectBenefitByIdAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'rejectBenefitById'),
    async (rejectBenefitByIdDto: RejectBenefitByIdDto) => {
      try {
        await AxiosService.post(E_CLAIM_URLS.benefitTab.rejectBenefitById, rejectBenefitByIdDto)
      } catch (error) {
        throw error
      }
    }
  )
}
