import { createAsyncThunk } from '@reduxjs/toolkit'

import { AUTHENTICATION_URLS } from '../../../configs/api'
import { AxiosService } from '../../../services/api'
import { createThunkName } from '../../../utils/createThunkname'

const PREFIX = 'auth'

export const AuthService = {
  loginAsyncThunk: createAsyncThunk(createThunkName(PREFIX, 'login'), async (loginDto: LoginDto) => {
    try {
      const result = await AxiosService.post<LoginResponse>(AUTHENTICATION_URLS.login, loginDto)
      localStorage.setItem('token', result.data.token)
    } catch (error) {
      throw error
    }
  }),
  forgotPassword: async (forgotPasswordDto: ForgotPasswordDto) => {
    try {
      await AxiosService.post(AUTHENTICATION_URLS.forgotPassword, forgotPasswordDto)
    } catch (error) {
      throw error
    }
  },
  resetPassword: async (resetPasswordDto: ResetPasswordDto) => {
    try {
      await AxiosService.post(AUTHENTICATION_URLS.resetPassword, resetPasswordDto)
    } catch (error) {
      throw error
    }
  },
  checkResetPasswordToken: async (checkPasswordResetTokenDto: CheckResetPasswordTokenDto) => {
    try {
      await AxiosService.get(AUTHENTICATION_URLS.checkPasswordResetToken, {
        params: {
          token: checkPasswordResetTokenDto.token
        }
      })
    } catch (error) {
      throw error
    }
  },
  getCurrentUserAsyncThunk: createAsyncThunk(createThunkName(PREFIX, 'user'), async () => {
    try {
      const result = await AxiosService.get<GetCurrentUserResponse>(AUTHENTICATION_URLS.getCurrentUser)
      return result.data.user
    } catch (error) {
      localStorage.setItem('token', '')
      return undefined
    }
  }),
  logout: () => {
    localStorage.setItem('token', '')
  }
}
