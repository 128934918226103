import React from 'react'

import { COLORS_THEME } from '../../../constants/colors'
import { IconProps } from '../../../types/types'

const SaveIcon = ({ color = COLORS_THEME.primary }: IconProps) => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'>
      <g fill={color} fillRule='nonzero'>
        <path d='M8 6.833A2.333 2.333 0 1 0 8 11.5a2.333 2.333 0 0 0 0-4.667zm0 3.5A1.167 1.167 0 1 1 8 8a1.167 1.167 0 0 1 0 2.333z' />
        <path d='m14.146 3.405-1.55-1.551A2.897 2.897 0 0 0 10.532 1H3.917A2.92 2.92 0 0 0 1 3.917v8.166A2.92 2.92 0 0 0 3.917 15h8.166A2.92 2.92 0 0 0 15 12.083V5.467a2.897 2.897 0 0 0-.854-2.062zm-3.23-1.192v.537a1.75 1.75 0 0 1-1.75 1.75H6.834a1.75 1.75 0 0 1-1.75-1.75v-.583h5.45c.13 0 .258.016.384.046zm2.917 9.87a1.75 1.75 0 0 1-1.75 1.75H3.917a1.75 1.75 0 0 1-1.75-1.75V3.917c0-.967.783-1.75 1.75-1.75v.583a2.92 2.92 0 0 0 2.916 2.917h2.334a2.911 2.911 0 0 0 2.894-2.698l1.26 1.26c.327.33.511.774.512 1.238v6.616z' />
      </g>
    </svg>
  )
}

export default SaveIcon
