import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { PlanService } from '../../services/plan/PlanService'
import { BannerType, EditState, PlanFilterValue, PlanState, PreviewPlan } from './types.d'

const initialPreviewPlanDetail = {
  logoImageUrl: '',
  companyName: '',
  displayInsuranceName: '',
  insuranceName: '',
  lifeInsurance: '',
  accidentalDeathAndDismembermentIndemnity: '',
  permanentDisabilityIndemnityTotal: '',
  hospitalAndBoardPerDay: '',
  icuCostPerDay: '',
  maximumDaysForHospitalIPD: '',
  maximumDaysForICURoom: '',
  nursingFees: '',
  generalHospitalExpenses: '',
  ambulanceFees: '',
  opdFollowUpTreatmentAfterIPDStay: '',
  surgeryFee: '',
  physicianHospitalVisitFee: '',
  accidentCostForOPDPerAccident: '',
  durationAfterOPDTreatment: '',
  specialistConsultationFee: '',
  hbIncentiveBenefit: '',
  maximumHBIncentiveBenefits: '',
  opdTreatingCost: '',
  dentalCost: '',
  lifeInsuranceUnit: '',
  accidentalDeathAndDismembermentIndemnityUnit: '',
  permanentDisabilityIndemnityTotalUnit: '',
  hospitalAndBoardPerDayUnit: '',
  icuCostPerDayUnit: '',
  maximumDaysForHospitalIPDUnit: '',
  maximumDaysForICURoomUnit: '',
  nursingFeesUnit: '',
  generalHospitalExpensesUnit: '',
  ambulanceFeesUnit: '',
  opdFollowUpTreatmentAfterIPDStayUnit: '',
  surgeryFeeUnit: '',
  physicianHospitalVisitFeeUnit: '',
  accidentCostForOPDPerAccidentUnit: '',
  durationAfterOPDTreatmentUnit: '',
  specialistConsultationFeeUnit: '',
  hbIncentiveBenefitUnit: '',
  maximumHBIncentiveBenefitsUnit: '',
  opdTreatingCostUnit: '',
  dentalCostUnit: '',
  otherCoverageDetail: '',
  additionalDetail: ''
}

const planFilterInitialValues = {
  search: '',
  company: 'ทั้งหมด',
  status: 'ทั้งหมด',
  specialLabel: 'ทั้งหมด',
  pageSize: '10',
  page: '1'
}

const initialState: PlanState = {
  // Responses
  fetchedPlans: undefined,
  fetchedPlanInfo: undefined,
  fetchedPlanDetail: undefined,
  timeStampData: undefined,
  insuranceTypesMasterData: [],
  // Stores
  generalInfoEditState: {
    insuranceInfo: false,
    planType: false,
    bannerType: false,
    specialCustomers: false
  },
  isPlanDetailOpen: false,
  isPreviewPlanOpen: false,
  currentPlan: '',
  planTypes: [],
  bannerType: BannerType.None,
  specialCustomers: [],
  planFile: null,
  previewPlan: initialPreviewPlanDetail,
  isPlanActive: true,
  insurancePlan: undefined,
  planFilterValues: planFilterInitialValues
}

const slice = createSlice({
  name: 'plan',
  initialState,
  reducers: {
    togglePlanActive: (state) => {
      state.isPlanActive = !state.isPlanActive
    },
    toggleEditState: (state, action: PayloadAction<{ key: keyof EditState; isEdit: boolean }>) => {
      state.generalInfoEditState[action.payload.key] = action.payload.isEdit
    },
    togglePlanType: (state, action: PayloadAction<string>) => {
      const currentId = action.payload
      if (state.planTypes.includes(currentId)) {
        state.planTypes = state.planTypes.filter((id) => id !== currentId)
      } else {
        state.planTypes.push(currentId)
      }
    },
    changeBannerType: (state, action: PayloadAction<BannerType>) => {
      if (state.bannerType === action.payload) {
        state.bannerType = BannerType.None
      } else {
        state.bannerType = action.payload
      }
    },
    updateSpecialCustomerId: (state, action: PayloadAction<{ id: string; name: string }>) => {
      const id = action.payload.id
      const stateIdsList = state.specialCustomers.map((customer) => customer.id)
      if (stateIdsList.includes(id)) {
        state.specialCustomers = state.specialCustomers.filter((customer) => customer.id !== id)
      } else {
        state.specialCustomers.push(action.payload)
      }
    },
    updatePlanFile: (state, action: PayloadAction<File | null>) => {
      state.planFile = action.payload
    },
    setPreviewPlan: (state, action: PayloadAction<PreviewPlan>) => {
      state.previewPlan = action.payload
    },
    openPreviewPlan: (state) => {
      state.isPreviewPlanOpen = true
    },
    closePreviewPlan: (state) => {
      state.isPreviewPlanOpen = false
    },
    openPlanDetail: (state, action: PayloadAction<string>) => {
      state.currentPlan = action.payload
      state.isPlanDetailOpen = true
    },
    closePlanDetail: (state) => {
      state.currentPlan = ''
      state.isPlanDetailOpen = false
    },
    setPlanFilterValues(state, action: PayloadAction<{ field: keyof PlanFilterValue; value: string }>) {
      const { field, value } = action.payload
      if (field === 'pageSize') state.planFilterValues.page = '1'
      state.planFilterValues[field] = value
    },
    resetPlanFilterValues(state) {
      state.planFilterValues = planFilterInitialValues
    },
    resetPlanState: (state) => {
      // Responses
      state.fetchedPlans = undefined
      state.fetchedPlanInfo = undefined
      state.fetchedPlanDetail = undefined
      state.timeStampData = undefined
      state.insuranceTypesMasterData = []
      // Stores
      state.generalInfoEditState = {
        insuranceInfo: false,
        planType: false,
        bannerType: false,
        specialCustomers: false
      }
      state.isPlanDetailOpen = false
      state.isPreviewPlanOpen = false
      state.currentPlan = ''
      state.planTypes = []
      state.bannerType = BannerType.None
      state.specialCustomers = []
      state.planFile = null
      state.previewPlan = initialPreviewPlanDetail
      state.isPlanActive = true
      state.insurancePlan = undefined
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(PlanService.getAllPlansAsyncThunk.fulfilled, (state, action) => {
        state.fetchedPlans = action.payload
      })
      .addCase(PlanService.getPlanInfoAsyncThunk.fulfilled, (state, action) => {
        state.fetchedPlanInfo = action.payload
        state.isPlanActive = action.payload.isActive!
      })
      .addCase(PlanService.getPlanInsuranceTypeAsyncThunk.fulfilled, (state, action) => {
        state.planTypes = action.payload
      })
      .addCase(PlanService.getPlanSpecialLabelAsyncThunk.fulfilled, (state, action) => {
        state.bannerType = (action.payload ?? '') as BannerType
      })
      .addCase(PlanService.getSpecialCustomersAsyncThunk.fulfilled, (state, action) => {
        state.specialCustomers = action.payload
      })
      .addCase(PlanService.getPlanDetailAsyncThunk.fulfilled, (state, action) => {
        state.fetchedPlanDetail = action.payload
      })
      .addCase(PlanService.getTimeStampDataAsyncThunk.fulfilled, (state, action) => {
        state.timeStampData = action.payload
      })
      .addCase(PlanService.getInsuranceTypeMasterDataAsyncThunk.fulfilled, (state, action) => {
        state.insuranceTypesMasterData = action.payload
      })
      .addCase(PlanService.getInsurancePlanAsyncThunk.fulfilled, (state, action) => {
        state.insurancePlan = action.payload
      })
  }
})

export const {
  toggleEditState,
  openPreviewPlan,
  closePreviewPlan,
  togglePlanType,
  changeBannerType,
  updateSpecialCustomerId,
  updatePlanFile,
  setPreviewPlan,
  resetPlanState,
  togglePlanActive,
  closePlanDetail,
  setPlanFilterValues,
  resetPlanFilterValues,
  openPlanDetail
} = slice.actions
export default slice.reducer
