import { Avatar, Box, styled, Typography } from '@mui/material'
import zIndex from '@mui/material/styles/zIndex'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { COLORS, COLORS_THEME } from '../../../constants/colors'
import { NAVBAR_HEIGHT } from '../../../constants/constants'
import { AuthService } from '../../../features/auth/services/AuthService'
import { currentUserSelector } from '../../../stores/current-user/selector'
import { resetUser } from '../../../stores/current-user/slice'
import { useAppDispatch } from '../../../stores/store'
import { combineImageUrl } from '../../../utils/combineImageUrl'
import ConfirmDialog from '../../global/interactions/ConfirmDialog'
import LogoutIcon from '../icons/LogoutIcon'

const Navbar = () => {
  const [isConfirmLogoutDialogOpen, setIsConfirmLogoutDialogOpen] = useState(false)

  const currentUser = useSelector(currentUserSelector)
  const dispatch = useAppDispatch()

  const onLogout = () => setIsConfirmLogoutDialogOpen(true)
  const onConfirm = () => {
    AuthService.logout()
    dispatch(resetUser())
  }
  const onCancel = () => setIsConfirmLogoutDialogOpen(false)

  return (
    <NavbarContainer>
      <ConfirmDialog
        title='ออกจากระบบ'
        content={
          <Box padding={6} paddingBottom={0}>
            <Typography variant='h6' textAlign='center'>
              คุณต้องการที่จะออกจากระบบหรือไม่?
            </Typography>
          </Box>
        }
        isOpen={isConfirmLogoutDialogOpen}
        onConfirm={onConfirm}
        onCancel={onCancel}
      />
      <Box marginRight={4}>
        <Typography variant='h6' color={COLORS_THEME.primary} textAlign='right'>
          {currentUser?.name}
        </Typography>
        <Typography variant='body2' fontWeight='normal' textAlign='right'>
          {currentUser?.role}
        </Typography>
      </Box>
      <StyledAvatar src={combineImageUrl(currentUser?.profileUrl ?? '')} />
      <LogoutBox marginLeft={2} onClick={onLogout}>
        {/* TODO: Waiting For Real Icon */}
        <LogoutIcon />
      </LogoutBox>
    </NavbarContainer>
  )
}

const NavbarContainer = styled(Box)(({ theme }) => ({
  height: NAVBAR_HEIGHT,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  position: 'sticky',
  top: 0,
  width: '100%',
  backgroundColor: COLORS.white,
  borderBottom: `1px solid ${COLORS.purple100}`,
  paddingRight: theme.spacing(8),
  zIndex: zIndex.appBar
}))

const avatarSize = '40px'
const StyledAvatar = styled(Avatar)({ width: avatarSize, height: avatarSize })

const LogoutBox = styled(Box)({
  width: avatarSize,
  height: avatarSize,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: COLORS.purple100,
  borderRadius: '50%',
  cursor: 'pointer'
})

export default Navbar
