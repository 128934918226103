import { Box, styled } from '@mui/material'
import { motion } from 'framer-motion'
import React from 'react'
import { useSelector } from 'react-redux'
import { Outlet } from 'react-router'

import Navbar from '../components/project/navigations/Navbar'
import Sidebar from '../components/project/navigations/sidebar/Sidebar'
import { COLORS } from '../constants/colors'
import { NAVBAR_HEIGHT, SIDEBAR_WIDTH } from '../constants/constants'
import PlanDetail from '../features/insurance/components/PlanDetail'
import PreviewPlanDetail from '../features/insurance/components/PreviewPlanDetail'
import { isPlanDetailOpenSelector, isPreviewPlanOpenSelector } from '../features/insurance/stores/plan/selector'

const AppLayout = () => {
  const isPreviewPlanOpen = useSelector(isPreviewPlanOpenSelector)
  const isPlanDetailOpen = useSelector(isPlanDetailOpenSelector)

  const variants = {
    enter: {
      opacity: 1,
      display: 'flex',
      left: 0
    },
    exit: { opacity: 0, left: '100vw' }
  }

  return (
    <Box width='100vw' height='100vh' display='flex' position='relative' overflow='hidden'>
      <Sidebar />
      <Box flex={1}>
        <Navbar />
        <ContentContainer>
          <Outlet />
        </ContentContainer>
      </Box>
      <motion.div
        transition={{
          duration: 0.3
        }}
        initial={{ opacity: 0, top: 0, left: '100vw', position: 'absolute', zIndex: 2000 }}
        animate={isPreviewPlanOpen ? 'enter' : 'exit'}
        variants={variants}
      >
        <PreviewPlanDetail />
      </motion.div>
      <motion.div
        transition={{
          duration: 0.3
        }}
        initial={{ opacity: 0, top: 0, left: '100vw', position: 'absolute', zIndex: 2000 }}
        animate={isPlanDetailOpen ? 'enter' : 'exit'}
        variants={variants}
      >
        <PlanDetail />
      </motion.div>
    </Box>
  )
}

const ContentContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(6, 8),
  width: `calc(100vw - ${SIDEBAR_WIDTH})`,
  backgroundColor: COLORS.grey150,
  height: `calc(100vh - ${NAVBAR_HEIGHT})`,
  overflowY: 'scroll'
}))

export default AppLayout
