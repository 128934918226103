import { Box, styled, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router'

import ArrowLeftIcon from '../../global/icons/ArrowLeftIcon'

interface BackButtonProps {
  title: string
  onClick?: () => void
}

const BackButton = ({ title, onClick }: BackButtonProps) => {
  const navigate = useNavigate()

  const onBackToPrevPage = () => navigate(-1)
  return (
    <Box display='flex' alignItems='center'>
      <CursorBox onClick={onClick ? onClick : onBackToPrevPage}>
        <ArrowLeftIcon />
      </CursorBox>
      <Typography color='black' variant='h2' marginLeft={4}>
        {title}
      </Typography>
    </Box>
  )
}

const CursorBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer'
}))

export default BackButton
