import { Box, Stack, styled, Table, TableBody, TableCell, TableRow, TableRowProps, Typography } from '@mui/material'
import DOMPurify from 'isomorphic-dompurify'
import React, { Fragment, PropsWithChildren, ReactNode, useState } from 'react'
import { useSelector } from 'react-redux'

import PDFIcon from '../../../components/global/icons/PDFIcon'
import CustomButton from '../../../components/global/interactions/CustomButton'
import CustomSwitch from '../../../components/global/interactions/CustomSwitch'
import { BaseCard } from '../../../components/global/ui/Card'
import BackButton from '../../../components/project/navigations/BackButton'
import ImageBox from '../../../components/project/ui/ImageBox'
import { COLORS, COLORS_THEME } from '../../../constants/colors'
import { languageSelector } from '../../../stores/language/selector'
import { useAppDispatch } from '../../../stores/store'
import { combineImageUrl } from '../../../utils/combineImageUrl'
import { convertToCurrency } from '../../../utils/convertToCurrency'
import { I18N_PLAN_DETAIL_TEXT_FIELD_LIST } from '../constants/planDetailTextFieldList'
import { bannerTypeSelector, previewPlanSelector } from '../stores/plan/selector'
import { closePreviewPlan } from '../stores/plan/slice'
import { BannerType } from '../stores/plan/types.d'
import BannerLabel from './BannerLabel'

interface StyledTableRowProps extends TableRowProps {
  isColored: boolean
}

interface ChildTableRowProps extends TableRowProps {
  isColored: boolean
  needBorder: boolean
}

const I18N_TEXT = {
  th: {
    download: 'ดาวน์โหลด',
    life_insurance: 'ประกันการเสียชีวิตทุกกรณี',
    accidental_death_and_dismemberment_indemnity: 'คุ้มครองเสียชีวิตจากอุบัติเหตุและการสูญเสียอวัยวะ',
    permanent_disability_indemnity_total: 'คุ้มครองทุพพลภาพถาวรสิ้นเชิง',
    hospital_and_board_per_day: 'ค่าห้องและค่าอาหารผู้ป่วยใน ต่อวัน',
    icu_cost_per_day: 'ค่าห้องผู้ป่วยหนัก ต่อวัน',
    maximum_days_for_hospital_IPD: 'จำกัดค่าห้องผู้ป่วยในต่อครั้ง สูงสุด',
    maximum_days_for_ICU_room: 'จำกัดค่าห้องผู้ป่วยหนักต่อครั้ง สูงสุด',
    nursing_fees: 'ค่าบริการพยาบาล',
    general_hospital_expenses: 'ค่ารักษาพยาบาลอื่นๆ ในโรงพยาบาล กรณีผู้ป่วยใน',
    ambulance_fees: 'ค่ารถพยาบาลฉุกเฉิน',
    opd_follow_up_treatment_after_IPD_stay: 'ค่ารักษาพยาบาลแบบผู้ป่วยนอก ต่อเนื่องจากการรักษาแบบผู้ป่วยใน',
    surgery_fee: 'ค่าธรรมเนียมแพทย์ผ่าตัดและหัตถการ',
    physician_hospital_visit_fee: 'ค่าแพทย์ตรวจรักษาในโรงพยาบาล',
    accident_cost_for_OPD_per_accident: 'ค่ารักษาพยาบาลอุบัติเหตุฉุกเฉิน แบบผู้ป่วยนอก ต่อการบาดเจ็บแต่ละครั้ง',
    duration_after_OPD_treatment: 'การรักษาต่อเนื่อง จากอุบัติเหตุฉุกเฉิน แบบผู้ป่วยนอก ต่อการบาดเจ็บแต่ละครั้ง',
    specialist_consultation_fee: 'ค่าปรึกษาแพทย์เชี่ยวชาญเฉพาะโรค ต่อการเข้ารักษาครั้งใดครั้งหนึ่ง',
    hb_incentive_benefit: 'ค่าชดเชยรายวัน กรณีใช้สวัสดิการอื่น (HB Incentive)',
    maximum_HB_incentive_benefits: 'จำนวนวัน HB Incentive สูงสุด',
    opd_treating_cost: 'ค่ารักษาพยาบาลแบบผู้ป่วยนอก',
    dental_cost: 'ค่ารักษาทันตกรรม',
    other_coverage_detail: 'ความคุ้มครองอื่นๆ'
  },
  en: {
    download: 'Download',
    life_insurance: 'Life Insurance',
    accidental_death_and_dismemberment_indemnity: 'Accidental Death and Dismemberment Indemnity',
    permanent_disability_indemnity_total: 'Total Permanenent Disability Indemnity',
    hospital_and_board_per_day: 'Hospital Room&Board (per day)',
    icu_cost_per_day: 'Intensive Care Unit Room (per day)',
    maximum_days_for_hospital_IPD: 'Maximum days for hospital R&B (inpatient)',
    maximum_days_for_ICU_room: 'Maximum days for Intensive Care Unit room',
    nursing_fees: 'Nursing fees',
    general_hospital_expenses: 'General Hospital Expenses (Inpatient)',
    ambulance_fees: 'Ambulance fee in case of an Emergency',
    opd_follow_up_treatment_after_IPD_stay: 'Outpatient follow-up treatments after discharge from inpatient stay',
    surgery_fee: 'Medical Expenses for surgery',
    physician_hospital_visit_fee: "Physician's Hospital visit fee",
    accident_cost_for_OPD_per_accident: 'Emergency accident outpatient treatment (per accident)',
    duration_after_OPD_treatment: 'Duration after outpatient accident treatment (per accident)',
    specialist_consultation_fee: 'Specialist consultation fee (per hospitalization)',
    hb_incentive_benefit: 'HB Incentive benefit (per day of hospitalization)',
    maximum_HB_incentive_benefits: 'Maximum HB Incentive benefits (days)',
    opd_treating_cost: 'Outpatient Treatment',
    dental_cost: 'Dental Treatment',
    other_coverage_detail: 'Other Coverage/Benefits'
  }
}

const MobileBox = ({ children }: PropsWithChildren) => {
  return <Mobile>{children}</Mobile>
}

const PreviewPlanDetail = () => {
  const [isMobile, setIsMobile] = useState(false)

  const language = useSelector(languageSelector)
  const previewPlan = useSelector(previewPlanSelector)
  const bannerType = useSelector(bannerTypeSelector)
  const dispatch = useAppDispatch()

  const cleanHTML = DOMPurify.sanitize(previewPlan.additionalDetail ?? '-')

  const toggleMobile = () => setIsMobile((prevIsMobile) => !prevIsMobile)
  const onCloseModal = () => dispatch(closePreviewPlan())

  const renderBannerType = () => bannerType !== BannerType.None && <BannerLabel bannerType={bannerType} />
  const renderDetailContent = (content: ReactNode) =>
    isMobile ? (
      <Box display='flex' justifyContent='center'>
        <MobileBox>
          <Typography variant='h3' marginBottom={8} textAlign='center' marginTop={2}>
            รายละเอียดแผนประกัน
          </Typography>
          <BaseCard position='relative' overflow='hidden'>
            {content}
            {renderBannerType()}
          </BaseCard>
        </MobileBox>
      </Box>
    ) : (
      <BaseCard position='relative' overflow='hidden'>
        {content}
        {renderBannerType()}
      </BaseCard>
    )

  return (
    <PreviewPlanDetailContainer>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <BackButton title='พรีวิว' onClick={onCloseModal} />
        <Stack direction='row' spacing={2} alignItems='center'>
          <CustomSwitch
            value={isMobile}
            onChange={toggleMobile}
            customLabel={{
              active: 'Mobile View',
              inActive: 'Desktop View'
            }}
          />
        </Stack>
      </Box>
      <Box marginTop={6}>
        {renderDetailContent(
          <>
            <Box
              paddingY={8}
              paddingX={18}
              padding={isMobile ? 4 : ''}
              display='flex'
              flexDirection={isMobile ? 'column' : 'row'}
              alignItems='center'
              justifyContent='space-between'
            >
              <Box display='flex' alignItems='center'>
                <ImageBox imageUrl={combineImageUrl(previewPlan.logoImageUrl)} size='large' />
                <Box marginLeft={4}>
                  <Typography variant='h6' color={COLORS.grey700}>
                    {previewPlan.displayInsuranceName}
                  </Typography>
                  <Typography variant='h3'>{previewPlan.insuranceName}</Typography>
                </Box>
              </Box>
              <Box display='flex' marginTop={isMobile ? 4 : ''} width={isMobile ? '100%' : 'auto'}>
                <CustomButton
                  text={I18N_TEXT[language].download}
                  variant='outlined'
                  startIcon={<PDFIcon />}
                  direction='column'
                  fullWidth={isMobile}
                />
              </Box>
            </Box>
            <Box borderTop={1} borderColor={COLORS.grey100} display='flex' flexDirection={isMobile ? 'column' : 'row'}>
              <Box paddingY={6} paddingX={4} borderRight={1} borderColor={COLORS.grey100} flex={1}>
                <Table>
                  <TableBody>
                    {I18N_PLAN_DETAIL_TEXT_FIELD_LIST.map((textField, index) => (
                      <Fragment key={textField.id}>
                        <StyledTableRow key={textField.id} isColored={index % 2 === 0}>
                          <TableCell>
                            <Typography variant='h6'>• {(I18N_TEXT[language] as any)[textField.label]}</Typography>
                          </TableCell>
                          <CenterTableCell>
                            <Typography marginLeft={6} variant='h6' fontWeight='normal'>
                              <Typography
                                component='span'
                                variant='inherit'
                                color={COLORS_THEME.primary}
                                fontWeight='bold'
                              >
                                {textField.unit &&
                                  (previewPlan as any)[textField.id] !== 0 &&
                                  (previewPlan as any)[textField.id] &&
                                  convertToCurrency((previewPlan as any)[textField.id], true)}{' '}
                              </Typography>
                              {!textField.unit &&
                                (previewPlan as any)[textField.id] &&
                                (previewPlan as any)[textField.id]}
                              {!(previewPlan as any)[`${textField.id}Unit`] && !(previewPlan as any)[textField.id]
                                ? '-'
                                : (previewPlan as any)[`${textField.id}Unit`]}
                            </Typography>
                          </CenterTableCell>
                        </StyledTableRow>
                        {textField?.child?.map((item, childIndex) => (
                          <ChildTableRow key={item.id} isColored={index % 2 === 0} needBorder={childIndex !== 0}>
                            <TableCell>
                              <Typography marginLeft={6} variant='h6' fontWeight='normal'>
                                - {(I18N_TEXT[language] as any)[item.label]}
                              </Typography>
                            </TableCell>
                            <CenterTableCell>
                              <Typography marginLeft={6} variant='h6' fontWeight='normal'>
                                {(previewPlan as any)[item.id] !== 0 &&
                                  (previewPlan as any)[item.id] &&
                                  convertToCurrency((previewPlan as any)[item.id], true)}{' '}
                                {(previewPlan as any)[`${item.id}Unit`]}
                                {!(previewPlan as any)[`${item.id}Unit`] && !(previewPlan as any)[item.id] && '-'}
                              </Typography>
                            </CenterTableCell>
                          </ChildTableRow>
                        ))}
                      </Fragment>
                    ))}
                  </TableBody>
                </Table>
              </Box>
              <Box
                paddingY={6}
                paddingX={4}
                flex={1}
                borderTop={isMobile ? 1 : 0}
                borderColor={COLORS.grey100}
                minHeight='500px'
              >
                <div dangerouslySetInnerHTML={{ __html: cleanHTML }} />
              </Box>
            </Box>
          </>
        )}
      </Box>
    </PreviewPlanDetailContainer>
  )
}

const Mobile = styled(Box)(({ theme }) => ({
  width: '500px',
  height: '80vh',
  overflowY: 'scroll',
  border: `20px solid ${COLORS.grey750}`,
  borderRadius: theme.spacing(10),
  padding: theme.spacing(4)
}))

const StyledTableRow = styled(TableRow, { shouldForwardProp: (props) => props !== 'isColored' })<StyledTableRowProps>(
  ({ theme, isColored }) => ({
    backgroundColor: isColored ? COLORS.purple100 : COLORS.white,
    '& td': {
      padding: theme.spacing(2, 4),
      minHeight: '56px',
      height: '56px',
      border: 'none'
    }
  })
)
const ChildTableRow = styled(TableRow, {
  shouldForwardProp: (props) => !['isColored', 'needBorder'].includes(props as string)
})<ChildTableRowProps>(({ theme, isColored, needBorder }) => ({
  backgroundColor: isColored ? COLORS.purple100 : COLORS.white,
  '& td': {
    padding: theme.spacing(2, 4),
    minHeight: '32px',
    border: 'none',
    borderTop: needBorder ? `1px solid ${COLORS.purple100}` : 'none'
  }
}))

const CenterTableCell = styled(TableCell)({ textAlign: 'center' })

const PreviewPlanDetailContainer = styled(Box)(({ theme }) => ({
  width: '100vw',
  height: '100vh',
  backgroundColor: COLORS.grey150,
  zIndex: theme.zIndex.appBar,
  padding: theme.spacing(14, 33),
  overflowY: 'scroll'
}))

export default PreviewPlanDetail
