import { Box, BoxProps, styled } from '@mui/material'
import React from 'react'

import { COLORS, COLORS_THEME } from '../../../../constants/colors'

interface ArrowProps extends BoxProps {
  isActive: boolean
}

const ActiveArrowStyle = '4px solid black'
const InactiveArrowStyle = `4px solid ${COLORS.grey500}`

export const ArrowUp = styled(Box, { shouldForwardProp: (props) => props !== 'isActive' })<ArrowProps>(
  ({ isActive }) => ({
    width: 0,
    height: 0,
    borderLeft: '4px solid transparent',
    borderRight: '4px solid transparent',
    borderBottom: isActive ? ActiveArrowStyle : InactiveArrowStyle
  })
)

export const ArrowDown = styled(Box, { shouldForwardProp: (props) => props !== 'isActive' })<ArrowProps>(
  ({ isActive }) => ({
    width: 0,
    height: 0,
    borderLeft: '4px solid transparent',
    borderRight: '4px solid transparent',
    borderTop: isActive ? ActiveArrowStyle : InactiveArrowStyle
  })
)

export const PlusInTableIcon = () => {
  return (
    <svg width='12' height='12' viewBox='0 0 12 12' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6 1.5a.75.75 0 0 1 .75.75l-.001 2.999 3.001.001a.75.75 0 1 1 0 1.5l-3.001-.001.001 3.001a.75.75 0 1 1-1.5 0l-.001-3.001-2.999.001a.75.75 0 0 1 0-1.5l2.999-.001.001-2.999A.75.75 0 0 1 6 1.5z'
        fill={COLORS_THEME.primary}
        fillRule='evenodd'
      />
    </svg>
  )
}

export const MinusInTableIcon = () => {
  return (
    <svg width='12' height='12' viewBox='0 0 12 12' xmlns='http://www.w3.org/2000/svg'>
      <rect x='1.5' y='5.25' width='9' height='1.5' rx='.75' fill={COLORS_THEME.primary} fillRule='evenodd' />
    </svg>
  )
}
