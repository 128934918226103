import React from 'react'

import { IconProps } from '../../../types/types'

const ImageIcon = ({ color, size = 16 }: IconProps) => {
  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} xmlns='http://www.w3.org/2000/svg'>
      <g fill={color || '#000'} fillRule='nonzero'>
        <path
          transform={`scale(${size / 16})`}
          d='M11.889 1.333H4.11a2.781 2.781 0 0 0-2.778 2.778v7.778a2.781 2.781 0 0 0 2.778 2.778h7.778a2.781 2.781 0 0 0 2.778-2.778V4.11a2.781 2.781 0 0 0-2.778-2.778zM4.11 2.444h7.778c.92 0 1.667.747 1.667 1.667v7.778a1.64 1.64 0 0 1-.167.714l-5.09-5.09a2.778 2.778 0 0 0-3.93 0L2.444 9.436V4.11c0-.92.747-1.667 1.667-1.667zm0 11.112c-.92 0-1.667-.747-1.667-1.667v-.881l2.71-2.71a1.667 1.667 0 0 1 2.358 0l5.09 5.09a1.64 1.64 0 0 1-.713.168H4.11z'
        />
        <path
          transform={`scale(${size / 16})`}
          d='M10.222 7.167a1.944 1.944 0 1 0 0-3.89 1.944 1.944 0 0 0 0 3.89zm0-2.778a.833.833 0 1 1 0 1.667.833.833 0 0 1 0-1.667z'
        />
      </g>
    </svg>
  )
}

export default ImageIcon
