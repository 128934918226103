import { Box, BoxProps, CircularProgress, styled, Tooltip, Typography } from '@mui/material'
import { Variant } from '@mui/material/styles/createTypography'
import React, { ChangeEvent, MouseEvent, useEffect, useState } from 'react'

import { COLORS } from '../../../constants/colors'
import { combineFileUrl } from '../../../utils/combineImageUrl'
import CheckedFileIcon from '../../project/icons/CheckedFileIcon'
import CancelIcon from '../icons/CancelIcon'
import ImportIcon from '../icons/ImportIcon'

interface AttachFileButtonProps {
  id: string
  previewFileUrlName?: string
  label?: string
  onSelectFile?: (file: File | null) => void
  onImportFile?: (file: File) => void
  textVariant?: Variant
  isLoading?: boolean
  isDisabled?: boolean
  customWidth?: string
  iconColor?: string
  iconSize?: number
  acceptType?: string
}

interface StyledBoxProps extends BoxProps {
  isDisabled: boolean
  customWidth?: string
}

const AttachFileButton = ({
  id,
  onSelectFile,
  onImportFile,
  isLoading,
  textVariant = 'body1',
  label = 'อัปโหลดเอกสาร',
  isDisabled = false,
  previewFileUrlName,
  iconColor,
  iconSize,
  customWidth,
  acceptType
}: AttachFileButtonProps) => {
  const [file, setFile] = useState<File | null>(null)

  const splitedFileName = previewFileUrlName ? previewFileUrlName?.split('/') : ''
  const previewFileName = splitedFileName[splitedFileName.length - 1]

  const onInnerSelectFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) return

    if (onSelectFile) {
      setFile(e.target.files[0])
      onSelectFile(e.target.files[0])
    }
    if (onImportFile) onImportFile(e.target.files[0])
  }

  const onDelete = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    setFile(null)
    if (onSelectFile) onSelectFile(null)
  }

  const onOpenFile = () =>
    previewFileUrlName && window.open(combineFileUrl(previewFileUrlName), '_blank', 'noopener,noreferrer')

  useEffect(() => {
    if (isDisabled) {
      setFile(null)
      onSelectFile && onSelectFile(null)
    }
  }, [isDisabled])

  return (
    <>
      <label htmlFor={id}>
        <StyledBox isDisabled={isDisabled} customWidth={customWidth} onClick={isDisabled ? onOpenFile : undefined}>
          {isLoading ? (
            <CircularProgress size={24} />
          ) : (
            <Box display='flex' alignItems='center'>
              {file?.name || previewFileUrlName ? (
                <CheckedFileIcon />
              ) : (
                <ImportIcon color={iconColor} size={iconSize} />
              )}
              <OverflowTypography
                variant={textVariant}
                marginLeft={2}
                width={file?.name || previewFileName ? '130px' : 'auto'}
              >
                {file || previewFileName ? (
                  <Tooltip
                    title={
                      <Typography variant='body1' color='white'>
                        {file?.name ? file.name : previewFileName}
                      </Typography>
                    }
                  >
                    <Typography component='span' variant='inherit'>
                      {file?.name ? file.name : previewFileName}
                    </Typography>
                  </Tooltip>
                ) : (
                  label
                )}
              </OverflowTypography>
            </Box>
          )}
          <Box display='flex' alignItems='center'>
            {file && !isDisabled && (
              <Box display='flex' alignItems='center' onClick={onDelete}>
                <CancelIcon />
              </Box>
            )}
          </Box>
        </StyledBox>
      </label>
      {!isDisabled && !isLoading && (
        <input
          type='file'
          id={id}
          hidden
          name='file-picker'
          accept={acceptType}
          onChange={onInnerSelectFile}
          onClick={(event: any) => {
            event.target.value = null
          }}
        />
      )}
    </>
  )
}

const OverflowTypography = styled(Typography)({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  userSelect: 'none'
})

const StyledBox = styled(Box, {
  shouldForwardProp: (props) => !['isDisabled', 'customWidth'].includes(props as string)
})<StyledBoxProps>(({ theme, isDisabled, customWidth }) => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: theme.spacing(2),
  border: `1px solid ${COLORS.grey350}`,
  cursor: 'pointer',
  width: customWidth ? customWidth : '200px',
  height: '42px',
  transition: 'background-color .3s',
  padding: theme.spacing(0, 2.5),
  backgroundColor: isDisabled ? COLORS.grey200 : COLORS.white,
  opacity: isDisabled ? 0.6 : 1,
  '&:active': {
    backgroundColor: isDisabled ? '' : COLORS.greyTransparent
  }
}))

export default AttachFileButton
