import { createTheme } from '@mui/material/styles'

import { COLORS_THEME } from '../constants/colors'

export const fontSize = {
  h1: '40px',
  h2: '32px',
  h3: '24px',
  h4: '20px',
  h5: '18px',
  h6: '16px',
  body1: '14px',
  body2: '12px',
  subtitle1: '10px',
  subtitle2: '8px'
}

export const fontFamily = 'prompt'

export const theme = createTheme({
  // Colors
  palette: {
    primary: {
      main: COLORS_THEME.primary,
      light: COLORS_THEME.lighterPrimary
    },
    secondary: {
      main: COLORS_THEME.secondary
    },
    error: {
      main: COLORS_THEME.error
    },
    background: {
      default: COLORS_THEME.background
    },
    info: {
      main: '#000000'
    },
    success: {
      main: COLORS_THEME.success
    }
  },
  // Typography
  typography: {
    fontFamily: fontFamily,
    h1: {
      fontFamily: fontFamily,
      fontSize: fontSize.h1,
      fontWeight: 500,
      lineHeight: 1.5
    },
    h2: {
      fontFamily: fontFamily,
      fontSize: fontSize.h2,
      fontWeight: 500,
      lineHeight: 1.5
    },
    h3: {
      fontFamily: fontFamily,
      fontSize: fontSize.h3,
      fontWeight: 500,
      lineHeight: 1.5
    },
    h4: {
      fontFamily: fontFamily,
      fontSize: fontSize.h4,
      fontWeight: 500,
      lineHeight: 1.5
    },
    h5: {
      fontFamily: fontFamily,
      fontSize: fontSize.h5,
      fontWeight: 500,
      lineHeight: 1.5
    },
    h6: {
      fontFamily: fontFamily,
      fontSize: fontSize.h6,
      fontWeight: 500,
      lineHeight: 1.5
    },
    body1: {
      fontFamily: fontFamily,
      fontSize: fontSize.body1,
      lineHeight: 1.5
    },
    body2: {
      fontFamily: fontFamily,
      fontSize: fontSize.body2,
      fontWeight: 300,
      lineHeight: 1.5
    },
    subtitle1: {
      fontFamily: fontFamily,
      fontSize: fontSize.subtitle1,
      fontWeight: 500,
      lineHeight: 1.5
    },
    subtitle2: {
      fontFamily: fontFamily,
      fontSize: fontSize.subtitle2,
      fontWeight: 'normal',
      lineHeight: 1.5
    },
    button: {
      textTransform: 'none'
    }
  },
  // Spacings
  // 4px / 1 spacing
  spacing: 4
})
