import { CustomerStatus, Status } from '../../../../types/types'

export type TableItem = GlobalBasedTableItem & ProjectBasedTableItem
export type TableItemKey = keyof TableItem
export interface TableHeader {
  type: TableItemType
  headerName: string
  key: string
  maxLength?: number
}
export type TableItemType = GlobalBasedItemType | ProjectBasedItemType

// Global Based Type
type GlobalBasedItemType = 'avatar-img' | 'img' | 'big-image' | 'text' | 'status' | 'currency' | 'icon' | 'isActive'
export interface GlobalBasedTableItem {
  id: string
  [key: string]: any
}

// Project Based Type
type ProjectBasedItemType =
  | 'employee-info'
  | 'employee-with-family-info'
  | 'special-label-status'
  | 'status-with-active'
  | 'quotation-status'
  | 'insurance-plan-with-logo'
  | 'employee-active'
  | 'all-point'
  | 'isDefault'
  | 'header-text'
  | 'medical-status'
  | 'medical-result'
  | 'text-field'
  | 'check-box'
  | 'switch'

export interface EmployeeTableInfo {
  imageUrl: string
  name: string
  employeeLevel: string
}

export interface FamilyInfo {
  id: string
  name: string
  gender: string
  personalId: string
  age: string
  insuranceStatus: string
  email: string
  status: string
}

export interface EmployeeWithFamilyTableInfo {
  name: string
  child: FamilyInfo[]
}

export interface StatusWithActive {
  status: CustomerStatus
  isActive: boolean
}

export enum QuotationStatus {
  pending = 'pending',
  waiting = 'waiting',
  accepted = 'accepted',
  rejected = 'rejected',
  requestEdit = 'request-edit'
}

export enum EmployeeActiveStatus {
  Effective = 'effective',
  Suspended = 'suspended',
  OverSixtyFive = 'overSixtyFive'
}

export interface InsurancePlanWithLogoInfo {
  insurerLogoUrl: string
  insuranceName: string
}

export interface TextFieldTable {
  id: string
  value: string
  label: string
  isDisabled?: boolean
  isRequired?: boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export interface CheckBoxTable {
  label: string
  value: boolean
  isDisabled?: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export interface SwitchTable {
  value: boolean
  isDisabled?: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

interface ProjectBasedTableItem {
  status?: Status
  isActionDisabled?: boolean
  employeeInfo?: EmployeeTableInfo
  employeeWithFamilyInfo?: EmployeeWithFamilyTableInfo
  isActive?: boolean
  statusWithActive?: StatusWithActive
  quotationStatus?: QuotationStatus
  employeeActive?: EmployeeActiveStatus
  insurancePlanWithLogo?: InsurancePlanWithLogoInfo
  textFieldTable?: TextFieldTable
  checkBoxTable?: CheckBoxTable
  switchTable?: SwitchTable
}
