import { Button, Stack, styled, Typography } from '@mui/material'
import React from 'react'

import { COLORS, COLORS_THEME } from '../../../constants/colors'
import EditIcon from '../../global/icons/EditIcon'
import SaveIcon from '../icons/SaveIcon'

interface EditToolBarProps {
  isEdit?: boolean
  onEdit?: () => void
  onCancel?: () => void
  onSubmit?: () => void
  onSelectNewPlan?: () => void
  isDisabledSubmit?: boolean
  isFormLoading?: boolean
}

const EditToolBar = ({
  isEdit,
  onCancel,
  onEdit,
  onSubmit,
  onSelectNewPlan,
  isDisabledSubmit,
  isFormLoading
}: EditToolBarProps) => {
  if (isEdit)
    return (
      <Stack direction='row' spacing={2}>
        {onSelectNewPlan && (
          <StyledSelectNewPlanButton onClick={onSelectNewPlan}>
            <Typography variant='body1' fontWeight={500}>
              เลือกแผนใหม่
            </Typography>
          </StyledSelectNewPlanButton>
        )}
        <StyledEditButton color='info' onClick={onCancel}>
          <Typography variant='body1' fontWeight={500}>
            ยกเลิก
          </Typography>
        </StyledEditButton>
        <StyledEditButton
          onClick={isFormLoading ? undefined : onSubmit}
          startIcon={<SaveIcon color={isDisabledSubmit ? COLORS.grey400 : COLORS_THEME.primary} />}
          disabled={isDisabledSubmit}
        >
          <Typography variant='body1' fontWeight={500} color={isDisabledSubmit ? COLORS.grey400 : COLORS_THEME.primary}>
            บันทึก
          </Typography>
        </StyledEditButton>
      </Stack>
    )

  return (
    <StyledEditButton color='info' onClick={onEdit} startIcon={<EditIcon color={COLORS.grey700} />}>
      <Typography variant='body1' fontWeight={500} color={COLORS.grey700}>
        แก้ไข
      </Typography>
    </StyledEditButton>
  )
}

const StyledEditButton = styled(Button)(({ theme, disabled }) => ({
  backgroundColor: COLORS.grey100,
  padding: theme.spacing(0.5, 3),
  borderRadius: theme.spacing(2),
  cursor: disabled ? 'not-allowed' : 'pointer',
  '& .MuiButton-startIcon': { marginRight: theme.spacing(1) }
}))

const StyledSelectNewPlanButton = styled(Button)(({ theme }) => ({
  backgroundColor: COLORS_THEME.primary,
  color: COLORS.white,
  padding: theme.spacing(0.5, 3),
  borderRadius: theme.spacing(2),
  ':hover': {
    backgroundColor: COLORS_THEME.primary
  }
}))

export default EditToolBar
