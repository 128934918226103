import { Box, Stack, styled } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'

import { RENDER_SIDEBAR_ROUTES } from '../../../../configs/routes'
import { NAVBAR_HEIGHT } from '../../../../constants/constants'
import { currentUserSelector } from '../../../../stores/current-user/selector'
import FlexbenLightLogo from '../../images/FlexbenLightLogo'
import SidebarGroup from './SidebarGroup'

const Sidebar = () => {
  const currentUser = useSelector(currentUserSelector)

  return (
    <SidebarContainer>
      <Box height={NAVBAR_HEIGHT} display='flex' justifyContent='center' alignItems='center'>
        <LogoBox src={FlexbenLightLogo} />
      </Box>
      <Stack paddingTop={6} spacing={4}>
        {RENDER_SIDEBAR_ROUTES.map((sidebar) => {
          const isHeaderHavePermission = (() => {
            // For Unprotected Routes
            if (sidebar.permissions.length < 1) return true
            if (!currentUser) return false
            const checkPermissionsList = sidebar.child
              ? sidebar.child.map((child) => child.permissions)
              : sidebar.permissions
            const isHavePermission = checkPermissionsList.some((perm) => currentUser.permissions.includes(perm))
            return isHavePermission
          })()

          if (!isHeaderHavePermission) return

          return <SidebarGroup key={sidebar.title} sidebar={sidebar} />
        })}
      </Stack>
      <Box height='10vh' />
    </SidebarContainer>
  )
}

const LogoBox = styled('img')({
  width: '129px',
  height: '24px'
})

const SidebarContainer = styled(Box)(({ theme }) => ({
  width: '268px',
  height: '100%',
  backgroundImage: 'linear-gradient(180deg, rgba(89,9,186,1) 50%, rgba(67,54,188,1) 100%)',
  borderTopRightRadius: theme.spacing(8),
  overflowY: 'scroll'
}))

export default Sidebar
