export const PLAN_DETAIL_TEXT_FIELD_LIST = [
  {
    id: 'lifeInsurance',
    label: 'ประกันการเสียชีวิตทุกกรณี',
    unit: 'บาท'
  },
  {
    id: 'accidentalDeathAndDismembermentIndemnity',
    label: 'คุ้มครองเสียชีวิตจากอุบัติเหตุและการสูญเสียอวัยวะ',
    unit: 'บาท'
  },
  {
    id: 'permanentDisabilityIndemnityTotal',
    label: 'คุ้มครองทุพพลภาพถาวรสิ้นเชิง',
    unit: 'บาท'
  },
  {
    id: 'hospitalAndBoardPerDay',
    label: 'ค่าห้องและค่าอาหารผู้ป่วยใน ต่อวัน',
    unit: 'บาท/วัน'
  },
  {
    id: 'icuCostPerDay',
    label: 'ค่าห้องผู้ป่วยหนัก ต่อวัน',
    unit: 'บาท/วัน',
    child: [
      { id: 'maximumDaysForHospitalIPD', label: 'จำกัดค่าห้องผู้ป่วยในต่อครั้ง สูงสุด', unit: 'วัน' },
      { id: 'maximumDaysForICURoom', label: 'จำกัดค่าห้องผู้ป่วยหนักต่อครั้ง สูงสุด', unit: 'วัน' },
      { id: 'nursingFees', label: 'ค่าบริการพยาบาล', unit: 'หน่วย' }
    ]
  },
  {
    id: 'generalHospitalExpenses',
    label: 'ค่ารักษาพยาบาลอื่นๆ ในโรงพยาบาล กรณีผู้ป่วยใน',
    unit: 'บาท/การรักษาครั้งใดครั้งหนึ่ง',
    child: [
      { id: 'ambulanceFees', label: 'ค่ารถพยาบาลฉุกเฉิน', unit: 'หน่วย' },
      {
        id: 'opdFollowUpTreatmentAfterIPDStay',
        label: 'ค่ารักษาพยาบาลแบบผู้ป่วยนอก ต่อเนื่องจากการรักษาแบบผู้ป่วยใน',
        unit: 'หน่วย'
      }
    ]
  },
  {
    id: 'surgeryFee',
    label: 'ค่าธรรมเนียมแพทย์ผ่าตัดและหัตถการ',
    unit: 'บาท/การรักษาครั้งใดครั้งหนึ่ง'
  },
  {
    id: 'physicianHospitalVisitFee',
    label: 'ค่าแพทย์ตรวจรักษาในโรงพยาบาล',
    unit: 'บาท/วัน'
  },
  {
    id: 'accidentCostForOPDPerAccident',
    label: 'ค่ารักษาพยาบาลอุบัติเหตุฉุกเฉิน แบบผู้ป่วยนอก ต่อการบาดเจ็บแต่ละครั้ง',
    unit: 'บาท/อุบัติเหตุแต่ละครั้ง'
  },
  {
    id: 'durationAfterOPDTreatment',
    label: 'การรักษาต่อเนื่อง จากอุบัติเหตุฉุกเฉิน แบบผู้ป่วยนอก ต่อการบาดเจ็บแต่ละครั้ง',
    unit: 'วัน'
  },
  {
    id: 'specialistConsultationFee',
    label: 'ค่าปรึกษาแพทย์เชี่ยวชาญเฉพาะโรค ต่อการเข้ารักษาครั้งใดครั้งหนึ่ง',
    unit: 'หน่วย'
  },
  {
    id: 'hbIncentiveBenefit',
    label: 'ค่าชดเชยรายวัน กรณีใช้สวัสดิการอื่น (HB Incentive)',
    unit: 'บาท/วัน'
  },
  {
    id: 'maximumHBIncentiveBenefits',
    label: 'จำนวนวัน HB Incentive สูงสุด',
    unit: 'วัน/การรักษาครั้งใดครั้งหนึ่ง'
  },
  {
    id: 'opdTreatingCost',
    label: 'ค่ารักษาพยาบาลแบบผู้ป่วยนอก',
    unit: 'บาท/ครั้ง'
  },
  {
    id: 'dentalCost',
    label: 'ค่ารักษาทันตกรรม',
    unit: 'บาท/ปี'
  },
  {
    id: 'otherCoverageDetail',
    label: 'ความคุ้มครองอื่นๆ'
  }
]

export const I18N_PLAN_DETAIL_TEXT_FIELD_LIST = [
  {
    id: 'lifeInsurance',
    label: 'life_insurance',
    unit: 'บาท'
  },
  {
    id: 'accidentalDeathAndDismembermentIndemnity',
    label: 'accidental_death_and_dismemberment_indemnity',
    unit: 'บาท'
  },
  {
    id: 'permanentDisabilityIndemnityTotal',
    label: 'permanent_disability_indemnity_total',
    unit: 'บาท'
  },
  {
    id: 'hospitalAndBoardPerDay',
    label: 'hospital_and_board_per_day',
    unit: 'บาท/วัน'
  },
  {
    id: 'icuCostPerDay',
    label: 'icu_cost_per_day',
    unit: 'บาท/วัน',
    child: [
      { id: 'maximumDaysForHospitalIPD', label: 'maximum_days_for_hospital_IPD', unit: 'วัน' },
      { id: 'maximumDaysForICURoom', label: 'maximum_days_for_ICU_room', unit: 'วัน' },
      { id: 'nursingFees', label: 'nursing_fees', unit: 'หน่วย' }
    ]
  },
  {
    id: 'generalHospitalExpenses',
    label: 'general_hospital_expenses',
    unit: 'บาท/การรักษาครั้งใดครั้งหนึ่ง',
    child: [
      { id: 'ambulanceFees', label: 'ambulance_fees', unit: 'หน่วย' },
      {
        id: 'opdFollowUpTreatmentAfterIPDStay',
        label: 'opd_follow_up_treatment_after_IPD_stay',
        unit: 'หน่วย'
      }
    ]
  },
  {
    id: 'surgeryFee',
    label: 'surgery_fee',
    unit: 'บาท/การรักษาครั้งใดครั้งหนึ่ง'
  },
  {
    id: 'physicianHospitalVisitFee',
    label: 'physician_hospital_visit_fee',
    unit: 'บาท/วัน'
  },
  {
    id: 'accidentCostForOPDPerAccident',
    label: 'accident_cost_for_OPD_per_accident',
    unit: 'บาท/อุบัติเหตุแต่ละครั้ง'
  },
  {
    id: 'durationAfterOPDTreatment',
    label: 'duration_after_OPD_treatment',
    unit: 'วัน'
  },
  {
    id: 'specialistConsultationFee',
    label: 'specialist_consultation_fee',
    unit: 'หน่วย'
  },
  {
    id: 'hbIncentiveBenefit',
    label: 'hb_incentive_benefit',
    unit: 'บาท/วัน'
  },
  {
    id: 'maximumHBIncentiveBenefits',
    label: 'maximum_HB_incentive_benefits',
    unit: 'วัน/การรักษาครั้งใดครั้งหนึ่ง'
  },
  {
    id: 'opdTreatingCost',
    label: 'opd_treating_cost',
    unit: 'บาท/ครั้ง'
  },
  {
    id: 'dentalCost',
    label: 'dental_cost',
    unit: 'บาท/ปี'
  },
  {
    id: 'otherCoverageDetail',
    label: 'other_coverage_detail'
  }
]
