import { createAsyncThunk } from '@reduxjs/toolkit'

import { CUSTOMER_URLS } from '../../../../configs/api'
import { REQUEST_OPTIONS } from '../../../../constants/request-options'
import { AxiosService } from '../../../../services/api'
import { createThunkName } from '../../../../utils/createThunkname'
import { saveData } from '../../../../utils/downloadFile'
import {
  CreateEmployeeDto,
  CreateOrUpdateChildrenDto,
  ExportAllChildrenQueryParams,
  ExportAllCoupleQueryParams,
  ExportAllEmployeeQueryParams,
  GetAllChildrenListQueryParams,
  GetAllChildrenListResponse,
  GetAllCoupleListQueryParams,
  GetAllCoupleListResponse,
  GetAllEmployeeListQueryParams,
  GetAllEmployeeListResponse,
  GetChildrenInfoResponse,
  GetCoupleInfoResponse,
  GetEmployeeInfoResponse,
  GetEmployeeLevelMasterDataResponse,
  GetEmployeeListFromExcelResponse,
  GetEmployeesCountResponse,
  GetInsurancePlanByEmployeeLevelIdQueryParams,
  GetInsurancePlanInfoResponse,
  GetInsurancePlanMasterDataByEmployeeLevelIdResponse,
  GetIsAlreadyHaveEmployeeResponse,
  GetNationalitiesMasterDataResponse,
  GetTimeStampInfoResponse,
  ImportEmployeeDto,
  UpdateCoupleDto,
  UpdateEmployeeDto,
  UpdateInsurancePlanDto,
  UpdateStatusWithTerminationDateDto
} from './types'

const PREFIX = 'employee-info'

export const EmployeeInfoService = {
  exportAllEmployee: async (yearId: string, customerId: string, queryParams: ExportAllEmployeeQueryParams) => {
    try {
      const result = await AxiosService.get(CUSTOMER_URLS.employeeInfoTab.exportEmployeeById(yearId, customerId), {
        ...REQUEST_OPTIONS,
        params: queryParams
      })
      saveData(result, result.request.getResponseHeader('Content-Disposition'))
    } catch (error) {
      throw error
    }
  },
  exportAllCouple: async (yearId: string, customerId: string, queryParams: ExportAllCoupleQueryParams) => {
    try {
      const result = await AxiosService.get(CUSTOMER_URLS.employeeInfoTab.exportAllCoupleById(yearId, customerId), {
        ...REQUEST_OPTIONS,
        params: queryParams
      })
      saveData(result, result.request.getResponseHeader('Content-Disposition'))
    } catch (error) {
      throw error
    }
  },
  exportAllChildren: async (yearId: string, customerId: string, queryParams: ExportAllChildrenQueryParams) => {
    try {
      const result = await AxiosService.get(CUSTOMER_URLS.employeeInfoTab.exportAllChildrenById(yearId, customerId), {
        ...REQUEST_OPTIONS,
        params: queryParams
      })
      saveData(result, result.request.getResponseHeader('Content-Disposition'))
    } catch (error) {
      throw error
    }
  },
  getAllEmployeeListAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getAllEmployeeList'),
    async (queryParams: GetAllEmployeeListQueryParams) => {
      try {
        const customerId = queryParams.customerId ?? ''
        const yearId = queryParams.yearId ?? ''
        delete queryParams.customerId
        delete queryParams.yearId
        const result = await AxiosService.get<GetAllEmployeeListResponse>(
          CUSTOMER_URLS.employeeInfoTab.getAllEmployeeById(yearId, customerId),
          { params: queryParams }
        )
        return result.data
      } catch (error) {
        throw error
      }
    }
  ),
  getAllCoupleListAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getAllCoupleList'),
    async (queryParams: GetAllCoupleListQueryParams) => {
      try {
        const customerId = queryParams.customerId ?? ''
        const yearId = queryParams.yearId ?? ''
        delete queryParams.customerId
        delete queryParams.yearId
        const result = await AxiosService.get<GetAllCoupleListResponse>(
          CUSTOMER_URLS.employeeInfoTab.getAllCoupleById(yearId, customerId),
          { params: queryParams }
        )
        return result.data
      } catch (error) {
        throw error
      }
    }
  ),
  getAllChildrenListAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getAllChildrenList'),
    async (queryParams: GetAllChildrenListQueryParams) => {
      try {
        const customerId = queryParams.customerId ?? ''
        const yearId = queryParams.yearId ?? ''
        delete queryParams.customerId
        delete queryParams.yearId
        const result = await AxiosService.get<GetAllChildrenListResponse>(
          CUSTOMER_URLS.employeeInfoTab.getAllChildrenById(yearId, customerId),
          { params: queryParams }
        )
        return result.data
      } catch (error) {
        throw error
      }
    }
  ),
  getEmployeeInfoByIdAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getEmployeeInfoById'),
    async ({ yearId, employeeId }: { yearId: string; employeeId: string }) => {
      try {
        const result = await AxiosService.get<GetEmployeeInfoResponse>(
          CUSTOMER_URLS.employeeInfoTab.getEmployeeInfoByEmpId(yearId, employeeId)
        )
        return result.data.employeeInfo
      } catch (error) {
        throw error
      }
    }
  ),
  getInsurancePlanByIdAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getInsurancePlanById'),
    async ({ yearId, employeeId }: { yearId: string; employeeId: string }) => {
      try {
        const result = await AxiosService.get<GetInsurancePlanInfoResponse>(
          CUSTOMER_URLS.employeeInfoTab.getInsurancePlanByEmpId(yearId, employeeId)
        )
        return result.data.insurancePlanId
      } catch (error) {
        throw error
      }
    }
  ),
  getCoupleInfoByIdAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getCoupleInfoById'),
    async ({ yearId, employeeId }: { yearId: string; employeeId: string }) => {
      try {
        const result = await AxiosService.get<GetCoupleInfoResponse>(
          CUSTOMER_URLS.employeeInfoTab.getCoupleInfoByEmpId(yearId, employeeId)
        )
        return result.data.coupleInfo
      } catch (error) {
        throw error
      }
    }
  ),
  getChildrenInfoByIdAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getChildrenInfoById'),
    async ({ yearId, employeeId }: { yearId: string; employeeId: string }) => {
      try {
        const result = await AxiosService.get<GetChildrenInfoResponse>(
          CUSTOMER_URLS.employeeInfoTab.getChildrenInfoByEmpId(yearId, employeeId)
        )
        return result.data.childrenInfo
      } catch (error) {
        throw error
      }
    }
  ),
  getTimestampDataByIdAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getTimestampDataById'),
    async (employeeId: string) => {
      try {
        const result = await AxiosService.get<GetTimeStampInfoResponse>(
          CUSTOMER_URLS.employeeInfoTab.getTimestampDataByEmpId(employeeId)
        )
        return result.data.timestampData
      } catch (error) {
        throw error
      }
    }
  ),
  getIsAlreadyHaveEmployeeAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getIsAlreadyHaveEmployee'),
    async (customerId: string) => {
      try {
        const result = await AxiosService.get<GetIsAlreadyHaveEmployeeResponse>(
          CUSTOMER_URLS.employeeInfoTab.getIsAlreadyHaveEmployee(customerId)
        )
        return result.data.isAlreadyHaveEmployees
      } catch (error) {
        throw error
      }
    }
  ),
  getEmployeeLevelMasterDataAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, ' getEmployeeLevelMasterData'),
    async ({ yearId, customerId }: { yearId: string; customerId: string }) => {
      try {
        const result = await AxiosService.get<GetEmployeeLevelMasterDataResponse>(
          CUSTOMER_URLS.masterData.getEmployeeLevelsMasterData(yearId, customerId)
        )
        return result.data.employeeLevels
      } catch (error) {
        throw error
      }
    }
  ),
  getNationalitiesMasterDataAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getNationalitiesMasterData'),
    async () => {
      try {
        const result = await AxiosService.get<GetNationalitiesMasterDataResponse>(
          CUSTOMER_URLS.masterData.getNationalityMasterData
        )
        return result.data.nationalities
      } catch (error) {
        throw error
      }
    }
  ),
  getInsurancePlanMasterDataByEmployeeLevelIdAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, ' getInsurancePlanMasterDataByEmployeeLevelId'),
    async (queryParams: GetInsurancePlanByEmployeeLevelIdQueryParams) => {
      try {
        const result = await AxiosService.get<GetInsurancePlanMasterDataByEmployeeLevelIdResponse>(
          CUSTOMER_URLS.masterData.getInsurancePlansMasterData,
          { params: queryParams }
        )
        return result.data.insurancePlans
      } catch (error) {
        throw error
      }
    }
  ),
  createEmployee: async (dto: CreateEmployeeDto) => {
    try {
      await AxiosService.post(CUSTOMER_URLS.employeeInfoTab.createEmployee, dto)
    } catch (error) {
      throw error
    }
  },
  updateEmployeeById: async (dto: UpdateEmployeeDto) => {
    try {
      await AxiosService.put(CUSTOMER_URLS.employeeInfoTab.updateEmployeeInfo, dto)
    } catch (error) {
      throw error
    }
  },
  updateEmployeeInsurancePlanById: async (dto: UpdateInsurancePlanDto) => {
    try {
      await AxiosService.put(CUSTOMER_URLS.employeeInfoTab.updateEmployeeInsurancePlan, dto)
    } catch (error) {
      throw error
    }
  },
  updateEmployeeCoupleInfoById: async (dto: UpdateCoupleDto) => {
    try {
      await AxiosService.post(CUSTOMER_URLS.employeeInfoTab.updateEmployeeCoupleInfo, dto)
    } catch (error) {
      throw error
    }
  },
  createOrUpdateChildrenByEmployee: async (dto: CreateOrUpdateChildrenDto) => {
    try {
      await AxiosService.post(CUSTOMER_URLS.employeeInfoTab.createOrUpdateChildrenByEmployee, {
        childrens: dto.childrens
          ? dto.childrens.map((child) => {
              delete (child as any).id
              return child
            })
          : null,
        employeeId: dto.employeeId,
        yearId: dto.yearId
      })
    } catch (error) {
      throw error
    }
  },
  deleteEmployeeById: async (employeeId: string) => {
    try {
      await AxiosService.delete(CUSTOMER_URLS.employeeInfoTab.deleteEmployeeById(employeeId))
    } catch (error) {
      throw error
    }
  },
  updateEmployeeStatusById: async (dto: UpdateStatusWithTerminationDateDto) => {
    try {
      await AxiosService.put(CUSTOMER_URLS.employeeInfoTab.updateEmployeeStatus, dto)
    } catch (error) {
      throw error
    }
  },
  getEmployeesCountAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, ' getEmployeesCount'),
    async ({ yearId, customerId }: { yearId: string; customerId: string }) => {
      try {
        const result = await AxiosService.get<GetEmployeesCountResponse>(
          CUSTOMER_URLS.employeeInfoTab.getEmployeesCount(yearId, customerId)
        )
        return result.data.employeesCount
      } catch (error) {
        throw error
      }
    }
  ),
  downloadEmployeeTemplateAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'downloadEmployeeTemplate'),
    async ({ yearId, customerId }: { yearId: string; customerId: string }) => {
      try {
        const result = await AxiosService.get(
          CUSTOMER_URLS.employeeInfoTab.downloadEmployeeTemplate(yearId, customerId),
          {
            ...REQUEST_OPTIONS
          }
        )
        saveData(result, result.request.getResponseHeader('Content-Disposition'))
      } catch (error) {
        throw error
      }
    }
  ),
  getEmployeeListFromExcelAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getEmployeeListFromExcel'),
    async ({ yearId, customerId, file }: { yearId: string; customerId: string; file: File }) => {
      try {
        const formData = new FormData()
        formData.append('file', file)
        const result = await AxiosService.post<GetEmployeeListFromExcelResponse>(
          CUSTOMER_URLS.employeeInfoTab.getEmployeeListFromExcel(yearId, customerId),
          formData
        )
        return result.data.employees
      } catch (error) {
        throw error
      }
    }
  ),
  importEmployeeAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'importEmployee'),
    async (dto: ImportEmployeeDto) => {
      try {
        await AxiosService.post(CUSTOMER_URLS.employeeInfoTab.importEmployee, dto)
      } catch (error) {
        throw error
      }
    }
  )
}
