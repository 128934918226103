import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { transformMasterData } from '../../../../utils/transformMasterData'
import { EClaimInfoService } from '../../services/e-claim-info/EClaimInfoService'
import { EClaimByCompanyFilterValue, EClaimFilterValue, EClaimInfoState } from './types'

const eClaimFilterInitialValues = {
  search: '',
  page: '1',
  pageSize: '20'
}

const eClaimByCompanyFilterInitialValues = {
  search: '',
  claimTypeId: 'ทั้งหมด',
  requestClaimedDate: '',
  benefitId: 'ทั้งหมด',
  status: 'ทั้งหมด',
  page: '1',
  pageSize: '10',
  subCompanyId: 'ทั้งหมด',
  departmentId: 'ทั้งหมด',
  sectionId: 'ทั้งหมด'
}

const initialState: EClaimInfoState = {
  //State
  currentEClaimTab: 'benefit',
  isEClaimTypeMasterDataLoading: false,
  isEClaimDocumentMasterDataLoading: false,
  isFetchedAllCustomerLoading: false,
  isFetchedCompanyNameLoading: false,
  isFetchedAllEClaimByCustomerId: false,
  isExportEClaimByCustomerIdLoading: false,
  isFetchedEClaimByIdLoading: false,
  isApproveEClaimByIdLoading: false,
  isRejectEClaimByIdLoading: false,
  isExportAllEClaimLoading: false,
  eClaimDocuments: [],
  fetchedCompanyName: '',
  eClaimFilterValues: eClaimFilterInitialValues,
  eClaimByCompanyFilterValues: eClaimByCompanyFilterInitialValues,

  //Master Data
  eClaimTypeMasterData: [],
  eClaimDocumentMasterData: []
}

const slice = createSlice({
  name: 'e-claim/e-claim-info',
  initialState,
  reducers: {
    setEClaimTab: (state, action: PayloadAction<'benefit' | 'eClaim'>) => {
      state.currentEClaimTab = action.payload
    },
    setEClaimFilterValues(state, action: PayloadAction<{ field: keyof EClaimFilterValue; value: string }>) {
      const { field, value } = action.payload
      if (field === 'pageSize') state.eClaimFilterValues.page = '1'
      state.eClaimFilterValues[field] = value
    },
    setEClaimByCompanyFilterValues(
      state,
      action: PayloadAction<{ field: keyof EClaimByCompanyFilterValue; value: string }>
    ) {
      const { field, value } = action.payload
      if (field === 'pageSize') state.eClaimByCompanyFilterValues.page = '1'
      state.eClaimByCompanyFilterValues[field] = value
    },
    resetEClaimFilterValues(state) {
      state.eClaimFilterValues = eClaimFilterInitialValues
    },
    resetEClaimByCompanyFilterValues(state) {
      state.eClaimByCompanyFilterValues = eClaimByCompanyFilterInitialValues
    },
    resetEClaimInfoState: (state) => {
      state.isEClaimTypeMasterDataLoading = false
      state.isEClaimDocumentMasterDataLoading = false
      state.isFetchedAllCustomerLoading = false
      state.isFetchedCompanyNameLoading = false
      state.isFetchedAllEClaimByCustomerId = false
      state.isExportEClaimByCustomerIdLoading = false
      state.isFetchedEClaimByIdLoading = false
      state.isApproveEClaimByIdLoading = false
      state.isRejectEClaimByIdLoading = false
      state.isExportAllEClaimLoading = false
      state.eClaimDocuments = []
      state.fetchedCompanyName = ''
      state.eClaimTypeMasterData = []
      state.eClaimDocumentMasterData = []
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(EClaimInfoService.getEClaimTypeMasterDataAsyncThunk.pending, (state) => {
        state.isEClaimTypeMasterDataLoading = true
      })
      .addCase(EClaimInfoService.getEClaimTypeMasterDataAsyncThunk.rejected, (state) => {
        state.isEClaimTypeMasterDataLoading = false
      })
      .addCase(EClaimInfoService.getEClaimTypeMasterDataAsyncThunk.fulfilled, (state, action) => {
        state.eClaimTypeMasterData = transformMasterData(action.payload)
        state.isEClaimTypeMasterDataLoading = false
      })
      .addCase(EClaimInfoService.getEClaimDocumentMasterDataAsyncThunk.pending, (state) => {
        state.isEClaimDocumentMasterDataLoading = true
      })
      .addCase(EClaimInfoService.getEClaimDocumentMasterDataAsyncThunk.rejected, (state) => {
        state.isEClaimDocumentMasterDataLoading = false
      })
      .addCase(EClaimInfoService.getEClaimDocumentMasterDataAsyncThunk.fulfilled, (state, action) => {
        state.isEClaimDocumentMasterDataLoading = false
        state.eClaimDocumentMasterData = transformMasterData(action.payload)
        state.eClaimDocuments = transformMasterData(action.payload).map((masterData) => ({
          ...masterData,
          fileUrl: ''
        }))
      })
      .addCase(EClaimInfoService.getAllCustomerAsyncThunk.pending, (state) => {
        state.isFetchedAllCustomerLoading = true
      })
      .addCase(EClaimInfoService.getAllCustomerAsyncThunk.rejected, (state) => {
        state.isFetchedAllCustomerLoading = false
      })
      .addCase(EClaimInfoService.getAllCustomerAsyncThunk.fulfilled, (state, action) => {
        state.fetchedAllCustomer = action.payload
        state.isFetchedAllCustomerLoading = false
      })
      .addCase(EClaimInfoService.getCompanyNameAsyncThunk.pending, (state) => {
        state.isFetchedCompanyNameLoading = true
      })
      .addCase(EClaimInfoService.getCompanyNameAsyncThunk.rejected, (state) => {
        state.isFetchedCompanyNameLoading = false
      })
      .addCase(EClaimInfoService.getCompanyNameAsyncThunk.fulfilled, (state, action) => {
        state.fetchedCompanyName = action.payload
        state.isFetchedCompanyNameLoading = false
      })
      .addCase(EClaimInfoService.getAllEClaimByCustomerIdAsyncThunk.pending, (state) => {
        state.isFetchedAllEClaimByCustomerId = true
      })
      .addCase(EClaimInfoService.getAllEClaimByCustomerIdAsyncThunk.rejected, (state) => {
        state.isFetchedAllEClaimByCustomerId = false
      })
      .addCase(EClaimInfoService.getAllEClaimByCustomerIdAsyncThunk.fulfilled, (state, action) => {
        state.fetchedAllEClaimByCustomerId = action.payload
        state.isFetchedAllEClaimByCustomerId = false
      })
      .addCase(EClaimInfoService.exportEClaimByCustomerIdAsyncThunk.pending, (state) => {
        state.isExportEClaimByCustomerIdLoading = true
      })
      .addCase(EClaimInfoService.exportEClaimByCustomerIdAsyncThunk.rejected, (state) => {
        state.isExportEClaimByCustomerIdLoading = false
      })
      .addCase(EClaimInfoService.exportEClaimByCustomerIdAsyncThunk.fulfilled, (state) => {
        state.isExportEClaimByCustomerIdLoading = false
      })
      .addCase(EClaimInfoService.getEClaimByIdAsyncThunk.pending, (state) => {
        state.isFetchedEClaimByIdLoading = true
      })
      .addCase(EClaimInfoService.getEClaimByIdAsyncThunk.rejected, (state) => {
        state.isFetchedEClaimByIdLoading = false
      })
      .addCase(EClaimInfoService.getEClaimByIdAsyncThunk.fulfilled, (state, action) => {
        state.isFetchedEClaimByIdLoading = false
        state.fetchedEClaimById = action.payload
        state.eClaimDocuments = state.eClaimDocuments.map((eClaimDocument) => {
          const currentDocument = action.payload.documents.find(
            (document) => document.claimDocumentMasterId === eClaimDocument.id
          )
          return {
            label: eClaimDocument.label,
            id: eClaimDocument.id,
            fileUrl: currentDocument ? currentDocument.fileUrl : ''
          }
        })
      })
      .addCase(EClaimInfoService.approveEClaimByIdAsyncThunk.pending, (state) => {
        state.isApproveEClaimByIdLoading = true
      })
      .addCase(EClaimInfoService.approveEClaimByIdAsyncThunk.rejected, (state) => {
        state.isApproveEClaimByIdLoading = false
      })
      .addCase(EClaimInfoService.approveEClaimByIdAsyncThunk.fulfilled, (state) => {
        state.isApproveEClaimByIdLoading = false
      })
      .addCase(EClaimInfoService.rejectEClaimByIdAsyncThunk.pending, (state) => {
        state.isRejectEClaimByIdLoading = true
      })
      .addCase(EClaimInfoService.rejectEClaimByIdAsyncThunk.rejected, (state) => {
        state.isRejectEClaimByIdLoading = false
      })
      .addCase(EClaimInfoService.rejectEClaimByIdAsyncThunk.fulfilled, (state) => {
        state.isRejectEClaimByIdLoading = false
      })
      .addCase(EClaimInfoService.exportAllEClaimAsyncThunk.pending, (state) => {
        state.isExportAllEClaimLoading = true
      })
      .addCase(EClaimInfoService.exportAllEClaimAsyncThunk.rejected, (state) => {
        state.isExportAllEClaimLoading = false
      })
      .addCase(EClaimInfoService.exportAllEClaimAsyncThunk.fulfilled, (state) => {
        state.isExportAllEClaimLoading = false
      })
  }
})

export const {
  resetEClaimInfoState,
  resetEClaimByCompanyFilterValues,
  setEClaimFilterValues,
  resetEClaimFilterValues,
  setEClaimByCompanyFilterValues,
  setEClaimTab
} = slice.actions
export default slice.reducer
