import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { transformMasterData } from '../../../../utils/transformMasterData'
import { CompanyInfoService } from '../../services/company-info/CompanyInfoService'
import { CustomerCompanyInfoState, CustomerFilterValue } from './types'

const customerFilterInitialValues = {
  search: '',
  name: '',
  email: '',
  phoneNumber: '',
  sellerCode: '',
  status: 'ทั้งหมด',
  pageSize: '10',
  page: '1'
}

const initialState: CustomerCompanyInfoState = {
  haveEClaim: false,
  haveMarketplace: false,
  haveFlexBen: false,
  haveMedicalPortal: false,
  isCustomerActive: true,
  isEmployeeLoginByEmail: false,
  isEmployeeLoginBySMS: false,
  customerImage: null,
  fetchedCustomers: undefined,
  fetchedCustomerCompanyName: '',
  fetchedYear: undefined,
  fetchedCustomer: undefined,
  fetchedProvinceMasterData: [],
  fetchedDistrictMasterData: [],
  fetchedSubDistrictMasterData: [],
  fetchedYearMasterData: [],
  fetchedRegistrationTypesMasterData: [],
  fetchedInsuranceCompaniesMasterData: [],
  fetchedCustomerMasterData: [],
  timeStampData: undefined,
  isEditInfo: false,
  customerFilterValues: customerFilterInitialValues
}

const slice = createSlice({
  name: 'customer/company-info',
  initialState,
  reducers: {
    toggleEClaim: (state) => {
      state.haveEClaim = !state.haveEClaim
    },
    toggleHaveMarketplace: (state) => {
      state.haveMarketplace = !state.haveMarketplace
    },
    toggleHaveFlexBen: (state) => {
      state.haveFlexBen = !state.haveFlexBen
    },
    toggleCustomerActive: (state) => {
      state.isCustomerActive = !state.isCustomerActive
    },
    toggleHaveMedicalPortal: (state) => {
      state.haveMedicalPortal = !state.haveMedicalPortal
    },
    toggleIsEmployeeLoginByEmail: (state) => {
      state.isEmployeeLoginByEmail = !state.isEmployeeLoginByEmail
    },
    toggleIsEmployeeLoginBySMS: (state) => {
      state.isEmployeeLoginBySMS = !state.isEmployeeLoginBySMS
    },
    setCustomerImage: (state, action: PayloadAction<File>) => {
      state.customerImage = action.payload
    },
    setIsEditInfo: (state, action: PayloadAction<boolean>) => {
      state.isEditInfo = action.payload
    },
    setCustomerFilterValues(state, action: PayloadAction<{ field: keyof CustomerFilterValue; value: string }>) {
      const { field, value } = action.payload
      if (field === 'pageSize') state.customerFilterValues.page = '1'
      state.customerFilterValues[field] = value
    },
    resetCustomerFilterValues(state) {
      state.customerFilterValues = customerFilterInitialValues
    },
    resetCustomerCompanyInfoState: (state) => {
      state.haveEClaim = false
      state.haveMarketplace = false
      state.haveFlexBen = false
      state.isCustomerActive = true
      state.isEmployeeLoginByEmail = false
      state.isEmployeeLoginBySMS = false
      state.customerImage = null
      state.fetchedCustomers = undefined
      state.fetchedCustomerCompanyName = ''
      state.fetchedYear = undefined
      state.fetchedCustomer = undefined
      state.fetchedProvinceMasterData = []
      state.fetchedDistrictMasterData = []
      state.fetchedSubDistrictMasterData = []
      state.fetchedYearMasterData = []
      state.fetchedRegistrationTypesMasterData = []
      state.fetchedInsuranceCompaniesMasterData = []
      state.fetchedCustomerMasterData = []
      state.timeStampData = undefined
      state.isEditInfo = false
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(CompanyInfoService.getAllCustomerAsyncThunk.fulfilled, (state, action) => {
        state.fetchedCustomers = action.payload
      })
      .addCase(CompanyInfoService.getCustomerCompanyNameAsyncThunk.fulfilled, (state, action) => {
        state.fetchedCustomerCompanyName = action.payload
      })
      .addCase(CompanyInfoService.getSelectYearAsyncThunk.fulfilled, (state, action) => {
        state.fetchedYear = action.payload
      })
      .addCase(CompanyInfoService.getCustomerByIdAsyncThunk.fulfilled, (state, action) => {
        state.fetchedCustomer = action.payload
        state.haveEClaim = action.payload.haveEClaim
        state.haveMarketplace = action.payload.haveMarketplace
        state.haveFlexBen = action.payload.haveFlexBen
        state.haveMedicalPortal = action.payload.haveMedicalPortal
        state.isCustomerActive = action.payload.isActive
        state.isEmployeeLoginByEmail = action.payload.isEmployeeLoginByEmail
        state.isEmployeeLoginBySMS = action.payload.isEmployeeLoginBySMS
      })
      .addCase(CompanyInfoService.getProvinceMasterDataAsyncThunk.fulfilled, (state, action) => {
        state.fetchedProvinceMasterData = transformMasterData(action.payload)
      })
      .addCase(CompanyInfoService.getDistrictMasterDataAsyncThunk.fulfilled, (state, action) => {
        state.fetchedDistrictMasterData = transformMasterData(action.payload)
      })
      .addCase(CompanyInfoService.getSubDistrictMasterDataAsyncThunk.fulfilled, (state, action) => {
        state.fetchedSubDistrictMasterData = action.payload.map((data) => ({
          id: data.id,
          label: data.name,
          postalCode: data.postalCode
        }))
      })
      .addCase(CompanyInfoService.getYearMasterDataAsyncThunk.fulfilled, (state, action) => {
        state.fetchedYearMasterData = transformMasterData(action.payload)
      })
      .addCase(CompanyInfoService.getRegistrationTypesMasterDataAsyncThunk.fulfilled, (state, action) => {
        state.fetchedRegistrationTypesMasterData = transformMasterData(action.payload)
      })
      .addCase(CompanyInfoService.getInsuranceCompaniesAsyncThunk.fulfilled, (state, action) => {
        state.fetchedInsuranceCompaniesMasterData = transformMasterData(action.payload)
      })
      .addCase(CompanyInfoService.getCustomerMasterDataAsyncThunk.fulfilled, (state, action) => {
        state.fetchedCustomerMasterData = transformMasterData(action.payload)
      })
      .addCase(CompanyInfoService.getTimestampDataAsyncThunk.fulfilled, (state, action) => {
        state.timeStampData = action.payload.timestampData
      })
  }
})

export const {
  toggleCustomerActive,
  toggleEClaim,
  toggleHaveFlexBen,
  toggleHaveMarketplace,
  toggleHaveMedicalPortal,
  toggleIsEmployeeLoginByEmail,
  toggleIsEmployeeLoginBySMS,
  setCustomerImage,
  setIsEditInfo,
  setCustomerFilterValues,
  resetCustomerFilterValues,
  resetCustomerCompanyInfoState
} = slice.actions
export default slice.reducer
