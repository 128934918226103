import { createAsyncThunk } from '@reduxjs/toolkit'

import { CUSTOMER_URLS } from '../../../../configs/api'
import { AxiosService } from '../../../../services/api'
import { createThunkName } from '../../../../utils/createThunkname'
import {
  AddBenefitDto,
  GetAllBenefitQueryParams,
  GetAllBenefitResponse,
  GetBenefitCategoryMasterDataResponse,
  GetBenefitDetailByIdResponse,
  GetBenefitDocumentByIdResponse,
  GetBenefitDocumentMasterDataResponse,
  ImportBenefitFromLastYearDto,
  UpdateBenefitDetailDto,
  UpdateBenefitDocumentDto
} from './types'

const PREFIX = 'welfare'

export const WelfareService = {
  getBenefitCategoryMasterDataAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getBenefitCategoryMasterData'),
    async () => {
      try {
        const result = await AxiosService.get<GetBenefitCategoryMasterDataResponse>(
          CUSTOMER_URLS.masterData.getBenefitCategoryMasterData
        )
        return result.data.benefitCategories
      } catch (error) {
        throw error
      }
    }
  ),
  getBenefitDocumentMasterDataAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getBenefitDocumentMasterData'),
    async () => {
      try {
        const result = await AxiosService.get<GetBenefitDocumentMasterDataResponse>(
          CUSTOMER_URLS.masterData.getBenefitDocumentMasterData
        )
        return result.data.benefitDocumentList
      } catch (error) {
        throw error
      }
    }
  ),
  importBenefitFromLastYearAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'importBenefitFromLastYear'),
    async (importBenefitFromLastYearDto: ImportBenefitFromLastYearDto) => {
      try {
        await AxiosService.post(CUSTOMER_URLS.welfareTab.importBenefitFromLastYear, importBenefitFromLastYearDto)
      } catch (error) {
        throw error
      }
    }
  ),
  getAllBenefitAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getAllBenefit'),
    async ({ customerId, queryParams }: { customerId: string; queryParams: GetAllBenefitQueryParams }) => {
      try {
        const result = await AxiosService.get<GetAllBenefitResponse>(
          CUSTOMER_URLS.welfareTab.getAllBenefit(customerId),
          { params: queryParams }
        )
        return result.data
      } catch (error) {
        throw error
      }
    }
  ),
  addBenefitAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'addBenefit'),
    async (addBenefitDto: AddBenefitDto) => {
      try {
        await AxiosService.post(CUSTOMER_URLS.welfareTab.addBenefit, addBenefitDto)
      } catch (error) {
        throw error
      }
    }
  ),
  updateBenefitDetailByIdAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'updateBenefitDetailById'),
    async (dto: UpdateBenefitDetailDto) => {
      try {
        await AxiosService.put(CUSTOMER_URLS.welfareTab.updateBenefitDetailById, dto)
      } catch (error) {
        throw error
      }
    }
  ),
  updateBenefitDocumentByIdAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'updateBenefitDocumentById'),
    async (dto: UpdateBenefitDocumentDto) => {
      try {
        await AxiosService.put(CUSTOMER_URLS.welfareTab.updateBenefitDocumentById, dto)
      } catch (error) {
        throw error
      }
    }
  ),
  getBenefitDetailByIdAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getBenefitDetailById'),
    async (benefitId: string) => {
      try {
        const result = await AxiosService.get<GetBenefitDetailByIdResponse>(
          CUSTOMER_URLS.welfareTab.getBenefitDetailById(benefitId)
        )
        return result.data.benefit
      } catch (error) {
        throw error
      }
    }
  ),
  getBenefitDocumentByIdAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getBenefitDocumentById'),
    async (benefitId: string) => {
      try {
        const result = await AxiosService.get<GetBenefitDocumentByIdResponse>(
          CUSTOMER_URLS.welfareTab.getBenefitDocumentById(benefitId)
        )
        return result.data.benefitDocumentList
      } catch (error) {
        throw error
      }
    }
  ),

  deleteBenefitByIdAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'deleteBenefitById'),
    async (benefitId: string) => {
      try {
        await AxiosService.delete(CUSTOMER_URLS.welfareTab.deleteBenefitById(benefitId))
      } catch (error) {
        throw error
      }
    }
  )
}
