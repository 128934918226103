import { createAsyncThunk } from '@reduxjs/toolkit'

import { INSURER_URLS } from '../../../../configs/api'
import { REQUEST_OPTIONS } from '../../../../constants/request-options'
import { AxiosService } from '../../../../services/api'
import { createThunkName } from '../../../../utils/createThunkname'
import { saveData } from '../../../../utils/downloadFile'
import {
  AddInsurerDto,
  DeleteInsurerDto,
  GetAllInsurerResponse,
  GetAllPlansByInsurerIdQueryParams,
  GetAllPlansByInsurerIdResponse,
  GetInsuranceCompaniesMasterDataResponse,
  GetInsurerByIdResponse,
  GetInsurerMasterDataResponse,
  GetInsurerMasterDataWithShortNameResponse,
  UpdateInsurerInfoDto,
  UpdateInsurerStatusDto,
  UpdatePlanOrderDto
} from './types'

const PREFIX = 'Insurer'

export const InsurerService = {
  getAllInsurersAsyncThunk: createAsyncThunk(createThunkName(PREFIX, 'getAllInsurers'), async () => {
    try {
      const result = await AxiosService.get<GetAllInsurerResponse>(INSURER_URLS.getAllInsurer)
      return result.data.insurers
    } catch (error) {
      throw error
    }
  }),
  getInsurerInfoByIdAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getInsurerInfo'),
    async (insurerId: string) => {
      try {
        const result = await AxiosService.get<GetInsurerByIdResponse>(INSURER_URLS.getInsurerById(insurerId))
        return result.data.insurer
      } catch (error) {
        throw error
      }
    }
  ),
  getAllPlansByInsurerIdAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getAllPlansByInsurerId'),
    async ({ insurerId, queryParams }: { insurerId: string; queryParams: GetAllPlansByInsurerIdQueryParams }) => {
      try {
        const result = await AxiosService.get<GetAllPlansByInsurerIdResponse>(
          INSURER_URLS.getAllPlansByInsurerId(insurerId),
          { params: queryParams }
        )
        return result.data
      } catch (error) {
        throw error
      }
    }
  ),
  addInsurer: async (addInsurerDto: AddInsurerDto) => {
    try {
      await AxiosService.post(INSURER_URLS.addInsurer, addInsurerDto)
    } catch (error) {
      throw error
    }
  },
  deleteInsurer: async (deleteInsurerDto: DeleteInsurerDto) => {
    try {
      await AxiosService.post(INSURER_URLS.deleteInsurer, deleteInsurerDto)
    } catch (error) {
      throw error
    }
  },
  exportInsurer: async () => {
    try {
      const result = await AxiosService.get(INSURER_URLS.exportInsurer, {
        ...REQUEST_OPTIONS
      })
      saveData(result, result.request.getResponseHeader('Content-Disposition'))
    } catch (error) {
      throw error
    }
  },
  updateInsurerStatus: async (updateInsurerStatusDto: UpdateInsurerStatusDto) => {
    try {
      await AxiosService.put(INSURER_URLS.updateInsurerStatus, updateInsurerStatusDto)
    } catch (error) {
      throw error
    }
  },
  updateInsurerInfo: async (updateInsurerInfoDto: UpdateInsurerInfoDto) => {
    try {
      await AxiosService.put(INSURER_URLS.updateInsurerInfo, updateInsurerInfoDto)
    } catch (error) {
      throw error
    }
  },
  updatePlanOrder: async (updatePlanOrderDto: UpdatePlanOrderDto) => {
    try {
      await AxiosService.put(INSURER_URLS.updateInsurerPlanOrder, updatePlanOrderDto)
    } catch (error) {
      throw error
    }
  },
  getInsurersMasterDataAsyncThunk: createAsyncThunk(createThunkName(PREFIX, 'getInsurersMasterData'), async () => {
    try {
      const result = await AxiosService.get<GetInsurerMasterDataResponse>(INSURER_URLS.masterData.getInsurerMasterData)
      return result.data.insurers
    } catch (error) {
      throw error
    }
  }),
  getInsurersMasterDataWithShortNameAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getInsurersMasterDataWithShortName'),
    async () => {
      try {
        const result = await AxiosService.get<GetInsurerMasterDataWithShortNameResponse>(
          INSURER_URLS.masterData.getInsurerShortNameMasterData
        )
        return result.data.insurers
      } catch (error) {
        throw error
      }
    }
  ),
  getInsuranceCompaniesMasterDataAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getInsuranceCompaniesMasterData'),
    async () => {
      try {
        const result = await AxiosService.get<GetInsuranceCompaniesMasterDataResponse>(
          INSURER_URLS.masterData.getInsuranceCompaniesMasterData
        )
        return result.data.insuranceCompanies
      } catch (error) {
        throw error
      }
    }
  )
}
