import { RootState } from '../../../../stores/store'

//State
export const currentEClaimTabSelector = (state: RootState) => state.eClaimInfo.currentEClaimTab
export const isEClaimTypeMasterDataLoadingSelector = (state: RootState) =>
  state.eClaimInfo.isEClaimTypeMasterDataLoading
export const isEClaimDocumentMasterDataLoadingSelector = (state: RootState) =>
  state.eClaimInfo.isEClaimDocumentMasterDataLoading
export const isFetchedAllCustomerLoadingSelector = (state: RootState) => state.eClaimInfo.isFetchedAllCustomerLoading
export const isFetchedCompanyNameLoadingSelector = (state: RootState) => state.eClaimInfo.isFetchedCompanyNameLoading
export const isFetchedAllEClaimByCustomerIdSelector = (state: RootState) =>
  state.eClaimInfo.isFetchedAllEClaimByCustomerId
export const isExportEClaimByCustomerIdLoadingSelector = (state: RootState) =>
  state.eClaimInfo.isExportEClaimByCustomerIdLoading
export const isFetchedEClaimByIdLoadingSelector = (state: RootState) => state.eClaimInfo.isFetchedEClaimByIdLoading
export const isApproveEClaimByIdLoadingSelector = (state: RootState) => state.eClaimInfo.isApproveEClaimByIdLoading
export const isRejectEClaimByIdLoadingSelector = (state: RootState) => state.eClaimInfo.isRejectEClaimByIdLoading
export const isExportAllEClaimLoadingSelector = (state: RootState) => state.eClaimInfo.isExportAllEClaimLoading
export const eClaimFilterValuesSelector = (state: RootState) => state.eClaimInfo.eClaimFilterValues
export const eClaimByCompanyFilterValuesSelector = (state: RootState) => state.eClaimInfo.eClaimByCompanyFilterValues

//Response
export const fetchedAllCustomerSelector = (state: RootState) => state.eClaimInfo.fetchedAllCustomer
export const fetchedCompanyNameSelector = (state: RootState) => state.eClaimInfo.fetchedCompanyName
export const fetchedAllEClaimByCustomerIdSelector = (state: RootState) => state.eClaimInfo.fetchedAllEClaimByCustomerId
export const fetchedEClaimByIdSelector = (state: RootState) => state.eClaimInfo.fetchedEClaimById
export const fetchedEClaimDocumentsSelector = (state: RootState) => state.eClaimInfo.eClaimDocuments

//Master Data
export const eClaimTypeMasterDataSelector = (state: RootState) => state.eClaimInfo.eClaimTypeMasterData
export const eClaimDocumentMasterDataSelector = (state: RootState) => state.eClaimInfo.eClaimDocumentMasterData
