import React from 'react'

import { IconProps } from '../../../types/types'

const EditIcon = ({ size = 16, color = '#005DA7' }: IconProps) => {
  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} xmlns='http://www.w3.org/2000/svg'>
      <g fill={color} fillRule='nonzero'>
        <path
          transform={`scale(${size / 16})`}
          d='M11.883 1.542 4.77 8.654a2.897 2.897 0 0 0-.854 2.063v.783c0 .322.26.583.583.583h.783a2.897 2.897 0 0 0 2.062-.854l7.112-7.112c.71-.711.71-1.863 0-2.575a1.863 1.863 0 0 0-2.574 0zm1.75 1.75L6.52 10.404a1.762 1.762 0 0 1-1.238.513h-.2v-.2c.002-.464.186-.909.512-1.238l7.112-7.112a.67.67 0 0 1 .926 0 .655.655 0 0 1 0 .925z'
        />
        <path
          transform={`scale(${size / 16})`}
          d='M14.417 6.238a.583.583 0 0 0-.584.583V9.75H11.5a1.75 1.75 0 0 0-1.75 1.75v2.333H3.917a1.75 1.75 0 0 1-1.75-1.75V3.917c0-.967.783-1.75 1.75-1.75H9.19a.583.583 0 1 0 0-1.167H3.917A2.92 2.92 0 0 0 1 3.917v8.166A2.92 2.92 0 0 0 3.917 15h6.616a2.898 2.898 0 0 0 2.063-.854l1.55-1.55A2.898 2.898 0 0 0 15 10.532V6.821a.583.583 0 0 0-.583-.583zM11.77 13.32a1.735 1.735 0 0 1-.854.467V11.5c0-.322.26-.583.583-.583h2.29a1.76 1.76 0 0 1-.467.854l-1.552 1.55z'
        />
      </g>
    </svg>
  )
}

export default EditIcon
