import React from 'react'

import { COLORS_THEME } from '../../../constants/colors'

const CheckedFileIcon = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='m18.757 6.613-2.963-2.905A5.971 5.971 0 0 0 11.588 2H7.75c-2.346.003-4.247 1.867-4.25 4.167v11.666c.003 2.3 1.904 4.164 4.25 4.167h8.5c2.346-.003 4.247-1.867 4.25-4.167v-7.095a5.738 5.738 0 0 0-1.743-4.125zm-1.201 1.179c.259.262.482.556.663.875H14.55a.842.842 0 0 1-.85-.834V4.236c.325.178.625.396.892.65l2.964 2.906zM18.8 17.833c0 1.381-1.142 2.5-2.55 2.5h-8.5c-1.408 0-2.55-1.119-2.55-2.5V6.167c0-1.381 1.142-2.5 2.55-2.5h3.838c.14 0 .274.026.412.039v4.127c0 1.381 1.142 2.5 2.55 2.5h4.21c.013.135.04.267.04.405v7.095zm-2.785-4.74c.324.333.31.86-.03 1.177l-3.05 2.845a2.586 2.586 0 0 1-3.592-.014l-1.31-1.145a.824.824 0 0 1-.266-.792.84.84 0 0 1 .566-.623.864.864 0 0 1 .833.17l1.347 1.178a.859.859 0 0 0 1.238.033l3.06-2.859a.86.86 0 0 1 1.204.03z'
        fill={COLORS_THEME.primary}
        fillRule='nonzero'
      />
    </svg>
  )
}

export default CheckedFileIcon
