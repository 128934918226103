import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { transformMasterData } from '../../../../utils/transformMasterData'
import { WelfareService } from '../../services/welfare/WelfareService'
import { WelfareFilterValue, WelfareState } from './types'

const welfareFilterInitialValues = {
  benefitCategoryId: '',
  page: '1',
  pageSize: '10'
}

const initialState: WelfareState = {
  // State
  isSelectDateAndTime: false,
  isBenefitCategoryMasterDataLoading: false,
  isBenefitDocumentMasterDataLoading: false,
  isImportBenefitFromLastYearLoading: false,
  isAddBenefitLoading: false,
  isFetchedAllBenefitLoading: false,
  isDeleteBenefitByIdLoading: false,
  isUpdateBenefitDetailByIdLoading: false,
  isUpdateBenefitDocumentByIdLoading: false,
  isGetBenefitDetailByIdLoading: false,
  isGetBenefitDocumentByIdLoading: false,
  benefitDocuments: [],
  benefitIconImage: null,
  isEditBenefitDetail: false,
  isEditBenefitDocument: false,
  welfareFilterValues: welfareFilterInitialValues,
  //Master Data
  benefitCategoryMasterData: [],
  benefitDocumentMasterData: []
}

const slice = createSlice({
  name: 'customer/welfare',
  initialState,
  reducers: {
    toggleSelectDateAndTime: (state) => {
      state.isSelectDateAndTime = !state.isSelectDateAndTime
    },
    selectBenefitDocument: (state, action: PayloadAction<string>) => {
      const currentId = action.payload
      if (state.benefitDocuments.includes(currentId)) {
        state.benefitDocuments = state.benefitDocuments.filter((id) => id !== currentId)
      } else {
        state.benefitDocuments.push(currentId)
      }
    },
    resetBenefitDocumentAndSelectDateAndTime: (state) => {
      state.benefitDocuments = []
      state.isSelectDateAndTime = false
    },
    setBenefitIconImage: (state, action: PayloadAction<File | null>) => {
      state.benefitIconImage = action.payload
    },
    setIsEditBenefitDetail: (state, action: PayloadAction<boolean>) => {
      state.isEditBenefitDetail = action.payload
    },
    setIsEditBenefitDocument: (state, action: PayloadAction<boolean>) => {
      state.isEditBenefitDocument = action.payload
    },
    setWelfareFilterValues(state, action: PayloadAction<{ field: keyof WelfareFilterValue; value: string }>) {
      const { field, value } = action.payload
      if (field === 'pageSize') state.welfareFilterValues.page = '1'
      state.welfareFilterValues[field] = value
    },
    resetWelfareFilterValues(state) {
      state.welfareFilterValues = welfareFilterInitialValues
    },
    resetWelfareState: (state) => {
      // State
      state.isSelectDateAndTime = false
      state.isBenefitCategoryMasterDataLoading = false
      state.isBenefitDocumentMasterDataLoading = false
      state.isImportBenefitFromLastYearLoading = false
      state.isAddBenefitLoading = false
      state.isUpdateBenefitDetailByIdLoading = false
      state.isUpdateBenefitDocumentByIdLoading = false
      state.isGetBenefitDetailByIdLoading = false
      state.isGetBenefitDocumentByIdLoading = false
      state.isFetchedAllBenefitLoading = false
      state.isDeleteBenefitByIdLoading = false
      state.benefitDocuments = []
      state.benefitIconImage = null
      state.isEditBenefitDetail = false
      state.isEditBenefitDocument = false

      //Response
      state.fetchedAllBenefit = undefined
      state.fetchedBenefitDetailById = undefined
      state.fetchedBenefitDocumentById = []

      //Master Data
      state.benefitCategoryMasterData = []
      state.benefitDocumentMasterData = []
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(WelfareService.getBenefitCategoryMasterDataAsyncThunk.pending, (state) => {
        state.isBenefitCategoryMasterDataLoading = true
      })
      .addCase(WelfareService.getBenefitCategoryMasterDataAsyncThunk.rejected, (state) => {
        state.isBenefitCategoryMasterDataLoading = false
      })
      .addCase(WelfareService.getBenefitCategoryMasterDataAsyncThunk.fulfilled, (state, action) => {
        state.benefitCategoryMasterData = transformMasterData(action.payload)
        state.isBenefitCategoryMasterDataLoading = false
      })
      .addCase(WelfareService.getBenefitDocumentMasterDataAsyncThunk.pending, (state) => {
        state.isBenefitDocumentMasterDataLoading = true
      })
      .addCase(WelfareService.getBenefitDocumentMasterDataAsyncThunk.rejected, (state) => {
        state.isBenefitDocumentMasterDataLoading = false
      })
      .addCase(WelfareService.getBenefitDocumentMasterDataAsyncThunk.fulfilled, (state, action) => {
        state.benefitDocumentMasterData = transformMasterData(action.payload)
        state.isBenefitDocumentMasterDataLoading = false
      })
      .addCase(WelfareService.importBenefitFromLastYearAsyncThunk.pending, (state) => {
        state.isImportBenefitFromLastYearLoading = true
      })
      .addCase(WelfareService.importBenefitFromLastYearAsyncThunk.rejected, (state) => {
        state.isImportBenefitFromLastYearLoading = false
      })
      .addCase(WelfareService.importBenefitFromLastYearAsyncThunk.fulfilled, (state) => {
        state.isImportBenefitFromLastYearLoading = false
      })
      .addCase(WelfareService.getAllBenefitAsyncThunk.pending, (state) => {
        state.isFetchedAllBenefitLoading = true
      })
      .addCase(WelfareService.getAllBenefitAsyncThunk.rejected, (state) => {
        state.isFetchedAllBenefitLoading = false
      })
      .addCase(WelfareService.getAllBenefitAsyncThunk.fulfilled, (state, action) => {
        state.fetchedAllBenefit = action.payload
        state.isFetchedAllBenefitLoading = false
      })
      .addCase(WelfareService.addBenefitAsyncThunk.pending, (state) => {
        state.isAddBenefitLoading = true
      })
      .addCase(WelfareService.addBenefitAsyncThunk.rejected, (state) => {
        state.isAddBenefitLoading = false
      })
      .addCase(WelfareService.addBenefitAsyncThunk.fulfilled, (state) => {
        state.isAddBenefitLoading = false
      })
      .addCase(WelfareService.deleteBenefitByIdAsyncThunk.pending, (state) => {
        state.isDeleteBenefitByIdLoading = true
      })
      .addCase(WelfareService.deleteBenefitByIdAsyncThunk.rejected, (state) => {
        state.isDeleteBenefitByIdLoading = false
      })
      .addCase(WelfareService.deleteBenefitByIdAsyncThunk.fulfilled, (state) => {
        state.isDeleteBenefitByIdLoading = false
      })
      .addCase(WelfareService.updateBenefitDetailByIdAsyncThunk.pending, (state) => {
        state.isUpdateBenefitDetailByIdLoading = true
      })
      .addCase(WelfareService.updateBenefitDetailByIdAsyncThunk.rejected, (state) => {
        state.isUpdateBenefitDetailByIdLoading = false
      })
      .addCase(WelfareService.updateBenefitDetailByIdAsyncThunk.fulfilled, (state) => {
        state.isUpdateBenefitDetailByIdLoading = false
      })
      .addCase(WelfareService.updateBenefitDocumentByIdAsyncThunk.pending, (state) => {
        state.isUpdateBenefitDocumentByIdLoading = true
      })
      .addCase(WelfareService.updateBenefitDocumentByIdAsyncThunk.rejected, (state) => {
        state.isUpdateBenefitDocumentByIdLoading = false
      })
      .addCase(WelfareService.updateBenefitDocumentByIdAsyncThunk.fulfilled, (state) => {
        state.isUpdateBenefitDocumentByIdLoading = false
      })
      .addCase(WelfareService.getBenefitDetailByIdAsyncThunk.pending, (state) => {
        state.isGetBenefitDetailByIdLoading = true
      })
      .addCase(WelfareService.getBenefitDetailByIdAsyncThunk.rejected, (state) => {
        state.isGetBenefitDetailByIdLoading = false
      })
      .addCase(WelfareService.getBenefitDetailByIdAsyncThunk.fulfilled, (state, action) => {
        state.fetchedBenefitDetailById = action.payload
        if (action.payload.startDate) state.isSelectDateAndTime = true
        state.isGetBenefitDetailByIdLoading = false
      })
      .addCase(WelfareService.getBenefitDocumentByIdAsyncThunk.pending, (state) => {
        state.isGetBenefitDocumentByIdLoading = true
      })
      .addCase(WelfareService.getBenefitDocumentByIdAsyncThunk.rejected, (state) => {
        state.isGetBenefitDocumentByIdLoading = false
      })
      .addCase(WelfareService.getBenefitDocumentByIdAsyncThunk.fulfilled, (state, action) => {
        state.fetchedBenefitDocumentById = action.payload
        state.isGetBenefitDocumentByIdLoading = false
      })
  }
})

export const {
  resetWelfareState,
  toggleSelectDateAndTime,
  selectBenefitDocument,
  setBenefitIconImage,
  resetBenefitDocumentAndSelectDateAndTime,
  setIsEditBenefitDetail,
  setIsEditBenefitDocument,
  setWelfareFilterValues,
  resetWelfareFilterValues
} = slice.actions
export default slice.reducer
