import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Pagination } from '../../../types/types'
import { transformMasterData } from '../../../utils/transformMasterData'
import { MedicalService } from '../services/MedicalService'
import { EmployeeMedicalByCompanyFilterValues, ImportMedicalFilterValues, MedicalState } from './types'

const employeeMedicalByCompanyFilterInitialValues = {
  search: '',
  gender: 'ทั้งหมด',
  employeeLevelId: 'ทั้งหมด',
  medicalStatus: 'ทั้งหมด',
  page: '1',
  pageSize: '10'
}

const importMedicalFilterInitialValues = {
  hospitalId: '',
  hospitalBranchId: '',
  yearId: ''
}

const initialState: MedicalState = {
  // State
  isGetAllMedicalCustomersLoading: false,
  isGetAllMedicalEmployeesLoading: false,
  isGetEmployeeInfoByEmployeeIdLoading: false,
  isGetHospitalBranchesMasterDataLoading: false,
  isGetHospitalMasterDataLoading: false,
  isGetMedicalResultYearsMasterDataLoading: false,
  isGetSummaryResultLoading: false,
  isGetMedicalResultByEmployeeIdLoading: false,
  isUpdateMedicalResultLoading: false,
  isDownloadMedicalTemplateByHospitalIdLoading: false,
  isGetMedicalResultListFromExcelLoading: false,
  isImportMedicalResultByHospitalIdLoading: false,
  isExportMedicalResultLoading: false,
  fetchedMedicalResultListFromExcel: [],
  medicalFilterValues: { page: 1, pageSize: 20 },
  employeeMedicalByCompanyFilterValues: employeeMedicalByCompanyFilterInitialValues,
  importMedicalFilterValues: importMedicalFilterInitialValues,
  // Master Data
  hospitalBranchesMasterData: [],
  hospitalsMasterData: [],
  medicalResultYearsMasterData: []
}
const slice = createSlice({
  name: 'medical',
  initialState,
  reducers: {
    setMedicalFilterValues(state, action: PayloadAction<{ field: keyof Pagination; value: string }>) {
      const { field, value } = action.payload
      if (field === 'pageSize') state.medicalFilterValues.page = 1
      state.medicalFilterValues[field] = Number(value)
    },
    resetMedicalFilterValues(state) {
      state.medicalFilterValues = { page: 1, pageSize: 20 }
    },
    setEmployeeMedicalByCompanyFilterValues(
      state,
      action: PayloadAction<{ field: keyof EmployeeMedicalByCompanyFilterValues; value: string }>
    ) {
      const { field, value } = action.payload
      if (field === 'pageSize') state.employeeMedicalByCompanyFilterValues.page = '1'
      state.employeeMedicalByCompanyFilterValues[field] = value
    },
    resetEmployeeMedicalByCompanyFilterValues(state) {
      state.employeeMedicalByCompanyFilterValues = employeeMedicalByCompanyFilterInitialValues
    },
    setImportMedicalFilterValues(
      state,
      action: PayloadAction<{ field: keyof ImportMedicalFilterValues; value: string }>
    ) {
      const { field, value } = action.payload
      if (field === 'yearId') {
        state.importMedicalFilterValues.hospitalBranchId = ''
        state.importMedicalFilterValues.hospitalId = ''
      }
      if (field === 'hospitalId') state.importMedicalFilterValues.hospitalBranchId = ''
      state.importMedicalFilterValues[field] = value
    },
    resetImportMedicalFilterValues(state) {
      state.importMedicalFilterValues = importMedicalFilterInitialValues
    },
    resetImportMedicalResultList(state) {
      state.fetchedMedicalResultListFromExcel = []
    },
    resetMedicalState: (state) => {
      //State
      state.isGetAllMedicalCustomersLoading = false
      state.isGetAllMedicalEmployeesLoading = false
      state.isGetEmployeeInfoByEmployeeIdLoading = false
      state.isGetSummaryResultLoading = false
      state.isGetHospitalMasterDataLoading = false
      state.isGetHospitalBranchesMasterDataLoading = false
      state.isGetMedicalResultYearsMasterDataLoading = false
      state.isGetMedicalResultByEmployeeIdLoading = false
      state.isUpdateMedicalResultLoading = false
      state.isDownloadMedicalTemplateByHospitalIdLoading = false
      state.isGetMedicalResultListFromExcelLoading = false
      state.isImportMedicalResultByHospitalIdLoading = false
      state.isExportMedicalResultLoading = false
      //Response
      state.fetchedAllMedicalCustomer = undefined
      state.fetchedAllMedicalEmployee = undefined
      state.fetchedMedicalResultByEmployeeId = undefined
      state.fetchedEmployeeInfoByEmployeeId = undefined
      state.fetchedSummaryResult = undefined
      //Master Data
      state.hospitalsMasterData = []
      state.hospitalBranchesMasterData = []
      state.medicalResultYearsMasterData = []
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(MedicalService.getAllMedicalCustomerAsyncThunk.pending, (state) => {
        state.isGetAllMedicalCustomersLoading = true
      })
      .addCase(MedicalService.getAllMedicalCustomerAsyncThunk.rejected, (state) => {
        state.isGetAllMedicalCustomersLoading = false
      })
      .addCase(MedicalService.getAllMedicalCustomerAsyncThunk.fulfilled, (state, action) => {
        state.isGetAllMedicalCustomersLoading = false
        state.fetchedAllMedicalCustomer = action.payload
      })
      .addCase(MedicalService.getAllMedicalEmployeeAsyncThunk.pending, (state) => {
        state.isGetAllMedicalEmployeesLoading = true
      })
      .addCase(MedicalService.getAllMedicalEmployeeAsyncThunk.rejected, (state) => {
        state.isGetAllMedicalEmployeesLoading = false
      })
      .addCase(MedicalService.getAllMedicalEmployeeAsyncThunk.fulfilled, (state, action) => {
        state.isGetAllMedicalEmployeesLoading = false
        state.fetchedAllMedicalEmployee = action.payload
      })
      .addCase(MedicalService.getEmployeeInfoByEmployeeIdAsyncThunk.pending, (state) => {
        state.isGetEmployeeInfoByEmployeeIdLoading = true
      })
      .addCase(MedicalService.getEmployeeInfoByEmployeeIdAsyncThunk.rejected, (state) => {
        state.isGetEmployeeInfoByEmployeeIdLoading = false
      })
      .addCase(MedicalService.getEmployeeInfoByEmployeeIdAsyncThunk.fulfilled, (state, action) => {
        state.isGetEmployeeInfoByEmployeeIdLoading = false
        state.fetchedEmployeeInfoByEmployeeId = action.payload
      })
      .addCase(MedicalService.getSummaryResultAsyncThunk.pending, (state) => {
        state.isGetSummaryResultLoading = true
      })
      .addCase(MedicalService.getSummaryResultAsyncThunk.rejected, (state) => {
        state.isGetSummaryResultLoading = false
      })
      .addCase(MedicalService.getSummaryResultAsyncThunk.fulfilled, (state, action) => {
        state.isGetSummaryResultLoading = false
        state.fetchedSummaryResult = action.payload
      })
      .addCase(MedicalService.getMedicalResultByEmployeeIdAsyncThunk.pending, (state) => {
        state.isGetMedicalResultByEmployeeIdLoading = true
      })
      .addCase(MedicalService.getMedicalResultByEmployeeIdAsyncThunk.rejected, (state) => {
        state.isGetMedicalResultByEmployeeIdLoading = false
      })
      .addCase(MedicalService.getMedicalResultByEmployeeIdAsyncThunk.fulfilled, (state, action) => {
        state.isGetMedicalResultByEmployeeIdLoading = false
        state.fetchedMedicalResultByEmployeeId = action.payload
      })
      .addCase(MedicalService.getHospitalMasterDataAsyncThunk.pending, (state) => {
        state.isGetHospitalMasterDataLoading = true
      })
      .addCase(MedicalService.getHospitalMasterDataAsyncThunk.rejected, (state) => {
        state.isGetHospitalMasterDataLoading = false
      })
      .addCase(MedicalService.getHospitalMasterDataAsyncThunk.fulfilled, (state, action) => {
        state.isGetHospitalMasterDataLoading = false
        state.hospitalsMasterData = transformMasterData(action.payload.hospitalList)
      })
      .addCase(MedicalService.getHospitalBranchesMasterDataAsyncThunk.pending, (state) => {
        state.isGetHospitalBranchesMasterDataLoading = true
      })
      .addCase(MedicalService.getHospitalBranchesMasterDataAsyncThunk.rejected, (state) => {
        state.isGetHospitalBranchesMasterDataLoading = false
      })
      .addCase(MedicalService.getHospitalBranchesMasterDataAsyncThunk.fulfilled, (state, action) => {
        state.isGetHospitalBranchesMasterDataLoading = false
        state.hospitalBranchesMasterData = transformMasterData(action.payload.hospitalBranchList)
      })
      .addCase(MedicalService.getMedicalResultYearMasterDataAsyncThunk.pending, (state) => {
        state.isGetMedicalResultYearsMasterDataLoading = true
      })
      .addCase(MedicalService.getMedicalResultYearMasterDataAsyncThunk.rejected, (state) => {
        state.isGetMedicalResultYearsMasterDataLoading = false
      })
      .addCase(MedicalService.getMedicalResultYearMasterDataAsyncThunk.fulfilled, (state, action) => {
        state.isGetMedicalResultYearsMasterDataLoading = false
        state.medicalResultYearsMasterData = transformMasterData(action.payload.yearList)
      })
      .addCase(MedicalService.updateMedicalResultAsyncThunk.pending, (state) => {
        state.isUpdateMedicalResultLoading = true
      })
      .addCase(MedicalService.updateMedicalResultAsyncThunk.rejected, (state) => {
        state.isUpdateMedicalResultLoading = false
      })
      .addCase(MedicalService.updateMedicalResultAsyncThunk.fulfilled, (state) => {
        state.isUpdateMedicalResultLoading = false
      })
      .addCase(MedicalService.downloadMedicalTemplateByHospitalIdAsyncThunk.pending, (state) => {
        state.isDownloadMedicalTemplateByHospitalIdLoading = true
      })
      .addCase(MedicalService.downloadMedicalTemplateByHospitalIdAsyncThunk.rejected, (state) => {
        state.isDownloadMedicalTemplateByHospitalIdLoading = false
      })
      .addCase(MedicalService.downloadMedicalTemplateByHospitalIdAsyncThunk.fulfilled, (state) => {
        state.isDownloadMedicalTemplateByHospitalIdLoading = false
      })
      .addCase(MedicalService.getMedicalResultListFromExcelAsyncThunk.pending, (state) => {
        state.isGetMedicalResultListFromExcelLoading = true
      })
      .addCase(MedicalService.getMedicalResultListFromExcelAsyncThunk.rejected, (state) => {
        state.isGetMedicalResultListFromExcelLoading = false
      })
      .addCase(MedicalService.getMedicalResultListFromExcelAsyncThunk.fulfilled, (state, action) => {
        state.isGetMedicalResultListFromExcelLoading = false
        state.fetchedMedicalResultListFromExcel = action.payload
      })
      .addCase(MedicalService.importMedicalResultByHospitalIdAsyncThunk.pending, (state) => {
        state.isImportMedicalResultByHospitalIdLoading = true
      })
      .addCase(MedicalService.importMedicalResultByHospitalIdAsyncThunk.rejected, (state) => {
        state.isImportMedicalResultByHospitalIdLoading = false
      })
      .addCase(MedicalService.importMedicalResultByHospitalIdAsyncThunk.fulfilled, (state) => {
        state.isImportMedicalResultByHospitalIdLoading = false
      })
      .addCase(MedicalService.exportMedicalResultAsyncThunk.pending, (state) => {
        state.isExportMedicalResultLoading = true
      })
      .addCase(MedicalService.exportMedicalResultAsyncThunk.rejected, (state) => {
        state.isExportMedicalResultLoading = false
      })
      .addCase(MedicalService.exportMedicalResultAsyncThunk.fulfilled, (state) => {
        state.isExportMedicalResultLoading = false
      })
  }
})

export const {
  resetMedicalState,
  setMedicalFilterValues,
  resetMedicalFilterValues,
  setEmployeeMedicalByCompanyFilterValues,
  resetEmployeeMedicalByCompanyFilterValues,
  setImportMedicalFilterValues,
  resetImportMedicalFilterValues,
  resetImportMedicalResultList
} = slice.actions
export default slice.reducer
