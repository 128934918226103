// Auth
export const loginPath = '/login'
export const forgotPasswordPath = '/forgot-password'
export const resetPasswordPath = '/reset-password'

// Dashboard
export const dashboardPath = '/dashboard'

// Insurance
export const insurerPath = '/insurer-management'
export const addInsurerPath = '/insurer-management/add'
export const editInsurerPath = (companyId: string) => `/insurer-management/edit/${companyId}`
export const planPath = '/plan-management'
export const addInsurancePlanPath = '/plan-management/add'
export const editInsurancePlanPath = (id: string) => `/plan-management/edit/${id}`

// Customer
export const customerPath = '/customer'
export const addCustomerPath = '/customer/add'
export const editCustomerPath = (yearId: string, customerId: string) => `/customer/edit/${yearId}/${customerId}`
export const selectYearPath = (customerId: string) => `/customer/select-year/${customerId}`
export const addEmployeePath = (yearId: string, customerId: string) =>
  `/customer/edit/${yearId}/${customerId}/add/employee`
export const editEmployeePath = (yearId: string, customerId: string, employeeId: string) =>
  `/customer/edit/${yearId}/${customerId}/edit/employee/${employeeId}`
export const addWelfarePath = (yearId: string, customerId: string) =>
  `/customer/edit/${yearId}/${customerId}/add/welfare`
export const editWelfarePath = (yearId: string, customerId: string, benefitId: string) =>
  `/customer/edit/${yearId}/${customerId}/edit/welfare/${benefitId}`

// E-Claim
export const eClaimPath = '/e-claim'
export const eClaimListByCompanyPath = (customerId: string) => `/e-claim/${customerId}`
export const eClaimDetailPath = (customerId: string, insuranceClaimId: string) =>
  `/e-claim/request/${customerId}/${insuranceClaimId}/detail`
export const benefitDetailPath = (customerId: string, benefitClaimId: string) =>
  `/e-claim/benefit/request/${customerId}/${benefitClaimId}/detail`

// Flex-benefit
export const flexBenefitPath = '/flex-benefit'
export const flexBenefitListByCompany = (customerId: string) => `/flex-benefit/${customerId}`
export const flexBenefitDetail = (yearId: string, employeeId: string) => `/flex-benefit/${yearId}/${employeeId}/detail`

// User
export const userPath = '/user'
export const addUserPath = '/user/add'
export const editUserPath = (userId: string) => `/user/edit/${userId}`
export const hrUserPath = '/hr-user'
export const hrUserListPath = (customerId: string) => `/hr-user/${customerId}`
export const addHRUserPath = (customerId: string) => `/hr-user/${customerId}/add`
export const editHRUserPath = (customerId: string, hrUserId: string) => `/hr-user/${customerId}/edit/${hrUserId}`
export const rolePath = '/role'
export const addRolePath = '/role/add'
export const editRolePath = (roleId: string) => `/role/edit/${roleId}`

// Medical
export const medicalPath = '/medical'
export const employeeMedicalListByCompanyPath = (customerId: string) => `/medical/${customerId}`
export const editMedicalPath = (customerId: string, employeeId: string) => `/medical/${customerId}/${employeeId}/edit`
export const importMedicalPath = (customerId: string) => `/medical/${customerId}/import`
