import { Dialog, DialogContent, DialogProps, styled } from '@mui/material'
import React, { ReactNode } from 'react'

interface CustomDialogProps {
  isOpen: boolean
  size: 'large' | 'small' | 'medium'
  content: ReactNode
  minHeight?: string
}

interface StyledDialogProps extends DialogProps {
  customSize: string
}

const CustomDialog = ({ isOpen, content, size, minHeight = '475px' }: CustomDialogProps) => {
  return (
    <StyledDialog open={isOpen} fullWidth customSize={size}>
      <StyledDialogContent dividers sx={{ minHeight: minHeight }}>
        {content}
      </StyledDialogContent>
    </StyledDialog>
  )
}
const StyledDialog = styled(Dialog, { shouldForwardProp: (prop) => prop !== 'customSize' })<StyledDialogProps>(
  ({ theme, customSize }) => ({
    '& .MuiDialog-container': {
      width: '100%',
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
      '& .MuiPaper-root': {
        borderRadius: theme.spacing(4),
        width: customSize === 'large' ? '1090px' : customSize === 'small' ? '375px' : '540px',
        margin: 0,
        maxWidth: customSize === 'large' ? '100%' : '600px'
      }
    }
  })
)

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(0)
}))

export default CustomDialog
