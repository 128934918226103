import React from 'react'

import { IconProps } from '../../../types/types'

const ArrowDownIcon = ({ size = 16, color = '#585858' }: IconProps) => {
  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} xmlns='http://www.w3.org/2000/svg'>
      <path
        transform={`scale(${size / 16})`}
        d='m2.857 5.929 4.991 4.937a.444.444 0 0 0 .629-.003l4.88-4.934h0'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        fill='none'
        fillRule='evenodd'
      />
    </svg>
  )
}

export default ArrowDownIcon
