import { createSlice } from '@reduxjs/toolkit'

import { AuthService } from '../../features/auth/services/AuthService'

const initialState: CurrentUserState = {
  user: undefined
}

const slice = createSlice({
  name: 'currentUser',
  initialState,
  reducers: {
    resetUser: () => ({ user: undefined })
  },
  extraReducers: (builder) => {
    builder.addCase(AuthService.getCurrentUserAsyncThunk.fulfilled, (state, action) => {
      state.user = action.payload
    })
  }
})

export const { resetUser } = slice.actions
export default slice.reducer
