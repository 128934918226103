import { createAsyncThunk } from '@reduxjs/toolkit'

import { E_CLAIM_URLS } from '../../../../configs/api'
import { REQUEST_OPTIONS } from '../../../../constants/request-options'
import { AxiosService } from '../../../../services/api'
import { createThunkName } from '../../../../utils/createThunkname'
import { saveData } from '../../../../utils/downloadFile'
import {
  ApproveEClaimByIdDto,
  ExportAllEClaimQueryParams,
  ExportEClaimByCustomerIdQueryParams,
  GetAllCustomerQueryParams,
  GetAllCustomerResponse,
  GetAllEClaimByCustomerIdQueryParams,
  GetAllEClaimByCustomerIdResponse,
  GetCompanyNameResponse,
  GetEClaimByIdResponse,
  GetEClaimDocumentMasterDataResponse,
  GetEClaimTypeMasterDataResponse,
  RejectEClaimByIdDto
} from './types'

const PREFIX = 'e-claim-info'

export const EClaimInfoService = {
  getAllCustomerAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getAllCustomer'),
    async (queryParams: GetAllCustomerQueryParams) => {
      try {
        const result = await AxiosService.get<GetAllCustomerResponse>(E_CLAIM_URLS.getAllCustomer, {
          params: queryParams
        })
        return result.data
      } catch (error) {
        throw error
      }
    }
  ),
  getCompanyNameAsyncThunk: createAsyncThunk(createThunkName(PREFIX, 'getCompanyName'), async (customerId: string) => {
    try {
      const result = await AxiosService.get<GetCompanyNameResponse>(E_CLAIM_URLS.getCompanyName(customerId))
      return result.data.companyName
    } catch (error) {
      throw error
    }
  }),
  getEClaimTypeMasterDataAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getEClaimTypeMasterData'),
    async (yearId: string) => {
      try {
        const result = await AxiosService.get<GetEClaimTypeMasterDataResponse>(
          E_CLAIM_URLS.masterData.getEClaimTypeMasterData(yearId)
        )
        return result.data.eClaimTypes
      } catch (error) {
        throw error
      }
    }
  ),
  getEClaimDocumentMasterDataAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getEClaimDocumentMasterData'),
    async () => {
      try {
        const result = await AxiosService.get<GetEClaimDocumentMasterDataResponse>(
          E_CLAIM_URLS.masterData.getEClaimDocumentMasterData
        )
        return result.data.eClaimDocuments
      } catch (error) {
        throw error
      }
    }
  ),
  getAllEClaimByCustomerIdAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getAllEClaimByCustomerId'),
    async ({
      queryParams,
      yearId,
      customerId
    }: {
      queryParams: GetAllEClaimByCustomerIdQueryParams
      yearId: string
      customerId: string
    }) => {
      try {
        const result = await AxiosService.get<GetAllEClaimByCustomerIdResponse>(
          E_CLAIM_URLS.eClaimTab.getAllEClaimByCustomerId(yearId, customerId),
          { params: queryParams }
        )
        return result.data
      } catch (error) {
        throw error
      }
    }
  ),
  exportEClaimByCustomerIdAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'exportEClaimByCustomerId'),
    async ({ queryParams, customerId }: { queryParams: ExportEClaimByCustomerIdQueryParams; customerId: string }) => {
      try {
        const result = await AxiosService.get(E_CLAIM_URLS.eClaimTab.exportEClaimByCustomerId(customerId), {
          ...REQUEST_OPTIONS,
          params: queryParams
        })
        saveData(result, result.request.getResponseHeader('Content-Disposition'))
      } catch (error) {
        throw error
      }
    }
  ),
  getEClaimByIdAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getEClaimById'),
    async (insuranceClaimId: string) => {
      try {
        const result = await AxiosService.get<GetEClaimByIdResponse>(
          E_CLAIM_URLS.eClaimTab.getEClaimById(insuranceClaimId)
        )
        return result.data.eClaim
      } catch (error) {
        throw error
      }
    }
  ),
  approveEClaimByIdAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'approveEClaimById'),
    async (approveEClaimByIdDto: ApproveEClaimByIdDto) => {
      try {
        await AxiosService.post(E_CLAIM_URLS.eClaimTab.approveEClaimById, approveEClaimByIdDto)
      } catch (error) {
        throw error
      }
    }
  ),
  rejectEClaimByIdAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'rejectEClaimById'),
    async (rejectEClaimByIdDto: RejectEClaimByIdDto) => {
      try {
        await AxiosService.post(E_CLAIM_URLS.eClaimTab.rejectEClaimById, rejectEClaimByIdDto)
      } catch (error) {
        throw error
      }
    }
  ),
  exportAllEClaimAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'exportAllEClaim'),
    async (queryParams: ExportAllEClaimQueryParams) => {
      try {
        const result = await AxiosService.get(E_CLAIM_URLS.exportAllEClaim, {
          ...REQUEST_OPTIONS,
          params: queryParams
        })
        saveData(result, result.request.getResponseHeader('Content-Disposition'))
      } catch (error) {
        throw error
      }
    }
  )
}
