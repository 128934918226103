import { Chip, ChipProps, styled, Typography } from '@mui/material'
import React from 'react'

import { COLORS, COLORS_THEME } from '../../../constants/colors'

interface BadgeStatusProps {
  label: string
  color: BadgeColor
  icon?: JSX.Element
  customWidth?: string
  variant?: 'body2' | 'body1'
  fontWeight?: '500' | 'normal'
}

interface StyledChipProps extends ChipProps {
  customWidth: string
  backgroundColor: string
}

export type BadgeColor =
  | 'default'
  | 'primary'
  | 'secondary'
  | 'error'
  | 'info'
  | 'success'
  | 'warning'
  | 'recommend'
  | 'greatValue'
  | 'popular'
  | 'verified'
  | 'needInfo'
  | 'approved'
  | 'rejected'
  | 'point'
  | 'lead'

const getBadgeStyle = (color: string) => {
  switch (color) {
    case 'default': {
      return {
        backgroundColor: COLORS.purple100,
        color: COLORS_THEME.primary
      }
    }
    case 'primary': {
      return {
        backgroundColor: COLORS.purple100,
        color: COLORS_THEME.primary
      }
    }
    case 'secondary': {
      return {
        backgroundColor: COLORS.purple100,
        color: COLORS_THEME.primary
      }
    }
    case 'error': {
      return {
        backgroundColor: COLORS.redTransparent,
        color: COLORS.red400
      }
    }
    case 'info': {
      return {
        backgroundColor: COLORS.purple100,
        color: COLORS_THEME.primary
      }
    }
    case 'success': {
      return {
        backgroundColor: COLORS.greenTransparent,
        color: COLORS.green400
      }
    }
    case 'warning': {
      return {
        backgroundColor: COLORS.orangeTransparent,
        color: COLORS.orange400
      }
    }
    case 'recommend': {
      return {
        backgroundColor: COLORS_THEME.success,
        color: COLORS.white
      }
    }
    case 'greatValue': {
      return {
        backgroundColor: COLORS.purple500,
        color: COLORS.white
      }
    }
    case 'popular': {
      return {
        backgroundColor: COLORS.pink400,
        color: COLORS.white
      }
    }
    case 'verified': {
      return {
        backgroundColor: COLORS.purple200,
        color: COLORS_THEME.primary
      }
    }
    case 'needInfo': {
      return {
        backgroundColor: COLORS.grey100,
        color: 'black'
      }
    }
    case 'approved': {
      return {
        backgroundColor: COLORS.purple100,
        color: COLORS_THEME.primary
      }
    }
    case 'point': {
      return {
        backgroundColor: COLORS.purple100,
        color: COLORS_THEME.primary
      }
    }
    case 'rejected': {
      return {
        backgroundColor: COLORS.redTransparent,
        color: COLORS.red350
      }
    }
    case 'lead': {
      return {
        backgroundColor: COLORS.blueTransparent,
        color: COLORS.blue400
      }
    }
    default: {
      return {
        backgroundColor: COLORS.purple100,
        color: COLORS_THEME.primary
      }
    }
  }
}

const BadgeStatus = ({
  label,
  color,
  icon,
  customWidth = 'min-content',
  variant = 'body2',
  fontWeight = '500'
}: BadgeStatusProps) => {
  const badgeStyle = getBadgeStyle(color)
  return (
    <StyledChip
      customWidth={customWidth}
      icon={icon}
      backgroundColor={badgeStyle.backgroundColor}
      label={
        <Typography color={badgeStyle.color} variant={variant} fontWeight={fontWeight} marginLeft={icon ? 2 : 0}>
          {label}
        </Typography>
      }
    />
  )
}

const StyledChip = styled(Chip, {
  shouldForwardProp: (props) => props !== 'customWidth' && props !== 'backgroundColor'
})<StyledChipProps>(({ theme, customWidth, backgroundColor }) => ({
  borderRadius: theme.spacing(2),
  backgroundColor: backgroundColor,
  padding: theme.spacing(0, 4),
  height: theme.spacing(6),
  width: customWidth,
  '& span': {
    padding: 0
  }
}))

export default BadgeStatus
