import { createAsyncThunk } from '@reduxjs/toolkit'

import { MEDICAL_URLS } from '../../../configs/api'
import { REQUEST_OPTIONS } from '../../../constants/request-options'
import { AxiosService } from '../../../services/api'
import { createThunkName } from '../../../utils/createThunkname'
import { saveData } from '../../../utils/downloadFile'
import {
  DownloadMedicalTemplateByHospitalIdRequest,
  ExportMedicalResultRequest,
  GetAllMedicalCustomersQueryParams,
  GetAllMedicalCustomersResponse,
  GetAllMedicalEmployeesRequest,
  GetAllMedicalEmployeesResponse,
  GetEmployeeInfoByEmployeeIdRequest,
  GetEmployeeInfoByEmployeeIdResponse,
  GetHospitalBranchMasterDataResponse,
  GetHospitalMasterDataResponse,
  GetMedicalResultByEmployeeIdRequest,
  GetMedicalResultByEmployeeIdResponse,
  GetMedicalResultListFromExcelRequest,
  GetMedicalResultListFromExcelResponse,
  GetMedicalResultYearMasterDataResponse,
  GetSummaryResultRequest,
  GetSummaryResultResponse,
  ImportMedicalResultByHospitalIdRequest,
  UpdateMedicalResultDto
} from './types'

const PREFIX = 'medical'

export const MedicalService = {
  getAllMedicalCustomerAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getAllMedicalCustomer'),
    async (queryParams: GetAllMedicalCustomersQueryParams) => {
      try {
        const result = await AxiosService.get<GetAllMedicalCustomersResponse>(MEDICAL_URLS.getAllCustomers, {
          params: queryParams
        })
        return result.data
      } catch (error) {
        throw error
      }
    }
  ),
  getAllMedicalEmployeeAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getAllMedicalEmployee'),
    async (request: GetAllMedicalEmployeesRequest) => {
      try {
        const result = await AxiosService.get<GetAllMedicalEmployeesResponse>(
          MEDICAL_URLS.getAllEmployees(request.customerId),
          {
            params: request.params
          }
        )
        return result.data
      } catch (error) {
        throw error
      }
    }
  ),
  getEmployeeInfoByEmployeeIdAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getEmployeeInfoByEmployeeId'),
    async (request: GetEmployeeInfoByEmployeeIdRequest) => {
      try {
        const result = await AxiosService.get<GetEmployeeInfoByEmployeeIdResponse>(
          MEDICAL_URLS.getEmployeeInfoByEmployeeId(request.customerId, request.yearId, request.employeeId)
        )
        return result.data
      } catch (error) {
        throw error
      }
    }
  ),
  getSummaryResultAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getSummaryResult'),
    async (request: GetSummaryResultRequest) => {
      try {
        const result = await AxiosService.get<GetSummaryResultResponse>(
          MEDICAL_URLS.getSummaryInfoByEmployeeId(request.customerId, request.employeeId)
        )
        return result.data
      } catch (error) {
        throw error
      }
    }
  ),
  getMedicalResultByEmployeeIdAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getMedicalResultByEmployeeId'),
    async (request: GetMedicalResultByEmployeeIdRequest) => {
      try {
        const result = await AxiosService.get<GetMedicalResultByEmployeeIdResponse>(
          MEDICAL_URLS.getMedicalResultByEmployeeId(request.customerId, request.yearId, request.employeeId)
        )
        return result.data
      } catch (error) {
        throw error
      }
    }
  ),
  updateMedicalResultAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'updateMedicalResult'),
    async (dto: UpdateMedicalResultDto) => {
      try {
        const result = await AxiosService.put(MEDICAL_URLS.updateMedicalResult, dto)
        return result.data
      } catch (error) {
        throw error
      }
    }
  ),
  getHospitalMasterDataAsyncThunk: createAsyncThunk(createThunkName(PREFIX, 'getHospitalMasterData'), async () => {
    try {
      const result = await AxiosService.get<GetHospitalMasterDataResponse>(
        MEDICAL_URLS.masterData.getHospitalMasterData
      )
      return result.data
    } catch (error) {
      throw error
    }
  }),
  getHospitalBranchesMasterDataAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getHospitalBranchesMasterData'),
    async (hospitalId: string) => {
      try {
        const result = await AxiosService.get<GetHospitalBranchMasterDataResponse>(
          MEDICAL_URLS.masterData.getHospitalBranchMasterData(hospitalId)
        )
        return result.data
      } catch (error) {
        throw error
      }
    }
  ),
  getMedicalResultYearMasterDataAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getMedicalResultYearMasterData'),
    async (employeeId: string) => {
      try {
        const result = await AxiosService.get<GetMedicalResultYearMasterDataResponse>(
          MEDICAL_URLS.masterData.getMedicalResultYearMasterData(employeeId)
        )
        return result.data
      } catch (error) {
        throw error
      }
    }
  ),
  downloadMedicalTemplateByHospitalIdAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'downloadMedicalTemplateByHospitalId'),
    async (request: DownloadMedicalTemplateByHospitalIdRequest) => {
      try {
        const result = await AxiosService.get(
          MEDICAL_URLS.downloadMedicalTemplateByHospitalId(request.customerId, request.yearId),
          {
            params: request.params,
            ...REQUEST_OPTIONS
          }
        )
        saveData(result, result.request.getResponseHeader('Content-Disposition'))
      } catch (error) {
        throw error
      }
    }
  ),
  getMedicalResultListFromExcelAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getMedicalResultListFromExcel'),
    async (request: GetMedicalResultListFromExcelRequest) => {
      try {
        const formData = new FormData()
        formData.append('file', request.file)
        const result = await AxiosService.post<GetMedicalResultListFromExcelResponse>(
          MEDICAL_URLS.getMedicalResultListFromExcel(request.customerId, request.yearId),
          formData,
          {
            params: request.params
          }
        )
        return result.data.employeeList
      } catch (error) {
        throw error
      }
    }
  ),
  importMedicalResultByHospitalIdAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'importMedicalResultByHospitalId'),
    async (request: ImportMedicalResultByHospitalIdRequest) => {
      try {
        await AxiosService.post(
          MEDICAL_URLS.importMedicalResultByHospitalId(request.customerId, request.yearId),
          request.dto,
          {
            params: request.params
          }
        )
      } catch (error) {
        throw error
      }
    }
  ),
  exportMedicalResultAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'exportMedicalResult'),
    async (request: ExportMedicalResultRequest) => {
      try {
        const result = await AxiosService.get(MEDICAL_URLS.exportMedicalResult(request.customerId), {
          ...REQUEST_OPTIONS,
          params: request.params
        })
        saveData(result, result.request.getResponseHeader('Content-Disposition'))
      } catch (error) {
        throw error
      }
    }
  )
}
