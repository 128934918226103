import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { transformMasterData } from '../../../../utils/transformMasterData'
import { EmployeeInfoService } from '../../services/employee-info/EmployeeInfoService'
import { EmployeeFilterValue, EmployeeInfoState } from './types'

const paginationInitialValues = {
  pageSize: 10,
  page: 1
}

const employeeFilterInitialValues = {
  search: '',
  gender: 'ทั้งหมด',
  employeeLevelId: 'ทั้งหมด',
  status: 'ทั้งหมด',
  startDate: '',
  endDate: '',
  subCompanyId: 'ทั้งหมด',
  departmentId: 'ทั้งหมด',
  sectionId: 'ทั้งหมด'
}

const initialState: EmployeeInfoState = {
  haveChildren: false,
  haveCouple: false,
  employeeImage: null,
  isInsurancePlanLoading: false,
  isChildrenLoading: false,
  isCouplesLoading: false,
  isEmployeesLoading: false,
  isAlreadyHaveEmployees: false,
  isDownloadEmployeeTemplateLoading: false,
  isGetEmployeeListFromExcelLoading: false,
  isImportEmployeeLoading: false,
  employeeSpouseListPagination: paginationInitialValues,
  employeeChildrenListPagination: paginationInitialValues,
  employeeListPagination: paginationInitialValues,
  employeeFilterValues: employeeFilterInitialValues,

  // Fetched Info is optional,
  employeeLevelMasterData: [],
  nationalitiesMasterData: [],
  insurancePlansForEmployeeMasterData: [],
  fetchedEmployeeListFromExcel: [],
  fetchedEmployeeCount: 0
}

const slice = createSlice({
  name: 'customer/employee-info',
  initialState,
  reducers: {
    setHaveChildren: (state, action: PayloadAction<boolean>) => {
      state.haveChildren = action.payload
    },
    setHaveCouple: (state, action: PayloadAction<boolean>) => {
      state.haveCouple = action.payload
    },
    setEmployeeImage: (state, action: PayloadAction<File>) => {
      state.employeeImage = action.payload
    },
    selectPlan: (state, action: PayloadAction<string>) => {
      state.fetchedCurrentEmployeeInsurancePlanId = action.payload
    },
    changeEmployeeChildrenListPagination: (state, action: PayloadAction<{ key: string; value: number }>) => {
      const { key, value } = action.payload

      switch (key) {
        case 'pageSize':
          state.employeeChildrenListPagination = { ...state.employeeChildrenListPagination, pageSize: value, page: 1 }
          break
        case 'page':
          state.employeeChildrenListPagination = { ...state.employeeChildrenListPagination, page: value }
          break
        default:
          break
      }
    },
    changeEmployeeSpouseListPagination: (state, action: PayloadAction<{ key: string; value: number }>) => {
      const { key, value } = action.payload

      switch (key) {
        case 'pageSize':
          state.employeeSpouseListPagination = { ...state.employeeSpouseListPagination, pageSize: value, page: 1 }
          break
        case 'page':
          state.employeeSpouseListPagination = { ...state.employeeSpouseListPagination, page: value }
          break
        default:
          break
      }
    },
    changeEmployeeListPagination: (state, action: PayloadAction<{ key: string; value: number }>) => {
      const { key, value } = action.payload

      switch (key) {
        case 'pageSize':
          state.employeeListPagination = { ...state.employeeListPagination, pageSize: value, page: 1 }
          break
        case 'page':
          state.employeeListPagination = { ...state.employeeListPagination, page: value }
          break
        default:
          break
      }
    },
    setEmployeeFilterValues(state, action: PayloadAction<{ field: keyof EmployeeFilterValue; value: string }>) {
      const { field, value } = action.payload
      state.employeeFilterValues[field] = value
    },
    resetEmployeeState: (state) => {
      // State
      state.haveCouple = false
      state.haveChildren = false
      state.employeeImage = null
      state.isInsurancePlanLoading = false
      state.isDownloadEmployeeTemplateLoading = false
      state.isGetEmployeeListFromExcelLoading = false
      state.isImportEmployeeLoading = false
      state.isEmployeesLoading = false
      state.isCouplesLoading = false
      state.isChildrenLoading = false
      state.isAlreadyHaveEmployees = false

      // Fetched
      state.fetchedEmployees = undefined
      state.fetchedCouples = undefined
      state.fetchedChildren = undefined
      state.fetchedEmployeeInfo = undefined
      state.fetchedCurrentEmployeeInsurancePlanId = undefined
      state.fetchedCoupleInfo = undefined
      state.fetchedChildrenInfos = undefined
      state.fetchedEmployeeListFromExcel = []
      state.timpstampData = undefined

      // MasterData
      state.employeeLevelMasterData = []
      state.nationalitiesMasterData = []
      state.insurancePlansForEmployeeMasterData = []
      state.fetchedEmployeeCount = 0
    },
    resetEmployeePagination: (state) => {
      state.employeeListPagination = paginationInitialValues
      state.employeeChildrenListPagination = paginationInitialValues
      state.employeeSpouseListPagination = paginationInitialValues
    },
    resetEmployeeFilterValues(state) {
      state.employeeFilterValues = employeeFilterInitialValues
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(EmployeeInfoService.getAllEmployeeListAsyncThunk.fulfilled, (state, action) => {
        state.fetchedEmployees = action.payload
        state.isEmployeesLoading = false
      })
      .addCase(EmployeeInfoService.getAllChildrenListAsyncThunk.fulfilled, (state, action) => {
        state.fetchedChildren = action.payload
        state.isChildrenLoading = false
      })
      .addCase(EmployeeInfoService.getAllCoupleListAsyncThunk.fulfilled, (state, action) => {
        state.fetchedCouples = action.payload
        state.isCouplesLoading = false
      })
      .addCase(EmployeeInfoService.getAllEmployeeListAsyncThunk.pending, (state) => {
        state.isEmployeesLoading = true
      })
      .addCase(EmployeeInfoService.getAllChildrenListAsyncThunk.pending, (state) => {
        state.isChildrenLoading = true
      })
      .addCase(EmployeeInfoService.getAllCoupleListAsyncThunk.pending, (state) => {
        state.isCouplesLoading = true
      })
      .addCase(EmployeeInfoService.getEmployeeInfoByIdAsyncThunk.fulfilled, (state, action) => {
        state.fetchedEmployeeInfo = action.payload
      })
      .addCase(EmployeeInfoService.getCoupleInfoByIdAsyncThunk.fulfilled, (state, action) => {
        if (action.payload) {
          state.haveCouple = true
          state.fetchedCoupleInfo = action.payload
        } else {
          state.haveCouple = false
          state.fetchedCoupleInfo = undefined
        }
      })
      .addCase(EmployeeInfoService.getInsurancePlanByIdAsyncThunk.fulfilled, (state, action) => {
        state.fetchedCurrentEmployeeInsurancePlanId = action.payload
      })
      .addCase(EmployeeInfoService.getTimestampDataByIdAsyncThunk.fulfilled, (state, action) => {
        state.timpstampData = action.payload
      })
      .addCase(EmployeeInfoService.getChildrenInfoByIdAsyncThunk.fulfilled, (state, action) => {
        if (action.payload.length > 0) {
          state.haveChildren = true
          state.fetchedChildrenInfos = action.payload
        } else {
          state.haveChildren = false
          state.fetchedChildrenInfos = undefined
        }
      })
      .addCase(EmployeeInfoService.getEmployeeLevelMasterDataAsyncThunk.fulfilled, (state, action) => {
        state.employeeLevelMasterData = transformMasterData(action.payload)
      })
      .addCase(EmployeeInfoService.getNationalitiesMasterDataAsyncThunk.fulfilled, (state, action) => {
        state.nationalitiesMasterData = transformMasterData(action.payload)
      })
      .addCase(EmployeeInfoService.getInsurancePlanMasterDataByEmployeeLevelIdAsyncThunk.fulfilled, (state, action) => {
        state.isInsurancePlanLoading = false
        state.insurancePlansForEmployeeMasterData = action.payload
      })
      .addCase(EmployeeInfoService.getInsurancePlanMasterDataByEmployeeLevelIdAsyncThunk.pending, (state) => {
        state.isInsurancePlanLoading = true
      })
      .addCase(EmployeeInfoService.getIsAlreadyHaveEmployeeAsyncThunk.fulfilled, (state, action) => {
        state.isAlreadyHaveEmployees = action.payload
      })
      .addCase(EmployeeInfoService.getEmployeesCountAsyncThunk.fulfilled, (state, action) => {
        state.fetchedEmployeeCount = action.payload
      })
      .addCase(EmployeeInfoService.downloadEmployeeTemplateAsyncThunk.pending, (state) => {
        state.isDownloadEmployeeTemplateLoading = true
      })
      .addCase(EmployeeInfoService.downloadEmployeeTemplateAsyncThunk.rejected, (state) => {
        state.isDownloadEmployeeTemplateLoading = false
      })
      .addCase(EmployeeInfoService.downloadEmployeeTemplateAsyncThunk.fulfilled, (state) => {
        state.isDownloadEmployeeTemplateLoading = false
      })
      .addCase(EmployeeInfoService.getEmployeeListFromExcelAsyncThunk.pending, (state) => {
        state.isGetEmployeeListFromExcelLoading = true
      })
      .addCase(EmployeeInfoService.getEmployeeListFromExcelAsyncThunk.rejected, (state) => {
        state.isGetEmployeeListFromExcelLoading = false
      })
      .addCase(EmployeeInfoService.getEmployeeListFromExcelAsyncThunk.fulfilled, (state, action) => {
        state.isGetEmployeeListFromExcelLoading = false
        state.fetchedEmployeeListFromExcel = action.payload
      })
      .addCase(EmployeeInfoService.importEmployeeAsyncThunk.pending, (state) => {
        state.isImportEmployeeLoading = true
      })
      .addCase(EmployeeInfoService.importEmployeeAsyncThunk.rejected, (state) => {
        state.isImportEmployeeLoading = false
      })
      .addCase(EmployeeInfoService.importEmployeeAsyncThunk.fulfilled, (state) => {
        state.isImportEmployeeLoading = false
      })
  }
})

export const {
  setHaveChildren,
  setHaveCouple,
  setEmployeeImage,
  resetEmployeeState,
  resetEmployeePagination,
  resetEmployeeFilterValues,
  selectPlan,
  changeEmployeeChildrenListPagination,
  changeEmployeeSpouseListPagination,
  changeEmployeeListPagination,
  setEmployeeFilterValues
} = slice.actions
export default slice.reducer
