import React from 'react'

const CalendarIcon = () => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'>
      <g transform='translate(1 1)' fill='#4D4949' fillRule='nonzero'>
        <path d='M11.083 1.167H10.5V.583a.583.583 0 1 0-1.167 0v.584H4.667V.583a.583.583 0 0 0-1.167 0v.584h-.583A2.92 2.92 0 0 0 0 4.083v7A2.92 2.92 0 0 0 2.917 14h8.166A2.92 2.92 0 0 0 14 11.083v-7a2.92 2.92 0 0 0-2.917-2.916zM1.167 4.083c0-.966.783-1.75 1.75-1.75h8.166c.967 0 1.75.784 1.75 1.75v.584H1.167v-.584zm9.916 8.75H2.917a1.75 1.75 0 0 1-1.75-1.75v-5.25h11.666v5.25a1.75 1.75 0 0 1-1.75 1.75z' />
        <circle cx='7' cy='8.75' r='1' />
        <circle cx='4.083' cy='8.75' r='1' />
        <circle cx='9.917' cy='8.75' r='1' />
      </g>
    </svg>
  )
}

export default CalendarIcon
