import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { InsuranceInfoService } from '../../services/insurance-info/InsuranceInfoService'
import { ChangeEmployeeLevelInfoByIdPayload, CustomerInsuranceInfoState } from './types'

let ID = 1
const createEmployeeLevelInfo = (id: string) => ({
  id: `mock-${id}`,
  employeeLevelName: '',
  employeeAmount: '',
  maleEmployeeAmount: '',
  femaleEmployeeAmount: '',
  averageBudgetRange: [0, 30000] as [number, number],
  insuranceTypes: [],
  plan: []
})

const initialState: CustomerInsuranceInfoState = {
  employeeLevelsInfo: [createEmployeeLevelInfo(`${ID++}`)],
  fetchedInsurancePlans: undefined,
  isUpdateInsurancePointAsyncThunkLoading: false,
  enrollmentData: {
    effectiveStartDate: '',
    effectiveEndDate: '',
    enrollmentStartDate: '',
    enrollmentEndDate: ''
  }
}

const slice = createSlice({
  name: 'customer/company-info',
  initialState,
  reducers: {
    addEmployeeLevel: (state) => {
      state.employeeLevelsInfo.push(createEmployeeLevelInfo(`${ID++}`))
    },
    onSetStartEffectiveDate: (state, action: PayloadAction<string>) => {
      state.enrollmentData.effectiveStartDate = action.payload
      state.enrollmentData.effectiveEndDate = ''
    },
    onSetEndEffectiveDate: (state, action: PayloadAction<string>) => {
      state.enrollmentData.effectiveEndDate = action.payload
    },
    onSetStartEnrollmentDate: (state, action: PayloadAction<string>) => {
      state.enrollmentData.enrollmentStartDate = action.payload
      state.enrollmentData.enrollmentEndDate = ''
    },
    onSetEndEnrollmentDate: (state, action: PayloadAction<string>) => {
      state.enrollmentData.enrollmentEndDate = action.payload
    },
    deleteEmployeeLevel: (state, action: PayloadAction<string>) => {
      state.employeeLevelsInfo = state.employeeLevelsInfo.filter((empLevel) => empLevel.id !== action.payload)
    },
    onChangeEmployeeLevelPlanPointById: (state, action: PayloadAction<{ point: string; insuranceId: string }>) => {
      state.employeeLevelPlanPointInfo = action.payload
    },
    onChangeInsuranceInEmployeeLevel: (state, action: PayloadAction<{ empLevelId: string; insuranceId: string }>) => {
      const insuranceId = action.payload.insuranceId
      const empLevelId = action.payload.empLevelId
      const findIndex = state.employeeLevelsInfo.findIndex((empLevelInfo) => empLevelInfo.id === empLevelId)
      const currentEmployeeLevelsInfo = state.employeeLevelsInfo[findIndex]
      const planIds = currentEmployeeLevelsInfo.plan.map((plan) => plan.id)
      if (planIds.includes(insuranceId)) {
        state.employeeLevelsInfo[findIndex].plan = state.employeeLevelsInfo[findIndex].plan.filter(
          (plan) => plan.id !== insuranceId
        )
      } else {
        const currentPlan = state.fetchedInsurancePlans?.find((insurance) => insurance.id === insuranceId)
        if (!currentPlan) return
        state.employeeLevelsInfo[findIndex].plan.push(currentPlan)
      }
    },
    onChangeEmployeeLevelInfoById: (state, action: PayloadAction<ChangeEmployeeLevelInfoByIdPayload>) => {
      const value = action.payload.value
      const findIndex = state.employeeLevelsInfo.findIndex((empInfo) => empInfo.id === action.payload.id)
      const currentEmployeeLevelInfo = state.employeeLevelsInfo[findIndex]
      const currentField = action.payload.field!
      if (action.payload.type === 'text') {
        state.employeeLevelsInfo[findIndex] = {
          ...currentEmployeeLevelInfo,
          [currentField]: value
        }
      } else if (action.payload.type === 'checkbox') {
        const currentInsuranceTypes = state.employeeLevelsInfo[findIndex].insuranceTypes
        const id = value as string
        if (currentInsuranceTypes.includes(id)) {
          state.employeeLevelsInfo[findIndex].insuranceTypes = state.employeeLevelsInfo[
            findIndex
          ].insuranceTypes.filter((curId) => curId !== id)
        } else {
          state.employeeLevelsInfo[findIndex].insuranceTypes.push(id)
        }
      } else {
        if (!Array.isArray(value)) return
        const activeThumb = action.payload.activeThumb
        const isFirstActive = activeThumb === 0
        const isSecondActive = activeThumb === 1
        const currentBudgetRange = currentEmployeeLevelInfo[currentField] as [number, number]
        state.employeeLevelsInfo[findIndex] = {
          ...currentEmployeeLevelInfo,
          [currentField]: [
            isFirstActive ? value[0] : currentBudgetRange[0],
            isSecondActive ? value[1] : currentBudgetRange[1]
          ]
        }
      }
    },
    resetCustomerInsuranceInfoState: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(InsuranceInfoService.getAllInsurancePlansAsyncThunk.fulfilled, (state, action) => {
        state.fetchedInsurancePlans = action.payload.insurances.map((insurance) => ({
          id: insurance.id,
          insurerId: insurance.insurerId,
          imageUrl: insurance.imageUrl,
          name: insurance.name,
          displayName: insurance.displayName,
          lifeInsuranceAmount: insurance.lifeInsurance,
          lifeInsuranceAmountUnit: insurance.lifeInsuranceUnit,
          hospitalAndBoardPerDay: insurance.hospitalAndBoardPerDay,
          hospitalAndBoardPerDayUnit: insurance.hospitalAndBoardPerDayUnit,
          generalHospitalExpenses: insurance.generalHospitalExpenses,
          generalHospitalExpensesUnit: insurance.generalHospitalExpensesUnit,
          dentalCost: insurance.dentalCost,
          dentalCostUnit: insurance.dentalCostUnit,
          opdTreatingCost: insurance.opdTreatingCost,
          opdTreatingCostUnit: insurance.opdTreatingCostUnit,
          specialLabel: insurance.specialLabel,
          updatedAt: insurance.updatedAt
        }))
      })
      .addCase(InsuranceInfoService.getInsuranceTabByCustomerIdAsyncThunk.fulfilled, (state, action) => {
        if (action.payload.enrollment) {
          state.enrollmentData = action.payload.enrollment
        }
        if (action.payload.employeeLevels) {
          state.employeeLevelsInfo = action.payload.employeeLevels.map((empLevel) => ({
            id: empLevel.id ?? '',
            employeeLevelName: empLevel.name,
            employeeAmount: empLevel.employeeAmount.toString(),
            maleEmployeeAmount: empLevel.maleAmount ? empLevel.maleAmount.toString() : '',
            femaleEmployeeAmount: empLevel.femaleAmount ? empLevel.femaleAmount.toString() : '',
            averageBudgetRange: [empLevel.minBudgetPerEmployee, empLevel.maxBudgetPerEmployee] as [number, number],
            insuranceTypes: empLevel.insuranceTypeIds,
            plan: empLevel.insurances!.map((insurance) => ({
              id: insurance.id,
              insurerId: insurance.insurerId,
              imageUrl: insurance.imageUrl,
              name: insurance.name,
              displayName: insurance.displayName,
              lifeInsuranceAmount: insurance.lifeInsurance,
              lifeInsuranceAmountUnit: insurance.lifeInsuranceUnit,
              hospitalAndBoardPerDay: insurance.hospitalAndBoardPerDay,
              hospitalAndBoardPerDayUnit: insurance.hospitalAndBoardPerDayUnit,
              generalHospitalExpenses: insurance.generalHospitalExpenses,
              generalHospitalExpensesUnit: insurance.generalHospitalExpensesUnit,
              dentalCost: insurance.dentalCost,
              dentalCostUnit: insurance.dentalCostUnit,
              opdTreatingCost: insurance.opdTreatingCost,
              opdTreatingCostUnit: insurance.opdTreatingCostUnit,
              specialLabel: insurance.specialLabel,
              updatedAt: insurance.updatedAt,
              point: insurance.point,
              price: insurance.sellPrice
            }))
          }))
        }
      })
      .addCase(InsuranceInfoService.updateInsurancePointAsyncThunk.pending, (state) => {
        state.isUpdateInsurancePointAsyncThunkLoading = true
      })
      .addCase(InsuranceInfoService.updateInsurancePointAsyncThunk.rejected, (state) => {
        state.isUpdateInsurancePointAsyncThunkLoading = false
      })
      .addCase(InsuranceInfoService.updateInsurancePointAsyncThunk.fulfilled, (state) => {
        state.isUpdateInsurancePointAsyncThunkLoading = false
      })
  }
})

export const {
  resetCustomerInsuranceInfoState,
  onChangeEmployeeLevelInfoById,
  addEmployeeLevel,
  onSetEndEffectiveDate,
  onSetStartEffectiveDate,
  onSetStartEnrollmentDate,
  onSetEndEnrollmentDate,
  deleteEmployeeLevel,
  onChangeInsuranceInEmployeeLevel,
  onChangeEmployeeLevelPlanPointById
} = slice.actions
export default slice.reducer
