import { createAsyncThunk } from '@reduxjs/toolkit'

import { PLAN_URLS } from '../../../../configs/api'
import { REQUEST_OPTIONS } from '../../../../constants/request-options'
import { AxiosService } from '../../../../services/api'
import { createThunkName } from '../../../../utils/createThunkname'
import { saveData } from '../../../../utils/downloadFile'
import {
  AddPlanDto,
  DeletePlanDto,
  ExportPlanDataQueryParams,
  GetAllPlansQueryParams,
  GetAllPlansResponse,
  GetInsurancePlanResponse,
  GetInsuranceTypeMasterDataResponse,
  GetPlanDetailByIdResponse,
  GetPlanInfoByIdResponse,
  GetPlanInsuranceTypeByIdResponse,
  GetPlanSpecialLabelByIdResponse,
  GetPlanTimeStampByIdResponse,
  GetSpecialCustomersByIdResponse,
  UpdatePlanDetailDto,
  UpdatePlanInfoDto,
  UpdatePlanInsuranceTypeDto,
  UpdatePlanSpecialCustomersDto,
  UpdatePlanSpecialLabelDto,
  UpdatePlanStatusDto
} from './types'

const PREFIX = 'Plan'

export const PlanService = {
  getAllPlansAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getAllPlans'),
    async (queryParams: GetAllPlansQueryParams) => {
      try {
        const result = await AxiosService.get<GetAllPlansResponse>(PLAN_URLS.getAllPlans, { params: queryParams })
        return result.data
      } catch (error) {
        throw error
      }
    }
  ),
  addPlan: async (addPlanDto: AddPlanDto) => {
    try {
      await AxiosService.post(PLAN_URLS.addPlan, addPlanDto)
    } catch (error) {
      throw error
    }
  },
  downloadTemplate: async () => {
    try {
      const result = await AxiosService.get(PLAN_URLS.downloadTemplate, {
        ...REQUEST_OPTIONS
      })
      saveData(result, result.request.getResponseHeader('Content-Disposition'))
    } catch (error) {
      throw error
    }
  },
  importTemplate: async (file: File) => {
    try {
      const formData = new FormData()
      formData.append('file', file)
      await AxiosService.post(PLAN_URLS.importTemplate, formData)
    } catch (error) {}
  },
  exportPlanData: async (queryParams: ExportPlanDataQueryParams) => {
    try {
      const result = await AxiosService.get(PLAN_URLS.exportPlanData, {
        ...REQUEST_OPTIONS,
        params: queryParams
      })
      saveData(result, result.request.getResponseHeader('Content-Disposition'))
    } catch (error) {
      throw error
    }
  },
  deletePlan: async (deletePlanDto: DeletePlanDto) => {
    try {
      await AxiosService.post(PLAN_URLS.deletePlan, deletePlanDto)
    } catch (error) {
      throw error
    }
  },
  getPlanInfoAsyncThunk: createAsyncThunk(createThunkName(PREFIX, 'getPlanInfo'), async (planId: string) => {
    try {
      const result = await AxiosService.get<GetPlanInfoByIdResponse>(PLAN_URLS.getPlanInfo(planId))
      return result.data.planInfo
    } catch (error) {
      throw error
    }
  }),
  getPlanInsuranceTypeAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getPlanInsuranceType'),
    async (planId: string) => {
      try {
        const result = await AxiosService.get<GetPlanInsuranceTypeByIdResponse>(PLAN_URLS.getPlanInsuranceType(planId))
        return result.data.insuranceTypeIds
      } catch (error) {
        throw error
      }
    }
  ),
  getPlanSpecialLabelAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getPlanSpecialLabel'),
    async (planId: string) => {
      try {
        const result = await AxiosService.get<GetPlanSpecialLabelByIdResponse>(PLAN_URLS.getPlanSpecialLabel(planId))
        return result.data.specialLabel
      } catch (error) {
        throw error
      }
    }
  ),
  getSpecialCustomersAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getSpecialCustomer'),
    async (planId: string) => {
      try {
        const result = await AxiosService.get<GetSpecialCustomersByIdResponse>(PLAN_URLS.getSpecialCustomers(planId))
        return result.data.specialCustomerIds
      } catch (error) {
        throw error
      }
    }
  ),
  getPlanDetailAsyncThunk: createAsyncThunk(createThunkName(PREFIX, 'getPlanDetail'), async (planId: string) => {
    try {
      const result = await AxiosService.get<GetPlanDetailByIdResponse>(PLAN_URLS.getPlanDetail(planId))
      return result.data.planDetail
    } catch (error) {
      throw error
    }
  }),
  getInsurancePlanAsyncThunk: createAsyncThunk(createThunkName(PREFIX, 'getInsurancePlan'), async (planId: string) => {
    try {
      const result = await AxiosService.get<GetInsurancePlanResponse>(PLAN_URLS.getInsurancePlanDetail(planId))
      return result.data.insurance
    } catch (error) {
      throw error
    }
  }),
  getTimeStampDataAsyncThunk: createAsyncThunk(createThunkName(PREFIX, 'getTimestamp'), async (planId: string) => {
    try {
      const result = await AxiosService.get<GetPlanTimeStampByIdResponse>(PLAN_URLS.getTimeStampData(planId))
      return result.data.timestampData
    } catch (error) {
      throw error
    }
  }),
  updatePlanInfo: async (dto: UpdatePlanInfoDto) => {
    try {
      await AxiosService.put(PLAN_URLS.updatePlanInfo, dto)
    } catch (error) {
      throw error
    }
  },
  updatePlanInsuranceType: async (dto: UpdatePlanInsuranceTypeDto) => {
    try {
      await AxiosService.put(PLAN_URLS.updatePlanInsuranceType, dto)
    } catch (error) {
      throw error
    }
  },
  updatePlanSpecialLabel: async (dto: UpdatePlanSpecialLabelDto) => {
    try {
      await AxiosService.put(PLAN_URLS.updatePlanSpecialLabel, dto)
    } catch (error) {
      throw error
    }
  },
  updateSpecialCustomers: async (dto: UpdatePlanSpecialCustomersDto) => {
    try {
      await AxiosService.put(PLAN_URLS.updateSpecialCustomers, dto)
    } catch (error) {
      throw error
    }
  },
  updatePlanDetail: async (dto: UpdatePlanDetailDto) => {
    try {
      await AxiosService.put(PLAN_URLS.updatePlanDetail, dto)
    } catch (error) {
      throw error
    }
  },
  updatePlanStatus: async (dto: UpdatePlanStatusDto) => {
    try {
      await AxiosService.put(PLAN_URLS.updatePlanStatus, dto)
    } catch (error) {
      throw error
    }
  },
  getInsuranceTypeMasterDataAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getInsuranceTypeMasterData'),
    async () => {
      try {
        const result = await AxiosService.get<GetInsuranceTypeMasterDataResponse>(
          PLAN_URLS.masterData.getInsuranceTypeMasterData
        )
        return result.data.insuranceTypes
      } catch (error) {
        throw error
      }
    }
  )
}
