import { createSlice } from '@reduxjs/toolkit'

import { transformMasterData } from '../../../../utils/transformMasterData'
import { BenefitInfoService } from '../../services/benefit-info/BenefitInfoService'
import { BenefitInfoState } from './types'

const initialState: BenefitInfoState = {
  //State
  isBenefitMasterDataLoading: false,
  isBenefitDocumentMasterDataLoading: false,
  isFetchedAllBenefitByCustomerIdLoading: false,
  isExportBenefitByCustomerIdLoading: false,
  isFetchedBenefitByIdLoading: false,
  isApproveBenefitByIdLoading: false,
  isRejectBenefitByIdLoading: false,
  benefitDocuments: [],
  //Master Data
  benefitMasterData: [],
  benefitDocumentMasterData: []
}

const slice = createSlice({
  name: 'e-claim/benefit-info',
  initialState,
  reducers: { resetBenefitInfoState: () => initialState },
  extraReducers: (builder) => {
    builder
      .addCase(BenefitInfoService.getBenefitMasterDataAsyncThunk.pending, (state) => {
        state.isBenefitMasterDataLoading = true
      })
      .addCase(BenefitInfoService.getBenefitMasterDataAsyncThunk.rejected, (state) => {
        state.isBenefitMasterDataLoading = false
      })
      .addCase(BenefitInfoService.getBenefitMasterDataAsyncThunk.fulfilled, (state, action) => {
        state.benefitMasterData = transformMasterData(action.payload)
        state.isBenefitMasterDataLoading = false
      })
      .addCase(BenefitInfoService.getBenefitDocumentMasterDataAsyncThunk.pending, (state) => {
        state.isBenefitDocumentMasterDataLoading = true
      })
      .addCase(BenefitInfoService.getBenefitDocumentMasterDataAsyncThunk.rejected, (state) => {
        state.isBenefitDocumentMasterDataLoading = false
      })
      .addCase(BenefitInfoService.getBenefitDocumentMasterDataAsyncThunk.fulfilled, (state, action) => {
        state.isBenefitDocumentMasterDataLoading = false
        state.benefitDocumentMasterData = transformMasterData(action.payload)
        state.benefitDocuments = transformMasterData(action.payload).map((masterData) => ({
          ...masterData,
          fileUrl: ''
        }))
        state.benefitDocuments = state.benefitDocuments.map((benefitDocument) => {
          const currentDocument =
            state.fetchedBenefitById &&
            state.fetchedBenefitById.documents.find(
              (document) => document.benefitDocumentMasterId === benefitDocument.id
            )
          return {
            label: benefitDocument.label,
            id: benefitDocument.id,
            fileUrl: currentDocument ? currentDocument.fileUrl : ''
          }
        })
      })
      .addCase(BenefitInfoService.getAllBenefitByCustomerIdAsyncThunk.pending, (state) => {
        state.isFetchedAllBenefitByCustomerIdLoading = true
      })
      .addCase(BenefitInfoService.getAllBenefitByCustomerIdAsyncThunk.rejected, (state) => {
        state.isFetchedAllBenefitByCustomerIdLoading = false
      })
      .addCase(BenefitInfoService.getAllBenefitByCustomerIdAsyncThunk.fulfilled, (state, action) => {
        state.fetchedAllBenefitByCustomerId = action.payload
        state.isFetchedAllBenefitByCustomerIdLoading = false
      })
      .addCase(BenefitInfoService.exportBenefitByCustomerIdAsyncThunk.pending, (state) => {
        state.isExportBenefitByCustomerIdLoading = true
      })
      .addCase(BenefitInfoService.exportBenefitByCustomerIdAsyncThunk.rejected, (state) => {
        state.isExportBenefitByCustomerIdLoading = false
      })
      .addCase(BenefitInfoService.exportBenefitByCustomerIdAsyncThunk.fulfilled, (state) => {
        state.isExportBenefitByCustomerIdLoading = false
      })
      .addCase(BenefitInfoService.getBenefitByIdAsyncThunk.pending, (state) => {
        state.isFetchedBenefitByIdLoading = true
      })
      .addCase(BenefitInfoService.getBenefitByIdAsyncThunk.rejected, (state) => {
        state.isFetchedBenefitByIdLoading = false
      })
      .addCase(BenefitInfoService.getBenefitByIdAsyncThunk.fulfilled, (state, action) => {
        state.isFetchedBenefitByIdLoading = false
        state.fetchedBenefitById = action.payload
      })
      .addCase(BenefitInfoService.approveBenefitByIdAsyncThunk.pending, (state) => {
        state.isApproveBenefitByIdLoading = true
      })
      .addCase(BenefitInfoService.approveBenefitByIdAsyncThunk.rejected, (state) => {
        state.isApproveBenefitByIdLoading = false
      })
      .addCase(BenefitInfoService.approveBenefitByIdAsyncThunk.fulfilled, (state) => {
        state.isApproveBenefitByIdLoading = false
      })
      .addCase(BenefitInfoService.rejectBenefitByIdAsyncThunk.pending, (state) => {
        state.isRejectBenefitByIdLoading = true
      })
      .addCase(BenefitInfoService.rejectBenefitByIdAsyncThunk.rejected, (state) => {
        state.isRejectBenefitByIdLoading = false
      })
      .addCase(BenefitInfoService.rejectBenefitByIdAsyncThunk.fulfilled, (state) => {
        state.isRejectBenefitByIdLoading = false
      })
  }
})

export const { resetBenefitInfoState } = slice.actions
export default slice.reducer
