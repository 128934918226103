import { Box, Stack, styled } from '@mui/material'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'

import AutoCompleteSelectField from '../../components/global/forms/AutoCompleteSelectField'
import CustomTextField from '../../components/global/forms/CustomTextField'
import AddIcon from '../../components/global/icons/AddIcon'
import EditIcon from '../../components/global/icons/EditIcon'
import SearchIcon from '../../components/global/icons/SearchIcon'
import CustomButton from '../../components/global/interactions/CustomButton'
import CustomIconButton from '../../components/global/interactions/CustomIconButton'
import { BaseCard } from '../../components/global/ui/Card'
import CustomTable, { CustomTableStyle } from '../../components/global/ui/CustomTable/CustomTable'
import LoadingWithBackdrop from '../../components/global/ui/LoadingWithBackdrop'
import BackButton from '../../components/project/navigations/BackButton'
import PaginationBottomBar from '../../components/project/ui/PaginationBottomBar'
import { COLORS } from '../../constants/colors'
import { FILTER_BUTTON_SIZE } from '../../constants/constants'
import { ISACTIVE_FILTER } from '../../constants/filter-status'
import { addHRUserPath, editHRUserPath } from '../../constants/route-name'
import { useAppDispatch } from '../../stores/store'
import { combineImageUrl } from '../../utils/combineImageUrl'
import { EClaimInfoService } from '../e-claim/services/e-claim-info/EClaimInfoService'
import {
  fetchedCompanyNameSelector,
  isFetchedCompanyNameLoadingSelector
} from '../e-claim/stores/e-claim-info/selector'
import { HRUserService } from './services/hr-user/HRUserService'
import { GetAllHRByCustomerIdQueryParams } from './services/hr-user/types'
import {
  fetchedAllHRUsersByCustomerIdSelector,
  hrUserListFilterValueSelector,
  isGetAllHRForUsersByCustomerIdLoadingSelector
} from './stores/hr-user/selector'
import { resetHRUserListFilterValues, setHRUserListFilterValues } from './stores/hr-user/slice'
import { HRUserListFilterValue } from './stores/hr-user/types'

const HRUserList = () => {
  const { customerId } = useParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const hrUserListFilterValue = useSelector(hrUserListFilterValueSelector)
  const fetchedCompanyName = useSelector(fetchedCompanyNameSelector)
  const isFetchedCompanyNameLoading = useSelector(isFetchedCompanyNameLoadingSelector)
  const fetchedAllHRUsersByCustomerId = useSelector(fetchedAllHRUsersByCustomerIdSelector)
  const isGetAllHRForUsersByCustomerIdLoading = useSelector(isGetAllHRForUsersByCustomerIdLoadingSelector)

  const onFetchedCompanyName = () => customerId && dispatch(EClaimInfoService.getCompanyNameAsyncThunk(customerId))
  const onSearchHRUser = () => onFetchHRUsers()
  const onResetHRUser = () => {
    dispatch(resetHRUserListFilterValues())
    onFetchHRUsers({ search: '', status: '', page: 1, pageSize: 20 })
  }
  const onFetchHRUsers = async (query?: GetAllHRByCustomerIdQueryParams) => {
    if (!customerId) return
    dispatch(HRUserService.getAllHRByCustomerIdAsyncThunk({ query: query ? query : queryParams, customerId }))
  }
  const onChangeFilterField = (field: string, value: string) =>
    dispatch(setHRUserListFilterValues({ field: field as keyof HRUserListFilterValue, value }))

  const onEditHRUser = (hrUserId: string) => customerId && navigate(editHRUserPath(customerId, hrUserId))
  const onAddHRUser = () => customerId && navigate(addHRUserPath(customerId))

  const queryParams = {
    search: hrUserListFilterValue.search,
    status: hrUserListFilterValue.status === 'ทั้งหมด' ? '' : hrUserListFilterValue.status,
    page: Number(hrUserListFilterValue.page),
    pageSize: Number(hrUserListFilterValue.pageSize)
  }

  useEffect(() => {
    onFetchedCompanyName()
  }, [])

  useEffect(() => {
    onFetchHRUsers()
  }, [hrUserListFilterValue.page, hrUserListFilterValue.pageSize])

  return (
    <>
      {isFetchedCompanyNameLoading && <LoadingWithBackdrop />}
      <Box display='flex' alignItems='center' marginBottom={6} justifyContent='space-between'>
        <BackButton title={fetchedCompanyName} />
        <CustomButton text='เพิ่มผู้ใช้งาน' size='medium' startIcon={<AddIcon size={16} />} onClick={onAddHRUser} />
      </Box>
      <StyledHeaderCard display='flex' marginBottom={4}>
        <Stack direction='row' spacing={4} width='100%'>
          <Box width='60%'>
            <CustomTextField
              label='ค้นหา'
              value={hrUserListFilterValue.search}
              onChange={(e) => onChangeFilterField('search', e.target.value)}
              id='search'
              endAdornment={<SearchIcon />}
            />
          </Box>
          <Box width='40%'>
            <AutoCompleteSelectField
              value={hrUserListFilterValue.status}
              onChange={(value) => onChangeFilterField('status', value)}
              options={ISACTIVE_FILTER}
              isNeedAll
              label='สถานะ'
            />
          </Box>
          <Stack direction='row' spacing={2}>
            <CustomButton text='ค้นหา' color='primary' exactWidth={FILTER_BUTTON_SIZE} onClick={onSearchHRUser} />
            <CustomButton
              text='ล้าง'
              variant='outlined'
              color='info'
              customBorderColor={COLORS.grey350}
              exactWidth={FILTER_BUTTON_SIZE}
              onClick={onResetHRUser}
            />
          </Stack>
        </Stack>
      </StyledHeaderCard>
      <StyledTableCard>
        <CustomTable
          style={CustomTableStyle.Secondary}
          isLoading={isGetAllHRForUsersByCustomerIdLoading}
          items={
            fetchedAllHRUsersByCustomerId
              ? fetchedAllHRUsersByCustomerId.hr.map((customer) => ({
                  id: customer.id,
                  imageUrl: combineImageUrl(customer.profileUrl),
                  name: customer.name,
                  email: customer.email,
                  telNumber: customer.phoneNumber,
                  position: customer.position,
                  isActive: customer.isActive
                }))
              : []
          }
          headers={[
            { type: 'avatar-img', headerName: 'รูป', key: 'imageUrl' },
            { type: 'text', headerName: 'ชื่อ-นามสกุล', key: 'name' },
            { type: 'text', headerName: 'อีเมล', key: 'email' },
            { type: 'text', headerName: 'เบอร์โทรศัพท์', key: 'telNumber' },
            { type: 'text', headerName: 'ตำแหน่ง', key: 'position' },
            { type: 'isActive', headerName: 'สถานะ', key: 'isActive' }
          ]}
          options={{
            manage: {
              items: [
                {
                  button: <CustomIconButton icon={<EditIcon color={COLORS.grey750} />} />,
                  onClick: onEditHRUser
                }
              ]
            }
          }}
        />
      </StyledTableCard>
      <Box paddingX={4} paddingY={6}>
        <PaginationBottomBar
          currentPage={Number(hrUserListFilterValue.page)}
          onChangePagination={onChangeFilterField}
          totalPage={fetchedAllHRUsersByCustomerId?.pagination.pageCount}
          count={fetchedAllHRUsersByCustomerId?.pagination.totalCount}
        />
      </Box>
    </>
  )
}

const StyledHeaderCard = styled(BaseCard)(({ theme }) => ({
  padding: theme.spacing(4)
}))

const StyledTableCard = styled(BaseCard)(({ theme }) => ({
  padding: theme.spacing(4)
}))

export default HRUserList
