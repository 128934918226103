import {
  Box,
  Dialog,
  DialogActions,
  DialogActionsProps,
  DialogContent,
  Divider,
  styled,
  Typography
} from '@mui/material'
import React, { ReactNode } from 'react'

import { COLORS_THEME } from '../../../constants/colors'
import { fontSize } from '../../../themes/themes'
import CancelIcon from '../icons/CancelIcon'
import CustomButton from './CustomButton'

interface ConfirmDialogProps {
  title: string
  content: ReactNode
  onConfirm: () => void
  onCancel?: () => void
  isOpen: boolean
  isConfirmDisabled?: boolean
}

interface StyledDialogActionsProps extends DialogActionsProps {
  haveCancel: boolean
}

const ConfirmDialog = ({ content, onConfirm, onCancel, isOpen, isConfirmDisabled, title }: ConfirmDialogProps) => {
  return (
    <StyledDialog open={isOpen}>
      <StyledDialogContent>
        <Box paddingY={4} paddingX={6} display='flex' alignItems='center' justifyContent='space-between'>
          <Typography variant='h5' color={COLORS_THEME.primary}>
            {title}
          </Typography>
          <IconBtn onClick={onCancel}>
            <CancelIcon size={24} />
          </IconBtn>
        </Box>
        <Divider />
        {content}
      </StyledDialogContent>
      <StyledDialogActions haveCancel={Boolean(onCancel)}>
        {onCancel && (
          <CustomButton
            text='ยกเลิก'
            variant='outlined'
            size='medium'
            color='inherit'
            textVariant='body1'
            onClick={onCancel}
          />
        )}
        <CustomButton
          text='ยืนยัน'
          size='medium'
          isDisabled={isConfirmDisabled}
          color='primary'
          textVariant='body1'
          onClick={onConfirm}
        />
      </StyledDialogActions>
    </StyledDialog>
  )
}

const IconBtn = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer'
}))

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-container': {
    width: '424px !important',
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    top: '-5%',
    '& .MuiPaper-root': {
      borderRadius: `${theme.spacing(4)} !important`
    }
  },
  '& .MuiDialog-container .MuiPaper-root': {
    margin: theme.spacing(0),
    width: '100%'
  }
}))

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(0),
  overflowY: 'hidden',
  fontSize: fontSize.h6
}))

const StyledDialogActions = styled(DialogActions, {
  shouldForwardProp: (props) => props !== 'haveCancel'
})<StyledDialogActionsProps>(({ theme, haveCancel }) => ({
  padding: theme.spacing(0, 8, 6, 8),
  marginTop: theme.spacing(8),
  justifyContent: haveCancel ? 'space-between' : 'flex-end',
  alignItems: 'stretch',
  '& > :not(:first-of-type)': {
    marginLeft: theme.spacing(4)
  }
}))

export default ConfirmDialog
