import { Autocomplete, Box, styled, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'

import { COLORS } from '../../../constants/colors'
import { fontSize } from '../../../themes/themes'
import ArrowDownIcon from '../../global/icons/ArrowDownIcon'
import CustomPagination from '../../global/ui/CustomPagination'

const PAGINATION_OPTIONS = [
  { id: 5, label: '5' },
  { id: 10, label: '10' },
  { id: 20, label: '20' },
  { id: 25, label: '25' },
  { id: 50, label: '50' }
]

interface PaginationBottomBarProps {
  onChangePagination?: (field: string, value: string) => void
  currentPage?: number
  totalPage?: number
  count?: number
  defaultPageLimit?: DefaultPageLimit
}

interface DefaultPageLimit {
  id: number
  label: string
}

const PaginationBottomBar = ({
  onChangePagination,
  currentPage,
  totalPage,
  count,
  defaultPageLimit = { id: 10, label: '10' }
}: PaginationBottomBarProps) => {
  const [pageLimit, setPageLimit] = useState(defaultPageLimit)

  const onChangeField = (_: any, newValue: any) => {
    setPageLimit(newValue)
    onChangePagination && onChangePagination('pageSize', newValue.id.toString())
  }
  const onChangePage = (_: React.ChangeEvent<unknown>, value: number) =>
    onChangePagination && onChangePagination('page', value.toString())

  return (
    <Box display='flex' justifyContent='space-between' alignItems='center'>
      <Box display='flex' alignItems='center'>
        <Typography variant='body2' color={COLORS.grey700} fontWeight='normal' marginRight={2}>
          แสดง
        </Typography>
        <StyledAutocomplete
          options={PAGINATION_OPTIONS}
          value={pageLimit}
          isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
          renderInput={(params) => <TextField {...params} />}
          disableClearable
          onChange={onChangeField}
          popupIcon={<ArrowDownIcon size={10} />}
        />
        <Typography variant='body2' color={COLORS.grey700} fontWeight='normal' marginLeft={2}>
          รายการ จากทั้งหมด {count} รายการ
        </Typography>
      </Box>
      <CustomPagination onChangePage={onChangePage} currentPage={currentPage} totalPage={totalPage} />
    </Box>
  )
}

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  width: '50px',
  '& .MuiOutlinedInput-root': {
    padding: 0,
    borderRadius: theme.spacing(2),
    backgroundColor: COLORS.white
  },
  '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
    fontSize: fontSize.body2,
    fontWeight: 500,
    padding: theme.spacing(1, 2)
  },
  '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
    borderColor: COLORS.grey100
  },
  '& .MuiOutlinedInput-root .MuiAutocomplete-endAdornment .MuiButtonBase-root': {
    marginTop: theme.spacing(1.5)
  }
}))

export default PaginationBottomBar
