import { createAsyncThunk } from '@reduxjs/toolkit'

import { CUSTOMER_URLS } from '../../../../configs/api'
import { AxiosService } from '../../../../services/api'
import { createThunkName } from '../../../../utils/createThunkname'

const PREFIX = 'insurance-info'

export const InsuranceInfoService = {
  getInsuranceTabByCustomerIdAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getInsuranceTabByCustomerId'),
    async ({ customerId, yearId }: { customerId: string; yearId: string }) => {
      try {
        const result = await AxiosService.get<GetInsuranceTabInfoByCustomerIdResponse>(
          CUSTOMER_URLS.insuranceInfoTab.getInsuranceTabByCustomerId(yearId, customerId)
        )
        return result.data
      } catch (error) {
        throw error
      }
    }
  ),
  getAllInsurancePlansAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getAllInsurancePlans'),
    async (queryParams: GetAllInsurancePlanQueryParams) => {
      try {
        const result = await AxiosService.get<GetAllInsurancePlanResponse>(
          CUSTOMER_URLS.insuranceInfoTab.getAllInsurancePlans,
          { params: queryParams }
        )
        return result.data
      } catch (error) {
        throw error
      }
    }
  ),
  createEmployeeLevel: async (dto: CreateEmployeeLevelDto) => {
    try {
      await AxiosService.post(CUSTOMER_URLS.insuranceInfoTab.createEmployeeLevel, dto)
    } catch (error) {
      throw error
    }
  },
  updateEffectiveDate: async (dto: UpdateEffectiveDto) => {
    try {
      await AxiosService.put(CUSTOMER_URLS.insuranceInfoTab.updateEffectiveDate, dto)
    } catch (error) {
      throw error
    }
  },
  updateEnrollmentDate: async (dto: UpdateEnrollmentDto) => {
    try {
      await AxiosService.put(CUSTOMER_URLS.insuranceInfoTab.updateEnrollmentDate, dto)
    } catch (error) {
      throw error
    }
  },
  updateEmployeeLevel: async (dto: UpdateEmployeeLevelDto) => {
    try {
      await AxiosService.put(CUSTOMER_URLS.insuranceInfoTab.updateEmployeeLevel, dto)
    } catch (error) {
      throw error
    }
  },
  importEmployeesFromLastYear: async (dto: ImportEmployeesFromLastYearDto) => {
    try {
      await AxiosService.post(CUSTOMER_URLS.insuranceInfoTab.importEmployeeFromLastYear, dto)
    } catch (error) {
      throw error
    }
  },
  updateInsurancePointAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'updateInsurancePoint'),
    async (dto: UpdatePointInEmployeeLevelInsuranceDto) => {
      try {
        await AxiosService.put(CUSTOMER_URLS.insuranceInfoTab.updateInsurancePoint, dto)
      } catch (error) {
        throw error
      }
    }
  )
}
