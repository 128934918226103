import React from 'react'

import { COLORS_THEME } from '../../../constants/colors'
import { IconProps } from '../../../types/types'

const PDFIcon = ({ size = 24, color = COLORS_THEME.primary }: IconProps) => {
  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} xmlns='http://www.w3.org/2000/svg'>
      <g fill={color} fillRule='nonzero'>
        <path
          transform={`scale(${size / 24})`}
          d='M7.117 2h12.186c.01.008.022.014.034.02 1.019.26 1.489.864 1.49 1.919v10.47c-.514-.01-1.007-.028-1.499-.029-1.1 0-1.85.749-1.855 1.848 0 .475.004.95-.005 1.425a.248.248 0 0 1-.11.187c-1.12.545-1.401 1.85-.596 2.796.333.392.67.783 1.003 1.171.044.053.084.107.144.186H7.453c-1.113 0-1.859-.747-1.86-1.862v-5.362h9.974c1.435 0 2.448-1.01 2.449-2.441V7.059c0-1.432-1.013-2.44-2.45-2.44H5.595c0-.31-.007-.602 0-.892.018-.568.242-1.044.711-1.365.242-.165.54-.245.812-.362z'
        />
        <path
          transform={`scale(${size / 24})`}
          d='M2 6.764c.075-.164.129-.343.23-.49.23-.333.566-.486.97-.486h12.34c.834 0 1.304.47 1.304 1.308v5.192c0 .837-.47 1.309-1.302 1.309-4.035 0-8.07-.008-12.105.008-.709 0-1.235-.252-1.435-.984L2 6.764zm6.006 2.914h.007v1.911c0 .298.148.444.443.446.351 0 .703.004 1.054-.006.714-.021 1.268-.338 1.608-.965.464-.855.476-1.748.08-2.627-.32-.71-.903-1.07-1.686-1.086-.344-.007-.689 0-1.033 0-.336 0-.47.136-.47.475-.004.617-.003 1.234-.003 1.852zm-2.997.648c.277 0 .542.012.806 0 .766-.045 1.352-.64 1.406-1.413.049-.713-.483-1.415-1.215-1.517-.473-.066-.96-.04-1.44-.034-.196 0-.335.178-.336.39a343.84 343.84 0 0 0 .006 3.882.44.44 0 0 0 .172.32c.098.062.261.067.379.034.156-.044.221-.19.222-.355v-1.307zm8.031-1.061V8.13h1.123c.267 0 .445-.157.45-.384.004-.227-.179-.395-.457-.396a162.2 162.2 0 0 0-1.425 0c-.343 0-.48.14-.484.487v3.651c0 .354.133.541.387.546.253.004.393-.18.394-.535v-1.454h1.037c.181 0 .316-.075.39-.24.113-.262-.073-.532-.374-.54-.344-.007-.688-.001-1.04-.001zM20.826 18.87h.574a.561.561 0 0 1 .533.33c.11.2.082.45-.071.62a126.84 126.84 0 0 1-1.702 1.983.554.554 0 0 1-.848 0 115.727 115.727 0 0 1-1.71-1.998c-.15-.178-.166-.39-.061-.604a.556.556 0 0 1 .512-.33c.188-.006.376 0 .594 0V16.236c0-.456.229-.684.684-.685h.783c.5 0 .716.215.716.708v2.38l-.004.23z'
        />
        <path
          transform={`scale(${size / 24})`}
          d='M8.8 11.25V8.11c.355.024.713.018 1.059.082.302.056.508.287.63.567.273.622.278 1.252-.02 1.865-.204.417-.551.626-1.02.626H8.8zM5.026 8.132c.252 0 .486-.008.719 0a.73.73 0 0 1 .697.702.717.717 0 0 1-.668.707c-.245.017-.49.003-.75.003l.002-1.412z'
        />
      </g>
    </svg>
  )
}

export default PDFIcon
