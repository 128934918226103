import { Box, styled, TableCell, TableCellProps, Tooltip } from '@mui/material'
import React, { PropsWithChildren } from 'react'

import { COLORS } from '../../../../constants/colors'
import { SortItem } from '../../../../hooks/useSort'
import { ArrowDown, ArrowUp } from './CustomTableComponents'

interface SortBtnProps {
  onClick: () => void
  sortItem?: SortItem
}

interface SortableTableCell extends TableCellProps {
  canSort: boolean
  isAlreadySort: boolean
}

const SortBtn = ({ sortItem, children, onClick }: PropsWithChildren<SortBtnProps>) => {
  const canSort = sortItem !== undefined
  const isAsc = sortItem?.sort === 'asc'
  const isDesc = sortItem?.sort === 'desc'
  const title = isAsc ? 'Click to sort descending' : isDesc ? 'Click to cancel sorting' : 'Click to sort ascending'

  const content = (
    <SortableTableCell canSort={canSort} onClick={onClick} isAlreadySort={isAsc || isDesc}>
      <Box display='flex' alignItems='center'>
        {children}
        {canSort && (
          <Box marginLeft={1.5}>
            <ArrowUp isActive={isAsc} />
            <Box marginBottom={0.5} />
            <ArrowDown isActive={isDesc} />
          </Box>
        )}
      </Box>
    </SortableTableCell>
  )

  return canSort ? (
    <Tooltip title={title} arrow placement='top'>
      {content}
    </Tooltip>
  ) : (
    content
  )
}

const SortableTableCell = styled(TableCell, {
  shouldForwardProp: (props) => !['canSort', 'isAlreadySort'].includes(props as string)
})<SortableTableCell>(({ canSort, isAlreadySort }) => ({
  cursor: canSort ? 'pointer' : 'auto',
  transition: 'all .3s',
  backgroundColor: isAlreadySort ? COLORS.purple100 : '',
  '&:hover': {
    backgroundColor: canSort ? COLORS.grey200 : ''
  }
}))

export default SortBtn
