import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { transformMasterData } from '../../../../utils/transformMasterData'
import { RoleService } from '../../services/role/RoleService'
import { RoleState } from './types'

const initialState: RoleState = {
  // Response
  roles: [],
  rolesMasterData: [],
  permissionsMasterData: [],
  roleInfo: undefined,
  // Store
  permissionNameList: []
}

const slice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    setPermissionNameList: (state, action: PayloadAction<string[]>) => {
      state.permissionNameList = action.payload
    },
    updatePermissionListByName: (state, action: PayloadAction<{ name: string; checked: boolean }>) => {
      const name = action.payload.name
      const checked = action.payload.checked
      // Don't have child
      const haveChildOrHaveName = state.permissionsMasterData.find((permission) => permission.name === name)?.name
      if (haveChildOrHaveName) {
        if (checked) {
          state.permissionNameList.push(name)
        } else {
          const findIndex = state.permissionNameList.indexOf(name)
          if (findIndex > -1) state.permissionNameList.splice(findIndex, 1)
        }
      } else {
        const currentPermissionGroup = state.permissionsMasterData.filter((permission) => name === permission.type)
        const isAllChecked = currentPermissionGroup.every((perm) => state.permissionNameList.includes(perm.name))
        if (isAllChecked) {
          state.permissionNameList = state.permissionNameList.filter(
            (perm) => !currentPermissionGroup.map((curPerm) => curPerm.name).includes(perm)
          )
        } else {
          state.permissionsMasterData.forEach((masterData) => {
            if (masterData.type === name) {
              if (!state.permissionNameList.includes(masterData.name)) state.permissionNameList.push(masterData.name)
            }
          })
        }
      }
    },
    resetRoleState: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(RoleService.getAllRoleAsyncThunk.fulfilled, (state, action) => {
        state.roles = action.payload
      })
      .addCase(RoleService.getRoleByIdAsyncThunk.fulfilled, (state, action) => {
        state.roleInfo = action.payload
      })
      .addCase(RoleService.getPermissionMasterDataAsyncThunk.fulfilled, (state, action) => {
        state.permissionsMasterData = action.payload
      })
      .addCase(RoleService.getRoleMasterDataAsyncThunk.fulfilled, (state, action) => {
        state.rolesMasterData = transformMasterData(action.payload)
      })
  }
})

export const { resetRoleState, updatePermissionListByName, setPermissionNameList } = slice.actions
export default slice.reducer
