import { IconButton, IconButtonProps, styled } from '@mui/material'
import React, { ReactNode } from 'react'

import { COLORS } from '../../../constants/colors'

interface CustomIconButtonProps {
  icon: ReactNode
  onClick?: () => void
  isDisabled?: boolean
}

interface StyledIconButtonProps extends IconButtonProps {
  isDisabled?: boolean
}

const CustomIconButton = ({ icon, isDisabled, onClick }: CustomIconButtonProps) => {
  return (
    <StyledIconButton onClick={isDisabled ? undefined : onClick} disabled={isDisabled}>
      {icon}
    </StyledIconButton>
  )
}

const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (props) => !['disabled'].includes(props as string)
})<StyledIconButtonProps>(({ disabled, theme }) => ({
  borderRadius: theme.spacing(2),
  padding: theme.spacing(1),
  backgroundColor: COLORS.grey200,
  cursor: disabled ? 'no-drop' : ''
}))

export default CustomIconButton
