import React from 'react'

const MedicalMenuIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <path
        d='M0.627218 7.01548C0.627218 5.71428 0.627218 4.41364 0.627218 3.11244C0.627218 2.76902 0.693003 2.68707 1.03754 2.6369C4.08761 2.18978 7.01057 1.31842 9.81199 0.0356129C9.91624 -0.0123319 10.0813 -0.0117744 10.1855 0.0367279C11.882 0.823357 13.6336 1.46002 15.4461 1.91048C16.619 2.20205 17.8149 2.40275 19.0012 2.64136C19.2727 2.69599 19.3625 2.78686 19.3625 3.05781C19.3625 5.47177 19.401 7.88685 19.3502 10.2997C19.2805 13.6068 17.8071 16.1752 15.0597 18.0099C13.5601 19.0112 11.9065 19.6545 10.1353 19.9885C9.9536 20.0225 9.74844 19.9745 9.56168 19.9327C7.49336 19.4705 5.59174 18.6471 3.95604 17.2801C2.07727 15.7097 0.969522 13.706 0.714188 11.2686C0.625545 10.4207 0.64673 9.56102 0.629448 8.70638C0.617741 8.1433 0.627218 7.57967 0.627218 7.01604V7.01548ZM9.99039 16.0068C13.3108 16.009 16.0164 13.3091 16.0164 9.99419C16.0164 6.68545 13.3248 3.99273 10.0105 3.98493C6.68833 3.97712 3.97945 6.66984 3.97443 9.98528C3.96942 13.2996 6.67217 16.0051 9.99095 16.0068H9.99039Z'
        fill='white'
      />
      <path
        d='M8.65687 11.3316C8.12614 11.3316 7.61937 11.3316 7.11205 11.3316C6.75469 11.3316 6.64988 11.2274 6.64933 10.8751C6.64877 10.2769 6.64821 9.67922 6.64933 9.08102C6.64988 8.77942 6.76528 8.66179 7.06355 8.66067C7.58481 8.65844 8.10662 8.66011 8.65632 8.66011C8.65632 8.53969 8.65632 8.43154 8.65632 8.32283C8.65632 7.89857 8.65464 7.47432 8.65687 7.05062C8.65855 6.78915 8.77952 6.65925 9.03486 6.65814C9.67598 6.65424 10.3165 6.65424 10.9577 6.65814C11.2108 6.65981 11.3306 6.78971 11.3323 7.05452C11.3345 7.58136 11.3329 8.10875 11.3329 8.66067H12.6993C13.3014 8.66067 13.3404 8.69914 13.3404 9.29399C13.3404 9.8225 13.3404 10.351 13.3404 10.8795C13.3404 11.2218 13.2323 11.3311 12.8944 11.3322C12.3927 11.3339 11.8915 11.3344 11.3897 11.3367C11.3775 11.3367 11.3658 11.3467 11.3329 11.3606C11.3329 11.8507 11.3329 12.3496 11.3329 12.848C11.3329 13.2427 11.2381 13.3358 10.8384 13.3358C10.253 13.3358 9.66818 13.337 9.0828 13.3358C8.77005 13.3353 8.65687 13.221 8.65632 12.9066C8.6552 12.3926 8.65632 11.8785 8.65632 11.3322L8.65687 11.3316Z'
        fill='white'
      />
    </svg>
  )
}

export default MedicalMenuIcon
