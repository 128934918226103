import { Box, Divider, Stack, styled, Typography } from '@mui/material'
import React from 'react'

import AttachFileButton from '../components/global/forms/AttachFileButton'
import AutoCompleteSelectField from '../components/global/forms/AutoCompleteSelectField'
import CustomDatePicker from '../components/global/forms/CustomDatePicker'
import CustomTextField from '../components/global/forms/CustomTextField'
import CustomTimePicker from '../components/global/forms/CustomTimePicker'
import ImagePicker from '../components/global/forms/ImagePicker'
import MultipleOptionsSelectInput from '../components/global/forms/MultipleOptionsSelectInput'
import EditIcon from '../components/global/icons/EditIcon'
import ImportIcon from '../components/global/icons/ImportIcon'
import CustomButton from '../components/global/interactions/CustomButton'
import CustomCheckbox from '../components/global/interactions/CustomCheckbox'
import CustomIconButton from '../components/global/interactions/CustomIconButton'
import CustomSwitch from '../components/global/interactions/CustomSwitch'
import RadioButton from '../components/global/interactions/RadioButton'
import TextButton from '../components/global/interactions/TextButton'
import BadgeStatus from '../components/global/ui/BadgeStatus'
import CustomTable, { CustomTableStyle } from '../components/global/ui/CustomTable/CustomTable'
import CustomTabs from '../components/global/ui/CustomTabs'
import { COLORS } from '../constants/colors'

const mockupFn = () => console.log('mock')

const renderFormsSection = () => (
  <Box padding={6}>
    <Typography variant='h1' marginBottom={2}>
      #Forms
    </Typography>
    <Stack spacing={4}>
      <Typography variant='h3'>CustomTextField</Typography>
      <Box width='40%'>
        <CustomTextField value='' label='eg. บริษัท กลุ่มพนักงานดี จำกัด' id='demo' />
      </Box>
      <Typography variant='h3' marginBottom={2}>
        AutoCompleteSelectField
      </Typography>
      <Box width='40%'>
        <AutoCompleteSelectField value='' label='เลือก' onChange={mockupFn} />
      </Box>
      <Typography variant='h3' marginBottom={2}>
        CustomDatePicker
      </Typography>
      <Box width='40%'>
        <CustomDatePicker value='' label='เลือก' onChange={mockupFn} />
      </Box>
      <Typography variant='h3' marginBottom={2}>
        CustomTimePicker
      </Typography>
      <Box width='40%'>
        <CustomTimePicker value='' label='เลือก' onChange={mockupFn} />
      </Box>
      <Typography variant='h3' marginBottom={2}>
        AttachFileButton
      </Typography>
      <Box width='40%'>
        <AttachFileButton label='อัพโหลดไฟล์' id='attach-demo' />
      </Box>
      <Typography variant='h3' marginBottom={2}>
        ImagePicker
      </Typography>
      <Box width='40%'>
        <ImagePicker id='picker' onSelectFile={mockupFn} isSelectNew />
        <ImagePicker id='picker-not' onSelectFile={mockupFn} />
      </Box>
      <Typography variant='h3' marginBottom={2}>
        MultipleOptionsSelectInput
      </Typography>
      <Box width='40%'>
        <MultipleOptionsSelectInput label='เลือก' value={['1']} onChange={mockupFn} />
      </Box>
    </Stack>
  </Box>
)

const renderInteractionsSection = () => (
  <Box padding={6}>
    <Typography variant='h1' marginBottom={2}>
      #Interactions
    </Typography>
    <Stack spacing={4}>
      <Typography variant='h3'>CustomButton</Typography>
      <CustomButton text='Click me!' />
      <CustomButton text='Click me!' variant='outlined' />

      <Typography variant='h3'>TextButton</Typography>
      <Box width='10%' display='flex'>
        <TextButton text='Click me!' />
      </Box>
      <Typography variant='h3'>CustomCheckbox</Typography>
      <Box width='40%' display='flex'>
        <CustomCheckbox label='Checked' value={true} onChange={mockupFn} />
        <Box marginRight={2} />
        <CustomCheckbox label='Unchecked' value={false} onChange={mockupFn} />
      </Box>
      <Typography variant='h3'>ConfirmDialog</Typography>
      <Typography variant='h4'>WIP</Typography>
      <Typography variant='h3'>CustomDialog</Typography>
      <Typography variant='h4'>WIP</Typography>
      <Typography variant='h3'>CustomIconButton</Typography>
      <Box>
        <CustomIconButton icon={<ImportIcon />} />
      </Box>
      <Typography variant='h3'>CustomSwitch</Typography>
      <CustomSwitch value onChange={mockupFn} />
      <CustomSwitch value={false} onChange={mockupFn} />
      <Typography variant='h3'>RadioButton</Typography>
      <Box width='40%' display='flex'>
        <RadioButton label='Checked' value='1' currentValue='1' onClick={mockupFn} />
        <Box marginRight={2} />
        <RadioButton label='Unchecked' value='1' currentValue='2' onClick={mockupFn} />
      </Box>
    </Stack>
  </Box>
)

const renderUISection = () => (
  <Box padding={6}>
    <Typography variant='h1' marginBottom={2}>
      #UIs
    </Typography>
    <Stack spacing={4}>
      <Typography variant='h3'>BadgeStatus</Typography>
      <BadgeStatus label='Badge' color='success' />
      <Typography variant='h3'>CustomTable</Typography>
      <CustomTable
        items={[
          {
            id: '1',
            employeeWithFamilyInfo: {
              name: 'มานพ บุญมา',
              child: [
                {
                  id: '10',
                  name: 'มานพ บุญมา',
                  age: '18',
                  gender: 'ชาย',
                  personalId: '1100401234168',
                  insuranceStatus: 'สมาย',
                  email: 'boydoubleb@gmail.com',
                  status: 'คู่สมรส'
                },
                {
                  id: '11',
                  name: 'มานพ บุญมา',
                  age: '18',
                  gender: 'ชาย',
                  personalId: '1100401234168',
                  insuranceStatus: 'สมาย',
                  email: 'boydoubleb@gmail.com',
                  status: 'บุตร'
                },
                {
                  id: '10',
                  name: 'มานพ บุญมา',
                  age: '18',
                  gender: 'ชาย',
                  personalId: '1100401234168',
                  insuranceStatus: 'สมาย',
                  email: 'boydoubleb@gmail.com',
                  status: 'คู่สมรส'
                },
                {
                  id: '11',
                  name: 'มานพ บุญมา',
                  age: '18',
                  gender: 'ชาย',
                  personalId: '1100401234168',
                  insuranceStatus: 'สมาย',
                  email: 'boydoubleb@gmail.com',
                  status: 'บุตร'
                },
                {
                  id: '10',
                  name: 'มานพ บุญมา',
                  age: '18',
                  gender: 'ชาย',
                  personalId: '1100401234168',
                  insuranceStatus: 'สมาย',
                  email: 'boydoubleb@gmail.com',
                  status: 'คู่สมรส'
                },
                {
                  id: '11',
                  name: 'มานพ บุญมา',
                  age: '18',
                  gender: 'ชาย',
                  personalId: '1100401234168',
                  insuranceStatus: 'สมาย',
                  email: 'boydoubleb@gmail.com',
                  status: 'บุตร'
                }
              ]
            },
            gender: 'ชาย',
            age: '55',
            personalId: '1100401234168',
            insuranceStatus: 'สมาย',
            email: 'boydoubleb@gmail.com',
            isActive: true
          },
          {
            id: '2',
            employeeWithFamilyInfo: {
              name: 'มานพ บุญมา',
              child: []
            },
            gender: 'ชาย',
            age: '55',
            personalId: '1100401234168',
            insuranceStatus: 'สมาย',
            email: 'boydoubleb@gmail.com',
            isActive: true
          },
          {
            id: '3',
            employeeWithFamilyInfo: {
              name: 'มานพ บุญมา',
              child: [
                {
                  id: '14',
                  name: 'มานพ บุญมา',
                  age: '18',
                  gender: 'ชาย',
                  personalId: '1100401234168',
                  insuranceStatus: 'สมาย',
                  email: 'boydoubleb@gmail.com',
                  status: 'บุตร'
                }
              ]
            },
            gender: 'ชาย',
            age: '55',
            personalId: '1100401234168',
            insuranceStatus: 'สมาย',
            email: 'boydoubleb@gmail.com',
            isActive: true
          }
        ]}
        headers={[
          {
            type: 'employee-with-family-info',
            headerName: 'ชื่อ-นามสกุล',
            key: 'employeeWithFamilyInfo'
          },
          {
            type: 'text',
            headerName: 'เพศ',
            key: 'gender'
          },
          {
            type: 'text',
            headerName: 'หมายเลขบัตรประชาชน',
            key: 'personalId'
          },
          {
            type: 'text',
            headerName: 'อายุ',
            key: 'age'
          },
          {
            type: 'text',
            headerName: 'สถานะประกัน',
            key: 'insuranceStatus'
          },
          {
            type: 'text',
            headerName: 'อีเมล',
            key: 'email'
          },
          {
            type: 'isActive',
            headerName: 'สถานะ',
            key: 'isActive'
          }
        ]}
        options={{
          manage: {
            items: [
              {
                button: <CustomIconButton icon={<EditIcon color={COLORS.grey750} />} />,
                onClick: () => console.log('mock')
              }
            ]
          }
        }}
        // options={{
        //   dragAndDrop: {
        //     enable: true,
        //     onDragEnd: () => console.log('')
        //   }
        // }}
        style={CustomTableStyle.Secondary}
      />
      <Typography variant='h3'>CustomTabs</Typography>
      <CustomTabs tabs={[]} value={''} onChange={mockupFn} />
    </Stack>
  </Box>
)

const ShowcasePage = () => {
  return (
    <StyledCard>
      <Box padding={4}>
        <Typography variant='h1' textAlign='center' marginBottom={2}>
          Global Components
        </Typography>
      </Box>
      <Divider />
      {renderFormsSection()}
      <Divider />
      {renderInteractionsSection()}
      <Divider />
      {renderUISection()}
      <Divider />
    </StyledCard>
  )
}

const StyledCard = styled(Box)(({ theme }) => ({
  minHeight: '90vh',

  margin: theme.spacing(6),
  borderRadius: theme.spacing(2),
  boxShadow: 'rgba(0, 0, 0, 0.2) 0px 18px 50px -10px'
}))

export default ShowcasePage
