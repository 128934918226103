import { Box, CircularProgress, styled } from '@mui/material'
import React from 'react'

const LoadingWithBackdrop = () => {
  return (
    <Backdrop>
      <CircularProgress size={50} />
    </Backdrop>
  )
}

const Backdrop = styled(Box)(() => ({
  zIndex: 10000,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}))

export default LoadingWithBackdrop
