import { Box, Typography } from '@mui/material'
import { format, isValid, parse } from 'date-fns'
import _ from 'lodash'
import React from 'react'

import { COLORS } from '../../../constants/colors'
import { DATE_FORMAT, TIME_FORMAT } from '../../../constants/date'

interface CardDetailBarProps {
  createdDetail?: Detail
  updatedDetail?: Detail
  direction?: 'row' | 'column'
}

interface Detail {
  username: string
  date: string
}

const CardDetailBar = ({ createdDetail, updatedDetail }: CardDetailBarProps) => {
  const parsedCreatedAt = createdDetail ? parse(createdDetail.date, `${DATE_FORMAT} ${TIME_FORMAT}`, new Date()) : ''
  const parsedUpdatedAt = updatedDetail ? parse(updatedDetail.date, `${DATE_FORMAT} ${TIME_FORMAT}`, new Date()) : ''

  // TODO: For Showing example
  if (!isValid(parsedCreatedAt))
    return (
      <Box
        paddingX={6}
        paddingY={3}
        borderTop={1}
        borderColor={COLORS.grey100}
        alignItems='center'
        display='flex'
        justifyContent='space-between'
      >
        <Typography variant='body2'>
          Created by{' '}
          <Typography variant='body2' fontWeight={600} component='span'>
            Kitisak{' '}
          </Typography>
          Create Date{' '}
          <Typography variant='body2' fontWeight={600} component='span'>
            09/09/2022 | 12:00:09{' '}
          </Typography>
        </Typography>
        <Typography variant='body2'>
          Updated by{' '}
          <Typography variant='body2' fontWeight={600} component='span'>
            Kitisak{' '}
          </Typography>
          Update Date{' '}
          <Typography variant='body2' fontWeight={600} component='span'>
            09/09/2022 | 12:00:09{' '}
          </Typography>
        </Typography>
      </Box>
    )

  return (
    <Box
      paddingX={6}
      paddingY={3}
      borderTop={1}
      borderColor={COLORS.grey100}
      alignItems='center'
      display='flex'
      justifyContent='space-between'
    >
      <Typography variant='body2'>
        Created by{' '}
        <Typography variant='body2' fontWeight={600} component='span'>
          {createdDetail?.username}{' '}
        </Typography>
        Create Date{' '}
        <Typography variant='body2' fontWeight={600} component='span'>
          {format(parsedCreatedAt as Date, DATE_FORMAT)} | {format(parsedCreatedAt as Date, 'HH:mm:ss')}{' '}
        </Typography>
      </Typography>
      <Typography variant='body2'>
        Updated by{' '}
        <Typography variant='body2' fontWeight={600} component='span'>
          {updatedDetail?.username}{' '}
        </Typography>
        Update Date{' '}
        <Typography variant='body2' fontWeight={600} component='span'>
          {format(parsedUpdatedAt as Date, DATE_FORMAT)} | {format(parsedUpdatedAt as Date, 'HH:mm:ss')}{' '}
        </Typography>
      </Typography>
    </Box>
  )
}

const areEqual = (prevProps: Readonly<CardDetailBarProps>, nextProps: Readonly<CardDetailBarProps>) =>
  _.isEqual(prevProps.createdDetail, nextProps.createdDetail) &&
  _.isEqual(prevProps.updatedDetail, nextProps.updatedDetail)

export default React.memo(CardDetailBar, areEqual)
