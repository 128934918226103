import { Box, Collapse } from '@mui/material'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { SidebarType } from '../../../../configs/routes'
import { currentUserSelector } from '../../../../stores/current-user/selector'
import SidebarButton from './SidebarButton'

interface SidebarGroupProps {
  sidebar: SidebarType
}

const SidebarGroup = ({ sidebar }: SidebarGroupProps) => {
  const [showAll, setShowAll] = useState(false)
  const currentUser = useSelector(currentUserSelector)

  return (
    <Box>
      <SidebarButton
        title={sidebar.title}
        path={sidebar.path}
        icon={sidebar.icon}
        isButton={Boolean(sidebar.path)}
        onClick={() => setShowAll((prevShowAll) => !prevShowAll)}
        isShowAll={showAll}
      />
      <Collapse in={showAll}>
        {sidebar.child?.map(
          (item) =>
            currentUser?.permissions.includes(item.permissions) && (
              <SidebarButton
                key={item.title}
                title={item.title}
                path={item.path}
                isChild
                isButton={Boolean(item.path)}
              />
            )
        )}
      </Collapse>
    </Box>
  )
}

export default SidebarGroup
