import { Box, BoxProps, styled, Typography } from '@mui/material'
import React from 'react'

import { COLORS } from '../../../constants/colors'
import { BannerType } from '../stores/plan/types.d'

interface BannerLabelProps {
  bannerType: BannerType
}

interface StyledBannerLabelProps extends BoxProps {
  bannerType: BannerType
}

const BannerLabel = ({ bannerType }: BannerLabelProps) => {
  const getBannerLabelText = () => {
    if (bannerType === BannerType.Recommend) return 'แนะนำ'
    if (bannerType === BannerType.Popular) return 'ยอดนิยม'
    if (bannerType === BannerType.GreatValue) return 'สุดคุ้ม'
    return ''
  }
  return (
    <StyledBannerLabel bannerType={bannerType}>
      <Typography color={COLORS.white} variant='body1' fontWeight={500}>
        {getBannerLabelText()}
      </Typography>
    </StyledBannerLabel>
  )
}

const StyledBannerLabel = styled(Box, {
  shouldForwardProp: (props) => !['bannerType'].includes(props as string)
})<StyledBannerLabelProps>(({ theme, bannerType }) => ({
  backgroundColor:
    bannerType === BannerType.Popular
      ? COLORS.pink400
      : bannerType === BannerType.GreatValue
      ? COLORS.purple500
      : theme.palette.success.main,
  textAlign: 'center',
  padding: theme.spacing(0.5, 0),
  transform: 'rotate(45deg)',
  transformOrigin: '',
  position: 'absolute',
  right: -27.5,
  top: 10,
  width: '100px'
}))

export default BannerLabel
