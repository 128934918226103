import React from 'react'

const SearchIcon = () => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'>
      <g fill='none' fillRule='evenodd'>
        <path d='M0 0h16v16H0z' />
        <circle stroke='#585858' strokeLinecap='round' strokeLinejoin='round' cx='7.25' cy='7.25' r='5.25' />
        <path stroke='#585858' strokeLinecap='round' strokeLinejoin='round' d='M10.962 10.963 14 14' />
      </g>
    </svg>
  )
}

export default SearchIcon
