import React from 'react'

const MenuIcon = () => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'>
      <g transform='translate(1 3.5)' fill='#DDD' fillRule='nonzero'>
        <rect y='3.938' width='14' height='1.125' rx='.563' />
        <rect width='14' height='1.125' rx='.563' />
        <rect y='7.875' width='14' height='1.125' rx='.563' />
      </g>
    </svg>
  )
}

export default MenuIcon
