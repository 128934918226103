import {
  Box,
  CircularProgress,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import React, { ReactNode, useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

import { COLORS } from '../../../../constants/colors'
import { SortItems } from '../../../../hooks/useSort'
import { fontFamily } from '../../../../themes/themes'
import MenuIcon from '../../icons/MenuIcon'
import CustomCheckbox from '../../interactions/CustomCheckbox'
import { MinusInTableIcon, PlusInTableIcon } from './CustomTableComponents'
import SortBtn from './SortBtn'
import { TableHeader, TableItem } from './types'
import { renderCellByType } from './utils'

export enum CustomTableStyle {
  Primary,
  Secondary,
  Tertiary
}

interface Options {
  prefix?: {
    checkbox: {
      enabled: boolean
      key: string
      onClick?: (id: string, event: any) => void
    }
  }
  dragAndDrop?: {
    enable: boolean
    onDragEnd: any
  }
  addTableRow?: {
    addRowButton: ReactNode
    removeRowButton: ReactNode
    onAddRow: () => void
    onDeleteRow: (index: number) => void
  }
  manage?: {
    items: { button: ReactNode; onClick: (id: string, type?: string) => void }[]
  }
  export?: {
    enable: boolean
    onExport: {
      onClick: () => void
      isLoading: boolean
    }
    onImport: {
      onClick: (file: File) => void
      isLoading: boolean
    }
  }
  includeOrder?: {
    maxItems: number
    currentPage: number
  }
}

interface CustomTableProps {
  items: TableItem[]
  headers: TableHeader[]
  options?: Options
  isLoading?: boolean
  style?: CustomTableStyle
  sortState?: SortItems
  onChangeSortState?: (headerName: string) => void
}

interface ControlledEmployeeWithFamilyInfoOpen {
  index: number
  isOpen: boolean
}

const CustomTable = ({
  options,
  items,
  headers,
  isLoading,
  sortState,
  onChangeSortState,
  style = CustomTableStyle.Primary
}: CustomTableProps) => {
  const [controlledEmployeeWithFamilyInfoOpen, setControlledEmployeeWithFamilyInfoOpen] = useState<
    ControlledEmployeeWithFamilyInfoOpen[]
  >([])

  // Variable
  const headerKeys = headers.map((header) => header.key)
  const StyledContentRow = (() => {
    switch (style) {
      case CustomTableStyle.Primary: {
        return PrimaryContentRow
      }
      case CustomTableStyle.Secondary: {
        return SecondaryContentRow
      }
      case CustomTableStyle.Tertiary: {
        return TertiaryContentRow
      }
    }
  })()

  // Functions
  const onToggleControlledState = (index: number) =>
    setControlledEmployeeWithFamilyInfoOpen((prevControlled) => {
      const newPrevControlled = prevControlled
      const findCurrentPrevControlled = newPrevControlled.find((control) => control.index === index)!
      const filteredPrevControlled = newPrevControlled.filter((control) => control.index !== index)
      return [
        ...filteredPrevControlled,
        { index: findCurrentPrevControlled.index, isOpen: !findCurrentPrevControlled.isOpen }
      ]
    })

  // Render fn()
  const renderEmptyState = () => <EmptyBox>ไม่มีข้อมูล</EmptyBox>

  const renderHeader = () => (
    <TableHead>
      <StyledTableRow>
        {options?.includeOrder && (
          <TableCell>
            <Typography
              variant={style === CustomTableStyle.Tertiary ? 'h6' : 'body1'}
              color={style === CustomTableStyle.Tertiary ? 'black' : COLORS.grey750}
            >
              ลำดับ
            </Typography>
          </TableCell>
        )}
        {options?.prefix?.checkbox.enabled && (
          <TableCell>
            <CustomCheckbox
              noBorder
              onChange={(event) => (options as any).prefix.switch.onClick('', event)}
              label=''
              value
            />
          </TableCell>
        )}
        {options?.dragAndDrop && <TableCell></TableCell>}
        {/* MAP RENDER HEADER FROM headers */}
        {headers.map((header) => (
          <SortBtn
            sortItem={sortState?.findCurrentSortItem(header.key)}
            key={`${header.headerName}`}
            onClick={() => onChangeSortState && onChangeSortState(header.key)}
          >
            <Typography
              variant={style === CustomTableStyle.Tertiary ? 'h6' : 'body1'}
              color={style === CustomTableStyle.Tertiary ? 'black' : COLORS.grey750}
              component='div'
              display='flex'
              alignItems='center'
            >
              {header.headerName}
            </Typography>
          </SortBtn>
        ))}
        {options?.manage && (
          <TableCell>
            <Typography
              variant={style === CustomTableStyle.Tertiary ? 'h6' : 'body1'}
              color={style === CustomTableStyle.Tertiary ? 'black' : COLORS.grey750}
              textAlign='right'
            >
              จัดการ
            </Typography>
          </TableCell>
        )}
      </StyledTableRow>
    </TableHead>
  )

  useEffect(() => {
    // Prepared Data
    const filteredItemWithChild = items
      .map((item, index) => ({ ...item, index: index }))
      .filter((item) => (item.employeeWithFamilyInfo ? item.employeeWithFamilyInfo.child.length > 0 : false))
    const initialControlledEmployeeWithFamilyInfoOpen = filteredItemWithChild.map((child) => ({
      index: child.index,
      isOpen: false
    }))
    setControlledEmployeeWithFamilyInfoOpen(initialControlledEmployeeWithFamilyInfoOpen)
  }, [items])

  return (
    <Box>
      <TableContainer component={Box} maxWidth='100%'>
        <StyledTable aria-label='Custom Table'>
          {renderHeader()}
          <DragDropContext onDragEnd={options?.dragAndDrop?.onDragEnd}>
            <Droppable droppableId='droppable'>
              {/* Droppable Start Here */}
              {(provided) => (
                <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                  {!isLoading &&
                    items.length > 0 &&
                    items.map((item, index) => (
                      // Draggable Start Here
                      <Draggable key={`${item.id}-row`} draggableId={item.id} index={index}>
                        {(providedDrag) => (
                          <StyledContentRow
                            ref={providedDrag.innerRef}
                            {...providedDrag.draggableProps}
                            key={index}
                            sx={{
                              verticalAlign: controlledEmployeeWithFamilyInfoOpen.find(
                                (controlState) => controlState.index === index
                              )?.isOpen
                                ? 'top'
                                : ''
                            }}
                          >
                            {/* Order */}
                            {options?.includeOrder && (
                              <TableCell>
                                {index + 1 + (options.includeOrder.currentPage - 1) * options.includeOrder.maxItems}
                              </TableCell>
                            )}
                            {/* Checkbox */}
                            {options?.prefix?.checkbox.enabled && (
                              <TableCell>
                                <CustomCheckbox
                                  noBorder
                                  onChange={(event) => (options as any).prefix.switch.onClick(item.id, event)}
                                  label=''
                                  value={item[options?.prefix?.checkbox?.key as any] === 'true'}
                                />
                              </TableCell>
                            )}
                            {/* Dragable Item */}
                            <TableCell sx={{ display: options?.dragAndDrop ? '' : 'none', cursor: 'grab' }}>
                              <Box {...providedDrag.dragHandleProps}>
                                <MenuIcon {...providedDrag.dragHandleProps} />
                              </Box>
                            </TableCell>
                            {/* RENDER BODY */}
                            {headerKeys.map((key) => {
                              const currentHeaders = headers.find((header) => header.key === key)!
                              const isHaveEmployeeWithFamilyInfo = headers.find(
                                (header) => header.type === 'employee-with-family-info'
                              )
                              const controlledOpen = controlledEmployeeWithFamilyInfoOpen.find(
                                (controlState) => controlState.index === index
                              )
                              const employeeWithFamilyInfoType = currentHeaders.type === 'employee-with-family-info'
                              return (
                                <TableCell key={`${item.id}${key}-cell`} sx={{ position: 'relative' }}>
                                  {renderCellByType(
                                    // Type
                                    currentHeaders.type,
                                    // Cell key
                                    item[key],
                                    // Style
                                    style,
                                    // Text max length
                                    currentHeaders.maxLength,
                                    // Cell Prefix
                                    employeeWithFamilyInfoType &&
                                      item.employeeWithFamilyInfo &&
                                      item.employeeWithFamilyInfo.child.length > 0 && (
                                        <ShowContentBox onClick={() => onToggleControlledState(index)}>
                                          {controlledOpen && !controlledOpen.isOpen ? (
                                            <PlusInTableIcon />
                                          ) : (
                                            <MinusInTableIcon />
                                          )}
                                        </ShowContentBox>
                                      )
                                  )}
                                  {isHaveEmployeeWithFamilyInfo && (
                                    <IncreaseableBox
                                      maxHeight={controlledOpen?.isOpen ? '200px' : '0px'}
                                      overflow='hidden'
                                    >
                                      {item.employeeWithFamilyInfo?.child.map((employeeInfo, childIndex) => {
                                        return (
                                          <Typography
                                            key={employeeInfo.id}
                                            color={COLORS.grey700}
                                            marginTop={childIndex === 0 ? (key === 'isActive' ? 3.75 : 1.75) : 0}
                                          >
                                            {key === 'employeeWithFamilyInfo' && employeeInfo.name}
                                            {key === 'isActive' && employeeInfo.status}
                                            {(!['employeeWithFamilyInfo', 'isActive', 'imgUrl'].includes(key) &&
                                              (employeeInfo as any)[key]) ??
                                              '-'}
                                          </Typography>
                                        )
                                      })}
                                    </IncreaseableBox>
                                  )}
                                </TableCell>
                              )
                            })}
                            {/* Manage Items */}
                            {options?.manage && options?.manage.items.length > 0 && !item.isActionDisabled && (
                              <TableCell>
                                <Box display='flex' justifyContent='flex-end'>
                                  {options?.manage?.items.map((btnItem, index) => (
                                    <Box
                                      onClick={() => btnItem.onClick(item.id, item?.service)}
                                      key={`${index}-manage-items(${Math.random()})`}
                                      sx={{
                                        marginLeft: index != 0 ? '8px' : ''
                                      }}
                                    >
                                      {btnItem.button}
                                    </Box>
                                  ))}
                                </Box>
                              </TableCell>
                            )}
                            {/* Add and remove row */}
                            {options?.addTableRow && items.length - 1 === index ? (
                              <TableCell>
                                <Box display='flex' alignItems='center' justifyContent='flex-end'>
                                  <Box marginRight={4} onClick={options.addTableRow.onAddRow}>
                                    {options.addTableRow.addRowButton}
                                  </Box>
                                  <Box onClick={() => options?.addTableRow?.onDeleteRow(index)}>
                                    {options.addTableRow.removeRowButton}
                                  </Box>
                                </Box>
                              </TableCell>
                            ) : (
                              options?.addTableRow && (
                                <TableCell>
                                  <Box display='flex' justifyContent='flex-end'>
                                    <Box onClick={() => options?.addTableRow && options.addTableRow.onDeleteRow(index)}>
                                      {options?.addTableRow?.removeRowButton}
                                    </Box>
                                  </Box>
                                </TableCell>
                              )
                            )}
                          </StyledContentRow>
                        )}
                      </Draggable>
                    ))}
                  {/* Need this for render placeholder when using dragable */}
                  {provided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        </StyledTable>
      </TableContainer>
      {isLoading && (
        <Box display='flex' alignItems='center' justifyContent='center' marginTop={2} sx={{ height: '275px' }}>
          <CircularProgress size={36} />
        </Box>
      )}
      {!isLoading && items.length < 1 && renderEmptyState()}
    </Box>
  )
}

const ShowContentBox = styled(Box)(() => ({
  position: 'absolute',
  top: '4px',
  left: '-18px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer'
}))

const IncreaseableBox = styled(Box)(() => ({
  transition: 'all .7s'
}))

const EmptyBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: theme.spacing(1),
  padding: theme.spacing(4, 0)
}))

const StyledTable = styled(Table)(({ theme }) => ({
  '&.MuiTable-root ': {
    borderSpacing: `${theme.spacing(0, 2)}  !important`,
    borderCollapse: 'separate !important'
  }
}))

const PrimaryContentRow = styled(TableRow)(({ theme }) => ({
  '& td': {
    padding: theme.spacing(4, 8),
    fontFamily: fontFamily,
    borderTop: '1px',
    borderBottom: '1px',
    borderLeft: '0',
    borderRight: '0',
    borderColor: COLORS.grey350,
    borderStyle: 'solid',
    fontWeight: 'normal',
    lineHeight: 1.5,
    whiteSpace: 'nowrap',
    verticalAlign: 'top'
  },
  '& td:first-of-type': {
    borderLeft: '1px',
    borderTop: '1px',
    borderBottom: '1px',
    borderColor: COLORS.grey350,
    borderStyle: 'solid',
    borderRight: '0',
    borderRadius: theme.spacing(2, 0, 0, 2)
  },
  '& td:last-of-type': {
    borderRight: '1px',
    borderTop: '1px',
    borderBottom: '1px',
    borderColor: COLORS.grey350,
    borderStyle: 'solid',
    borderLeft: '0',
    borderRadius: theme.spacing(0, 2, 2, 0)
  }
}))

const SecondaryContentRow = styled(TableRow)(({ theme }) => ({
  '& td': {
    fontFamily: fontFamily,
    borderBottom: `1px solid ${COLORS.grey100}`,
    fontWeight: 'normal',
    lineHeight: 1.5,
    whiteSpace: 'nowrap',
    padding: theme.spacing(3, 8),
    transition: 'all .7s'
  }
}))

const TertiaryContentRow = styled(TableRow)(({ theme }) => ({
  '& td': {
    fontFamily: fontFamily,
    border: 'none',
    fontWeight: 'normal',
    lineHeight: 1.5,
    whiteSpace: 'nowrap',
    verticalAlign: 'top',
    padding: theme.spacing(3, 8)
  },
  '&:nth-of-type(odd)': {
    backgroundColor: COLORS.purple100
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '& .MuiTableCell-root': {
    border: 'none',
    padding: theme.spacing(3, 8),
    whiteSpace: 'nowrap'
  },
  '& .MuiTableCell-root:first-of-type': {
    borderRadius: theme.spacing(2, 0, 0, 2)
  },
  '& .MuiTableCell-root:last-of-type': {
    borderRadius: theme.spacing(0, 2, 2, 0)
  }
}))

export default CustomTable
