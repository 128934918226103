import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { UserService } from '../../services/user/UserService'
import { UserState } from './types'

const initialState: UserState = {
  isActive: true,
  userImage: null,
  users: [],
  userInfo: undefined
}

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserImage: (state, action: PayloadAction<File>) => {
      state.userImage = action.payload
    },
    toggleUserActive: (state) => {
      state.isActive = !state.isActive
    },
    resetUserState: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(UserService.getAllUserAsyncThunk.fulfilled, (state, action) => {
      state.users = action.payload
    })
    builder.addCase(UserService.getUserByIdAsyncThunk.fulfilled, (state, action) => {
      state.userInfo = action.payload
      state.isActive = action.payload.isActive
    })
  }
})

export const { setUserImage, toggleUserActive, resetUserState } = slice.actions
export default slice.reducer
