import { createAsyncThunk } from '@reduxjs/toolkit'

import { CUSTOMER_URLS } from '../../../../configs/api'
import { AxiosService } from '../../../../services/api'
import { createThunkName } from '../../../../utils/createThunkname'
import {
  ApproveDocumentDto,
  ApproveInsurerDocumentDto,
  CancelDocumentDto,
  CancelInsurerDocumentDto,
  ConfirmPaymentDto,
  ConfirmQuotationDto,
  ConfirmUploadDocumentDto,
  ConfirmUploadInsurerDocumentDto,
  ConfirmUploadQuotationDto,
  GetBanksMasterDataResponse,
  GetCanAccessPaymentResponse,
  GetCustomerDocumentMasterDataResponse,
  GetDocumentsResponse,
  GetFrontChequeResponse,
  GetInsurerDocumentsMasterDataResponse,
  GetInsurerDocumentsResponse,
  GetInsurerInfoByCustomerIdResponse,
  GetInvoiceResponse,
  GetOriginalQuotationFileResponse,
  GetPayInResponse,
  GetPaymentBanksMasterDataResponse,
  GetPaymentMethodsMasterDataResponse,
  GetQuotationHistoryDetailResponse,
  GetQuotationHistoryResponse,
  GetSignedQuotationResponse,
  GetSlipResponse,
  GeyPaymentResponse,
  RejectPaymentDto,
  RejectSignedQuotationDto,
  SavePaymentDto,
  UpdateOriginalInsurerDocumentDto,
  UpdateQuotationDto,
  UploadFrontChequeDto,
  UploadInvoiceDto,
  UploadInvoiceFileDto,
  UploadInvoicePaymentDto,
  UploadOriginalInsurerDocumentDto,
  UploadPayInDto,
  UploadSignedQuotationDto,
  UploadSlipDto
} from './types'

const PREFIX = 'order'

const PaymentService = {
  getPaymentAsyncThunk: createAsyncThunk(createThunkName(PREFIX, 'getPayment'), async (customerId: string) => {
    try {
      const result = await AxiosService.get<GeyPaymentResponse>(CUSTOMER_URLS.orderTab.getPayment(customerId))
      return result.data.payment
    } catch (error) {
      throw error
    }
  }),
  getSlipAsyncThunk: createAsyncThunk(createThunkName(PREFIX, 'getSlip'), async (customerId: string) => {
    try {
      const result = await AxiosService.get<GetSlipResponse>(CUSTOMER_URLS.orderTab.getSlip(customerId))
      return result.data.slipFileUrl
    } catch (error) {
      throw error
    }
  }),
  getPayInAsyncThunk: createAsyncThunk(createThunkName(PREFIX, 'getPayIn'), async (customerId: string) => {
    try {
      const result = await AxiosService.get<GetPayInResponse>(CUSTOMER_URLS.orderTab.getPayIn(customerId))
      return result.data.payInFileUrl
    } catch (error) {
      throw error
    }
  }),
  getFrontChequeAsyncThunk: createAsyncThunk(createThunkName(PREFIX, 'getFrontCheque'), async (customerId: string) => {
    try {
      const result = await AxiosService.get<GetFrontChequeResponse>(CUSTOMER_URLS.orderTab.getFrontCheque(customerId))
      return result.data.frontChequeFileUrl
    } catch (error) {
      throw error
    }
  }),
  getInvoiceAsyncThunk: createAsyncThunk(createThunkName(PREFIX, 'getInvoice'), async (customerId: string) => {
    try {
      const result = await AxiosService.get<GetInvoiceResponse>(CUSTOMER_URLS.orderTab.getInvoice(customerId))
      return result.data.paymentInvoice
    } catch (error) {
      throw error
    }
  }),
  savePayment: async (dto: SavePaymentDto) => {
    try {
      await AxiosService.post(CUSTOMER_URLS.orderTab.savePayment, dto)
    } catch (error) {
      throw error
    }
  },
  rejectPayment: async (dto: RejectPaymentDto) => {
    try {
      await AxiosService.post(CUSTOMER_URLS.orderTab.rejectPayment, dto)
    } catch (error) {
      throw error
    }
  },
  confirmPayment: async (dto: ConfirmPaymentDto) => {
    try {
      await AxiosService.put(CUSTOMER_URLS.orderTab.confirmPayment, dto)
    } catch (error) {
      throw error
    }
  },
  uploadSlip: async (dto: UploadSlipDto) => {
    try {
      await AxiosService.put(CUSTOMER_URLS.orderTab.uploadSlip, dto)
    } catch (error) {
      throw error
    }
  },
  uploadPayIn: async (dto: UploadPayInDto) => {
    try {
      await AxiosService.put(CUSTOMER_URLS.orderTab.uploadPayIn, dto)
    } catch (error) {
      throw error
    }
  },
  uploadFrontCheque: async (dto: UploadFrontChequeDto) => {
    try {
      await AxiosService.put(CUSTOMER_URLS.orderTab.uploadFrontCheque, dto)
    } catch (error) {
      throw error
    }
  },
  uploadInvoice: async (dto: UploadInvoiceDto) => {
    try {
      await AxiosService.put(CUSTOMER_URLS.orderTab.uploadInvoice, dto)
    } catch (error) {
      throw error
    }
  },
  uploadInvoiceFile: async (dto: UploadInvoiceFileDto) => {
    try {
      await AxiosService.put(CUSTOMER_URLS.orderTab.updateInvoiceFile, dto)
    } catch (error) {
      throw error
    }
  },
  uploadInvoicePayment: async (dto: UploadInvoicePaymentDto) => {
    try {
      await AxiosService.put(CUSTOMER_URLS.orderTab.updateInvoicePayment, dto)
    } catch (error) {
      throw error
    }
  }
}

export const OrderService = {
  ...PaymentService,
  getCustomerDocumentMasterDataAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getCustomerDocumentMasterData'),
    async () => {
      try {
        const result = await AxiosService.get<GetCustomerDocumentMasterDataResponse>(
          CUSTOMER_URLS.masterData.getCustomerDocumentMasterData
        )
        return result.data.customerDocuments
      } catch (error) {
        throw error
      }
    }
  ),
  getPaymentMethodMasterDataAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getPaymentMethodMasterData'),
    async () => {
      try {
        const result = await AxiosService.get<GetPaymentMethodsMasterDataResponse>(
          CUSTOMER_URLS.masterData.getPaymentMethodMasterData
        )
        return result.data.paymentMethods
      } catch (error) {
        throw error
      }
    }
  ),
  getBanksMasterDataAsyncThunk: createAsyncThunk(createThunkName(PREFIX, 'getBanksMasterData'), async () => {
    try {
      const result = await AxiosService.get<GetBanksMasterDataResponse>(CUSTOMER_URLS.masterData.getBankMasterData)
      return result.data.banks
    } catch (error) {
      throw error
    }
  }),
  getInsurerDocumentsMasterDataAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getInsurerDocumentsMasterData'),
    async (insurerId: string) => {
      try {
        const result = await AxiosService.get<GetInsurerDocumentsMasterDataResponse>(
          CUSTOMER_URLS.masterData.getInsurerDocumentsMasterData(insurerId)
        )
        return result.data.insurerDocuments
      } catch (error) {
        throw error
      }
    }
  ),
  // Customer Document
  confirmUploadDocument: async (dto: ConfirmUploadDocumentDto) => {
    try {
      await AxiosService.post(CUSTOMER_URLS.orderTab.confirmUploadDocument, dto)
    } catch (error) {
      throw error
    }
  },
  getDocumentsAsyncThunk: createAsyncThunk(createThunkName(PREFIX, 'getDocuments'), async (customerId: string) => {
    try {
      const result = await AxiosService.get<GetDocumentsResponse>(CUSTOMER_URLS.orderTab.getDocuments(customerId))
      return result.data.customerDocuments
    } catch (error) {
      throw error
    }
  }),
  approveDocument: async (dto: ApproveDocumentDto) => {
    try {
      await AxiosService.put(CUSTOMER_URLS.orderTab.approveDocument, dto)
    } catch (error) {
      throw error
    }
  },
  cancelDocument: async (dto: CancelDocumentDto) => {
    try {
      await AxiosService.post(CUSTOMER_URLS.orderTab.cancelDocument, dto)
    } catch (error) {
      throw error
    }
  },
  // Quotation
  confirmUploadQuotation: async (dto: ConfirmUploadQuotationDto) => {
    try {
      await AxiosService.post(CUSTOMER_URLS.orderTab.confirmUploadQuotation, dto)
    } catch (error) {
      throw error
    }
  },
  getOriginalQuotationFileAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getOriginalQuotation'),
    async (customerId: string) => {
      try {
        const result = await AxiosService.get<GetOriginalQuotationFileResponse>(
          CUSTOMER_URLS.orderTab.getOriginalQuotation(customerId)
        )
        return result.data.quotation
      } catch (error) {
        throw error
      }
    }
  ),
  getQuotationHistorysAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getQuotationHistorys'),
    async (customerId: string) => {
      try {
        const result = await AxiosService.get<GetQuotationHistoryResponse>(
          CUSTOMER_URLS.orderTab.getQuotationHistory(customerId)
        )
        return result.data.quotationHistories
      } catch (error) {
        throw error
      }
    }
  ),
  getSignedQuotationsAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getSignedQuotations'),
    async (customerId: string) => {
      try {
        const result = await AxiosService.get<GetSignedQuotationResponse>(
          CUSTOMER_URLS.orderTab.getSignedQuotation(customerId)
        )
        return result.data.signedQuotation
      } catch (error) {
        throw error
      }
    }
  ),
  getQuotationDetailAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getQuotationDetail'),
    async (historyId: string) => {
      try {
        const result = await AxiosService.get<GetQuotationHistoryDetailResponse>(
          CUSTOMER_URLS.orderTab.getQuotationDetail(historyId)
        )
        return result.data.quotationHistoryDetail
      } catch (error) {
        throw error
      }
    }
  ),
  updateQuotation: async (dto: UpdateQuotationDto) => {
    try {
      await AxiosService.put(CUSTOMER_URLS.orderTab.updateQuotation, dto)
    } catch (error) {
      throw error
    }
  },
  uploadSignedQuotation: async (dto: UploadSignedQuotationDto) => {
    try {
      await AxiosService.post(CUSTOMER_URLS.orderTab.uploadSignedQuotation, dto)
    } catch (error) {
      throw error
    }
  },
  rejectQuotationDocument: async (dto: RejectSignedQuotationDto) => {
    try {
      await AxiosService.post(CUSTOMER_URLS.orderTab.rejectQuotation, dto)
    } catch (error) {
      throw error
    }
  },
  confirmQuotationDocument: async (dto: ConfirmQuotationDto) => {
    try {
      await AxiosService.put(CUSTOMER_URLS.orderTab.confirmQuotation, dto)
    } catch (error) {
      throw error
    }
  },
  // Insurer Documents
  getInsurerInfoByCustomerIdAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getInsurerInfoByCustomerId'),
    async (customerId: string) => {
      try {
        const result = await AxiosService.get<GetInsurerInfoByCustomerIdResponse>(
          CUSTOMER_URLS.orderTab.getInsurerInfoByCustomerId(customerId)
        )
        return result.data.insurerInfo
      } catch (error) {
        throw error
      }
    }
  ),
  getInsurerDocumentsAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getInsurerDocuments'),
    async (customerId: string) => {
      try {
        const result = await AxiosService.get<GetInsurerDocumentsResponse>(
          CUSTOMER_URLS.orderTab.getInsurerDocuments(customerId)
        )
        return result.data.insurerDocuments
      } catch (error) {
        throw error
      }
    }
  ),
  uploadOriginalInsurerDocument: async (dto: UploadOriginalInsurerDocumentDto) => {
    try {
      await AxiosService.post(CUSTOMER_URLS.orderTab.uploadOriginalInsurerDocument, dto)
    } catch (error) {
      throw error
    }
  },
  confirmUploadInsurerDocument: async (dto: ConfirmUploadInsurerDocumentDto) => {
    try {
      await AxiosService.post(CUSTOMER_URLS.orderTab.confirmUploadInsurerDocument, dto)
    } catch (error) {
      throw error
    }
  },
  updateOriginalInsurerDocument: async (dto: UpdateOriginalInsurerDocumentDto) => {
    try {
      await AxiosService.put(CUSTOMER_URLS.orderTab.updateOriginalInsurerDocument, dto)
    } catch (error) {
      throw error
    }
  },
  approveInsurerDocument: async (dto: ApproveInsurerDocumentDto) => {
    try {
      await AxiosService.put(CUSTOMER_URLS.orderTab.approveInsurerDocument, dto)
    } catch (error) {
      throw error
    }
  },
  cancelInsurerDocument: async (dto: CancelInsurerDocumentDto) => {
    try {
      await AxiosService.post(CUSTOMER_URLS.orderTab.cancelInsurerDocument, dto)
    } catch (error) {
      throw error
    }
  },
  getCanAccessPaymentAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getCanAccessPayment'),
    async (customerId: string) => {
      try {
        const result = await AxiosService.get<GetCanAccessPaymentResponse>(
          CUSTOMER_URLS.orderTab.getCanAccessPayment(customerId)
        )
        return result.data.isAlreadyHavePayment
      } catch (error) {
        throw error
      }
    }
  ),
  getPaymentBanksMasterDataAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getPaymentBanksMasterData'),
    async () => {
      try {
        const result = await AxiosService.get<GetPaymentBanksMasterDataResponse>(
          CUSTOMER_URLS.masterData.getPaymentBanksMasterData
        )
        return result.data.paymentBanks
      } catch (error) {
        throw error
      }
    }
  )
}
