import * as yup from 'yup'

const yupTaxOrPersonalIDValidation = (label: string) =>
  yup
    .string()
    .trim()
    .matches(/^[0-9]{13}$/, label)

export const YupValidation = {
  telNumber: yup
    .string()
    .trim()
    .test('telNumber', 'กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง', (value) => {
      if (!value) return true
      const phoneNumberRegex = /^(0([0-9]{9}))$/
      const homeTelNumberRegex = /^(02([0-9]{7}))$/
      const isPhoneNumberValid = phoneNumberRegex.test(value)
      const isHomeTelNumberValid = homeTelNumberRegex.test(value)
      if (isPhoneNumberValid || isHomeTelNumberValid) {
        return true
      }
      return false
    }),
  email: (label?: string) =>
    yup
      .string()
      .trim()
      .email(label ?? 'กรุณากรอกอีเมล์ให้ถูกต้อง'),
  taxId: yupTaxOrPersonalIDValidation('กรุณากรอกเลขประจำตัวผู้เสียภาษีให้ถูกต้อง'),
  personalId: yupTaxOrPersonalIDValidation('กรุณากรอกเลขประจำตัวประชาชนให้ถูกต้อง'),
  bankNumber: yup.number().typeError('กรุณากรอกเลขบัญชีให้ถูกต้อง')
}
