import { Box, styled, Theme } from '@mui/material'

import { COLORS } from '../../../constants/colors'

export const DefaultCardStyle = (theme: Theme) => ({
  borderRadius: theme.spacing(4),
  backgroundColor: COLORS.white,
  boxShadow: `0 0 10px -5px ${COLORS.shadow}`
})

export const BaseCard = styled(Box)(({ theme }) => ({
  ...DefaultCardStyle(theme)
}))

export const TransparencyCard = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(4),
  boxShadow: `0 0 10px -5px ${COLORS.shadow}`,
  backgroundColor: COLORS.whiteTransparent,
  padding: theme.spacing(8)
}))

export const BorderCard = styled(Box)(({ theme }) => ({
  border: `1px solid ${COLORS.grey600}`,
  borderRadius: theme.spacing(4)
}))

export const InfoCard = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  border: `1px solid ${COLORS.grey350}`
}))
