import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Language } from '../../../../types/types'
import { transformMasterData } from '../../../../utils/transformMasterData'
import { InsurerService } from '../../services/insurer/InsurerService'
import { InsuranceState } from './types'

const initialDocuments = [{ id: 'mockup-1', name: '', order: 1 }]

const initialState: InsuranceState = {
  // Responses
  insurers: [],
  insurer: undefined,
  plans: undefined,
  insurersMasterData: [],
  insurersWithShortNameMasterData: [],
  insuranceCompaniesMasterData: [],
  // Store
  insurerImage: null,
  documents: { th: initialDocuments, en: initialDocuments },
  isActive: true,
  insurerInsurancePlanPagination: { pageSize: 10, page: 1 }
}

const slice = createSlice({
  name: 'insurer',
  initialState,
  reducers: {
    onChangeDocuments: (
      state,
      action: PayloadAction<{
        language: Language
        id: string
        name: string
      }>
    ) => {
      const language = action.payload.language
      const id = action.payload.id
      const name = action.payload.name
      const currentIndex = state.documents[language].findIndex((document) => document.id === id)
      state.documents[language][currentIndex].name = name
    },
    toggleDocument: (
      state,
      action: PayloadAction<{
        order: number
        isEnable: boolean
      }>
    ) => {
      const order = action.payload.order
      const isEnable = action.payload.isEnable
      const initialDocument = { id: `mockup-${order}`, name: '', order }
      if (isEnable) {
        state.documents[Language.TH].push(initialDocument)
        state.documents[Language.EN].push(initialDocument)
      } else {
        if (state.documents.en.length > 1) {
          const findIndex = state.documents[Language.EN].findIndex((document) => document.order === order)
          state.documents[Language.TH].splice(findIndex, 1)
          state.documents[Language.EN].splice(findIndex, 1)
          state.documents[Language.TH] = state.documents[Language.TH].map((document) =>
            document.order > order
              ? {
                  ...document,
                  order: document.order - 1,
                  id: document.id.includes('mockup') ? `mockup-${document.order - 1}` : document.id
                }
              : document
          )
          state.documents[Language.EN] = state.documents[Language.EN].map((document) =>
            document.order > order
              ? {
                  ...document,
                  order: document.order - 1,
                  id: document.id.includes('mockup') ? `mockup-${document.order - 1}` : document.id
                }
              : document
          )
        } else if (order === 1) {
          state.documents[Language.TH][0].name = ''
          state.documents[Language.TH][0].name = ''
        }
      }
    },
    toggleInsurerActive: (state) => {
      state.isActive = !state.isActive
    },
    setInsurerImage: (state, action: PayloadAction<File>) => {
      state.insurerImage = action.payload
    },
    changeInsurerInsurancePlanPagination: (state, action: PayloadAction<{ key: string; value: number }>) => {
      const { key, value } = action.payload

      switch (key) {
        case 'pageSize':
          state.insurerInsurancePlanPagination = { ...state.insurerInsurancePlanPagination, pageSize: value, page: 1 }
          break
        case 'page':
          state.insurerInsurancePlanPagination = { ...state.insurerInsurancePlanPagination, page: value }
          break
        default:
          break
      }
    },
    resetInsurerState: (state) => {
      // Responses
      state.insurers = []
      state.insurer = undefined
      state.plans = undefined
      state.insurersMasterData = []
      state.insurersWithShortNameMasterData = []
      state.insuranceCompaniesMasterData = []
      // Store
      state.insurerImage = null
      state.documents = { th: initialDocuments, en: initialDocuments }
      state.isActive = true
    },
    resetInsurerPagination: (state) => {
      state.insurerInsurancePlanPagination = { pageSize: 10, page: 1 }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(InsurerService.getAllInsurersAsyncThunk.fulfilled, (state, action) => {
        state.insurers = action.payload
      })
      .addCase(InsurerService.getAllPlansByInsurerIdAsyncThunk.fulfilled, (state, action) => {
        state.plans = action.payload
      })
      .addCase(InsurerService.getInsurerInfoByIdAsyncThunk.fulfilled, (state, action) => {
        state.insurer = action.payload
        state.isActive = action.payload.isActive!
        state.documents = {
          th: action.payload.documents.map((document) => ({
            id: document.id!,
            name: document.name,
            order: document.order!
          })),
          en: action.payload.documents.map((document) => ({
            id: document.id!,
            name: document.name,
            order: document.order!
          }))
        }
      })
      .addCase(InsurerService.getInsurersMasterDataAsyncThunk.fulfilled, (state, action) => {
        state.insurersMasterData = transformMasterData(action.payload)
      })
      .addCase(InsurerService.getInsuranceCompaniesMasterDataAsyncThunk.fulfilled, (state, action) => {
        state.insuranceCompaniesMasterData = transformMasterData(action.payload)
      })
      .addCase(InsurerService.getInsurersMasterDataWithShortNameAsyncThunk.fulfilled, (state, action) => {
        state.insurersMasterData = transformMasterData(
          action.payload.map((data) => ({ id: data.id, name: data.fullName }))
        )
        state.insurersWithShortNameMasterData = action.payload
      })
  }
})

export const {
  onChangeDocuments,
  toggleDocument,
  setInsurerImage,
  resetInsurerState,
  resetInsurerPagination,
  toggleInsurerActive,
  changeInsurerInsurancePlanPagination
} = slice.actions
export default slice.reducer
