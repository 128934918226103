import { Box, CircularProgress } from '@mui/material'
import React from 'react'

import FlexBenLogo from '../components/project/images/FlexBenLogo'

const OnboardingPage = () => {
  return (
    <Box height='100vh' width='100vw' display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
      <img src={FlexBenLogo} />
      <Box marginTop={6} />
      <CircularProgress size={64} />
    </Box>
  )
}

export default OnboardingPage
