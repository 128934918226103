import 'react-image-crop/dist/ReactCrop.css'
import './ImagePicker.css'

import { Box, BoxProps, styled, Typography } from '@mui/material'
import Compressor from 'compressorjs'
import React, { useEffect, useRef, useState } from 'react'
import ReactCrop, { Crop } from 'react-image-crop'

import { COLORS, COLORS_THEME } from '../../../constants/colors'
import CancelIcon from '../icons/CancelIcon'
import ImageIcon from '../icons/ImageIcon'
import CustomButton from '../interactions/CustomButton'
import CustomDialog from '../interactions/CustomDialog'

interface ImagePickerProps {
  id: string
  // Display State Section
  isEdit?: boolean
  isError?: boolean
  isSelectNew?: boolean
  isRequired?: boolean
  allowFileSuffix?: string[]
  allowFileDimension?: {
    height: number
    width: number
  }
  label?: string
  // State Section
  onSelectFile: any
  previewImage?: string
  // Styling Section
  imageBackgroundSize?: 'cover' | 'contain'
  height?: string
  imagePreviewIconSize?: number
  // Delete Section
  isDisabledRecommendText?: boolean
  isAllowDelete?: boolean
  onDeletePreviewImage?: () => void
}

export interface Image {
  previewImage: undefined | string
  file: any
  isError: boolean
}

export interface StyledBoxProps extends BoxProps {
  isCenter?: boolean
  height: string
  isError?: boolean
  imageBackgroundSize?: 'cover' | 'contain'
}

const ImagePicker = ({
  imageBackgroundSize = 'cover',
  isEdit,
  isRequired,
  isSelectNew,
  height = '230px',
  onSelectFile,
  isDisabledRecommendText,
  label,
  isError = false,
  previewImage,
  allowFileDimension = {
    height: 500,
    width: 500
  },
  imagePreviewIconSize = 32,
  id,
  allowFileSuffix = ['jpg', 'png', 'jpeg']
}: ImagePickerProps) => {
  const [image, setImage] = useState<Image>({
    previewImage: undefined,
    file: undefined,
    isError: false
  })
  const [crop, setCrop] = useState<Crop>({
    width: allowFileDimension.width / 2,
    height: allowFileDimension.height / 2,
    unit: 'px',
    x: 0,
    y: 0
  })
  const [isCropModalOpen, setIsCropModalOpen] = useState(false)

  const imageRef = useRef(null) as any

  const onSelectImage = async (e: any) => {
    if (!e.target.files || e.target.files.length === 0) {
      setImage({
        file: undefined,
        previewImage: undefined,
        isError: true
      })
      return
    }
    const objectUrl = URL.createObjectURL(e.target.files[0])
    const newImage = (await onLoadImage(objectUrl)) as HTMLImageElement
    if (!(newImage.width <= allowFileDimension.width) && !(newImage.height <= allowFileDimension.height)) {
      setIsCropModalOpen(true)
    }
    URL.revokeObjectURL(objectUrl)
    setImage({ ...image, file: e.target.files[0], isError: false })
  }
  const onLoadImage = (src: string) =>
    new Promise((resolve, reject) => {
      const img = new Image()
      img.onload = () => {
        resolve(img)
      }
      img.onerror = reject
      img.src = src
    })
  const cropImageNow = async () => {
    const currentImage = imageRef.current
    if (!currentImage || !crop) return
    const canvas = document.createElement('canvas')
    const scaleX = currentImage.naturalWidth / currentImage.width
    const scaleY = currentImage.naturalHeight / currentImage.height
    canvas.width = crop.width
    canvas.height = crop.height
    const ctx = canvas.getContext('2d')
    if (!ctx) return

    const pixelRatio = window.devicePixelRatio
    canvas.width = crop.width * pixelRatio
    canvas.height = crop.height * pixelRatio
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
    ctx.imageSmoothingQuality = 'high'
    ctx.drawImage(
      currentImage,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    )
    const imageFile = (await new Promise((resolve) => {
      canvas.toBlob((file: any) => {
        if (!file) return
        file.name = 'image.jpeg'
        file.lastModifiedDate = new Date()
        resolve(file)
      }, image.file.type)
    })) as Blob
    const file = new File([imageFile], image.file.name, { type: image.file.type })
    new Compressor(file, {
      quality: 0.7,
      maxHeight: allowFileDimension.height,
      maxWidth: allowFileDimension.width,
      success(result) {
        const newPreviewImageUrl = window.URL.createObjectURL(result)
        const resultFile = new File([result], image.file.name, { type: image.file.type })
        setImage({
          ...image,
          previewImage: newPreviewImageUrl,
          file: resultFile
        })
        setIsCropModalOpen(false)
      }
    })
  }
  const onCloseCropModal = () => {
    setImage({ ...image, file: undefined, previewImage: undefined })
    onSelectFile(undefined)
    setIsCropModalOpen(false)
  }
  const onValidateAndSetImage = async (objectUrl: string) => {
    try {
      setImage({
        ...image,
        previewImage: objectUrl,
        isError: false
      })
      onSelectFile(image.file)
    } catch (error) {
      alert(error)
      setImage({ ...image, file: undefined, previewImage: undefined })
    }
  }

  useEffect(() => {
    setImage({ ...image, isError: isError })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError])

  useEffect(() => {
    if (!image.file) {
      return
    }
    const objectUrl = URL.createObjectURL(image.file)
    onValidateAndSetImage(objectUrl)

    return () => URL.revokeObjectURL(objectUrl)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image.file])

  const renderButtonBox = (buttonLabel?: string) => (
    <StyledButtonBox>
      <ImageIcon color={image.isError ? COLORS_THEME.error : COLORS.white} />
      {image.isError ? (
        <Typography variant='body1' textAlign='center' color={COLORS_THEME.error} marginLeft={2}>
          โปรดเลือกรูปภาพ
        </Typography>
      ) : (
        <Typography variant='body1' color={COLORS.white} marginLeft={2}>
          {buttonLabel || 'เลือกรูป'}
        </Typography>
      )}
    </StyledButtonBox>
  )

  const renderEditImagePicker = () => (
    <StyledBox
      imageBackgroundSize={imageBackgroundSize}
      height={height}
      isError={image.isError}
      style={{
        backgroundImage: `url('${image.previewImage || previewImage}')`
      }}
    >
      {renderButtonBox('เปลี่ยนรูป')}
    </StyledBox>
  )
  const renderSelectNewImagePicker = () => (
    <StyledBox
      imageBackgroundSize={imageBackgroundSize}
      height={height}
      isError={image.isError}
      isCenter={!image.previewImage}
      style={
        image.previewImage
          ? {
              backgroundImage: `url('${image.previewImage}')`
            }
          : {}
      }
    >
      {!image.previewImage && (
        <Box display='flex' justifyContent='center' alignItems='center' height='100%'>
          <ImageIcon size={imagePreviewIconSize} color={COLORS.grey500} />
        </Box>
      )}
      {image.previewImage ? renderButtonBox('เปลี่ยนรูป') : renderButtonBox()}
    </StyledBox>
  )
  const renderDefaultImagePicker = () => (
    <StyledBox
      imageBackgroundSize={imageBackgroundSize}
      height={height}
      style={{
        cursor: 'auto',
        backgroundImage: `url('${previewImage}')`
      }}
    />
  )

  const renderCropModal = () => (
    <Box>
      <Box
        padding={6}
        borderBottom={1}
        borderColor={COLORS.grey350}
        display='flex'
        justifyContent='space-between'
        alignItems='center'
      >
        <Typography variant='h3'>Crop รูปภาพ</Typography>
        <CursorBox onClick={onCloseCropModal}>
          <CancelIcon size={24} />
        </CursorBox>
      </Box>
      <Box padding={6} display='flex' alignItems='center' flexDirection='column'>
        <ReactCrop crop={crop} onChange={setCrop} aspect={allowFileDimension.width / allowFileDimension.height}>
          <img src={image.previewImage} ref={imageRef} />
        </ReactCrop>
        <Box marginTop={4}>
          <CustomButton text='ยืนยัน' onClick={cropImageNow} isDisabled={!crop.height || !crop.width} />
        </Box>
      </Box>
    </Box>
  )

  return (
    <>
      <CustomDialog content={renderCropModal()} isOpen={isCropModalOpen} size='medium' />
      <Box position='relative' width={'100%'}>
        <label htmlFor={id}>
          {isEdit ? renderEditImagePicker() : isSelectNew ? renderSelectNewImagePicker() : renderDefaultImagePicker()}
        </label>
        {(isEdit || isSelectNew) && (
          <input
            id={id}
            type='file'
            onChange={onSelectImage}
            name='img'
            accept='image/png, image/jpeg, image/jpg'
            hidden
            onClick={(event: any) => {
              event.target.value = null
            }}
          />
        )}
        <Typography variant='h6' textAlign='center' fontWeight='bold' marginTop={2}>
          {isRequired && (
            <Typography component='span' variant='inherit' color={COLORS.red400}>
              *{' '}
            </Typography>
          )}
          {label}
        </Typography>
        {!isDisabledRecommendText && (
          <Typography variant='body2' textAlign='center' color={COLORS.grey700} fontWeight='normal' marginTop={3}>
            ไฟล์ที่แนะนำ: {allowFileSuffix.join(' ,').toUpperCase()} <br />
            (ขนาด
            {` ${allowFileDimension.width}x${allowFileDimension.height}`}px.)
          </Typography>
        )}
      </Box>
    </>
  )
}

const CursorBox = styled(Box)(() => ({
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}))

const StyledBox = styled(Box, {
  shouldForwardProp: (props) => !['isCenter', 'isError', 'imageBackgroundSize'].includes(props as string)
})<StyledBoxProps>(({ theme, isCenter, height, isError, imageBackgroundSize }) => ({
  height: height,
  width: '100%',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: isCenter ? 'center' : 'flex-end',

  objectFit: 'contain',
  backgroundSize: imageBackgroundSize,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',

  backgroundColor: COLORS.grey200,
  borderRadius: theme.spacing(2),
  border: `solid 1px ${isError ? theme.palette.error.main : COLORS.grey200}`,
  userSelect: 'none'
}))

const StyledButtonBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',

  padding: theme.spacing(1.5, 0),
  backgroundColor: COLORS.greyTransparent,
  borderBottomLeftRadius: theme.spacing(2),
  borderBottomRightRadius: theme.spacing(2)
}))

export default React.memo(ImagePicker)
