const INACTIVE = { label: 'Inactive', id: 'inactive' }
const ACTIVE = { label: 'Active', id: 'active' }
const VISITOR = { label: 'Visitor', id: 'visitor' }
const LEAD = { label: 'Lead', id: 'lead' }
const VERIFIED = { label: 'Verified', id: 'verified' }
const PLAN_CONFIRMED = { label: 'Plan Confirmed', id: 'planConfirmed' }
const EMP_CONFIRMED = { label: 'Emp Confirmed', id: 'empConfirmed' }
const CURRENT = { label: 'Current', id: 'current' }
const EFFECTIVE = { label: 'Effective', id: 'effective' }
const SUSPENDED = { label: 'Suspended', id: 'suspended' }
const OVERSIXTYFIVE = { label: 'มากกว่า 65 ปี', id: 'overSixtyFive' }
const PENDING = { label: 'อยู่ระหว่างพิจารณา', id: 'pending' }
const APPROVED = { label: 'อนุมัติ', id: 'approved' }
const REJECTED = { label: 'ปฎิเสธ', id: 'rejected' }

export const ISACTIVE_FILTER = [INACTIVE, ACTIVE]
export const CUSTOMER_FILTER = [VISITOR, LEAD, VERIFIED, PLAN_CONFIRMED, EMP_CONFIRMED, CURRENT]
export const FLEX_BENEFIT_FILTER = [PLAN_CONFIRMED, EMP_CONFIRMED, CURRENT]

export const EMPLOYEE_FILTER = [EFFECTIVE, SUSPENDED, OVERSIXTYFIVE]
export const E_CLAIM_FILTER = [PENDING, APPROVED, REJECTED]
export const GENDER_FILTER = [
  { label: 'ชาย', id: 'male' },
  { label: 'หญิง', id: 'female' }
]
export const MEDICAL_STATUS_FILTER = [
  { label: 'ปกติ', id: 'normal' },
  { label: 'ผิดปกติ', id: 'abnormal' }
]
