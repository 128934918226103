import { createAsyncThunk } from '@reduxjs/toolkit'

import { HR_USERS_URL } from '../../../../configs/api'
import { AxiosService } from '../../../../services/api'
import { createThunkName } from '../../../../utils/createThunkname'
import {
  CreateHRUserDto,
  GetAllCustomersForHRUserQueryParams,
  GetAllCustomersForHRUserResponse,
  GetAllHRByCustomerIdRequest,
  GetAllHRUserByCustomerIdResponse,
  GetDepartmentMasterDataResponse,
  GetHRInfoByIdResponse,
  GetSectionMasterDataResponse,
  GetSubCompanyMasterDataResponse,
  UpdateHRUserDto,
  UpdateHRUserStatusDTO
} from './types'

const PREFIX = 'hr-user'

export const HRUserService = {
  getAllCustomerForHRAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getAllCustomerForHR'),
    async (query: GetAllCustomersForHRUserQueryParams) => {
      try {
        const result = await AxiosService.get<GetAllCustomersForHRUserResponse>(HR_USERS_URL.getAllCustomers, {
          params: query
        })
        return result.data
      } catch (error) {
        throw error
      }
    }
  ),
  getAllHRByCustomerIdAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getAllHRByCustomerId'),
    async (req: GetAllHRByCustomerIdRequest) => {
      try {
        const result = await AxiosService.get<GetAllHRUserByCustomerIdResponse>(
          HR_USERS_URL.getAllHRByCustomerId(req.customerId),
          {
            params: req.query
          }
        )
        return result.data
      } catch (error) {
        throw error
      }
    }
  ),
  getHRInfoByIdAsyncThunk: createAsyncThunk(createThunkName(PREFIX, 'getHRInfoById'), async (hrId: string) => {
    try {
      const result = await AxiosService.get<GetHRInfoByIdResponse>(HR_USERS_URL.getHRInfoById(hrId))
      return result.data.hr
    } catch (error) {
      throw error
    }
  }),
  getSubCompanyListMasterDataAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getSubCompanyListMasterData'),
    async (customerId: string) => {
      try {
        const result = await AxiosService.get<GetSubCompanyMasterDataResponse>(
          HR_USERS_URL.masterData.getSubCompanyMasterData(customerId)
        )
        return result.data
      } catch (error) {
        throw error
      }
    }
  ),
  getDepartmentListMasterDataAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getDepartmentListMasterData'),
    async (customerId: string) => {
      try {
        const result = await AxiosService.get<GetDepartmentMasterDataResponse>(
          HR_USERS_URL.masterData.getDepartmentMasterData(customerId)
        )
        return result.data
      } catch (error) {
        throw error
      }
    }
  ),
  getSectionMasterDataAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getSectionMasterData'),
    async (customerId: string) => {
      try {
        const result = await AxiosService.get<GetSectionMasterDataResponse>(
          HR_USERS_URL.masterData.getSectionMasterData(customerId)
        )
        return result.data
      } catch (error) {
        throw error
      }
    }
  ),
  createHRUser: async (dto: CreateHRUserDto) => {
    try {
      await AxiosService.post(HR_USERS_URL.createHR, dto)
    } catch (error) {
      throw error
    }
  },
  updateHRUser: async (dto: UpdateHRUserDto) => {
    try {
      await AxiosService.put(HR_USERS_URL.updateHR, dto)
    } catch (error) {
      throw error
    }
  },
  updateHRUserStatus: async (dto: UpdateHRUserStatusDTO) => {
    try {
      await AxiosService.put(HR_USERS_URL.updateHRStatus, dto)
    } catch (error) {
      throw error
    }
  }
}
