import { styled, TextField } from '@mui/material'
import { TimePicker } from '@mui/x-date-pickers'
import { format, isValid } from 'date-fns'
import React, { useEffect, useState } from 'react'

import { COLORS } from '../../../constants/colors'
import { fontSize } from '../../../themes/themes'

interface CustomTimePickerProps {
  label: string
  customWidth?: string
  isDisabled?: boolean
  isRequired?: boolean
  isError?: boolean
  helperText?: string | false | undefined
  onChange?: (value: string) => void
  minTime?: string
  value?: string
  disabledFuture?: boolean
}

const CustomTimePicker = ({
  customWidth = '100%',
  isDisabled = false,
  isError,
  helperText,
  onChange,
  minTime,
  value,
  label,
  isRequired,
  disabledFuture
}: CustomTimePickerProps) => {
  const [innerValue, setInnerValue] = useState<Date | null>(null)

  const onChangeInnerValue = (value: Date | null) => {
    if (!isValid(value) || value === null) {
      return
    }
    onChange!(format(value, 'HH:mm'))
    setInnerValue(value)
  }

  useEffect(() => {
    if (value) {
      const newTime = new Date(`2020/09/29 ${value}:00`)
      setInnerValue(newTime)
    } else {
      setInnerValue(null)
    }
  }, [value])

  const usedMinTime = minTime ? new Date(`2020.09.29 ${minTime}:00`) : undefined
  const maxTime = disabledFuture ? new Date() : undefined

  return (
    <>
      <TimePicker
        value={innerValue}
        ampm={false}
        disabled={isDisabled}
        minTime={usedMinTime}
        minutesStep={1}
        maxTime={maxTime}
        onChange={onChangeInnerValue}
        renderInput={(params) => (
          <StyledTextField
            {...params}
            required={isRequired}
            InputLabelProps={{ shrink: false }}
            label={label}
            customWidth={customWidth}
            error={isError}
            value={innerValue}
            helperText={helperText}
            inputProps={{ ...params.inputProps, placeholder: '00:00' }}
            disabled={isDisabled}
          />
        )}
      />
    </>
  )
}

const StyledTextField = styled(TextField, {
  shouldForwardProp: (props) => props !== 'customWidth' && props !== 'error'
})<any>(({ theme, error, customWidth, value, disabled }) => ({
  width: customWidth,
  backgroundColor: COLORS.white,
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.spacing(2)
  },
  '& .MuiOutlinedInput-root .MuiInputBase-input': {
    padding: theme.spacing(4.5, 2.5, 1.5, 2.5),
    fontSize: fontSize.body1
  },
  '& .MuiInputBase-root.Mui-disabled': {
    backgroundColor: COLORS.grey200
  },
  '& .MuiInputBase-root .MuiOutlinedInput-notchedOutline, & .MuiInputBase-root.Mui-disabled .MuiOutlinedInput-notchedOutline':
    {
      borderColor: error ? theme.palette.error.main : COLORS.grey300
    },
  '& .MuiInputBase-root:focus .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.error.main
  },
  '& .MuiInputLabel-root': {
    fontSize: value ? fontSize.subtitle1 : fontSize.body1,
    fontWeight: value ? 500 : 'normal',
    color: value && !disabled ? theme.palette.primary.main : COLORS.grey500,
    transform: value
      ? `translate(${theme.spacing(2.5)},${theme.spacing(1.5)})`
      : `translate(${theme.spacing(2.5)},${theme.spacing(3.25)})`
  },
  '& .MuiInputLabel-root .MuiInputLabel-asterisk': {
    color: COLORS.orange500,
    marginRight: theme.spacing(0.5)
  },
  '& .MuiInputLabel-root.Mui-required': {
    display: 'flex',
    flexDirection: 'row-reverse'
  },
  '& .MuiInputLabel-root.Mui-focused': {
    fontSize: fontSize.subtitle1,
    fontWeight: 500,
    color: theme.palette.primary.main,
    transform: `translate(${theme.spacing(2.5)},${theme.spacing(1.5)})`
  }
}))

export default React.memo(CustomTimePicker)
