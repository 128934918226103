import { Box, styled } from '@mui/material'
import React from 'react'
import { Outlet } from 'react-router'

import { BaseCard } from '../components/global/ui/Card'
import AuthBackgroundImage from '../components/project/images/AuthBackgroundImage'
import FlexBenLogo from '../components/project/images/FlexBenLogo'

const AuthLayout = () => {
  return (
    <AuthLayoutContainer>
      <AuthCard>
        <AuthTopBox>
          <Logo src={FlexBenLogo} />
        </AuthTopBox>
        <Outlet />
      </AuthCard>
    </AuthLayoutContainer>
  )
}

const Logo = styled('img')({
  width: '204px',
  height: '40px'
})

const AuthCard = styled(BaseCard)(() => ({
  width: '718px',
  overflow: 'hidden'
}))

const AuthTopBox = styled(Box)({
  height: '96px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundImage: 'linear-gradient(to right, #f7faff, #fff)'
})

const AuthLayoutContainer = styled(Box)({
  background: `url(${AuthBackgroundImage})`,
  height: '100vh',
  width: '100vw',
  backgroundSize: 'cover',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
})

export default AuthLayout
