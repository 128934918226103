import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Language } from '../../types/types'
import { LanguageState } from './types'

const initialState: LanguageState = {
  language: Language.TH
}

const slice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLanguage: (state, action: PayloadAction<Language>) => {
      localStorage.setItem('language', action.payload)
      state.language = action.payload
    },
    resetLanguage: (state) => {
      state.language = Language.TH
      localStorage.setItem('language', Language.TH)
    }
  }
})

export const { setLanguage, resetLanguage } = slice.actions
export default slice.reducer
