import { Box, styled } from '@mui/material'
import React from 'react'

import { COLORS } from '../../../constants/colors'
import { theme } from '../../../themes/themes'

type ImageBoxSize = 'small' | 'medium' | 'large'

interface ImageBoxProps {
  imageUrl: string
  noBorder?: boolean
  size?: ImageBoxSize
}

interface StyledBoxProps {
  noBorder: boolean
  imageUrl: string
  size: ImageBoxSize
}

const ImageBox = ({ imageUrl, noBorder = false, size = 'medium' }: ImageBoxProps) => {
  return <StyledBox noBorder={noBorder} imageUrl={imageUrl} size={size} />
}

const StyledBox = styled(Box, {
  shouldForwardProp: (props) => !['noBorder', 'imageUrl', 'size'].includes(props as string)
})<StyledBoxProps>(({ noBorder, imageUrl, size }) => {
  const isMediumSize = size === 'medium'
  const isLargeSize = size === 'large'
  const boxSize = isLargeSize ? '136px' : isMediumSize ? '64px' : '40px'
  const borderRadiusSize = isLargeSize || isMediumSize ? 4 : 2
  return {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    border: noBorder ? 'none' : `1px solid ${COLORS.grey100}`,
    backgroundImage: `url("${imageUrl}")`,
    width: boxSize,
    height: boxSize,
    borderRadius: theme.spacing(borderRadiusSize)
  }
})

export default ImageBox
