import { Autocomplete, AutocompleteProps, Box, Chip, styled, TextField, Typography } from '@mui/material'
import React from 'react'

import { COLORS } from '../../../constants/colors'
import { fontFamily, fontSize } from '../../../themes/themes'

const values = [
  { id: '1', label: 'Starbucks' },
  { id: '2', label: 'Muji Cafe' },
  { id: '3', label: 'https://www.youtube.com/watch?v=gQ6i7YWXrGE' },
  { id: '4', label: 'https://www.google.com/watch?v=gQ6i7YWXrGE' }
]

interface MultipleOptionsSelectInputProps {
  label: string
  value: string[]
  onChange: (value: string[]) => void
  isDisabled?: boolean
  width?: string
  options?: { id: string; label: string }[]
  isLoading?: boolean
  isRequired?: boolean
}

interface StyledAutoCompleteSelectInputProps
  extends AutocompleteProps<{ label: string; id: string } | null, true, true, false> {
  isError?: boolean
  customWidth?: string
}

const MultipleOptionsSelectInput = ({
  isDisabled,
  onChange,
  value,
  width,
  options = values,
  isLoading,
  label,
  isRequired
}: MultipleOptionsSelectInputProps) => {
  const valueObjs = !value
    ? [null]
    : value.map((val) => {
        const newValueObj = options.find((option) => option.id === val)!
        return newValueObj
      })!

  const handleOnChange = (_: any, value: unknown) => {
    if (onChange) onChange!((value as { id: string; label: string }[]).map((val) => val.id))
  }

  return (
    <StyledMultipleValuesAutocomplete
      multiple
      fullWidth={!width}
      customWidth={width}
      disabled={isDisabled}
      loading={isLoading}
      disableClearable
      options={options}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          // eslint-disable-next-line react/jsx-key
          <Chip
            label={
              <Box color={COLORS.purple400} marginRight={2}>
                <Typography variant='h5' fontWeight={800} color='inherit'>
                  {(option as any).label}
                </Typography>
              </Box>
            }
            {...getTagProps({ index })}
          />
        ))
      }
      onChange={handleOnChange}
      getOptionLabel={(option: any) => (option as any).label}
      value={valueObjs}
      renderInput={(params) => (
        <TextField {...params} required={isRequired} InputLabelProps={{ shrink: false }} label={label} />
      )}
    />
  )
}

export const StyledMultipleValuesAutocomplete = styled(Autocomplete, {
  shouldForwardProp: (prop) => prop !== 'isError' && prop !== 'customWidth'
})<StyledAutoCompleteSelectInputProps>(({ theme, isError, customWidth, value }) => ({
  width: customWidth,
  minWidth: '0px',
  '& .MuiOutlinedInput-root': {
    padding: value && value.length > 0 ? theme.spacing(6, 0, 2, 4) : theme.spacing(2, 2, 0, 2),
    backgroundColor: COLORS.white
  },
  // Styling Chip
  '& .MuiInputBase-root .MuiButtonBase-root.MuiChip-root': {
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2),
    marginRight: theme.spacing(2),
    backgroundColor: COLORS.white,
    border: `1px solid ${COLORS.purple400}`,
    height: 'auto'
  },
  '& .MuiInputBase-root.Mui-disabled': {
    backgroundColor: COLORS.grey200,
    color: COLORS.grey200
  },
  '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
    fontSize: fontSize.h6
  },
  '& .MuiInputBase-root .MuiOutlinedInput-notchedOutline, & .MuiInputBase-root.Mui-disabled .MuiOutlinedInput-notchedOutline':
    {
      borderRadius: theme.spacing(2),
      borderColor: isError ? theme.palette.error.main : COLORS.grey300
    },
  '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'black'
  },
  '& .Mui-disabled:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: COLORS.grey300
  },
  '& .Mui-focused:hover .MuiOutlinedInput-notchedOutline, & .Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.primary.main
  },
  '& .MuiFormHelperText-root.Mui-error': {
    fontSize: fontSize.subtitle1,
    fontFamily: fontFamily
  },
  '& .MuiInputLabel-root': {
    fontSize: value && value.length > 0 ? fontSize.body2 : fontSize.h6,
    fontWeight: value && value.length > 0 ? 500 : 'normal',
    color: COLORS.grey500,
    transform:
      value && value.length > 0
        ? `translate(${theme.spacing(2.5)},${theme.spacing(2.5)})`
        : `translate(${theme.spacing(2.5)},${theme.spacing(3.25)})`
  },
  '& .MuiInputLabel-root .MuiInputLabel-asterisk': {
    color: COLORS.orange500,
    marginRight: theme.spacing(0.5)
  },
  '& .MuiInputLabel-root.Mui-required': {
    display: 'flex',
    flexDirection: 'row-reverse'
  },
  '& .MuiInputLabel-root.Mui-focused': {
    fontSize: fontSize.body2,
    fontWeight: 500,
    color: COLORS.grey500,
    transform:
      value && value.length > 0
        ? `translate(${theme.spacing(2.5)},${theme.spacing(2.5)})`
        : `translate(${theme.spacing(2.5)},${theme.spacing(1.5)})`
  }
}))

export default MultipleOptionsSelectInput
