import { RootState } from '../../../../stores/store'

export const isPreviewPlanOpenSelector = (state: RootState) => state.plan.isPreviewPlanOpen
export const isPlanDetailOpenSelector = (state: RootState) => state.plan.isPlanDetailOpen
export const currentPlanIdSelector = (state: RootState) => state.plan.currentPlan
export const bannerTypeSelector = (state: RootState) => state.plan.bannerType
export const planTypesSelector = (state: RootState) => state.plan.planTypes
export const specialCustomersSelector = (state: RootState) => state.plan.specialCustomers
export const previewPlanSelector = (state: RootState) => state.plan.previewPlan
export const isPlanActiveSelector = (state: RootState) => state.plan.isPlanActive
export const insuranceTypesMasterDataSelector = (state: RootState) => state.plan.insuranceTypesMasterData
export const planFileSelector = (state: RootState) => state.plan.planFile
export const generalInfoEditStateSelector = (state: RootState) => state.plan.generalInfoEditState
export const planFilterValuesSelector = (state: RootState) => state.plan.planFilterValues

export const fetchedPlansSelector = (state: RootState) => state.plan.fetchedPlans
export const fetchedPlanInfoSelector = (state: RootState) => state.plan.fetchedPlanInfo
export const fetchedPlanDetailSelector = (state: RootState) => state.plan.fetchedPlanDetail
export const planTimestampDataSelector = (state: RootState) => state.plan.timeStampData
export const insurancePlanSelector = (state: RootState) => state.plan.insurancePlan
