import { createAsyncThunk } from '@reduxjs/toolkit'

import { ROLE_URLS } from '../../../../configs/api'
import { AxiosService } from '../../../../services/api'
import { createThunkName } from '../../../../utils/createThunkname'
import {
  CreateRoleDto,
  DeleteRoleDto,
  GetAllRoleQueryParams,
  GetAllRoleResponse,
  GetPermissionMasterDataResponse,
  GetRoleByIdResponse,
  GetRoleMasterDataResponse,
  UpdateRoleInfoByIdDto,
  UpdateRolePermissionByIdDto
} from './types'

const PREFIX = 'role'

export const RoleService = {
  getAllRoleAsyncThunk: createAsyncThunk(
    createThunkName(PREFIX, 'getAllRole'),
    async (getAllRolesQueryParams: GetAllRoleQueryParams) => {
      try {
        const result = await AxiosService.get<GetAllRoleResponse>(ROLE_URLS.getAllRole, {
          params: getAllRolesQueryParams
        })
        return result.data.roles
      } catch (error) {
        throw error
      }
    }
  ),
  getRoleByIdAsyncThunk: createAsyncThunk(createThunkName(PREFIX, 'getRoleById'), async (id: string) => {
    try {
      const result = await AxiosService.get<GetRoleByIdResponse>(ROLE_URLS.getRoleById(id))
      return result.data.role
    } catch (error) {
      throw error
    }
  }),
  createRole: async (createRoleDto: CreateRoleDto) => {
    try {
      await AxiosService.post(ROLE_URLS.createRole, createRoleDto)
    } catch (error) {
      throw error
    }
  },
  deleteRole: async (deleteRoleDto: DeleteRoleDto) => {
    try {
      await AxiosService.post(ROLE_URLS.deleteRole, deleteRoleDto)
    } catch (error) {
      throw error
    }
  },
  updateRoleInfoById: async (updateRoleInfoByIdDto: UpdateRoleInfoByIdDto) => {
    try {
      await AxiosService.put(ROLE_URLS.updateRoleInfo, updateRoleInfoByIdDto)
    } catch (error) {
      throw error
    }
  },
  updateRolePermissionById: async (updateRolePermissionByIdDto: UpdateRolePermissionByIdDto) => {
    try {
      await AxiosService.put(ROLE_URLS.updateRolePermissions, updateRolePermissionByIdDto)
    } catch (error) {
      throw error
    }
  },
  getPermissionMasterDataAsyncThunk: createAsyncThunk(createThunkName(PREFIX, 'getPermissionMasterData'), async () => {
    try {
      const result = await AxiosService.get<GetPermissionMasterDataResponse>(
        ROLE_URLS.masterData.getPermissionMasterData
      )
      return result.data.permissions
    } catch (error) {
      throw error
    }
  }),
  getRoleMasterDataAsyncThunk: createAsyncThunk(createThunkName(PREFIX, 'getRoleMasterData'), async () => {
    try {
      const result = await AxiosService.get<GetRoleMasterDataResponse>(ROLE_URLS.masterData.getRoleMasterData)
      return result.data.roles
    } catch (error) {
      throw error
    }
  })
}
