import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { transformMasterData } from '../../../../utils/transformMasterData'
import { OrderService } from '../../services/order/OrderService'
import { PurchaseOrderState } from './types'

const initialState: PurchaseOrderState = {
  // State
  customerDocuments: [],
  insurerDocuments: [],
  isQuotationHistoriesLoading: false,
  isSignedQuotationsLoading: false,
  isInsurerDocumentLoading: false,
  isInsurerDocumentMasterDataLoading: false,
  isInsurerInfoLoading: false,
  isFrontChequeLoading: false,
  isInvoiceLoading: false,
  isPayInLoading: false,
  isPaymentLoading: false,
  isSlipLoading: false,
  isPaymentBanksMasterDataLoading: false,
  isFetchedQuotationDetailLoading: false,
  // Response
  paymentMethodsMasterData: [],
  banksMasterData: [],
  paymentBanksMasterData: [],
  fetchedQuotationHistories: [],
  canAccessPayment: false,
  fetchedOriginalQuotationFileUrl: '',
  fetchedCurrentQuotationId: '',
  fetchedSlip: '',
  fetchedPayIn: '',
  fetchedFrontCheque: ''
}

const slice = createSlice({
  name: 'customer/order-info',
  initialState,
  reducers: {
    changeCustomerDocumentFile: (state, action: PayloadAction<{ id: string; file: File | null }>) => {
      const findIndex = state.customerDocuments.findIndex(
        (customerDocument) => customerDocument.id === action.payload.id
      )
      state.customerDocuments[findIndex].file = action.payload.file
    },
    changeInsurerOriginalDocumentFile: (state, action: PayloadAction<{ id: string; file: File | null }>) => {
      const findIndex = state.insurerDocuments.findIndex((insurerDocument) => insurerDocument.id === action.payload.id)
      state.insurerDocuments[findIndex].originalFile = action.payload.file
    },
    changeInsurerDocumentFile: (state, action: PayloadAction<{ id: string; file: File | null }>) => {
      const findIndex = state.insurerDocuments.findIndex((insurerDocument) => insurerDocument.id === action.payload.id)
      state.insurerDocuments[findIndex].file = action.payload.file
    },
    resetPurchaseOrderState: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(OrderService.getCustomerDocumentMasterDataAsyncThunk.fulfilled, (state, action) => {
        state.customerDocuments = transformMasterData(action.payload).map((masterData) => ({
          ...masterData,
          reason: '',
          file: null
        }))
      })
      .addCase(OrderService.getPaymentMethodMasterDataAsyncThunk.fulfilled, (state, action) => {
        state.paymentMethodsMasterData = transformMasterData(action.payload)
      })
      .addCase(OrderService.getBanksMasterDataAsyncThunk.fulfilled, (state, action) => {
        state.banksMasterData = transformMasterData(action.payload)
      })
      .addCase(OrderService.getInsurerDocumentsMasterDataAsyncThunk.pending, (state) => {
        state.isInsurerDocumentMasterDataLoading = true
      })
      .addCase(OrderService.getInsurerDocumentsMasterDataAsyncThunk.fulfilled, (state, action) => {
        state.isInsurerDocumentMasterDataLoading = false
        state.insurerDocuments = transformMasterData(action.payload).map((masterData) => ({
          ...masterData,
          reason: '',
          file: null,
          originalFile: null
        }))
      })
      .addCase(OrderService.getDocumentsAsyncThunk.fulfilled, (state, action) => {
        state.customerDocuments = state.customerDocuments.map((customerDocument) => {
          const currentDocument = action.payload.find((document) => document.documentMasterId === customerDocument.id)
          return {
            label: customerDocument.label,
            id: customerDocument.id,
            customerDocumentId: currentDocument ? currentDocument.customerDocumentId : '',
            updatedAt: currentDocument?.updatedAt,
            reason: currentDocument ? currentDocument.reason : '',
            fileUrl: currentDocument ? currentDocument.fileUrl : '',
            status: currentDocument ? currentDocument.status : '',
            file: null
          }
        })
      })
      .addCase(OrderService.getQuotationHistorysAsyncThunk.pending, (state) => {
        state.isQuotationHistoriesLoading = true
      })
      .addCase(OrderService.getSignedQuotationsAsyncThunk.pending, (state) => {
        state.isSignedQuotationsLoading = true
      })
      .addCase(OrderService.getSignedQuotationsAsyncThunk.rejected, (state) => {
        state.isSignedQuotationsLoading = false
      })
      .addCase(OrderService.getQuotationHistorysAsyncThunk.fulfilled, (state, action) => {
        state.fetchedQuotationHistories = action.payload
        state.isQuotationHistoriesLoading = false
      })
      .addCase(OrderService.getOriginalQuotationFileAsyncThunk.fulfilled, (state, action) => {
        state.fetchedOriginalQuotationFileUrl = action.payload.originalFileUrl
        state.fetchedCurrentQuotationId = action.payload.quotationId
      })
      .addCase(OrderService.getSignedQuotationsAsyncThunk.fulfilled, (state, action) => {
        state.fetchedSignedQuotation = action.payload
        state.isSignedQuotationsLoading = false
      })
      .addCase(OrderService.getInsurerDocumentsAsyncThunk.pending, (state) => {
        state.isInsurerDocumentLoading = true
      })
      .addCase(OrderService.getInsurerDocumentsAsyncThunk.fulfilled, (state, action) => {
        state.isInsurerDocumentLoading = false
        state.insurerDocuments = state.insurerDocuments.map((insurerDocument) => {
          const currentDocument = action.payload.find(
            (document) => document.insurerDocumentMasterId === insurerDocument.id
          )
          return {
            label: insurerDocument.label,
            id: insurerDocument.id,
            reason: currentDocument ? currentDocument.reason : '',
            insurerDocumentId: currentDocument ? currentDocument.insurerDocumentId : '',
            updatedAt: currentDocument ? currentDocument.updatedAt : '',
            fileUrl: currentDocument ? currentDocument.fileUrl : '',
            originalFileUrl: currentDocument ? currentDocument.originalFileUrl : '',
            status: currentDocument ? currentDocument.status : '',
            file: null,
            originalFile: null
          }
        })
      })
      .addCase(OrderService.getInsurerInfoByCustomerIdAsyncThunk.pending, (state) => {
        state.isInsurerInfoLoading = true
      })
      .addCase(OrderService.getInsurerInfoByCustomerIdAsyncThunk.fulfilled, (state, action) => {
        state.isInsurerInfoLoading = false
        state.fetchedInsurerInfo = action.payload
      })
      .addCase(OrderService.getCanAccessPaymentAsyncThunk.fulfilled, (state, action) => {
        state.canAccessPayment = action.payload
      })
      .addCase(OrderService.getPaymentAsyncThunk.pending, (state) => {
        state.isPaymentLoading = true
      })
      .addCase(OrderService.getSlipAsyncThunk.pending, (state) => {
        state.isSlipLoading = true
      })
      .addCase(OrderService.getPayInAsyncThunk.pending, (state) => {
        state.isPayInLoading = true
      })
      .addCase(OrderService.getFrontChequeAsyncThunk.pending, (state) => {
        state.isFrontChequeLoading = true
      })
      .addCase(OrderService.getInvoiceAsyncThunk.pending, (state) => {
        state.isInvoiceLoading = true
      })
      .addCase(OrderService.getPaymentAsyncThunk.fulfilled, (state, action) => {
        state.isPaymentLoading = false
        state.fetchedPayment = action.payload
      })
      .addCase(OrderService.getSlipAsyncThunk.fulfilled, (state, action) => {
        state.isSlipLoading = false
        state.fetchedSlip = action.payload
      })
      .addCase(OrderService.getPayInAsyncThunk.fulfilled, (state, action) => {
        state.isPayInLoading = false
        state.fetchedPayIn = action.payload
      })
      .addCase(OrderService.getFrontChequeAsyncThunk.fulfilled, (state, action) => {
        state.isFrontChequeLoading = false
        state.fetchedFrontCheque = action.payload
      })
      .addCase(OrderService.getInvoiceAsyncThunk.fulfilled, (state, action) => {
        state.isInvoiceLoading = false
        state.fetchedInvoice = action.payload
      })
      .addCase(OrderService.getPaymentBanksMasterDataAsyncThunk.pending, (state) => {
        state.isPaymentBanksMasterDataLoading = true
      })
      .addCase(OrderService.getPaymentBanksMasterDataAsyncThunk.rejected, (state) => {
        state.isPaymentBanksMasterDataLoading = false
      })
      .addCase(OrderService.getPaymentBanksMasterDataAsyncThunk.fulfilled, (state, action) => {
        state.isPaymentBanksMasterDataLoading = false
        state.paymentBanksMasterData = transformMasterData(action.payload)
      })
      .addCase(OrderService.getQuotationDetailAsyncThunk.pending, (state) => {
        state.isFetchedQuotationDetailLoading = true
      })
      .addCase(OrderService.getQuotationDetailAsyncThunk.rejected, (state) => {
        state.isFetchedQuotationDetailLoading = false
      })
      .addCase(OrderService.getQuotationDetailAsyncThunk.fulfilled, (state, action) => {
        state.isFetchedQuotationDetailLoading = false
        state.fetchedQuotationDetail = action.payload
      })
  }
})

export const {
  resetPurchaseOrderState,
  changeCustomerDocumentFile,
  changeInsurerDocumentFile,
  changeInsurerOriginalDocumentFile
} = slice.actions
export default slice.reducer
