import { Avatar, Box, styled, Tooltip, Typography } from '@mui/material'
import { Variant } from '@mui/material/styles/createTypography'
import React from 'react'

import { COLORS } from '../../../../constants/colors'
import { CustomerStatus, MedicalStatus, Status } from '../../../../types/types'
import { convertToCurrency } from '../../../../utils/convertToCurrency'
import { elipsisText } from '../../../../utils/elipsisText'
import CorruptFileIcon from '../../../project/icons/CorruptFileIcon'
import CustomTextField, { CustomInputStyle } from '../../forms/CustomTextField'
import CheckedIcon from '../../icons/CheckedIcon'
import CustomCheckbox from '../../interactions/CustomCheckbox'
import CustomSwitch from '../../interactions/CustomSwitch'
import BadgeStatus from '../BadgeStatus'
import { CustomTableStyle } from './CustomTable'
import {
  CheckBoxTable,
  EmployeeActiveStatus,
  EmployeeTableInfo,
  EmployeeWithFamilyTableInfo,
  InsurancePlanWithLogoInfo,
  QuotationStatus,
  StatusWithActive,
  SwitchTable,
  TableItem,
  TableItemKey,
  TableItemType,
  TextFieldTable
} from './types'

interface StyledInsurerLogoBoxProps {
  insurerLogoUrl: string
}

export const renderCellByType = (
  type: TableItemType,
  content: TableItem[TableItemKey],
  style: CustomTableStyle,
  maxLength?: number,
  prefix?: any
) => {
  const typographyProps =
    style === CustomTableStyle.Tertiary
      ? { variant: 'h6' as Variant, fontWeight: 'normal', color: COLORS.grey700 }
      : style === CustomTableStyle.Secondary
      ? { variant: 'body1' as Variant }
      : { variant: 'h6' as Variant }
  switch (type) {
    case 'employee-active': {
      const employeeActiveStatus = content as EmployeeActiveStatus
      if (employeeActiveStatus === EmployeeActiveStatus.Effective) {
        return <IsActiveDot bgcolor={COLORS.green300} />
      } else if (employeeActiveStatus === EmployeeActiveStatus.OverSixtyFive) {
        return <IsActiveDot bgcolor={COLORS.yellow400} />
      } else if (employeeActiveStatus === EmployeeActiveStatus.Suspended) {
        return <IsActiveDot bgcolor={COLORS.red400} />
      } else {
        return <IsActiveDot bgcolor={COLORS.red400} />
      }
      break
    }
    case 'isActive': {
      const isActive = content as boolean
      return <IsActiveDot bgcolor={isActive ? COLORS.green300 : COLORS.red400} />
    }
    case 'avatar-img': {
      return <Avatar src={content} sx={{ width: 40, height: 40 }} />
    }
    case 'img': {
      return <RoundedAvatar src={content} variant='rounded' />
    }
    case 'big-image': {
      return <RoundedAvatar src={content} variant='rounded' sx={{ width: 105 }} />
    }
    case 'currency': {
      return <Typography {...typographyProps}>{convertToCurrency(content)}</Typography>
    }
    case 'text': {
      if (!maxLength)
        return (
          <Typography {...typographyProps}>{(content === '' || !content) && content !== 0 ? '-' : content}</Typography>
        )
      const elipsisObject = elipsisText(maxLength, (content === '' || !content) && content !== 0 ? '-' : content)
      if (elipsisObject.isElipsis) {
        return (
          <Tooltip
            title={
              <Typography {...typographyProps} color='white'>
                {content}
              </Typography>
            }
          >
            <Typography {...typographyProps}>{elipsisObject.text}</Typography>
          </Tooltip>
        )
      }
      return <Typography {...typographyProps}>{content}</Typography>
    }
    case 'status': {
      const status = content as Status
      if (status === Status.Pending) {
        return <BadgeStatus label='อยู่ระหว่างพิจารณา' color='success' />
      } else if (status === Status.NeedInfo) {
        return <BadgeStatus label='ขอข้อมูลเพิ่มเติม' color='needInfo' />
      } else if (status === Status.Approve) {
        return <BadgeStatus label='อนุมัติ' color='approved' />
      } else if (status === Status.Rejected) {
        return <BadgeStatus label='ปฎิเสธ' color='rejected' />
      } else {
        return <BadgeStatus label='Close' color='info' />
      }
    }
    case 'quotation-status': {
      const status = content as QuotationStatus
      if (status === QuotationStatus.pending) {
        return <BadgeStatus label='รอยืนยัน' color='verified' />
      } else if (status === QuotationStatus.waiting) {
        return <BadgeStatus label='รอลงนาม' color='lead' />
      } else if (status === QuotationStatus.rejected) {
        return <BadgeStatus label='เอกสารไม่ถูกต้อง' color='error' />
      } else if (status === QuotationStatus.accepted) {
        return <BadgeStatus label='สำเร็จ' color='success' />
      } else {
        return <BadgeStatus label='แก้ไขใบเสนอราคา' color='warning' />
      }
    }
    case 'special-label-status': {
      if (content === 'recommend') {
        return <BadgeStatus label='แนะนำ' color='recommend' />
      } else if (content === 'greatValue') {
        return <BadgeStatus label='สุดคุ้ม' color='greatValue' />
      } else if (content === 'popular') {
        return <BadgeStatus label='ยอดนิยม' color='popular' />
      }
      return ''
    }
    case 'employee-info': {
      const employeeInfo = content as EmployeeTableInfo
      return (
        <Box display='flex'>
          <Avatar src={employeeInfo.imageUrl} sx={{ width: 32, height: 32 }} />
          <Box marginLeft={2}>
            <Typography variant='h6'>{employeeInfo.name}</Typography>
            <Typography variant='body2' color={COLORS.grey600}>
              {employeeInfo.employeeLevel}
            </Typography>
          </Box>
        </Box>
      )
    }
    case 'employee-with-family-info': {
      return (
        <Typography {...typographyProps} sx={{ position: 'relative' }} component='span'>
          {prefix}
          {(content as EmployeeWithFamilyTableInfo).name}
        </Typography>
      )
    }
    case 'status-with-active': {
      const statusWithActive = content as StatusWithActive
      const isActive = statusWithActive.isActive
      const renderStatus = () => {
        switch (statusWithActive.status) {
          case CustomerStatus.Visitor: {
            return <BadgeStatus label='Visitor' color='needInfo' />
          }
          case CustomerStatus.Lead: {
            return <BadgeStatus label='Lead' color='lead' />
          }
          case CustomerStatus.Verified: {
            return <BadgeStatus label='Verified' color='verified' />
          }
          case CustomerStatus.Unpaid: {
            return <BadgeStatus label='Unpaid' color='warning' />
          }
          case CustomerStatus.PlanConfirmed: {
            return <BadgeStatus label='Plan Confirmed' color='popular' />
          }
          case CustomerStatus.EmpConfirmed: {
            return <BadgeStatus label='Emp Confirmed' color='popular' />
          }
          case CustomerStatus.Current: {
            return <BadgeStatus label='Current' color='success' />
          }
        }
      }
      return (
        <Box display='flex' alignItems='center'>
          {renderStatus()}
          <Box marginRight={4} />
          <IsActiveDot bgcolor={isActive ? COLORS.green300 : COLORS.red400} />
        </Box>
      )
    }
    case 'insurance-plan-with-logo': {
      const insurancePlanWithLogo = content as InsurancePlanWithLogoInfo
      return (
        <Box display='flex' alignItems='center'>
          {insurancePlanWithLogo.insurerLogoUrl && insurancePlanWithLogo.insuranceName ? (
            <>
              <StyledInsurerLogoBox insurerLogoUrl={insurancePlanWithLogo.insurerLogoUrl} />
              <Box marginLeft={2}>
                <Typography variant='h6' fontWeight='normal' color={CustomTableStyle.Secondary ? COLORS.grey750 : ''}>
                  {insurancePlanWithLogo.insuranceName}
                </Typography>
              </Box>
            </>
          ) : (
            <>
              <CorruptFileIcon size={24} />
              <Box marginLeft={2}>
                <Typography variant='h6' fontWeight='normal' color={CustomTableStyle.Secondary ? COLORS.grey750 : ''}>
                  ยังไม่เลือก
                </Typography>
              </Box>
            </>
          )}
        </Box>
      )
    }
    case 'all-point': {
      return <BadgeStatus label={content} color='point' />
    }
    case 'isDefault': {
      const isDefault = content as boolean
      return isDefault && <CheckedIcon size={16} />
    }
    case 'header-text': {
      return (
        <Typography {...typographyProps} fontWeight={700}>
          {(content === '' || !content) && content !== 0 ? '-' : content}
        </Typography>
      )
    }
    case 'medical-status': {
      const medicalStatus = content as MedicalStatus
      const isNormalStatus = medicalStatus === MedicalStatus.Normal

      return (
        <Box display='flex' alignItems='center'>
          <IsActiveDot bgcolor={isNormalStatus ? COLORS.green300 : COLORS.red350} />
          <Box marginRight={2} />
          <Typography variant='body1' fontWeight={700}>
            {isNormalStatus ? 'ปกติ' : 'ผิดปกติ'}
          </Typography>
        </Box>
      )
    }
    case 'medical-result': {
      const medicalStatus = content.status as boolean
      const medicalResult = content.value

      return (
        <Typography variant='body1' fontWeight={700} color={medicalStatus ? '' : COLORS.red400}>
          {medicalResult ? medicalResult : '-'}
        </Typography>
      )
    }
    case 'text-field': {
      const textField = content as TextFieldTable

      return (
        <CustomTextField
          value={textField.value}
          label={textField.label}
          id={textField.id}
          onChange={textField.onChange}
          isRequired={textField.isRequired}
          isDisabled={textField.isDisabled}
          style={CustomInputStyle.Secondary}
        />
      )
    }
    case 'check-box': {
      const checkBox = content as CheckBoxTable

      return (
        <CustomCheckbox
          label={checkBox.label}
          value={checkBox.value}
          onChange={checkBox.onChange}
          noBorder
          isDisabled={checkBox.isDisabled}
        />
      )
    }
    case 'switch': {
      const switchTable = content as SwitchTable

      return (
        <CustomSwitch
          value={switchTable.value}
          onChange={switchTable.onChange}
          isDisabled={switchTable.isDisabled}
          disabledLabel
        />
      )
    }
    default:
      break
  }
}

const StyledInsurerLogoBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'insurerLogoUrl'
})<StyledInsurerLogoBoxProps>(({ theme, insurerLogoUrl }) => ({
  width: 24,
  height: 24,
  backgroundImage: `url('${insurerLogoUrl}')`,
  backgroundSize: 'cover',
  borderRadius: theme.spacing(1.5),
  border: `solid 0.5px ${COLORS.grey100}`
}))

const dotSize = '12px'
const IsActiveDot = styled(Box)(() => ({
  width: dotSize,
  height: dotSize,
  borderRadius: '50%'
}))

const RoundedAvatar = styled(Avatar)(({ theme }) => ({
  height: 40,
  borderRadius: theme.spacing(2)
}))
