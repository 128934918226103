import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: VisibleState = {
  isFooterVisible: false,
  isScrollTop: true
}

const slice = createSlice({
  name: 'visible',
  initialState,
  reducers: {
    setFooterVisible: (state, action: PayloadAction<boolean>) => {
      state.isFooterVisible = action.payload
    },
    setIsScrollTop: (state, action: PayloadAction<boolean>) => {
      state.isScrollTop = action.payload
    }
  }
})

export const { setFooterVisible, setIsScrollTop } = slice.actions
export default slice.reducer
