import React from 'react'

import { IconProps } from '../../../types/types'

const CorruptFileIcon = ({ size = 16 }: IconProps) => {
  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} xmlns='http://www.w3.org/2000/svg'>
      <g stroke='red' strokeWidth='1.333' fill='none' fillRule='evenodd' strokeLinecap='round' strokeLinejoin='round'>
        <path
          transform={`scale(${size / 16})`}
          d='M5.898 1.333h4.995c.052 0 .103.004.155.01 2.033.14 3.612 1.798 3.619 3.8v5.715c-.008 2.108-1.752 3.813-3.898 3.809H5.898C3.75 14.67 2.007 12.966 2 10.857V5.143c.007-2.109 1.751-3.814 3.898-3.81z'
        />
        <path
          transform={`scale(${size / 16})`}
          d='M14.667 5.143h-3.62v-3.8M6.525 6.667l1.81 1.777m0 0 1.809 1.778m-1.81-1.778-1.81 1.778m1.81-1.778 1.81-1.777'
        />
      </g>
    </svg>
  )
}

export default CorruptFileIcon
